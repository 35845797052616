import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';

export type ClientOptions = {
	throw?: boolean;
	error?: string;
	success?: string;
};

export type ClientResult<T> = {
	loaded: boolean;
	working: boolean;
	error: Error | null;
	data?: T;
};

export default function useClient<T>(options: ClientOptions = {}) {
	const snackbar = useSnackbar();
	const {
		throw: shouldThrow = false,
		error: errorMessage,
		success: successMessage,
	} = options;

	const [state, setState] = useState<ClientResult<T>>({
		loaded: false,
		working: false,
		error: null,
		data: undefined,
	});

	const execute = useCallback(
		async (fn: () => Promise<any>): Promise<void> => {
			if (!state.working) {
				setState({
					loaded: false,
					working: true,
					error: null,
					data: undefined,
				});
				try {
					const response = await fn();
					if (successMessage && snackbar) {
						snackbar.enqueueSnackbar(`${successMessage}`, {
							variant: 'success',
						});
					}
					setState({
						loaded: true,
						working: false,
						error: null,
						data: response,
					});
				} catch (error) {
					if (errorMessage && snackbar) {
						snackbar.enqueueSnackbar(`${errorMessage}: ${error}`, {
							variant: 'error',
						});
					}
					setState({
						loaded: true,
						working: false,
						error: error as Error,
						data: undefined,
					});
					if (shouldThrow === true) {
						throw error;
					}
				}
			}
		},
		[errorMessage, shouldThrow, snackbar, state.working, successMessage]
	);

	const resetError = () => {
		setState((s) => ({
			...s,
			error: null,
		}));
	};

	return { ...state, execute, resetError };
}
