import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { getLocalStorageItem, setLocalStorageItem } from '../../storage';
import Routes from '../Routes';
import { AppBar, VerticalResize } from '../common';
import { TOOLBAR_HEIGHT } from '../constants';
import { Search } from './Search';
import { Sidebar } from './Sidebar';
import { SidebarButton } from './SidebarButton';
import { SidebarIcons } from './SidebarIcons';
import './common.css';
import { NavigationItems } from './constants';

const SIDEBAR_STORAGE = 'rlinc-sidebar';

type SidebarOptions = {
	open: boolean;
	key: string;
	pinned: boolean;
	width: number;
};

const initialSidebarOptions = getLocalStorageItem<SidebarOptions>(
	SIDEBAR_STORAGE,
	{
		open: true,
		key: '',
		pinned: true,
		width: 350,
	}
);

function App() {
	const navigate = useNavigate();
	const theme = useTheme();
	const { isMobileLayout, isWideLayout } = useAppContext();
	const stackRef = useRef<SnackbarProvider | null>(null);

	const [sidebar, setSidebar] = useState<SidebarOptions>(
		initialSidebarOptions
	);

	useEffect(() => {
		setLocalStorageItem(SIDEBAR_STORAGE, { ...sidebar });
	}, [sidebar]);

	const onClickDismiss = useCallback(
		(key: string) => () => {
			stackRef.current?.closeSnackbar(key);
		},
		[]
	);

	const snackbarAction = useCallback(
		(key: string | number) => {
			return (
				<IconButton color="inherit" onClick={onClickDismiss(`${key}`)}>
					<Icon path={mdiClose} size={1} />
				</IconButton>
			);
		},
		[onClickDismiss]
	);

	const onSidebarButtonClick = () => {
		if (!isWideLayout) {
			setSidebar({
				...sidebar,
				open: !sidebar.open,
				key: !sidebar.open ? '' : sidebar.key,
			});
			// history.push('/');
		} else {
			navigate('/');
		}
	};

	useEffect(() => {
		if (window) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});

			// There is some weird issue that allows the user to move the window viewport off the screen
			// while editing inside an <input> and then does not return the window viewport to 0
			// This is my hack (because I can't figure out the right way to handle this)
			// This may be caused by @material-ui (needs upgraded) or the fact that amplify-ui
			// wraps the whole app and overrides my css
			window.addEventListener('scroll', (event) => {
				try {
					// @ts-ignore
					const active = event.target?.activeElement as HTMLElement;
					if (active) {
						active.addEventListener('blur', () => {
							window.scrollTo({
								top: 0,
								behavior: 'smooth',
							});
						});
					}
				} catch (error) {
					// Do Nothing
				}
			});
		}
	}, []);

	return (
		<SnackbarProvider
			ref={stackRef}
			maxSnack={3}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			preventDuplicate
			action={snackbarAction}
		>
			<AppBar>
				<SidebarButton
					isOpen={sidebar.open}
					onClick={onSidebarButtonClick}
				/>
				{isMobileLayout && <span style={{ flexGrow: 1 }} />}
				{!isMobileLayout && <span style={{ width: '40px' }} />}
				<Search />
			</AppBar>
			<Box data-component="app-row-container" style={{
				position: 'relative',
				display: 'flex',
				flexFlow: 'row nowrap',
				width: '100%',
				height: `calc(100% - ${TOOLBAR_HEIGHT})`,
				// height: '100%',
				flexGrow: 1,
				overflow: 'hidden',
			}}>
				{isWideLayout && (
					<Box data-component="app-sidebar-icons-container" style={{
						position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						width: '90px',
						minWidth: '90px',
						maxWidth: '90px',
						height: '100%',
						overflow: 'hidden',
					}}>
						<SidebarIcons
							selected={sidebar.key}
							onClick={(key) => {
								setSidebar({
									...sidebar,
									key,
									open: true,
								});
							}}
						/>
					</Box>
				)}
				<Box
					data-component="app-sidebar-container"
					style={{
						width: isMobileLayout ? '100%' : `${sidebar.width}px`,
						maxWidth: isMobileLayout ? '100%' : '500px',
						display: sidebar.open ? 'flex' : 'none',
						position: 'relative',
						flexDirection: 'column',
						// height: '100%',
						overflow: 'hidden',
						flexGrow: 1,
						borderRight: `1px solid ${theme.palette.divider}`,
					}}
				>
					<Sidebar
						selected={sidebar.key}
						title={sidebar.key}
						pinned={sidebar.pinned}
						onClick={(key) =>
							setSidebar({ ...sidebar, key, open: true })
						}
						onClose={() =>
							setSidebar({ ...sidebar, open: false, key: '' })
						}
						onTogglePin={() =>
							setSidebar({
								...sidebar,
								pinned: !sidebar.pinned,
							})
						}
						onNavigate={(url: string) => {
							if (!isWideLayout || !sidebar.pinned) {
								setSidebar({
									...sidebar,
									open: false,
									key: '',
								});
							}
							navigate(url);
						}}
						onNavigateKey={(key) => {
							if (key === NavigationItems.HOME) {
								setSidebar({
									...sidebar,
									open: false,
									key: '',
								});
							} else {
								setSidebar({
									...sidebar,
									open: true,
									key,
								});
							}
						}}
					/>
				</Box>
				{!isMobileLayout && (
					<VerticalResize
						enabled={sidebar.open}
						onChange={(x) => {
							const w =
								sidebar.width + x < 200
									? 200
									: sidebar.width + x;
							const nw = w < 300 ? 300 : w > 700 ? 700 : w;
							setSidebar({ ...sidebar, width: nw });
						}}
					/>
				)}
				<Box
					data-component="app-content-container"
					sx={{
						position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						height: '100%',
						overflow: 'hidden',
						width: isMobileLayout ? '0px' : 'initial'
					}}
				>
					<Routes />
				</Box>
			</Box>
		</SnackbarProvider>
	);
}

export default App;
