import React, { useEffect, useState } from 'react';
import { JobSummary } from '../../core';
import { Container } from '../common';
import { JobListItem } from '../jobs';
import { useRecentItems } from '../recent';
import { useAppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';

type CompanyJobListProps = {
	companyId: number;
	onCountChanged: (count: number) => void;
	show: boolean;
};

export const CompanyJobList = (props: CompanyJobListProps) => {
	const navigate = useNavigate();
	const { api } = useAppContext();
	const { addRecentJob } = useRecentItems();
	const { companyId, onCountChanged, show } = props;
	const [jobs, setJobs] = useState<JobSummary[]>([]);

	useEffect(() => {
		if (companyId) {
			api.company.queryJobs(companyId).then(setJobs).catch(console.error);
		}
	}, [api.company, companyId]);

	useEffect(() => {
		onCountChanged(jobs.length);
	}, [jobs.length, onCountChanged]);

	if (!show) return null;

	return (
		<Container component="CompanyJobList" scrollable>
			{jobs.map((job) => (
				<JobListItem
					key={job.JobID}
					job={job}
					onClick={() => {
						addRecentJob({
							id: job.JobID,
							name: job.JobName,
							number: job.JobNumber,
							desc: job.DamageDesc,
						});
						navigate(`/jobs/${job.JobID}`);
					}}
				/>
			))}
		</Container>
	);
};
