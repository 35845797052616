import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { useAppContext } from '../../contexts/AppContext';
import { SearchResult } from '../../core';
import { useRecentItems } from '../recent';

export const Search = () => {
	const { api } = useAppContext();
	const theme = useTheme();
	const navigate = useNavigate();
	const { addRecentJob } = useRecentItems();
	const [query, setQuery] = React.useState('');
	const [queryScope, setQueryScope] = React.useState<'all' | 'open'>('open');
	const [loading, setLoading] = React.useState(false);
	const [options, setOptions] = React.useState<SearchResult[]>([]);

	useDebounce(
		() => {
			if (query) {
				setLoading(true);
				api.search
					.query(query, queryScope)
					.then((results) => {
						setOptions(results);
						setLoading(false);
					})
					.catch(console.error);
			}
		},
		700,
		[query, queryScope]
	);

	const handleOpenJob = (option: SearchResult | null) => {
		if (option) {
			const url = `/jobs/${option.id}`;
			addRecentJob({
				id: option.id,
				name: option.name,
				number: option.number,
				desc: option.description,
			});
			navigate(url);
		}
	};

	return (
		<Autocomplete
			sx={{
				position: 'relative',
				width: 300,
				[theme.breakpoints.down('sm')]: {
					// width: 160,
				},
				[theme.breakpoints.down('xs')]: {
					width: 200,
				},
			}}
			getOptionLabel={(option) => `${option.number}: ${option.name}`}
			options={options}
			loading={loading}
			onChange={(e, value) => handleOpenJob(value)}
			value={null}
			clearOnBlur
			clearOnEscape
			blurOnSelect
			selectOnFocus
			onBlur={() => {
				setQuery('');
				setOptions([]);
			}}
			autoComplete
			freeSolo={false}
			autoHighlight
			size="small"
			filterOptions={(x) => x}
			onInputChange={(_event, newInputValue) => {
				setQuery(newInputValue);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder={
						queryScope === 'all'
							? 'search all jobs'
							: 'search open jobs'
					}
					variant="outlined"
					margin="dense"
					// onChange={(e) => setQuery(e.target.value)}
					// value={query}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						...params.InputProps,
						style: {
							backgroundColor: 'rgba(255,255,255,0.7)',
							padding: theme.spacing(0.3, 1),
							marginTop: theme.spacing(0.5),
						},
						startAdornment: (
							<InputAdornment position="start">
								<Tooltip title="Search All Jobs">
									<Checkbox
										style={{ padding: 0 }}
										value={queryScope}
										checked={queryScope === 'all'}
										onChange={() =>
											setQueryScope(
												queryScope === 'all'
													? 'open'
													: 'all'
											)
										}
										color="primary"
									/>
								</Tooltip>
							</InputAdornment>
						),
						endAdornment: (
							<React.Fragment>
								{loading ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	);
};
