import { mdiFax, mdiPhone } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { formatPhoneNumber, validatePhoneNumber } from '../../core';
import { useWindowOpen } from '../useWindowOpen';

type PhoneCardProps = {
	label?: string;
	phone: string | null | undefined;
	extension?: string | null | undefined;
	sx?: any;
	isCallable?: boolean;
};

export const PhoneCard = (props: PhoneCardProps) => {
	const {
		label = 'Phone Number',
		phone,
		extension,
		isCallable = true,
		sx = {},
	} = props;
	const isValid = phone ? validatePhoneNumber(phone) : true;
	const open = useWindowOpen();

	const handleOpen = () => {
		if (isValid && phone) {
			open(`tel:${phone}`);
		}
	};

	let formatted = '';
	if (phone && !extension) {
		formatted = formatPhoneNumber(phone);
	} else if (phone && extension) {
		formatted = `${formatPhoneNumber(phone)} x: ${extension}`;
	}

	return (
		<TextField
			aria-label={label}
			label={label}
			title={label}
			value={formatted}
			onChange={() => { }}
			onFocus={(e) => e.target.select()}
			error={!isValid}
			variant="outlined"
			margin="dense"
			sx={sx}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Tooltip title="Call Phone Number">
							<span>
								<IconButton
									aria-label="Call Phone Number"
									onClick={handleOpen}
									size="small"
									disabled={!isValid || !isCallable}
								>
									<Icon
										path={isCallable ? mdiPhone : mdiFax}
										size={1}
									/>
								</IconButton>
							</span>
						</Tooltip>
					</InputAdornment>
				),
			}}
		/>
	);
};
