import React from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import { VendorService } from '../../../core';
import { Container, Dialog, ErrorMessage } from '../../common';
import { VendorServiceForm } from './VendorServiceForm';

type State = {
	working: boolean;
	error?: any;
	data: Partial<VendorService>;
};

const initialState: State = { working: false, data: {} };

type Props = {
	companyId: number;
	onServiceAdded: (task: VendorService) => void;
	onClose: () => void;
};

export const AddVendorServiceDialog = ({
	companyId,
	onClose,
	onServiceAdded,
}: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>(initialState);

	const resetData = () => {
		setState(initialState);
	};

	const handleCreateVendorService = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			const data = await api.company.addVendorService(companyId, {
				...state.data,
				VendorID: companyId,
			});
			onServiceAdded(data);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const isValid = !!state.data.ServiceID;

	return (
		<Dialog
			isOpen={true}
			title="Add Vendor Service"
			negativeAction={{
				text: 'Clear',
				onClick: resetData,
				isDisabled: () => state.working || !!state.error,
			}}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
				isDisabled: () => state.working,
			}}
			positiveAction={{
				text: 'Add Vendor Service',
				isDisabled: () => state.working || !!state.error || !isValid,
				onClick: handleCreateVendorService,
			}}
			onClose={onClose}
		>
			<ErrorMessage
				error={state.error}
				onRetry={() => setState((s) => ({ ...s, error: undefined }))}
			/>
			{!state.error && (
				<Container
					component="AddVendorService"
					scrollable
					sx={theme => ({ padding: theme.spacing(2) })}
				>
					<VendorServiceForm
						data={state.data}
						onChanged={(data) => setState((s) => ({ ...s, data }))}
					/>
				</Container>
			)}
		</Dialog>
	);
};
