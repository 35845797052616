import Typography from '@mui/material/Typography';
import React from 'react';
import { Card, ErrorMessage, FlexColumn, FlexRow, Loading } from '.';
import { JobTotals } from '../../core';

type State = {
	working: boolean;
	error?: any;
	data: JobTotals[];
};

const initialState: State = {
	working: true,
	data: [],
};

export type Props = {
	id: number;
	execute: (id: number) => Promise<JobTotals[]>;
};

const COLUMN_WIDTH = 80;

export const JobSummary = ({ id, execute }: Props) => {
	const [state, setState] = React.useState<State>(initialState);

	const handleLoadSummary = React.useCallback(
		async (itemId: number) => {
			setState((s) => ({ ...s, working: true, error: undefined }));
			try {
				const data = await execute(itemId);
				setState((s) => ({ ...s, working: false, data }));
			} catch (error) {
				setState((s) => ({ ...s, working: false, error }));
			}
		},
		[execute]
	);

	React.useEffect(() => {
		if (id) {
			handleLoadSummary(id);
		}
	}, [id, handleLoadSummary]);

	return (
		<Card label="Summary Data" sx={{
			display: 'flex',
			flexDirection: 'column',
		}}>
			<FlexRow nowrap style={{ alignItems: 'center' }}>
				<FlexColumn style={{ flexGrow: 1, padding: '6px' }}>
					<Typography variant="caption">Summary Data</Typography>
				</FlexColumn>
				<FlexColumn
					style={{
						minWidth: `${COLUMN_WIDTH}px`,
						alignItems: 'center',
					}}
				>
					<Typography variant="caption">This Year</Typography>
				</FlexColumn>
				<FlexColumn
					style={{
						minWidth: `${COLUMN_WIDTH}px`,
						alignItems: 'center',
					}}
				>
					<Typography variant="caption">Last Year</Typography>
				</FlexColumn>
				<FlexColumn
					style={{
						minWidth: `${COLUMN_WIDTH}px`,
						alignItems: 'center',
					}}
				>
					<Typography variant="caption">2 Years Ago</Typography>
				</FlexColumn>
			</FlexRow>
			{!state.working &&
				state.data.map((item) => (
					<FlexRow
						key={item.RowSort}
						nowrap
						style={{ alignItems: 'center' }}
					>
						<FlexColumn style={{ flexGrow: 1, padding: '6px' }}>
							<Typography variant="body2">
								{item.RowDesc || 0}
							</Typography>
						</FlexColumn>
						<FlexColumn
							style={{
								minWidth: `${COLUMN_WIDTH}px`,
								alignItems: 'center',
							}}
						>
							<Typography variant="body2">
								{(item.Year1 || 0).toLocaleString('en-US')}
							</Typography>
						</FlexColumn>
						<FlexColumn
							style={{
								minWidth: `${COLUMN_WIDTH}px`,
								alignItems: 'center',
							}}
						>
							<Typography variant="body2">
								{(item.Year2 || 0).toLocaleString('en-US')}
							</Typography>
						</FlexColumn>
						<FlexColumn
							style={{
								minWidth: `${COLUMN_WIDTH}px`,
								alignItems: 'center',
							}}
						>
							<Typography variant="body2">
								{(item.Year3 || 0).toLocaleString('en-US')}
							</Typography>
						</FlexColumn>
					</FlexRow>
				))}
			{state.working && <Loading />}
			<ErrorMessage
				error={state.error}
				onRetry={() => handleLoadSummary(id)}
			/>
		</Card>
	);
};
