import { CompanyData } from './companies';
import {
	ContactData,
	EmailAddressData,
	PhoneNumberData,
	StreetAddressData,
} from './contacts';

export interface ValidationError {
	name: string;
	error: string;
}

export const validateStreetAddress = (
	data?: Partial<StreetAddressData> | null | undefined
): string[] => {
	if (!data) return [];
	const errors: string[] = [];

	if (!data.AddressLine1) {
		errors.push('AddressLine1');
	}
	if (!data.City) {
		errors.push('City');
	}
	if (!data.State) {
		errors.push('State');
	}
	if (!data.ZIP) {
		errors.push('Zip');
	}

	return errors;
};

export const isStreetAddressValid = (
	data?: Partial<StreetAddressData> | null | undefined
): boolean => {
	return validateStreetAddress(data).length === 0;
};

export const isCompanyInformationValid = (
	company?: Partial<CompanyData> | null | undefined
): boolean => {
	if (!company) return false;
	if (!company.CompanyTypeID) return false;
	if (!company.CompanyName) return false;
	// if (!company.CompanyID) return false;
	// if (!company.OfficeName) return false;
	return true;
};

export const isContactInformationValid = (
	contact?: Partial<ContactData> | null | undefined
): boolean => {
	if (!contact) return false;
	if (!contact.ContactTypeID) return false;
	if (!contact.LastName) return false;
	return true;
};

export const validateEmail = (email: string | null | undefined) => {
	if (!email) return false;
	// eslint-disable-next-line no-useless-escape
	const re = /^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email.toLowerCase());
};

export const isEmailInformationValid = (
	email?: Partial<EmailAddressData> | null | undefined
): boolean => {
	if (!email) return false;
	if (email.EmailAddress) {
		if (!email.EmailTypeID) return false;
		if (!validateEmail(email.EmailAddress)) return false;
	}
	return true;
};

export const validatePhoneNumber = (value: string | null | undefined) => {
	if (!value) return false;
	// eslint-disable-next-line no-useless-escape
	const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
	return re.test(value.toLowerCase());
};

export const validatePassword = (value: string | null | undefined) => {
	if (!value) return false;
	// eslint-disable-next-line no-useless-escape
	const re = /[\w-]+_[0-9a-zA-Z]+/gm;
	return re.test(value);
};

export const isPhoneInformationValid = (
	phone?: Partial<PhoneNumberData> | null | undefined
): boolean => {
	if (!phone) return false;
	if (phone.PhoneNumber) {
		if (!phone.PhoneTypeID) return false;
		if (!validatePhoneNumber(phone.PhoneNumber)) return false;
	}
	return true;
};

export const validateUrl = (value: string | null | undefined) => {
	if (!value) return false;
	try {
		const uri = new URL(value);
		return !!uri.hostname;
	} catch (error) {
		return false;
	}
};
