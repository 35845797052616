import { http as client } from './client/HttpApiClient';

import AccountingApi from './accounting';
import AddressApi from './address';
import CompanyApi from './company';
import ContactApi from './contact';
import EmailApi from './email';
import EmployeeApi from './employee';
import JobApi from './job';
import LeadApi from './lead';
import LookupApi from './lookup';
import ManagementApi from './management';
import PermissionApi from './permission';
import PhoneApi from './phone';
import SearchApi from './search';
import UserApi from './user';
import ReportsApi from './reports';
import HealthApi from './health';
import TemplatesApi from './templates';
import TaskTemplatesApi from './taskTemplates';

export const api = {
	accounting: new AccountingApi(client),
	address: new AddressApi(client),
	company: new CompanyApi(client),
	contact: new ContactApi(client),
	email: new EmailApi(client),
	employee: new EmployeeApi(client),
	job: new JobApi(client),
	lead: new LeadApi(client),
	lookup: new LookupApi(client),
	management: new ManagementApi(client),
	permission: new PermissionApi(client),
	phone: new PhoneApi(client),
	search: new SearchApi(client),
	user: new UserApi(client),
	reports: new ReportsApi(client),
	health: new HealthApi(client),
	templates: new TemplatesApi(client),
	taskTemplates: new TaskTemplatesApi(client),
};
