import { mdiCancel, mdiCurrencyUsd, mdiPlusCircle, mdiPrinter } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import {
	JobAccountingData,
	JobAccountingDataItem,
	JobAccountingSalesData,
	JobData,
	JobDetail,
	formatAmount,
	formatCommission,
	formatDate,
	formatDecimal,
} from '../../../core';
import {
	Permissions,
	calculateBalance,
	calculateContractBalance,
	calculateGP,
	calculateTotal,
} from '../../../lib';
import { ApplicationEvent } from '../../ApplicationEvent';
import { AddressCard } from '../../addresses';
import {
	Caption,
	Card,
	ConfirmDialog,
	ErrorDialog,
	InfoDialog,
} from '../../common';
import { PrintInvoiceDialog } from './PrintInvoiceDialog';
import { SellJobDialog, SellMode } from './accounting/SellJobDialog';

type SellJobDialogData = {
	mode: SellMode;
	salesDataId?: number;
};

type Props = {
	job?: JobDetail;
	onChange: (data: Partial<JobData>) => void;
};

export const JobAccounting = ({ job, onChange }: Props) => {
	const theme = useTheme();
	const { api, hasPermission } = useAppContext();
	const hasAccountingPermission = hasPermission([Permissions.AccountingJobs]);
	const canAdminJob = hasPermission([Permissions.AdminJobs]);

	const jobId = job?.JobID;
	const accountingNumber = job?.AccountingSystemNumber;

	const [data, setData] = useState<JobAccountingData>();
	const [dataItems, setDataItems] = useState<JobAccountingDataItem[]>([]);
	const [salesItems, setSalesItems] = useState<JobAccountingSalesData[]>([]);
	const [sellJob, setSellJob] = useState<SellJobDialogData>();
	const [voidSale, setVoidSale] = useState(false);
	const [showPrintInvoiceDialog, setShowPrintInvoiceDialog] = useState(false);
	const [showNoAccountingNumberText, setShowNoAccountingNumberText] =
		useState('');
	const [error, setError] = useState<any>();

	const handleReloadJob = () => {
		setSellJob(undefined);
		if (jobId) {
			console.log('Sending Reload Job');
			ApplicationEvent.ReloadJob(jobId);
		}
	};

	const handleVoidSale = async () => {
		try {
			if (!!job) {
				await api.job.void(job);
				handleReloadJob();
			}
		} catch (error) {
			setVoidSale(false);
			setError(error);
		}
	};

	const loadData = React.useCallback(
		async (id: number, accountNumber?: string) => {
			try {
				const [accounting, data, sales] = await Promise.all([
					api.job.getAccounting(id),
					accountNumber
						? api.job.getAccountingData(id, accountNumber)
						: Promise.resolve([]),
					api.job.getSalesData(id),
				]);
				setData(accounting);
				setDataItems(data);
				setSalesItems(sales);
			} catch (error) {
				console.error(error);
			}
		},
		[api.job]
	);

	const handleSellJobClick = (data: SellJobDialogData) => {
		if (!job?.AccountingSystemNumber) {
			setShowNoAccountingNumberText(
				'Please provide the accounting number first.'
			);
		} else {
			setSellJob(data);
		}
	};

	useEffect(() => {
		if (jobId) {
			loadData(jobId, accountingNumber);
		}
	}, [accountingNumber, jobId, loadData]);

	useEffect(() => {
		const fn = () => {
			if (jobId) {
				console.log('OnReloadJob Received - Job Accounting ');
				loadData(jobId, accountingNumber);
			}
		};
		const unsubscribe = ApplicationEvent.OnReloadJob(fn);
		return () => unsubscribe();
	}, [accountingNumber, jobId, loadData]);

	if (!job) return null;

	const jobTotal = calculateTotal(data);
	const contractBalance = calculateContractBalance(data);
	const balanceDue = calculateBalance(data);
	const grossProfit = calculateGP(data);

	const isJobSold = !!data?.OriginalTotal ? data.OriginalTotal > 0 : false;

	const canPrintInvoice = !!job.AccountingSystemNumber;

	return (
		<Card
			label="Accounting Information"
			actions={[
				{
					key: 'printInvoice',
					label: 'Print Invoice',
					icon: mdiPrinter,
					iconSize: 0.85,
					onClick: () => setShowPrintInvoiceDialog(true),
					visible: canPrintInvoice,
				},
			]}
		>
			<Grid container direction="row" wrap="wrap" spacing={1}>
				<Grid item sx={{ flexGrow: 1 }}>
					<Grid item sx={{ flexGrow: 1 }}>
						<TextField
							label="Accounting #"
							aria-label="Accounting #"
							title="Accounting #"
							value={job.AccountingSystemNumber || ''}
							disabled={!hasAccountingPermission}
							onChange={(e) =>
								onChange({
									...job,
									AccountingSystemNumber: e.target.value,
								})
							}
							InputLabelProps={{ shrink: true }}
							variant="outlined"
							margin="dense"
						/>
					</Grid>
					<Grid item sx={{ flexGrow: 1 }}>
						<TextField
							label="Estimating #"
							aria-label="Estimating #"
							title="Estimating #"
							value={job.EstimateSystemNumber || ''}
							disabled={!hasAccountingPermission}
							onChange={(e) =>
								onChange({
									...job,
									EstimateSystemNumber: e.target.value,
								})
							}
							InputLabelProps={{ shrink: true }}
							variant="outlined"
							margin="dense"
						/>
					</Grid>
				</Grid>
				<Grid item sx={{ flexGrow: 1 }} style={{ paddingTop: theme.spacing(2) }}>
					<AddressCard
						label="Billing Address"
						name={job.BillingAddressName}
						line1={job.BillingAddressLine1}
						line2={job.BillingAddressLine2}
						city={job.BillingAddressCity}
						state={job.BillingAddressState}
						zip={job.BillingAddressZip}
					/>
				</Grid>
			</Grid>

			<Divider sx={{ margin: theme.spacing(2, 0) }} />

			<Grid
				container
				direction="row"
				sx={{ padding: theme.spacing(2) }}
			>
				<Grid item sx={{ flexGrow: 1 }}>
					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Fee
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
								border: '1px solid rgba(0,0,0,0.3)',
								backgroundColor: '#fff',
							}}
						>
							{formatDecimal(data?.FeeAmt)}
						</Grid>
						<Grid item
							sx={{
								maxWidth: '32px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
							}}
						>
							&nbsp;
						</Grid>
					</Grid>
					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Contract Amount
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
								border: '1px solid rgba(0,0,0,0.3)',
								backgroundColor: '#fff',
							}}
						>
							{formatDecimal(data?.OriginalTotal)}
						</Grid>
						<Grid item
							sx={{
								maxWidth: '32px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
							}}
						>
							{!isJobSold && hasAccountingPermission && (
								<Tooltip title="Sell Job">
									<span>
										<IconButton
											size="small"
											aria-label="Sell Job"
											onClick={() =>
												handleSellJobClick({
													mode: 'job',
												})
											}
										>
											<Icon
												path={mdiCurrencyUsd}
												size={0.75}
											/>
										</IconButton>
									</span>
								</Tooltip>
							)}
							{isJobSold && hasAccountingPermission && (
								<Tooltip title="Void Sale">
									<span>
										<IconButton
											size="small"
											aria-label="Void Sale"
											onClick={() => setVoidSale(true)}
										>
											<Icon
												path={mdiCancel}
												size={0.75}
											/>
										</IconButton>
									</span>
								</Tooltip>
							)}
						</Grid>
					</Grid>
					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Supplements
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
								border: '1px solid rgba(0,0,0,0.3)',
								backgroundColor: '#fff',
							}}
						>
							{formatDecimal(data?.SupplementalTotal)}
						</Grid>
						<Grid item
							sx={{
								maxWidth: '32px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
							}}
						>
							{hasAccountingPermission && (
								<Tooltip title="Supplement">
									<span>
										<IconButton
											size="small"
											aria-label="Supplement"
											onClick={() =>
												handleSellJobClick({
													mode: 'supplement',
												})
											}
											disabled={!hasAccountingPermission}
										>
											<Icon
												path={mdiPlusCircle}
												size={0.75}
											/>
										</IconButton>
									</span>
								</Tooltip>
							)}
						</Grid>
					</Grid>
					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Change Orders
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
								border: '1px solid rgba(0,0,0,0.3)',
								backgroundColor: '#fff',
							}}
						>
							{formatDecimal(data?.ChangeOrderTotal)}
						</Grid>
						<Grid item
							sx={{
								maxWidth: '32px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
							}}
						>
							{hasAccountingPermission && (
								<Tooltip title="Change Order">
									<span>
										<IconButton
											size="small"
											aria-label="Change Order"
											onClick={() =>
												handleSellJobClick({
													mode: 'order',
												})
											}
										>
											<Icon
												path={mdiPlusCircle}
												size={0.75}
											/>
										</IconButton>
									</span>
								</Tooltip>
							)}
						</Grid>
					</Grid>
					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Sales Tax
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
								border: '1px solid rgba(0,0,0,0.3)',
								backgroundColor: '#fff',
							}}
						>
							{formatDecimal(data?.SalesTaxTotal)}
						</Grid>
						<Grid item
							sx={{
								maxWidth: '32px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
							}}
						>
							&nbsp;
						</Grid>
					</Grid>

					<Divider
						sx={{
							margin: theme.spacing(2)
						}}
					/>

					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Total Jobs
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
								border: '1px solid rgba(0,0,0,0.3)',
								backgroundColor: '#fff',
							}}
						>
							{jobTotal}
						</Grid>
						<Grid item
							sx={{
								maxWidth: '32px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
							}}
						>
							&nbsp;
						</Grid>
					</Grid>
					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Paid to Date
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
								border: '1px solid rgba(0,0,0,0.3)',
								backgroundColor: '#fff',
							}}
						>
							{formatDecimal(data?.CashReceipt)}
						</Grid>
						<Grid item
							sx={{
								maxWidth: '32px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
							}}
						>
							&nbsp;
						</Grid>
					</Grid>

					<Divider
						sx={{
							margin: theme.spacing(2)
						}}
					/>

					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Contract Balance
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
								border: '1px solid rgba(0,0,0,0.3)',
								backgroundColor: '#fff',
							}}
						>
							{contractBalance}
						</Grid>
						<Grid item
							sx={{
								maxWidth: '32px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
							}}
						>
							&nbsp;
						</Grid>
					</Grid>
				</Grid>
				<Grid item sx={{ flexGrow: 1 }}>
					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Invoiced to Date
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
								border: '1px solid rgba(0,0,0,0.3)',
								backgroundColor: '#fff',
							}}
						>
							{formatDecimal(data?.InvoiceAmount)}
						</Grid>
						<Grid item
							sx={{
								maxWidth: '32px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
							}}
						>
							&nbsp;
						</Grid>
					</Grid>
					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Balance Due
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
								border: '1px solid rgba(0,0,0,0.3)',
								backgroundColor: '#fff',
							}}
						>
							{balanceDue}
						</Grid>
						<Grid item
							sx={{
								maxWidth: '32px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
							}}
						>
							&nbsp;
						</Grid>
					</Grid>

					<Divider
						sx={{
							margin: theme.spacing(2)
						}}
					/>

					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						></Grid>
						<Grid
							item
							sx={{
								minWidth: '80px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right'
							}}
						>
							Estimated
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '80px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								marginLeft: theme.spacing(1),
								textAlign: 'right'
							}}
						>
							Actual
						</Grid>
					</Grid>
					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							Cost
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '80px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								border: '1px solid rgba(0,0,0,0.3)',
								textAlign: 'right',
								backgroundColor: '#fff',
							}}
						>
							{formatDecimal(data?.EstimatedCost)}
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '80px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								marginLeft: theme.spacing(1),
								border: '1px solid rgba(0,0,0,0.3)',
								textAlign: 'right',
								backgroundColor: '#fff',
							}}
						>
							{formatDecimal(data?.ActualCost)}
						</Grid>
					</Grid>
					<Grid container direction="row" sx={{
						margin: theme.spacing(0.5, 0),
						alignItems: 'center',
						flexShrink: 0,
					}}>
						<Grid
							item
							sx={{
								minWidth: '120px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								textAlign: 'right',
							}}
						>
							GP%
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '80px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								border: '1px solid rgba(0,0,0,0.3)',
								textAlign: 'right',
								backgroundColor: '#fff',
							}}
						>
							{grossProfit.estimated}
						</Grid>
						<Grid
							item
							sx={{
								minWidth: '80px',
								padding: theme.spacing(0.5, 1),
								fontSize: theme.typography.caption.fontSize,
								marginLeft: theme.spacing(1),
								border: '1px solid rgba(0,0,0,0.3)',
								textAlign: 'right',
								backgroundColor: '#fff',
							}}
						>
							{grossProfit.actual}
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{salesItems?.length > 0 && (
				<Caption
					id="salesdata"
					text="Sales Data"
					sx={{ marginTop: theme.spacing(2) }}
				/>
			)}

			{salesItems && salesItems?.length > 0 && (
				<Table
					size="small"
					aria-label="Sales Data"
					sx={{ backgroundColor: '#fff' }}
				>
					<TableHead>
						<TableRow>
							<TableCell>Change Type</TableCell>
							<TableCell>Change Description</TableCell>
							<TableCell>Change Amount</TableCell>
							<TableCell>Change Date</TableCell>
							<TableCell>Sent Date</TableCell>
							<TableCell>Approved Date</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{salesItems.map((item) => (
							<TableRow key={`sales_${item.SalesDataID}`}>
								<TableCell>{item.ChangeType}</TableCell>
								<TableCell>{item.ChangeDesc}</TableCell>
								<TableCell>
									{formatAmount(item.ChangeAmt)}
								</TableCell>
								<TableCell>
									{formatDate(item.ChangeDate)}
								</TableCell>
								<TableCell>
									{formatDate(item.SentDate)}
								</TableCell>
								<TableCell>
									{formatDate(item.ApprovedDate)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}

			{canAdminJob && salesItems?.length > 0 && (
				<Caption
					id="commissions"
					text="Commissions"
					sx={{ marginTop: theme.spacing(2) }}
				/>
			)}

			{canAdminJob && salesItems && salesItems?.length > 0 && (
				<Box sx={{ overflowX: 'auto' }}>
					<Table
						size="small"
						aria-label="Commissions"
						style={{ backgroundColor: '#fff' }}
					>
						<TableHead>
							<TableRow>
								<TableCell>Item</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>EST</TableCell>
								<TableCell>MKTING</TableCell>
								<TableCell>DRM</TableCell>
								<TableCell>PM</TableCell>
								<TableCell>LEVEL 5</TableCell>
								<TableCell>LEAD</TableCell>
								<TableCell>ADMIN</TableCell>
								<TableCell>ACCT</TableCell>
								<TableCell>OPEN</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{salesItems.map((item) => (
								<TableRow
									key={`commissions_${item.SalesDataID}`}
									sx={{
										cursor: 'pointer',
										'&:hover': {
											backgroundColor: hasAccountingPermission ? theme.palette.action.hover : undefined,
										},
									}}
									onClick={
										hasAccountingPermission
											? () =>
												handleSellJobClick({
													mode: 'edit',
													salesDataId:
														item.SalesDataID,
												})
											: undefined
									}
								>
									<TableCell>{item.ChangeType}</TableCell>
									<TableCell>
										{formatAmount(item.ChangeAmt)}
									</TableCell>
									<TableCell>
										{formatCommission(item.usr1Rate)}
									</TableCell>
									<TableCell>
										{formatCommission(item.usr9Rate)}
									</TableCell>
									<TableCell>
										{formatCommission(item.usr7Rate)}
									</TableCell>
									<TableCell>
										{formatCommission(item.usr2Rate)}
									</TableCell>
									<TableCell>
										{formatCommission(item.usr3Rate)}
									</TableCell>
									<TableCell>
										{formatCommission(item.usr4Rate)}
									</TableCell>
									<TableCell>
										{formatCommission(item.usr5Rate)}
									</TableCell>
									<TableCell>
										{formatCommission(item.usr6Rate)}
									</TableCell>
									<TableCell>
										{formatCommission(item.usr8Rate)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			)}

			{dataItems?.length > 0 && (
				<Caption
					id="accountingdata"
					text="Accounting Data"
					sx={{ marginTop: theme.spacing(2) }}
				/>
			)}

			{dataItems && dataItems?.length > 0 && (
				<Box sx={{ overflowX: 'auto' }}>
					<Table
						size="small"
						aria-label="Accounting Data"
						style={{ backgroundColor: '#fff' }}
					>
						<TableHead>
							<TableRow>
								<TableCell>Transaction Date</TableCell>
								<TableCell>Transaction Type</TableCell>
								<TableCell>Invoice #</TableCell>
								<TableCell>Cash Receipt</TableCell>
								<TableCell>Deposit Date</TableCell>
								<TableCell>Accounting Date</TableCell>
								<TableCell>Amount</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{dataItems.map((item) => (
								<TableRow key={item.AccountingDataID}>
									<TableCell>
										{formatDate(item.TransactionDate)}
									</TableCell>
									<TableCell>
										{item.TransactionType}
									</TableCell>
									<TableCell>{item.InvoiceNumber}</TableCell>
									<TableCell>{item.CashReceipt}</TableCell>
									<TableCell>
										{formatDate(item.DepositDate)}
									</TableCell>
									<TableCell>
										{formatDate(item.AccountingDate)}
									</TableCell>
									<TableCell>
										{formatAmount(item.Amount)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			)}
			{!!jobId && !!sellJob && (
				<SellJobDialog
					mode={sellJob.mode}
					salesDataId={sellJob.salesDataId || 0}
					jobId={jobId}
					onClose={() => setSellJob(undefined)}
					onSave={handleReloadJob}
				/>
			)}
			{!!voidSale && (
				<ConfirmDialog
					isOpen={true}
					title="Void Sale"
					question="Are you sure you want to void this sale?"
					onCancel={() => setVoidSale(false)}
					onConfirm={handleVoidSale}
				/>
			)}
			{!!showNoAccountingNumberText && (
				<InfoDialog
					isOpen={true}
					title="Missing Accounting Number"
					text={showNoAccountingNumberText}
					onClose={() => setShowNoAccountingNumberText('')}
				/>
			)}
			{!!showPrintInvoiceDialog && data && (
				<PrintInvoiceDialog
					job={job}
					jobTotal={jobTotal}
					balanceDue={balanceDue}
					accountingData={data}
					salesData={salesItems}
					accountingDataItems={dataItems}
					onCancel={() => setShowPrintInvoiceDialog(false)}
				/>
			)}
			{!!error && (
				<ErrorDialog
					error={error}
					onClose={() => setError(undefined)}
				/>
			)}
		</Card>
	);
};
