import React from 'react';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// Monorepo
import { LookupTypes, VendorService } from '../../../core';

// Components
import { AutoCompleteLookup, FlexColumn } from '../../common';

type Props = {
	data: Partial<VendorService>;
	onChanged: (data: Partial<VendorService>) => void;
	style?: React.CSSProperties;
};

export const VendorServiceForm = ({ data, onChanged, style = {} }: Props) => {
	const theme = useTheme();
	return (
		<FlexColumn style={style}>
			<AutoCompleteLookup
				label="Type"
				placeholder="Type"
				lookupType={LookupTypes.ServiceType}
				value={data.ServiceID || null}
				emptyOption={{ value: -1, label: 'None' }}
				error={!data.ServiceID}
				required
				onChange={(value) =>
					onChanged({
						...data,
						ServiceID: !!value && value > 0 ? value : undefined,
					})
				}
			/>
			<TextField
				label="Service Quality"
				aria-label="Service Quality"
				title="Service Quality"
				value={data?.ServiceQuality || ''}
				onChange={(e) =>
					onChanged({ ...data, ServiceQuality: e.target.value })
				}
				onFocus={(e) => e.target.select()}
				variant="outlined"
				margin="dense"
				fullWidth
				InputLabelProps={{ shrink: true }}
			/>
			<TextField
				label="Service Capacity"
				aria-label="Service Capacity"
				title="Service Capacity"
				value={data?.ServiceCapacity || ''}
				onChange={(e) =>
					onChanged({ ...data, ServiceCapacity: e.target.value })
				}
				onFocus={(e) => e.target.select()}
				variant="outlined"
				margin="dense"
				fullWidth
				InputLabelProps={{ shrink: true }}
			/>
			<FormControlLabel
				label="ERS"
				style={{ padding: theme.spacing(0, 2) }}
				control={
					<Checkbox
						edge="start"
						value={data.isERS}
						checked={data.isERS}
						onChange={(_e, checked) =>
							onChanged({ ...data, isERS: checked })
						}
						color="primary"
					/>
				}
			/>
		</FlexColumn>
	);
};
