import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

// Monorepo
import {
	CompanyAddressViewData,
	ContactAddressViewData,
	PrimaryStreetAddressData,
} from '../../core';

// Components
import { useAppContext } from '../../contexts/AppContext';
import { Dialog, Loading } from '../common';
import { AddressForm } from './AddressForm';

export type Props = {
	parentId: number;
	parentType: 'Company' | 'Contact';
	autoComplete?: boolean;
	onClose: () => void;
	onAddressAdded: (
		address: ContactAddressViewData | CompanyAddressViewData
	) => void;
};

export const AddAddressDialog = ({
	parentId,
	parentType,
	onClose,
	onAddressAdded,
	autoComplete,
}: Props) => {
	const theme = useTheme();
	const { api } = useAppContext();
	const [busy, setBusy] = useState(false);
	const [address, setAddress] = useState<Partial<PrimaryStreetAddressData>>({});

	const isValid =
		!!address.AddressTypeID &&
		!!address.AddressLine1 &&
		!!address.City &&
		!!address.State &&
		!!address.ZIP;

	const handleAddAddress = async () => {
		setBusy(true);
		try {
			const added =
				parentType === 'Company'
					? await api.company.addAddress(parentId, address)
					: await api.contact.addAddress(parentId, address);
			onAddressAdded(added);
			onClose();
		} catch (error) {
			console.error(error);
			setBusy(false);
		}
	};

	return (
		<Dialog
			isOpen={true}
			title="Add Address"
			negativeAction={{
				text: 'Clear',
				isDisabled: false,
				onClick: () => setAddress({}),
			}}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
			}}
			positiveAction={{
				text: 'Add Address',
				isDisabled: () => busy || !isValid,
				onClick: handleAddAddress,
			}}
			onClose={onClose}
		>
			<Box
				data-component="AddAddressContent"
				sx={{
					backgroundColor: '#fff',
					padding: theme.spacing(2),
					// @ts-ignore
					'& .MuiOutlinedInput-root': {
						backgroundColor: '#fff',
					},
				}}
			>
				{busy && <Loading />}
				{!busy && (
					<AddressForm
						address={address}
						onChanged={(data) => setAddress(data)}
						autoComplete={autoComplete}
					/>
				)}
			</Box>
		</Dialog>
	);
};
