import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { useDebounce } from 'react-use';
import Icon from '@mdi/react';
import { mdiLinkVariant } from '@mdi/js';
import { validateUrl } from '../../core';
import { useWindowOpen } from '../useWindowOpen';

type LinkCardProps = {
	label?: string;
	defaultValue: string | null | undefined;
	readOnly?: boolean;
	onChange: (value: string | null | undefined) => void;
	className?: string;
	style?: React.CSSProperties;
};

export const LinkCard = (props: LinkCardProps) => {
	const {
		label = 'Link',
		defaultValue,
		readOnly = false,
		onChange,
		className,
		style = {},
	} = props;
	const [value, setValue] = useState(defaultValue);
	const isValid = value ? validateUrl(value) : true;
	const open = useWindowOpen();

	useDebounce(
		() => {
			if (isValid && value !== defaultValue) {
				onChange(value);
			}
		},
		1000,
		[value]
	);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!readOnly) {
			setValue(e.target.value || null);
		}
	};

	const handleOpen = () => {
		if (isValid && value) {
			open(value);
		}
	};

	return (
		<TextField
			label={label}
			aria-label={label}
			title={label}
			value={value || ''}
			onChange={handleChange}
			onFocus={(e) => e.target.select()}
			error={!isValid}
			variant="outlined"
			margin="dense"
			className={className}
			style={style}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Tooltip title="Open Link">
							<span>
								<IconButton
									aria-label="Open Link"
									onClick={handleOpen}
									size="small"
									disabled={!isValid}
								>
									<Icon path={mdiLinkVariant} size={1} />
								</IconButton>
							</span>
						</Tooltip>
					</InputAdornment>
				),
			}}
		/>
	);
};
