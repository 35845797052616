import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { LookupTypes } from '../../core';
import { AutoCompleteLookup } from '../common';
const defaultJobNoteTypeId = 441;

type AddNoteProps = {
	onAddNote: (text: string, typeId: number) => void;
	onClose: () => void;
};

export const AddNote = (props: AddNoteProps) => {
	const { onAddNote, onClose } = props;
	const [noteText, setNoteText] = useState('');
	const [typeId, setTypeId] = useState<number>(defaultJobNoteTypeId);

	const canSaveNote = !!noteText && typeId > 0;

	const handleAddNote = () => {
		onAddNote(noteText, typeId);
		setNoteText('');
		setTypeId(defaultJobNoteTypeId);
	};

	return (
		<Box sx={theme => ({
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			padding: theme.spacing(1),
		})}>
			<Grid container direction="row" alignItems="center">
				<Grid item style={{ flexGrow: 1 }}>
					<AutoCompleteLookup
						label="Type"
						placeholder="Type"
						lookupType={LookupTypes.NoteType}
						value={typeId || null}
						onChange={(value) =>
							setTypeId(value || defaultJobNoteTypeId)
						}
					/>
				</Grid>
			</Grid>
			<TextField
				id="note"
				label="New Note"
				variant="outlined"
				color="primary"
				margin="none"
				size="small"
				value={noteText}
				multiline
				minRows={5}
				onChange={(e) => setNoteText(e.target.value)}
				sx={theme => ({ margin: theme.spacing(1, 0) })}
				InputLabelProps={{ shrink: true }}
			/>
			<Grid container alignItems="center" justifyContent="flex-end">
				<Grid item>
					<Button size="small" aria-label="Close" onClick={onClose}>
						Cancel
					</Button>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}></Grid>
				<Grid item>
					<Button
						size="small"
						aria-label="Add Note"
						color="secondary"
						disabled={!canSaveNote}
						onClick={handleAddNote}
					>
						Add Note
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};
