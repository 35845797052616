import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { JobTaskWithJobData } from '../../core';
import { JobTaskListItem } from '../jobs/detail/JobTaskListItem';
import { Divider, Typography } from '@mui/material';

type Props = {
	mode: 'table' | 'list';
	items: JobTaskWithJobData[];
};

export const JobTaskListForPrint = ({ mode, items }: Props) => {
	return (
		<>
		<Typography variant="h5">Job Tasks</Typography>
		<Divider sx={theme => ({ marginBottom: theme.spacing(2)})} />
		<List>
			{items.map((item) => (
				<ListItem key={item.JobTaskID}>
					<JobTaskListItem
						task={item}
						key={item.JobTaskID}
						mode="table"
						isEmployeeTasks={true}
						forPrinting
					/>
				</ListItem>
			))}
		</List>
		</>
	);
};
