import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useLocation } from 'react-router-dom';
import { JobTaskWithJobData } from '../../core';
import { JobTaskListItem } from '../jobs/detail/JobTaskListItem';
import { ListItemButton } from '@mui/material';

type Props = {
	mode: 'table' | 'list';
	items: JobTaskWithJobData[];
	onOpenJobTask: (task: JobTaskWithJobData) => void;
};

export const JobTaskList = ({ mode, items, onOpenJobTask }: Props) => {
	const location = useLocation();

	return (
		<List>
			{items.map((item) => (
				<ListItemButton
					key={item.JobTaskID}
					onClick={() => onOpenJobTask(item)}
					selected={location.pathname.includes(`/jobs/${item.JobID}`)}
				>
					<ListItem>
						<JobTaskListItem
							task={item}
							key={item.JobTaskID}
							mode={mode}
							isEmployeeTasks={true}
						/>
					</ListItem>
				</ListItemButton>
			))}
		</List>
	);
};
