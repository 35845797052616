import React from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { StreetAddressData } from '../../core';
import { AutoCompleteAsync, AutoCompleteOptionOf, FlexRow } from '../common';
import { useAppContext } from '../../contexts/AppContext';
import FieldBase from "../common/FieldBase";
import { AddressListItem } from "../addresses/AddressListItem";
import { IconButton, Tooltip, useTheme } from '@mui/material';

export type Props = {
	label: string;
	onChange: (address: StreetAddressData | null) => void;
	onAddClick?: () => void;
	parentId?: number;
	parentType: 'Company' | 'Contact',
	typeId?: number;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
};

export const AddressAutoComplete = ({
	label,
	onChange,
	onAddClick,
	required = false,
	disabled = false,
	error = false,
	typeId,
	parentType,
	parentId,
}: Props) => {
	const theme = useTheme();
	const { api } = useAppContext();

	const selectedValue: number | null = null;
	const [results, setResults] = React.useState<StreetAddressData[]>([]);

	const handleSearch = async (
		search: string
	): Promise<AutoCompleteOptionOf<number | string>[]> => {
		try {
			const addresses = await api.address.query({
				search,
				parentId,
				parentType,
				// typeId,
				limit: 200,
				offset: 0,
			});
			setResults(addresses);
			return addresses.map((x) => ({
				value: x.AddressID,
				label: x.AddressLine1,
			}));
		} catch (error) {
			console.error(error);
			return [{ value: 0, label: 'Error searching addresses' }];
		}
	};

	return (
		<FieldBase>
			<FlexRow nowrap style={{ width: '100%', alignItems: 'center' }}>
				<AutoCompleteAsync
					label={label}
					autoOpen={false}
					required={required}
					disabled={disabled}
					delay={700}
					fixed={true}
					renderOption={(props, option) => (
						<AddressListItem props={props} style={{ paddingLeft: theme.spacing(1) }} address={results.find((x) => x.AddressID === option?.value)} />
					)}
					error={error}
					value={selectedValue}
					onLoadAsync={handleSearch}
					onChange={(value) => onChange(results.find((x) => x.AddressID === value) || null)}
				/>
				{!!onAddClick && (
					<Tooltip title="Add Address">
						<IconButton size="small" style={{ marginLeft: '8px' }} onClick={onAddClick}>
							<Icon path={mdiPlus} size={1} />
						</IconButton>
					</Tooltip>
				)}
			</FlexRow>
		</FieldBase>
	);
};
