import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CompanyData, LookupTypes } from '../../core';
import { Toolbar } from '../common';
import { useLookupTypes } from '../lookups';
import { useRecentItems } from '../recent';
import { AddCompany } from './AddCompany';
import { useAddCompany } from './useAddCompany';

export const AddCompanyContainer = () => {
	const { addRecentCompany } = useRecentItems();
	const [companyTypes] = useLookupTypes([LookupTypes.CompanyType]);
	const controller = useAddCompany();
	const navigate = useNavigate();

	const { working, isCompanyValid, error } = controller;

	const handleOpenCompany = React.useCallback(
		async (company: CompanyData) => {
			addRecentCompany({
				id: company.CompanyID,
				name: company.CompanyName,
				type:
					companyTypes?.find(
						(x: any) => x.ID === company.CompanyTypeID
					)?.Name || '',
			});
			navigate(`/companies/${company.CompanyID}`);
		},
		[addRecentCompany, companyTypes, navigate]
	);

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			height: '100%',
			overflow: 'hidden',
		}}>
			<Toolbar>
				<h4 style={{ flexGrow: 1 }}>Add New Company</h4>
				<Button
					variant="contained"
					color="secondary"
					disabled={working || !!error || !isCompanyValid}
					onClick={() => controller.createCompany()}
				>
					Create Company
				</Button>
			</Toolbar>
			<AddCompany
				controller={controller}
				onCompanyAdded={handleOpenCompany}
			/>
		</Box>
	);
};
