import { mdiPlusCircleOutline } from '@mdi/js';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import { LookupTypes, VendorService } from '../../../core';
import { Card, ErrorMessage, FlexColumn, Loading } from '../../common';
import { useLookupTypes } from '../../lookups';
import { AddVendorServiceDialog } from './AddVendorServiceDialog';
import { EditVendorServiceDialog } from './EditVendorServiceDialog';

type State = {
	working: boolean;
	error?: any;
	data: VendorService[];
};

const initialState: State = {
	working: true,
	data: [],
};

export type Props = {
	companyId: number;
	canEdit?: boolean;
};

export const VendorServices = ({ companyId, canEdit }: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>(initialState);
	const [serviceTypes] = useLookupTypes([LookupTypes.ServiceType]);
	const [editService, setEditService] = React.useState<VendorService>();
	const [showAddVendorService, setShowAddVendorService] =
		React.useState(false);

	const handleLoadData = React.useCallback(
		async (id: number) => {
			setState((s) => ({ ...s, working: true, error: undefined }));
			try {
				const data = await api.company.queryVendorServices(id);
				setState((s) => ({ ...s, working: false, data }));
			} catch (error) {
				setState((s) => ({ ...s, working: false, error }));
			}
		},
		[api.company]
	);

	const handleVendorServiceAdded = (data: VendorService) => {
		setState((s) => {
			const services = [...s.data];
			services.push(data);
			return { ...s, data: services };
		});
		setShowAddVendorService(false);
	};

	const handleServiceUpdated = (data: VendorService) => {
		setState((s) => {
			const services = [...s.data];
			const index = services.findIndex(
				(x) => x.VendorServiceID === data.VendorServiceID
			);
			if (index !== -1) {
				services[index] = data;
			}
			return { ...s, data: services };
		});
		setEditService(undefined);
	};

	const handleServiceDeleted = (data: VendorService) => {
		setState((s) => {
			const services = [...s.data];
			const index = services.findIndex(
				(x) => x.VendorServiceID === data.VendorServiceID
			);
			if (index !== -1) {
				services.splice(index, 1);
			}
			return { ...s, data: services };
		});
		setEditService(undefined);
	};

	React.useEffect(() => {
		if (companyId) {
			handleLoadData(companyId);
		}
	}, [companyId, handleLoadData]);

	return (
		<Card
			label="Vendor Services"
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
			actions={[
				{
					key: 'addaddress',
					label: 'Add Service',
					icon: mdiPlusCircleOutline,
					onClick: () => setShowAddVendorService(true),
					visible: canEdit,
				},
			]}
		>
			<List disablePadding>
				<ListItem disableGutters>
					<FlexColumn
						fill
						style={{
							minWidth: `150px`,
							marginLeft: '8px',
						}}
					>
						<Typography variant="caption">Service</Typography>
					</FlexColumn>
					<FlexColumn
						fill
						style={{
							width: `40%`,
						}}
					>
						<Typography variant="caption">Quality</Typography>
					</FlexColumn>
					<FlexColumn
						fill
						style={{
							width: `40%`,
						}}
					>
						<Typography variant="caption">Capacity</Typography>
					</FlexColumn>
					<FlexColumn
						style={{
							minWidth: `80px`,
							alignItems: 'center',
						}}
					>
						<Typography variant="caption">ERS</Typography>
					</FlexColumn>
				</ListItem>
			</List>
			<List disablePadding>
				{!state.working &&
					state.data.map((item) => (
						<ListItem
							disableGutters
							key={item.VendorServiceID}
							button
							onClick={() => setEditService(item)}
						>
							<FlexColumn
								fill
								style={{
									minWidth: `150px`,
								}}
							>
								<Typography variant="body2">
									{serviceTypes?.find(
										(x: any) => x.ID === item.ServiceID
									)?.Name || ''}
								</Typography>
							</FlexColumn>
							<FlexColumn
								fill
								style={{
									width: `40%`,
								}}
							>
								<Typography variant="body2">
									{item.ServiceQuality || ''}
								</Typography>
							</FlexColumn>
							<FlexColumn
								fill
								style={{
									width: `40%`,
								}}
							>
								<Typography variant="body2">
									{item.ServiceCapacity || ''}
								</Typography>
							</FlexColumn>
							<FlexColumn
								style={{
									minWidth: `80px`,
									alignItems: 'center',
								}}
							>
								<Typography variant="body2">
									{item.isERS ? 'Yes' : 'No'}
								</Typography>
							</FlexColumn>
						</ListItem>
					))}
			</List>
			{state.working && <Loading />}
			<ErrorMessage
				error={state.error}
				onRetry={() => handleLoadData(companyId)}
			/>
			{!!editService && (
				<EditVendorServiceDialog
					data={editService}
					onServiceUpdated={handleServiceUpdated}
					onServiceDeleted={handleServiceDeleted}
					onClose={() => setEditService(undefined)}
				/>
			)}
			{showAddVendorService && (
				<AddVendorServiceDialog
					companyId={companyId}
					onClose={() => setShowAddVendorService(false)}
					onServiceAdded={handleVendorServiceAdded}
				/>
			)}
		</Card>
	);
};
