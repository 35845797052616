import { fetchAuthSession, getCurrentUser, signIn, signOut } from 'aws-amplify/auth';
import { AuthUser } from '../core';

export interface AuthProvider {
    signIn: (username: string, password: string) => Promise<AuthUser>;
    signOut: () => Promise<void>;
    getUser: () => Promise<AuthUser>;
}

export class CognitoAuthProvider implements AuthProvider {
    constructor() {
        this.getUser = this.getUser.bind(this);
        this.signIn = this.signIn.bind(this);
        this.signOut = this.signOut.bind(this);
        // console.log('cognito-auth:constructor');
    }

    public async getUser(): Promise<AuthUser> {
        try {
            const r = await getCurrentUser();
            const s = await fetchAuthSession();

            const groups = s.tokens?.idToken?.payload['cognito:groups'] as string[] || [];

            const user: AuthUser = {
                username: r.username,
                sub: r.userId,
                groups,
            };

            // console.log('cognito-auth:getUser', user);
            return user;
        } catch (error) {
            throw error;
        }
    }

    public async signIn(username: string, password: string): Promise<AuthUser> {
        try {
            const response = await signIn({
                username,
                password,
            });
            // console.log('cognito-auth:signIn', response);
            if (response.isSignedIn) {
                return this.getUser();
            } else {
                throw new Error('Could not login at this time. Please try again.');
            }
        } catch (error) {
            throw error;
        }
    }

    public async signOut(): Promise<void> {
        try {
            // console.log('cognito-auth:signOut');
            return signOut();
        } catch (error) {
            throw error;
        }
    }
}