import React, { useState } from 'react';
import { PrimaryPhoneNumberData } from '../../core';
import { Dialog } from '../common';
import { PhoneNumberForm } from './PhoneNumberForm';

type EditPhoneNumberDialogProps = {
	isOpen: boolean;
	phoneNumber: PrimaryPhoneNumberData;
	onCancel: () => void;
	onSaveChanges: (data: PrimaryPhoneNumberData) => void;
};

export const EditPhoneNumberDialog = (props: EditPhoneNumberDialogProps) => {
	const { isOpen, phoneNumber, onCancel, onSaveChanges } = props;
	const [isPrimary] = useState(phoneNumber.isPrimary === true);
	const [changes, setChanges] = useState<PrimaryPhoneNumberData>(phoneNumber);

	const handleOnChanged = (data: Partial<PrimaryPhoneNumberData>) => {
		setChanges({ ...changes, ...data });
	};

	return (
		<Dialog
			isOpen={isOpen}
			title="Edit Phone Number"
			cancelAction={{
				text: 'Cancel',
				onClick: onCancel,
			}}
			positiveAction={{
				text: 'Save Changes',
				onClick: () => {
					onSaveChanges(changes);
				},
			}}
			onClose={onCancel}
		>
			<PhoneNumberForm
				required
				phoneNumber={changes}
				onChanged={handleOnChanged}
				disablePrimary={isPrimary}
				style={{ padding: '12px', overflow: 'hidden' }}
			/>
		</Dialog>
	);
};
