import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';
import { JobDetail, UserPermissions } from '../../../core';
import { useS3FileBrowserContext } from '../../browser/S3FileBrowserContext';
import { isMediaFile } from '../../browser/utils';
import { BottomSpace, Container, Sidebar } from '../../common';
import { JobNoteList } from '../../notes/JobNoteList';
import { ChangeState } from '../../useChangeState';
import { DamageDesc } from './DamageDesc';
import { JobAccounting } from './JobAccounting';
import { JobContactList } from './JobContactList';
import { JobFileList } from './JobFileList';
import { JobImageList } from './JobImageList';
import { JobInfo } from './JobInfo';
import { JobOtherFields } from './JobOtherFields';
import { JobReferral } from './JobReferral';
import { JobRoles } from './JobRoles';
import { JobStatus } from './JobStatus';
import { JobTaskList } from './JobTaskList';
import { JobTitle } from './JobTitle';
import { NavSectionBar } from './NavSectionBar';

const RelatedContents = {
	Tasks: 'Tasks',
	Notes: 'Notes',
	Contacts: 'Contacts',
	Images: 'Images',
	Files: 'Files',
	Other: 'Other',
} as const;
type RelatedContent = keyof typeof RelatedContents;

type Props = {
	state: ChangeState<JobDetail>;
	show: boolean;
};

export function JobLayout({ state, show }: Props) {
	const theme = useTheme();
	const { files } = useS3FileBrowserContext();
	const { isWideLayout, hasPermission } = useAppContext();
	const canEditJob = hasPermission([UserPermissions.EditJobs]);
	const canUploadToJob = hasPermission([UserPermissions.ViewJobs]);
	const canDeleteFiles = hasPermission([UserPermissions.AdminJobs]);

	const [relatedContent, setRelatedContent] = useState<RelatedContent>();

	const [taskCount, setTaskCount] = useState(0);
	const [noteCount, setNoteCount] = useState(0);
	const [contactCount, setContactCount] = useState(0);
	const [imageCount, setImageCount] = useState(0);
	const [fileCount, setFileCount] = useState(0);

	const { id } = useParams<{ id: string }>();
	const jobId = Number(id);

	useEffect(() => {
		if (files.length > 0) {
			setImageCount(
				files.filter((x) => x.type !== 'folder' && isMediaFile(x.name))
					.length
			);
			setFileCount(
				files.filter((x) => x.type !== 'folder' && !isMediaFile(x.name))
					.length
			);
		} else {
			setImageCount(0);
			setFileCount(0);
		}
	}, [files]);

	return (
		<Container
			key={state.item?.JobID || 'undefined'}
			component="JobContainer"
			sx={{ display: show ? 'flex' : 'none' }}
		>
			<JobTitle
				job={state.item as JobDetail}
				taskCount={taskCount}
				noteCount={noteCount}
				contactCount={contactCount}
				imageCount={imageCount}
				fileCount={fileCount}
				onRelatedItemClick={(content: string) =>
					setRelatedContent(content as RelatedContent)
				}
			/>
			{!isWideLayout && (
				<NavSectionBar
					jobId={jobId}
					onClose={() => setRelatedContent(undefined)}
				/>
			)}
			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				flexGrow: 1,
				flexShrink: 1,
				height: `calc(100% - ${theme.mixins.toolbar.minHeight
					}px - ${theme.spacing(1)}px)`,
				[theme.breakpoints.down('md')]: {
					height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
				},
				overflow: 'hidden',
			}}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						height: `100%`,
						width: '100%',
						overflowY: 'auto',
						overflowX: 'hidden',
						transition: theme.transitions.create('margin', {
							easing: !!relatedContent ? theme.transitions.easing.easeOut : theme.transitions.easing.sharp,
							duration: !!relatedContent ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
						}),
						marginRight: 0,
						[theme.breakpoints.down('md')]: {
							marginRight: !!relatedContent ? 0 : undefined,
						},
					}}
				>
					<DamageDesc
						canEditJob={canEditJob}
						job={state.item}
						onChange={(data) => state.onChange(data as JobDetail)}
					/>
					<JobInfo
						canEditJob={canEditJob}
						job={state.item}
						onChange={(data) => state.onChange(data as JobDetail)}
					/>
					<JobRoles
						canEditJob={canEditJob}
						job={state.item}
						onChange={(data) => state.onChange(data as JobDetail)}
					/>
					<JobReferral
						canEditJob={canEditJob}
						job={state.item}
						onChange={(data) => state.onChange(data as JobDetail)}
					/>
					<JobStatus
						canEditJob={canEditJob}
						job={state.item}
						onChange={(data) => state.onChange(data as JobDetail)}
					/>
					<JobAccounting
						job={state.item}
						onChange={(data) => state.onChange(data as JobDetail)}
					/>
					<BottomSpace />
				</Box>
				<Sidebar
					variant="persistent"
					anchor="right"
					resizeEnabled
					minWidth={350}
					open={!!relatedContent}
					onClose={() => setRelatedContent(undefined)}
					title={relatedContent}
					tools={null}
					sx={{
						marginLeft: theme.spacing(1),
						[theme.breakpoints.down('md')]: {
							marginLeft: 0,
						},
					}}
				>
					<JobTaskList
						job={state.item}
						onChange={(data) => state.onChange(data as JobDetail)}
						onCountChanged={setTaskCount}
						show={relatedContent === RelatedContents.Tasks}
					/>
					<JobNoteList
						job={state.item}
						onChange={(data) => state.onChange(data as JobDetail)}
						onCountChanged={setNoteCount}
						show={relatedContent === RelatedContents.Notes}
					/>
					<JobContactList
						canEditJob={canEditJob}
						job={state.item}
						onCountChanged={setContactCount}
						show={relatedContent === RelatedContents.Contacts}
					/>
					<JobImageList
						canUploadToJob={canUploadToJob}
						canDelete={canDeleteFiles}
						job={state.item}
						onChange={(data) => state.onChange(data as JobDetail)}
						show={relatedContent === RelatedContents.Images}
					/>
					<JobFileList
						canUploadToJob={canUploadToJob}
						canDelete={canDeleteFiles}
						show={relatedContent === RelatedContents.Files}
					/>
					{relatedContent === RelatedContents.Other && (
						<JobOtherFields
							canEditJob={canEditJob}
							job={state.item}
							onChange={(data) =>
								state.onChange(data as JobDetail)
							}
						/>
					)}
				</Sidebar>
			</Box>
		</Container>
	);
}
