import {
	EmailAddressData,
	EmailSearchOptions,
	stringifySearchOptions,
} from '../core';

import { ApiClientInterface } from './client';

export default class EmailApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
		this.count = this.count.bind(this);
		this.update = this.update.bind(this);
	}

	public async query(
		options: EmailSearchOptions
	): Promise<EmailAddressData[]> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<EmailAddressData[]>(
			`/email?${search}`
		);
		return response;
	}

	public async count(options: EmailSearchOptions): Promise<number> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<number>(
			`/email?${search}&count=true`
		);
		return response;
	}

	public async update(email: EmailAddressData): Promise<EmailAddressData> {
		const response = await this.client.put<
			EmailAddressData,
			EmailAddressData
		>(`/email/${email.EmailID}`, email);
		return response;
	}
}
