import {
	mdiAccountMultipleOutline,
	mdiClipboardCheckOutline,
	mdiClipboardFlowOutline,
	mdiCogs,
	mdiFormatListBulleted,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Box, ListItemButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';

const items = [
	{
		key: 'Users and Groups',
		icon: mdiAccountMultipleOutline,
		url: '/admin/users',
	},
	{
		key: 'Task Template Maintenance',
		icon: mdiClipboardFlowOutline,
		url: '/admin/templates',
	},
	{
		key: 'Task Maintenance',
		icon: mdiClipboardCheckOutline,
		url: '/admin/tasks',
	},
	{ key: 'Lookup Types', icon: mdiFormatListBulleted, url: '/admin/lookups' },
	{ key: 'System Settings', icon: mdiCogs, url: '/admin/settings' },
];

type Props = {
	show: boolean;
};

export function AdminSidebar({ show }: Props) {
	const navigate = useNavigate();
	if (!show) return null;
	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			padding: 0,
		}}>
			<List>
				{items.map((item) => (
					<ListItemButton
						key={item.key}
						onClick={() => navigate(item.url)}
					>
						<ListItem>
							<ListItemIcon>
								<Icon path={item.icon} size={1} color="#555555" />
							</ListItemIcon>
							<ListItemText primary={item.key} />
						</ListItem>
					</ListItemButton>
				))}
			</List>
		</Box>
	);
}
