import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Lib
import { getLocalStorageItem, setLocalStorageItem } from '../storage';
import { RootState } from '.';
import { RecentItem } from '../features/recent/types';

const STORAGE_KEY = 'rlinc-recentitems';
const MAX_ITEMS_COUNT = 10;

interface RecentItemsState {
	items: RecentItem[];
}

const initialState: RecentItemsState = {
	items: getLocalStorageItem(STORAGE_KEY, []),
};

export const recent = createSlice({
	name: 'recent',
	initialState,
	reducers: {
		addRecentItem: (state, action: PayloadAction<RecentItem>) => {
			const item = action.payload;
			const index = state.items.findIndex((x) => x.key === item.key);
			if (index !== -1) {
				state.items.splice(index, 1); // Remove
				state.items.splice(0, 0, item); // Add back at beginning
			} else {
				state.items.splice(0, 0, item);
			}
			if (state.items.length > MAX_ITEMS_COUNT) {
				const count = MAX_ITEMS_COUNT - state.items.length;
				const start = state.items.length - 1 - count;
				state.items.splice(start, count);
			}
			setLocalStorageItem(STORAGE_KEY, state.items);
		},
		removeRecentItems: (state, action: PayloadAction<string[]>) => {
			state.items = state.items.filter(
				(x) => !action.payload.includes(x.key)
			);
			setLocalStorageItem(STORAGE_KEY, state.items);
		},
		clearRecentItems: (state) => {
			state.items = [];
			setLocalStorageItem(STORAGE_KEY, []);
		},
	},
});

// Action creators are generated for each case reducer function
export const { addRecentItem, removeRecentItems, clearRecentItems } =
	recent.actions;

export const selectRecentItems = (state: RootState) => state.recent.items;

export default recent.reducer;
