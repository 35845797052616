import { useTheme } from '@mui/material';
import React from 'react';
import { JobData, JobDetail } from '../../../core';
import { S3FileBrowser } from '../../browser/S3FileBrowser';
import { useS3FileBrowserContext } from '../../browser/S3FileBrowserContext';
import { storage } from '../../browser/StorageProvider';
import { S3BrowserView, S3Object } from '../../browser/types';
import { isMediaFile, toggleS3BrowserView } from '../../browser/utils';
import { Container, ErrorMessage, LinkCard, Loading } from '../../common';
import { useWindowOpen } from '../../useWindowOpen';

type JobImageListProps = {
	canUploadToJob?: boolean;
	canDelete?: boolean;
	job?: JobDetail;
	onChange: (data: Partial<JobData>) => void;
	show: boolean;
};

export const JobImageList = ({
	job,
	onChange,
	show,
	canUploadToJob,
	canDelete,
}: JobImageListProps) => {
	const theme = useTheme();
	const { root, busy, error, resetError } = useS3FileBrowserContext();
	const [view, setView] = React.useState<S3BrowserView>('thumbnail');
	const [folder, setFolder] = React.useState(`${root}Pictures/`);
	const open = useWindowOpen();

	const handleToggleView = () => {
		setView(toggleS3BrowserView(view));
	};

	const handleOpenFile = async (file: S3Object) => {
		const url = await storage.getDownloadUrl(file.key);
		if (url) {
			open(url);
		}
	};

	React.useEffect(() => {
		setFolder(`${root}Pictures/`);
	}, [root]);

	if (!show) return null;

	return (
		<Container component="FileList">
			<LinkCard
				label="Matterport Link"
				defaultValue={job?.UserDefined1 || ''}
				onChange={(val) =>
					onChange({ ...job, UserDefined1: val || '' })
				}
				style={{
					margin: theme.spacing(2, 1, 1, 1),
					display: 'flex',
				}}
			/>
			<ErrorMessage error={error} onRetry={resetError} />
			{busy && <Loading />}
			{!busy && !error && (
				<S3FileBrowser
					view={view}
					folder={folder}
					fileFilter={(files) =>
						files.filter((x) => isMediaFile(x.name))
					}
					readOnly={!canUploadToJob}
					canDelete={canDelete}
					onToggleView={handleToggleView}
					onChangeFolder={(f) => setFolder(f.key)}
					onFileOpen={handleOpenFile}
				/>
			)}
		</Container>
	);
};
