import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// Monorepo
import { PrimaryStreetAddressData } from '../../core';

// Components
import { Dialog, Loading } from '../common';
import { AddressForm } from './AddressForm';
import { api } from '../../api';

export type EditAddressDialogProps = {
	parentId: number;
	parentType: 'Company' | 'Contact';
	defaultAddress: Partial<PrimaryStreetAddressData>;
	autoComplete?: boolean;
	onClose: () => void;
	onAddressUpdated: (address: PrimaryStreetAddressData) => void;
};

export const EditAddressDialog = (props: EditAddressDialogProps) => {
	const theme = useTheme();
	const { parentId, parentType, onClose, onAddressUpdated, autoComplete, defaultAddress } = props;
	const [busy, setBusy] = useState(false);
	const [isPrimary] = useState(defaultAddress.isPrimary === true);
	const [address, setAddress] =
		useState<Partial<PrimaryStreetAddressData>>(defaultAddress);

	const isAddressValid =
		!!address.AddressTypeID &&
		!!address.AddressLine1 &&
		!!address.City &&
		!!address.State &&
		!!address.ZIP;

	const handleUpdateAddress = async () => {
		setBusy(true);
		try {
			if (parentType === 'Company') {
				await api.company.updateAddress(parentId, address as PrimaryStreetAddressData);
			} else {
				await api.contact.updateAddress(parentId, address as PrimaryStreetAddressData);
			}
			onAddressUpdated(address as PrimaryStreetAddressData);
			onClose();
		} catch (error) {
			console.error(error);
			setBusy(false);
		}
	};

	return (
		<Dialog
			isOpen={true}
			title="Edit Address"
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
			}}
			positiveAction={{
				text: 'Save',
				isDisabled: () => busy || !isAddressValid,
				onClick: handleUpdateAddress,
			}}
			onClose={onClose}
		>
			<Box
				data-component="AddEmailAddressContent"
				sx={{
					backgroundColor: '#fff',
					padding: theme.spacing(2),
					// @ts-ignore
					'& .MuiOutlinedInput-root': {
						backgroundColor: '#fff',
					},
				}}
			>
				{busy && <Loading />}
				{!busy && (
					<AddressForm
						address={address}
						onChanged={(data) => setAddress(data)}
						autoComplete={autoComplete}
						disablePrimary={isPrimary}
					/>
				)}
			</Box>
		</Dialog>
	);
};
