import { mdiChevronDown, mdiChevronUp, mdiPlusCircleOutline } from '@mdi/js';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
	ContactAddressViewData,
	PrimaryStreetAddressData,
	StreetAddressData,
} from '../../core';
import { AddAddressDialog, EditAddressDialog } from '../addresses';
import { Card, ConfirmDialog, InfoDialog } from '../common';
import { useWindowOpen } from '../useWindowOpen';
import { AddressListItem } from './AddressListItem';
import { useTheme } from '@mui/material';

type ContactAddressListProps = {
	contactId: number;
	canEdit?: boolean;
};

type DialogAction = {
	title: string;
	text: string;
	action: () => void;
};

export const ContactAddressList = (props: ContactAddressListProps) => {
	const theme = useTheme();
	const { api } = useAppContext();
	const { contactId, canEdit } = props;
	const [addresses, setAddresses] = useState<ContactAddressViewData[]>([]);
	const [confirm, setConfirm] = useState<DialogAction>();
	const [info, setInfo] = useState<DialogAction>();
	const [showAddAddress, setShowAddAddress] = useState(false);
	const [editAddress, setEditAddress] = useState<StreetAddressData>();
	const [count, setCount] = useState(1);
	const open = useWindowOpen();

	const loadAddresses = useCallback(() => {
		api.contact
			.queryAddresses(contactId)
			.then(setAddresses)
			.catch(console.error);
	}, [api.contact, contactId]);

	useEffect(() => {
		loadAddresses();
	}, [contactId, loadAddresses]);

	const handleAddressAdded = useCallback(
		(address: ContactAddressViewData) => {
			setShowAddAddress(false);
			loadAddresses();
		},
		[loadAddresses]
	);

	const handleOnEdit = (data: PrimaryStreetAddressData) => {
		setEditAddress(data as StreetAddressData);
	};

	const handleAddressUpdated = (data: StreetAddressData) => {
		setEditAddress(undefined);
		loadAddresses();
	};

	const handleSetPrimary = useCallback(
		(data: ContactAddressViewData) => {
			if (!data.isPrimary) {
				setConfirm({
					title: 'Set Primary Address',
					text: `Are you sure you want to set '${data.AddressLine1}' as the primary address?`,
					action: () => {
						api.contact
							.setPrimaryAddress(contactId, data.AddressID)
							.then(() => {
								setConfirm(undefined);
								loadAddresses();
							})
							.catch((error) => {
								setConfirm(undefined);
								console.error(error);
							});
					},
				});
			}
		},
		[api.contact, contactId, loadAddresses]
	);

	const handleOnRemove = useCallback(
		(data: ContactAddressViewData) => {
			if (!data.isPrimary) {
				setConfirm({
					title: 'Remove Address?',
					text: `Are you sure you want to remove '${data.AddressLine1}' from this company?`,
					action: () => {
						api.contact
							.removeAddress(contactId, data.AddressID)
							.then(() => {
								setConfirm(undefined);
								loadAddresses();
							})
							.catch((error) => {
								setConfirm(undefined);
								console.error(error);
							});
					},
				});
			} else {
				setInfo({
					title: 'Cannot Remove the Primary Address',
					text: 'Please set a different address as the primary before removing this one.',
					action: () => setInfo(undefined),
				});
			}
		},
		[api.contact, contactId, loadAddresses]
	);

	const handleOnOpen = (data: PrimaryStreetAddressData) => {
		const link = [];
		if (data?.AddressLine1) link.push(encodeURI(data.AddressLine1));
		if (data?.AddressLine2) link.push(encodeURI(data.AddressLine2));
		if (data?.City) link.push(encodeURI(data.City));
		if (data?.State) link.push(encodeURI(data.State));
		if (data?.ZIP) link.push(encodeURI(data.ZIP));
		const url = `http://maps.google.com/maps?q=${link.join(',')}`;
		open(url);
	};

	return (
		<Card
			label={`Addresses (${addresses.length})`}
			actions={[
				{
					key: 'addaddress',
					label: 'Add Address',
					icon: mdiPlusCircleOutline,
					onClick: () => setShowAddAddress(true),
					visible: canEdit,
				},
				{
					key: 'collapse',
					label: 'Show Primary Only',
					icon: mdiChevronUp,
					onClick: () => setCount(1),
					visible: addresses.length > 1,
				},
				{
					key: 'expand',
					label: 'Expand All',
					icon: mdiChevronDown,
					onClick: () => setCount(addresses.length),
					visible: addresses.length > 1,
				},
			]}
			sx={theme => ({ margin: theme.spacing(1, 0, 0, 0) })}
		>
			{addresses.length === 0 && <p>None</p>}
			{addresses.slice(0, count).map((address, index) => (
				<AddressListItem
					canEdit={canEdit}
					key={address.AddressID}
					address={address}
					onSetPrimary={(addy) =>
						handleSetPrimary({ ...address, ...addy })
					}
					onRemove={(addy) => handleOnRemove({ ...address, ...addy })}
					onEdit={handleOnEdit}
					onOpen={handleOnOpen}
					sx={{
						backgroundColor: index % 2 ? 'transparent' : 'rgba(0, 0, 0, 0.1)',
						padding: theme.spacing(1, 0.5),
					}}
				/>
			))}
			{!!confirm && (
				<ConfirmDialog
					isOpen={true}
					title={confirm.title}
					question={confirm.text}
					onCancel={() => setConfirm(undefined)}
					onConfirm={() => confirm.action()}
				/>
			)}
			{!!info && (
				<InfoDialog
					isOpen={true}
					title={info.title}
					text={info.text}
					onClose={() => info.action()}
				/>
			)}
			{showAddAddress && (
				<AddAddressDialog
					onAddressAdded={(data) =>
						handleAddressAdded(data as ContactAddressViewData)
					}
					onClose={() => setShowAddAddress(false)}
					parentId={contactId}
					parentType="Contact"
					autoComplete
				/>
			)}
			{!!editAddress && (
				<EditAddressDialog
					parentId={contactId}
					parentType="Contact"
					defaultAddress={editAddress}
					onClose={() => setEditAddress(undefined)}
					onAddressUpdated={handleAddressUpdated}
				/>
			)}
		</Card>
	);
};
