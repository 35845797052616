import Box from '@mui/material/Box';
import { sum, uniq } from 'lodash';
import React from 'react';
import {
	JobAccountingData,
	JobAccountingDataItem,
	JobAccountingSalesData,
	JobDetail,
	LookupTypes
} from '../../../core';
import { useAppContext } from '../../../contexts/AppContext';
import {
	AutoComplete,
	AutoCompleteLookup,
	ErrorMessage,
	FlexColumn,
	Loading,
} from '../../common';
import { Dialog } from '../../common/Dialog';
import { useS3FileBrowserContext } from '../../browser/S3FileBrowserContext';
// import awsExports from '../../../aws-exports';
import { useLookupTypes } from '../../lookups';
import { useWindowOpen } from '../../useWindowOpen';
import { storage } from '../../browser/StorageProvider';

type Props = {
	job: JobDetail;
	jobTotal: string;
	balanceDue: string;
	accountingData: JobAccountingData;
	accountingDataItems: JobAccountingDataItem[];
	salesData: JobAccountingSalesData[];
	onCancel: () => void;
};

type State = {
	working: boolean;
	error?: any;
	invoiceNumber?: string;
	invoiceType?: number;
	invoices: string[];
};

const getInvoiceDate = (dt: Date): string => {
	const [d, t] = dt.toISOString().split('T');
	if (d && t) {
		const [y, m, day] = d?.split('-');
		const [h, min, s] = t?.split(':');
		const [sec] = s ? s.split('.') : ['00'];
		return `${y}${m}${day}${h}${min}${sec}`;
	}
	return dt.toISOString();
};

const getCurrentDate = (dt: Date): string => {
	// Jan 04, 2023
	const [, m, d, y] = dt.toDateString().split(' ');
	return `${m} ${d}, ${y}`;
};

export const PrintInvoiceDialog = ({
	job,
	jobTotal,
	balanceDue,
	accountingData,
	accountingDataItems,
	salesData,
	onCancel,
}: Props) => {
	const { api, bucket } = useAppContext();
	const [lookups] = useLookupTypes([LookupTypes.SystemSetting]);
	const [downloadKey, setDownloadKey] = React.useState('');
	const open = useWindowOpen();
	const { reload } = useS3FileBrowserContext();

	const [state, setState] = React.useState<State>({
		working: false,
		invoices: [],
	});

	const loadInvoices = React.useCallback(async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			const invoices = await api.accounting.getIssuedInvoices(
				job.AccountingSystemNumber
			);
			setState((s) => ({
				...s,
				working: false,
				error: undefined,
				invoices: uniq(invoices.map((x) => x.InvoiceNumber)),
			}));
		} catch (error) {
			setState((s) => ({ ...s, working: false, error: undefined }));
		}
	}, [job.AccountingSystemNumber, api.accounting]);

	const handleGenerateInvoice = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			const dt = new Date();

			let SUPPLEMENTCHANGEDESC = '';
			let SUPPCHANGEAMT = '';
			let CHANGEORDERDESC = '';
			let CHANGEORDERAMT = '';

			salesData.forEach((item) => {
				if (item.ChangeType === 'Supplemental Estimate') {
					SUPPCHANGEAMT += item.ChangeAmt + '';
					SUPPLEMENTCHANGEDESC += item.ChangeDesc + '';
				} else if (item.ChangeType === 'Change Order') {
					CHANGEORDERAMT += item.ChangeAmt + '';
					CHANGEORDERDESC += item.ChangeDesc + '';
				}
			});

			const amount = sum(
				accountingDataItems
					.filter(
						(x) =>
							x.InvoiceNumber === state.invoiceNumber &&
							x.AmountType === 'Total billed'
					)
					.map((x) => x.Amount)
			).toFixed(2);

			const data: any = {
				CURRENTDATE: getCurrentDate(dt),
				ACCOUNTINGJOBNUMBER: job.JobID || '',
				INVOICENUMBER: state.invoiceNumber || '',
				CUSTOMERNAME: job.CustomerName || '',
				CUSTOMERADDRESSLINE1: `${job.BillingAddressLine1} ${job.BillingAddressLine2} `,
				CUSTOMERCITY: job.BillingAddressCity || '',
				CUSTOMERSTATE: job.BillingAddressState || '',
				CUSTOMERZIP: job.BillingAddressZip || '',
				JOBSITEADDRESSLINE1: job.JobsiteAddressLine1 || '',
				JOBSITEADDRESSLINE2: job.JobsiteAddressLine2 || '',
				JOBSITECITY: job.JobsiteAddressCity || '',
				JOBSITESTATE: job.JobsiteAddressState || '',
				JOBSITEZIP: job.JobsiteAddressZip || '',
				CLAIMNUMBER: job.InsClaimNumber || '',
				CONTRACTAMOUNT: accountingData.OriginalTotal || '',
				SALESTAXAMOUNT: accountingData.SalesTaxTotal || '',
				JOBTOTAL: jobTotal || '',
				PAIDTODATE: accountingData.CashReceipt || '',
				BALANCEDUE: balanceDue || '',
				RECEIVEDDATE: job.LeadDate || '',
				JOBDESCRIPTION: job.DamageDesc || '',
				SUPPLEMENTCHANGEDESC,
				SUPPCHANGEAMT,
				CHANGEORDERDESC,
				CHANGEORDERAMT,
				PRODCOMPLETE: job.ProdCompleteDate || '',
				CUSTOMERCOMPANY: job.CompanyCustomerName || '',
				INVOICEAMTDUE: amount || '',
			};

			const invoiceType = lookups?.find(
				(x: any) => x.ID === state.invoiceType
			);
			const dateStamp = getInvoiceDate(dt);
			if (invoiceType) {
				const targetInvoiceKey = await api.templates.generateInvoice(
					job.JobID,
					{
						bucket,
						key: `public/templates/${invoiceType.ShortText}`,
						targetKey: `public/${job.JobID}/Invoices/Invoice_${dateStamp}.docx`,
						data,
					}
				);

				if (targetInvoiceKey) {
					setDownloadKey(
						`${job.JobID}/Invoices/Invoice_${dateStamp}.docx`
					);
					setState((s) => ({
						...s,
						working: false,
						error: undefined,
					}));
				} else {
					setState((s) => ({
						...s,
						working: false,
						error: new Error('Could not generate invoice.'),
					}));
				}
				reload();
			}
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const handleDownloadInvoice = async () => {
		let url = await storage.getDownloadUrl(downloadKey);
		if (url) {
			open(url);
		}
	};

	const handleOnChange = (property: string, value: any) => {
		setState((s) => ({
			...s,
			[property]: value,
		}));
	};

	React.useEffect(() => {
		loadInvoices();
	}, [loadInvoices]);

	const isValid = !!state.invoiceNumber && !!state.invoiceType;

	return (
		<Dialog
			isOpen={true}
			title="Generate Invoice"
			onClose={onCancel}
			cancelAction={{
				text: 'Cancel',
				color: 'inherit',
				onClick: onCancel,
				isDisabled: state.working,
			}}
			positiveAction={{
				text: 'Generate',
				color: 'secondary',
				onClick: handleGenerateInvoice,
				isDisabled: !isValid || state.working || !!state.error,
			}}
			auxAction={{
				text: 'Download',
				color: 'secondary',
				onClick: handleDownloadInvoice,
				isDisabled: !downloadKey,
			}}
		>
			<Box style={{ padding: '16px' }}>
				{state.working && <Loading />}
				<ErrorMessage error={state.error} />
				{!state.error && !state.working && (
					<FlexColumn>
						<AutoComplete
							label="Invoice Number"
							placeholder="Invoice Number"
							required
							value={state.invoiceNumber || null}
							options={state.invoices.map((item) => ({
								label: item,
								value: item,
							}))}
							onChange={(value) =>
								handleOnChange('invoiceNumber', value || null)
							}
						/>
						<AutoCompleteLookup
							lookupType={LookupTypes.SystemSetting}
							typesFilter={['InvoiceType']}
							label="Invoice Type"
							placeholder="Invoice Type"
							required
							descriptionAsLabel
							value={state?.invoiceType || null}
							onChange={(value) =>
								handleOnChange('invoiceType', value || null)
							}
						/>
					</FlexColumn>
				)}
			</Box>
		</Dialog>
	);
};
