import { mdiChevronDown, mdiChevronUp, mdiPlusCircleOutline } from '@mdi/js';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { CompanyContactViewData, ContactData } from '../../core';
import { Card, ConfirmDialog, InfoDialog } from '../common';
import { AddContactDialog } from '../contacts/AddContactDialog';
import { useRecentItems } from '../recent';
import { ContactListItem } from './ContactListItem';
import { useTheme } from '@mui/material';

type CompanyContactListProps = {
	companyId: number;
	canEdit?: boolean;
};

type DialogAction = {
	title: string;
	text: string;
	action: () => void;
};

export const CompanyContactList = (props: CompanyContactListProps) => {
	const theme = useTheme();
	const { api } = useAppContext();
	const navigate = useNavigate();
	const { addRecentContact } = useRecentItems();
	const { companyId, canEdit } = props;
	const [contacts, setContacts] = useState<CompanyContactViewData[]>([]);
	const [confirm, setConfirm] = useState<DialogAction>();
	const [info, setInfo] = useState<DialogAction>();
	const [showAddContact, setShowAddContact] = useState(false);
	const [count, setCount] = useState(1);

	const loadContacts = useCallback(() => {
		api.company
			.queryContacts(companyId)
			.then(setContacts)
			.catch(console.error);
	}, [api.company, companyId]);

	useEffect(() => {
		loadContacts();
	}, [loadContacts]);

	const handleAddContact = useCallback(() => {
		setShowAddContact(true);
	}, []);

	const handleContactAdded = (contact: ContactData) => {
		return api.company
			.addContact(companyId, {
				CompanyID: companyId,
				ContactID: contact.ContactID || 0,
				isPrimary: false,
			})
			.then(() => {
				setShowAddContact(false);
				loadContacts();
			})
			.catch(console.error);
	};

	const handleSetPrimary = useCallback(
		(data: CompanyContactViewData) => {
			if (!data.isPrimary) {
				setConfirm({
					title: 'Set Primary Contact',
					text: `Are you sure you want to set '${data.fullname}' as the primary contact?`,
					action: () => {
						api.company
							.setPrimaryContact(companyId, data.ContactID)
							.then(() => {
								setConfirm(undefined);
								loadContacts();
							})
							.catch((error) => {
								setConfirm(undefined);
								console.error(error);
							});
					},
				});
			}
		},
		[api.company, companyId, loadContacts]
	);

	const handleOnRemove = useCallback(
		(data: CompanyContactViewData) => {
			if (!data.isPrimary) {
				setConfirm({
					title: 'Remove Contact?',
					text: `Are you sure you want to remove '${data.fullname}' from this company?`,
					action: () => {
						api.company
							.removeContact(companyId, data.ContactID)
							.then(() => {
								setConfirm(undefined);
								loadContacts();
							})
							.catch((error) => {
								setConfirm(undefined);
								console.error(error);
							});
					},
				});
			} else {
				setInfo({
					title: 'Cannot Remove the Primary Contact',
					text: 'Please set a different contact as the primary contact before removing this contact.',
					action: () => setInfo(undefined),
				});
			}
		},
		[api.company, companyId, loadContacts]
	);

	const handleOnOpen = useCallback(
		(data: CompanyContactViewData) => {
			addRecentContact({
				contactType: 'Contact',
				id: data.ContactID,
				name: data.fullname,
				lookupType: data.ContactType,
			});
			navigate(`/contacts/${data.ContactID}`);
		},
		[addRecentContact, navigate]
	);

	return (
		<Card
			label={`Contacts (${contacts.length})`}
			actions={[
				{
					key: 'addcontact',
					label: 'Add Contact',
					icon: mdiPlusCircleOutline,
					onClick: handleAddContact,
					visible: canEdit,
				},
				{
					key: 'collapse',
					label: 'Show Primary Only',
					icon: mdiChevronUp,
					onClick: () => setCount(1),
					visible: contacts.length > 1,
				},
				{
					key: 'expand',
					label: 'Expand All',
					icon: mdiChevronDown,
					onClick: () => setCount(contacts.length),
					visible: contacts.length > 1,
				},
			]}
			sx={theme => ({ margin: theme.spacing(1, 0, 0, 0) })}
		>
			{contacts.length === 0 && <p>None</p>}
			{contacts.slice(0, count).map((contact, index) => (
				<ContactListItem
					canEdit={canEdit}
					key={contact.ContactID}
					contact={contact}
					onSetPrimary={handleSetPrimary}
					onRemove={handleOnRemove}
					onOpen={handleOnOpen}
					sx={{
						backgroundColor: index % 2 ? 'transparent' : 'rgba(0, 0, 0, 0.1)',
						padding: theme.spacing(1, 0.5),
					}}
				/>
			))}
			{!!confirm && (
				<ConfirmDialog
					isOpen={true}
					title={confirm.title}
					question={confirm.text}
					onCancel={() => setConfirm(undefined)}
					onConfirm={() => confirm.action()}
				/>
			)}
			{!!info && (
				<InfoDialog
					isOpen={true}
					title={info.title}
					text={info.text}
					onClose={() => info.action()}
				/>
			)}
			{showAddContact && (
				<AddContactDialog
					onClose={() => setShowAddContact(false)}
					onContactAdded={(data) => handleContactAdded(data)}
				/>
			)}
		</Card>
	);
};
