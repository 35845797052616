import { mdiClose, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React from 'react';

type SearchFieldProps = {
	placeholder?: string;
	text: string;
	onChange: (text: string) => void;
};

export const SearchField = (props: SearchFieldProps) => {
	const { placeholder, text, onChange } = props;

	let endAdornment: React.ReactNode | undefined = undefined;
	if (text) {
		endAdornment = (
			<InputAdornment position="end">
				<IconButton
					size="small"
					aria-label="Clear"
					onClick={() => onChange('')}
				>
					<Icon path={mdiClose} size={1} />
				</IconButton>
			</InputAdornment>
		);
	}

	return (
		<TextField
			sx={theme => ({ padding: theme.spacing(0, 1) })}
			placeholder={placeholder}
			variant="outlined"
			margin="dense"
			onChange={(e) => onChange(e.target.value)}
			value={text}
			fullWidth
			InputLabelProps={{ shrink: true }}
			InputProps={{
				style: {
					backgroundColor: '#fff',
				},
				startAdornment: (
					<InputAdornment position="start">
						<Icon path={mdiMagnify} size={1} />
					</InputAdornment>
				),
				endAdornment,
			}}
		/>
	);
};
