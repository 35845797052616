import { mdiLogoutVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, ListItemButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
	show: boolean;
};

export function AuthSidebar({ show }: Props) {
	const { user, signout, permissions } = useAppContext();

	if (!user) return null;
	if (!show) return null;
	return (
		<Box sx={theme => ({
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			height: '100%',
			overflow: 'hidden',
			overflowY: 'auto',
			position: 'relative',
			padding: theme.spacing(2),
		})}>
			<List>
				<ListItem>
					<ListItemText
						primary={user.EmployeeName}
						secondary={`${user.EmployeeNumber}: ${user.UserRoleName} User`}
					/>
				</ListItem>
				{permissions.map((permission) => (
					<ListItem
						key={permission.PermissionID}
						sx={theme => ({
							paddingLeft: theme.spacing(2),
							opacity: 0.4,
						})}
					>
						<ListItemText primary={permission.PermissionName} />
					</ListItem>
				))}
			</List>
			<Divider />
			<List dense={false}>
				<ListItemButton onClick={() => signout()}>
					<ListItem>
						<ListItemIcon sx={theme => ({ marginLeft: theme.spacing(1)})}>
							<Icon
								path={mdiLogoutVariant}
								size={1}
								color="#555555"
							/>
						</ListItemIcon>
						<ListItemText primary="Logout" />
					</ListItem>
				</ListItemButton>
			</List>
		</Box>
	);
}
