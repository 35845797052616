import { useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
	ContactPhoneNumberViewData,
	PrimaryPhoneNumberData,
	validatePhoneNumber,
} from '../../core';
import { Container, Dialog, Loading } from '../common';
import { PhoneNumberForm } from './PhoneNumberForm';

type Props = {
	contactId: number;
	onPhoneNumberAdded: (phoneNumber: ContactPhoneNumberViewData) => void;
	onClose: () => void;
};

export const AddPhoneNumberDialog = ({
	contactId,
	onClose,
	onPhoneNumberAdded,
}: Props) => {
	const { api } = useAppContext();
	const [busy, setBusy] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState<Partial<PrimaryPhoneNumberData>>(
		{}
	);

	const isValid =
		!!phoneNumber.PhoneTypeID &&
		!!phoneNumber.PhoneNumber &&
		validatePhoneNumber(phoneNumber.PhoneNumber);

	const handleAddPhoneNumber = async () => {
		setBusy(true);
		try {
			const added = await api.contact.addPhoneNumber(
				contactId,
				phoneNumber
			);
			onPhoneNumberAdded(added);
			onClose();
		} catch (error) {
			console.error(error);
			setBusy(false);
		}
	};

	return (
		<Dialog
			isOpen={true}
			title="Add Phone Number"
			negativeAction={{
				text: 'Clear',
				onClick: () => setPhoneNumber({}),
			}}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
			}}
			positiveAction={{
				text: 'Add Phone',
				isDisabled: () => busy || !isValid,
				onClick: handleAddPhoneNumber,
			}}
			onClose={onClose}
		>
			<Container
				component="AddPhoneNumberContent"
				scrollable
				sx={theme => ({
					backgroundColor: '#fff',
					padding: theme.spacing(2),
					'& .MuiOutlinedInput-root': {
						backgroundColor: '#fff',
					},
				})}
			>
				{busy && <Loading />}
				{!busy && (
					<PhoneNumberForm
						required
						autoComplete={true}
						phoneNumber={phoneNumber}
						onChanged={(data) => setPhoneNumber(data)}
						style={{ padding: '12px', overflow: 'hidden' }}
					/>
				)}
			</Container>
		</Dialog>
	);
};
