import { Box } from '@mui/material';
import React from 'react';

export interface NothingHereProps {
    text: string;
    style?: React.CSSProperties;
}

export function NothingHere(props: NothingHereProps) {
    const { text, style = {} } = props;

    return (
        <Box sx={theme => ({
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: theme.spacing(5),
            margin: theme.spacing(1),
            // backgroundColor: '#f0f0f0',
            borderRadius: theme.spacing(1),
            fontSize: theme.typography.body1.fontSize,
            opacity: 0.5,
            height: '100%',
        })} style={style}>
            {text}
        </Box>
    );
}
