import { useParams } from 'react-router-dom';
import { ContactData } from '../../core';
import { Loading } from '../common';
import { ContactLayout } from "./ContactLayout";
import { useAppContext } from '../../contexts/AppContext';
import { useChangeState } from '../useChangeState';

const validateContact = (contact: Partial<ContactData>): Promise<string[]> => {
	const errors: string[] = [];
	if (!contact.ContactTypeID) {
		errors.push('ContactTypeID');
	}
	return Promise.resolve(errors);
};

export const ContactContainer = () => {
	const { api } = useAppContext();
	const { id } = useParams<{ id: string }>();
	const contactId = Number(id);

	const state = useChangeState<ContactData>({
		key: contactId,
		onLoadItem: () => api.contact.get(contactId),
		onSaveChanges: api.contact.update,
		onValidateChanges: validateContact,
	});

	return (
		<>
			{state.loading && <Loading />}
			{!state.loading && <ContactLayout state={state} />}
		</>
	);
};
