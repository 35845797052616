import {
	CloneJobRequest,
	JobAccountingData,
	JobAccountingDataItem,
	JobAccountingSalesData,
	JobContactData,
	JobDetail,
	JobNote,
	JobOtherContactData,
	JobSearchOptions,
	JobSiteContactData,
	JobSummary,
	JobTaskWithJobData,
	KillJobRequest,
	OnHoldRequest,
	RenameJobRequest,
	SellData,
	UpdateRequest,
	stringifySearchOptions,
} from '../core';

import { ApiClientInterface } from './client';

export default class JobApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
		this.get = this.get.bind(this);
		this.update = this.update.bind(this);
		this.getAccounting = this.getAccounting.bind(this);
		this.getAccountingData = this.getAccountingData.bind(this);
		this.getSalesData = this.getSalesData.bind(this);
		this.queryJobsiteContacts = this.queryJobsiteContacts.bind(this);
		this.queryTasks = this.queryTasks.bind(this);
		this.completeTask = this.completeTask.bind(this);
		this.reassignTask = this.reassignTask.bind(this);
		this.queryNotes = this.queryNotes.bind(this);
		this.addNote = this.addNote.bind(this);
		this.updateNote = this.updateNote.bind(this);
		this.addJobsiteContact = this.addJobsiteContact.bind(this);
		this.sellJob = this.sellJob.bind(this);
		this.getSalesRecord = this.getSalesRecord.bind(this);
		this.clone = this.clone.bind(this);
		this.rename = this.rename.bind(this);
		this.kill = this.kill.bind(this);
		this.unkill = this.unkill.bind(this);
		this.onHold = this.onHold.bind(this);
	}

	// Job
	public async query(options: JobSearchOptions): Promise<JobSummary[]> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<JobSummary[]>(`/job?${search}`);
		return response;
	}

	public async queryCount(options: JobSearchOptions): Promise<number> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<number>(
			`/job?${search}&count=true`
		);
		return response;
	}

	public async get(jobId: number): Promise<JobDetail> {
		const response = await this.client.get<JobDetail>(`/job/${jobId}`);
		return response;
	}

	// public async update(data: Partial<JobData>): Promise<JobDetail> {

	// 	if (!data.JobName || !data.JobNumber) {
	// 		throw new Error('Invalid Job Update Data');
	// 	}

	// 	const response = await this.client.put<Partial<JobData>, JobDetail>(
	// 		`/job/${data.JobID}`,
	// 		data
	// 	);
	// 	return response;
	// }

	public async update(request: UpdateRequest): Promise<void> {
		await this.client.patch<UpdateRequest, void>(
			`/job/${request.id}`,
			request
		);
	}

	// Accounting
	public async getAccounting(jobId: number): Promise<JobAccountingData> {
		const response = await this.client.get<JobAccountingData>(
			`/job/${jobId}/accounting`
		);
		return response;
	}

	public async getAccountingData(
		jobId: number,
		accountingNumber: string
	): Promise<JobAccountingDataItem[]> {
		const response = await this.client.get<JobAccountingDataItem[]>(
			`/job/${jobId}/accounting/data/${accountingNumber}`
		);
		return response;
	}

	public async getSalesData(
		jobId: number
	): Promise<JobAccountingSalesData[]> {
		const response = await this.client.get<JobAccountingSalesData[]>(
			`/job/${jobId}/accounting/sales`
		);
		return response;
	}

	// Jobsite Contacts
	public async queryJobsiteContacts(
		jobId: number
	): Promise<JobContactData[]> {
		const response = await this.client.get<JobContactData[]>(
			`/job/${jobId}/contact/jobsite`
		);
		return response;
	}

	public async addJobsiteContact(
		jobsiteContact: Partial<JobSiteContactData>
	): Promise<JobSiteContactData> {
		const response = await this.client.post<
			Partial<JobSiteContactData>,
			JobSiteContactData
		>(`/job/${jobsiteContact.JobID}/contact/jobsite`, jobsiteContact);
		return response;
	}

	public async updateJobsiteContact(
		jobsiteContact: JobSiteContactData
	): Promise<JobSiteContactData> {
		const response = await this.client.put<
			JobSiteContactData,
			JobSiteContactData
		>(
			`/job/${jobsiteContact.JobID}/contact/jobsite/${jobsiteContact.JobsiteContactID}`,
			jobsiteContact
		);
		return response;
	}

	public async removeJobsiteContact(
		jobId: number,
		jobsiteContactId: number
	): Promise<void> {
		await this.client.del(
			`/job/${jobId}/contact/jobsite/${jobsiteContactId}`
		);
	}

	// Job Other Contacts
	public async queryJobOtherContacts(
		jobId: number
	): Promise<JobContactData[]> {
		const response = await this.client.get<JobContactData[]>(
			`/job/${jobId}/contact/other`
		);
		return response;
	}

	public async addJobOtherContact(
		data: Partial<JobOtherContactData>
	): Promise<JobOtherContactData> {
		const response = await this.client.post<
			Partial<JobOtherContactData>,
			JobOtherContactData
		>(`/job/${data.JobID}/contact/other`, data);
		return response;
	}

	public async removeJobOtherContact(
		jobId: number,
		contactId: number
	): Promise<void> {
		await this.client.del(
			`/job/${jobId}/contact/other/${contactId}`
		);
	}

	// Tasks
	public async queryTasks(jobId: number): Promise<JobTaskWithJobData[]> {
		const response = await this.client.get<JobTaskWithJobData[]>(
			`/job/${jobId}/task`
		);
		return response;
	}

	public async completeTask(
		jobId: number,
		jobTaskId: number
	): Promise<JobTaskWithJobData> {
		const response = await this.client.put<any, JobTaskWithJobData>(
			`/job/${jobId}/task/${jobTaskId}/complete`,
			{}
		);
		return response;
	}

	public async reassignTask(
		jobId: number,
		jobTaskId: number,
		employeeNumber: string
	): Promise<JobTaskWithJobData> {
		const response = await this.client.put<
			{ employeeNumber: string },
			JobTaskWithJobData
		>(`/job/${jobId}/task/${jobTaskId}/reassign`, { employeeNumber });
		return response;
	}

	// Notes
	public async queryNotes(jobId: number): Promise<JobNote[]> {
		const response = await this.client.get<JobNote[]>(`/job/${jobId}/note`);
		return response;
	}

	public async addNote(note: Partial<JobNote>): Promise<JobNote> {
		const response = await this.client.post<Partial<JobNote>, JobNote>(
			`/job/${note.JobID}/note`,
			note
		);
		return response;
	}

	public async updateNote(note: JobNote): Promise<any> {
		const response = await this.client.put<JobNote, JobNote>(
			`/job/${note.JobID}/note/${note.JobNoteID}`,
			note
		);
		return response;
	}

	// Sell Job
	public async sellJob(data: Partial<SellData>): Promise<void> {
		await this.client.post<Partial<SellData>, void>(
			`/job/${data.jobId}/sell`,
			data
		);
	}

	public async getSalesRecord(
		jobId: number,
		salesDataId: number
	): Promise<SellData> {
		const response = await this.client.get<SellData>(
			`/job/${jobId}/sell/${salesDataId}`
		);
		return response;
	}

	public async void(job: JobDetail): Promise<void> {
		await this.client.post<JobDetail, void>(`/job/${job.JobID}/void`, job);
	}

	// Clone
	public async clone(data: CloneJobRequest): Promise<number> {
		const response = await this.client.post<CloneJobRequest, number>(
			`/job/${data.jobId}/clone`,
			data
		);
		return response;
	}

	// Rename
	public async rename(data: RenameJobRequest): Promise<void> {
		const response = await this.client.post<RenameJobRequest, void>(
			`/job/${data.jobId}/rename`,
			data
		);
		return response;
	}

	// Kill
	public async kill(data: KillJobRequest): Promise<void> {
		await this.client.post<KillJobRequest, void>(
			`/job/${data.jobId}/kill`,
			data
		);
	}

	public async unkill(jobId: number): Promise<void> {
		await this.client.post<undefined, void>(
			`/job/${jobId}/unkill`,
			undefined
		);
	}

	// On Hold
	public async onHold(data: OnHoldRequest): Promise<void> {
		console.log()
		await this.client.post<OnHoldRequest, void>(
			`/job/${data.jobId}/onhold`,
			data
		);
	}
}
