import { HubEventProvider, UnsubscribeFunction } from './HubEventProvider';

export type Listener = (event: ApplicationEvent) => void;

const provider = new HubEventProvider();

export class ApplicationEvent {
	/**
	 * Reload Job
	 */
	public static ReloadJob(jobId: number) {
		const channel = `appevent-reload-job`;
		provider.dispatch({ channel, payload: jobId });
	}

	public static OnReloadJob(listener: Listener) {
		const channel = `appevent-reload-job`;
		return provider.subscribe(channel, listener);
	}

	/**
	 * Reload My Tasks
	 */
	public static ReloadMyTasks() {
		const channel = `appevent-reload-my-tasks`;
		provider.dispatch({ channel });
	}

	public static OnReloadMyTasks(listener: Listener): UnsubscribeFunction {
		const channel = `appevent-reload-my-tasks`;
		return provider.subscribe(channel, listener);
	}

}
