import { post, get, put, patch, del } from 'aws-amplify/api';
import { ApiClientInterface } from './types';
import { fetchAuthSession } from 'aws-amplify/auth';

export class HttpApiClient implements ApiClientInterface {
  private prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  private async getAuthToken() {
    return (await fetchAuthSession()).tokens?.idToken?.toString();
    // return (await fetchAuthSession()).tokens?.accessToken?.toString();
  }

  private async getHeaders() {
    return {
      // Authorization: await this.getAuthToken() || '',
    }
  }

  public async post<TBody, TResult>(pathSuffix: string, payload: TBody) {
    const path = `/${this.prefix}${pathSuffix}`;
    try {
      const op = post({
        apiName: 'api',
        path,
        options: {
          body: (payload as any),
          headers: (await this.getHeaders()),
        }
      });
      const { body, statusCode } = await op.response;
      if (statusCode !== 200) {
        throw new Error(`Invalid Http Response: ${statusCode}`);
      }
      try {
        return (await body.json() as TResult);
      } catch (err) {
        // TODO: void
        return undefined as any;
      }
    } catch (e) {
      console.error('POST call failed: ', `${e}`);
      throw e;
    }
  }

  public async put<TBody, TResult>(pathSuffix: string, payload: TBody) {
    const path = `/${this.prefix}${pathSuffix}`;
    try {
      const op = put({
        apiName: 'api',
        path,
        options: {
          body: (payload as any),
          headers: (await this.getHeaders()),
        }
      });
      const { body, statusCode } = await op.response;
      if (statusCode !== 200) {
        throw new Error(`Invalid Http Response: ${statusCode}`);
      }
      return (await body.json() as TResult);
    } catch (e) {
      console.error('PUT call failed: ', `${e}`);
      throw e;
    }
  }

  public async patch<TBody, TResult>(pathSuffix: string, payload?: TBody) {
    const path = `/${this.prefix}${pathSuffix}`;
    try {
      const op = patch({
        apiName: 'api',
        path,
        options: {
          body: (payload as any),
          headers: (await this.getHeaders()),
        }
      });
      const { body, statusCode } = await op.response;
      if (statusCode !== 200) {
        throw new Error(`Invalid Http Response: ${statusCode}`);
      }
      return (await body.json() as TResult);
    } catch (e) {
      console.error('PATCH call failed: ', `${e}`);
      throw e;
    }
  }

  public async del(pathSuffix: string) {
    const path = `/${this.prefix}${pathSuffix}`;
    try {
      const op = del({
        apiName: 'api',
        path,
        options: {
          headers: (await this.getHeaders()),
        }
      });
      const { statusCode } = await op.response;
      if (statusCode !== 200) {
        throw new Error(`Invalid Http Response: ${statusCode}`);
      }
    } catch (e) {
      console.error('DEL call failed: ', `${e}`);
      throw e;
    }
  }

  public async get<TResult>(pathSuffix: string, payload?: any): Promise<TResult> {
    const path = `/${this.prefix}${pathSuffix}`;
    try {
      const op = get({
        apiName: 'api',
        path,
        options: {
          body: payload,
          headers: (await this.getHeaders()),
        }
      });
      const { body, statusCode } = await op.response;
      if (statusCode !== 200) {
        throw new Error(`Invalid Http Response: ${statusCode}`);
      }
      return (await body.json() as TResult);
    } catch (e) {
      console.error('GET call failed: ', `${e}`);
      throw e;
    }
  }
}

export const http = new HttpApiClient('api');