import * as React from 'react';
import Box from '@mui/material/Box';

type Props = {
	name?: string;
	children: React.ReactNode;
	style?: React.CSSProperties;
	fill?: boolean;
	nowrap?: boolean;
	hide?: boolean;
};

const fillSx: React.CSSProperties = {
	flexGrow: 1,
	width: '100%',
	overflow: 'hidden',
};

export const FlexRow = ({
	name,
	children,
	fill,
	nowrap,
	hide,
	style = {},
}: Props) => {
	let styles: React.CSSProperties = {
		position: 'relative',
		display: 'flex',
		flexFlow: nowrap ? 'row nowrap' : 'row wrap',
	};

	if (fill) {
		styles = {
			...styles,
			...fillSx,
		};
	}

	if (hide) {
		styles = {
			...styles,
			display: 'none',
		};
	}

	return (
		<Box
			data-component={name}
			sx={{
				...style,
				...styles,
			}}
		>
			{children}
		</Box>
	);
};
