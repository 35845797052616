import { useCallback } from 'react';

// Lib
import { useAppDispatch, useAppSelector } from '../../store';
import {
	addRecentItem as addRecentItemAction,
	clearRecentItems as clearRecentItemsAction,
	removeRecentItems as removeRecentItemsAction,
	selectRecentItems,
} from '../../store/recent';
import {
	RecentCompany,
	RecentContact,
	RecentItem,
	RecentItemType,
	RecentItemTypes,
	RecentJob,
} from './types';

export const useRecentItems = () => {
	const dispatch = useAppDispatch();
	const items = useAppSelector(selectRecentItems);

	const addRecentItem = useCallback(
		(item: RecentItem) => dispatch(addRecentItemAction(item)),
		[dispatch]
	);
	const removeRecentItems = useCallback(
		(keys: string[]) => dispatch(removeRecentItemsAction(keys)),
		[dispatch]
	);
	const clearRecentItems = useCallback(
		() => dispatch(clearRecentItemsAction()),
		[dispatch]
	);

	const addRecentJob = useCallback(
		(item: RecentJob) => {
			addRecentItem({
				key: `${RecentItemTypes.Job}_${item.id}`,
				id: item.id,
				name: `${item.number}: ${item.name}`,
				description: item.desc,
				type: RecentItemTypes.Job as RecentItemType,
			});
		},
		[addRecentItem]
	);

	const addRecentContact = useCallback(
		(item: RecentContact) => {
			addRecentItem({
				key: `${RecentItemTypes.Contact}_${item.id}`,
				id: item.id,
				name: item.name,
				description: `${item.contactType}: ${item.lookupType}`,
				type: RecentItemTypes.Contact as RecentItemType,
			});
		},
		[addRecentItem]
	);

	const addRecentCompany = useCallback(
		(item: RecentCompany) => {
			addRecentItem({
				key: `${RecentItemTypes.Contact}_${item.id}`,
				id: item.id,
				name: item.name,
				description: item.type,
				type: RecentItemTypes.Contact as RecentItemType,
			});
		},
		[addRecentItem]
	);

	return {
		items,
		addRecentItem,
		removeRecentItems,
		clearRecentItems,
		addRecentJob,
		addRecentContact,
		addRecentCompany,
	};
};
