import {
	amber,
	blue,
	green,
	grey,
	orange,
	red,
} from '@mui/material/colors';
import { JobSummary } from '../../core';
import { themeOverrides } from '../../lib';
import { createTheme as createMuiTheme } from '@mui/material';

const createTheme = (primary: any, secondary: any) => {
	return createMuiTheme({
		...themeOverrides,
		palette: {
			primary: primary,
			secondary: secondary,
		},
	});
};

export const salesTheme = createTheme(amber, grey);
export const billingTheme = createTheme(orange, grey);
export const productionTheme = createTheme(green, grey);
export const closedTheme = createTheme(red, grey);
export const newTheme = createTheme(blue, grey);

export type JobStatus = {
	status: string;
	phase: string;
	theme: any;
};

export const getJobStatus = (job: JobSummary | null | undefined): JobStatus => {
	if (!job) {
		return {
			status: 'Unknown',
			phase: 'Unknown',
			theme: closedTheme,
		};
	}
	if (job.IsOnHold === true) {
		return {
			status: 'On Hold',
			phase: 'Sales',
			theme: salesTheme,
		};
	} else if (job.isJobKilled) {
		return {
			status: 'Killed',
			phase: 'Closed',
			theme: closedTheme,
		};
	} else if (job.isPaidInFull) {
		return {
			status: 'Paid in Full',
			phase: 'Billing',
			theme: billingTheme,
		};
	} else if (job.isInvoiced) {
		return {
			status: 'Invoiced',
			phase: 'Billing',
			theme: billingTheme,
		};
	} else if (job.isCustomerApproval) {
		return {
			status: 'Customer COC',
			phase: 'Production',
			theme: productionTheme,
		};
	} else if (job.isProdComplete) {
		return {
			status: 'Prod Complete',
			phase: 'Production',
			theme: productionTheme,
		};
	} else if (job.isProdStarted) {
		return {
			status: 'Prod Started',
			phase: 'Production',
			theme: productionTheme,
		};
	} else if (job.isPreliminary) {
		return {
			status: 'Preliminary',
			phase: 'Production',
			theme: productionTheme,
		};
	} else if (job.isOutcome) {
		return {
			status: 'Sold',
			phase: 'Sales',
			theme: salesTheme,
		};
	} else if (job.isEstimated) {
		return {
			status: 'Estimated',
			phase: 'Sales',
			theme: salesTheme,
		};
	} else if (job.isInspected) {
		return {
			status: 'Inspected',
			phase: 'Sales',
			theme: salesTheme,
		};
	} else if (job.isContacted) {
		return {
			status: 'Contacted',
			phase: 'Sales',
			theme: salesTheme,
		};
	} else {
		return {
			status: 'Not Contacted',
			phase: 'New',
			theme: newTheme,
		};
	}
};
