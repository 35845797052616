import { Grid } from '@mui/material';
import { LookupTypes } from '../../core';
import { useAppSelector } from '../../store';
import { User } from '../auth';
import { AutoCompleteLookup, EmployeeDropDown } from '../common';
import { selectActiveEmployees } from '../employees';
import {
	ComponentProps,
	assignmentItems1,
	assignmentItems2,
	assignmentRequired,
} from './shared';

interface ItemsProps extends ComponentProps {
	showDivision: boolean;
	employees: User[];
	items: { key: string; label: string }[];
}

const Items = ({
	showDivision,
	items,
	state,
	setState,
	validationErrors,
}: ItemsProps) => {
	const handleStateChange = (key: string, value: any) => {
		setState((s) => ({ ...s, [key]: value }));
	};

	return (
		<Grid container direction="column" sx={{ marginTop: 0 }}>
			{items.map((item) => (
				<Grid item key={item.key}>
					<EmployeeDropDown
						label={item.label}
						placeholder={item.label}
						emptyOption={{ value: '', label: 'None' }}
						autoOpen
						value={(state as any)[item.key] || ''}
						required={assignmentRequired.includes(item.key)}
						onChange={(value) =>
							handleStateChange(item.key, value || null)
						}
						filter={(employees) =>
							item.key !== 'usr8'
								? employees.filter(
									(x) => (x as any)[item.key] === true
								)
								: employees
						}
						error={!!(validationErrors as any)[item.key]}
					/>
				</Grid>
			))}
			{showDivision && (
				<Grid item>
					<AutoCompleteLookup
						label="Div"
						placeholder="Div"
						lookupType={LookupTypes.Division}
						value={state.DivisionID || null}
						emptyOption={{
							label: 'None',
							value: -1,
						}}
						onChange={(value) =>
							handleStateChange(
								'DivisionID',
								!!value && value > 0 ? value : undefined
							)
						}
						error={!!(validationErrors as any).DivisionID}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export const Assignment = (props: ComponentProps) => {
	const employees = useAppSelector(selectActiveEmployees);

	return (
		<Grid
			container
			direction="row"
			wrap="wrap"
			justifyContent="space-around"
			spacing={2}
			sx={theme => ({
				padding: theme.spacing(1),
				marginTop: 0,
			})}
		>
			<Grid item sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
			}}>
				<Items
					showDivision={false}
					items={assignmentItems1}
					employees={employees}
					{...props}
				/>
			</Grid>
			<Grid item sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
			}}>
				<Items
					showDivision
					items={assignmentItems2}
					employees={employees}
					{...props}
				/>
			</Grid>
		</Grid>
	);
};
