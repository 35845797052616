import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { formatDateTime } from '../../core';
import { Avatar } from '../common';

type NoteListItemProps = {
	date: Date;
	content: string;
	type?: string | null | undefined;
	employeeNumber?: string | null | undefined;
	showCheckbox?: boolean;
	onToggleSelection?: () => void;
	onEditNote: () => void;
	selected?: boolean;
	className?: string;
	style?: React.CSSProperties;
};

export const NoteListItem = (props: NoteListItemProps) => {
	const {
		date,
		content,
		onEditNote,
		employeeNumber,
	} = props;

	const expirationDate = new Date(new Date(date).getTime() + 10 * 60000);
	const dt = new Date();
	const canEditNote = dt <= expirationDate;

	console.log('NoteListItem', employeeNumber);

	return (
		<Grid
			container
			direction="column"
			sx={theme => ({
				display: 'flex',
				flexDirection: 'column',
				padding: theme.spacing(1, 1, 2, 1),
				borderBottom: '1px solid rgba(0,0,0,0.2)',
				'&:hover': {
					backgroundColor: '#f6f6f6',
					'& $metadata': {
						opacity: 1,
					},
				},
			})}
		>
			<Grid
				container
				direction="row"
				wrap="nowrap"
				alignItems="center"
				sx={theme => ({
					[theme.breakpoints.up('sm')]: {
						opacity: 0.5,
					},
					'&:hover': {
						opacity: 1,
					},
				})}
			>
				<Grid item sx={{ flexGrow: 1 }}>
					<Box sx={theme => ({
						fontSize: theme.typography.caption.fontSize,
						color: 'rgba(0,0,0,0.8)',
					})}>{formatDateTime(date)}</Box>
				</Grid>
				{employeeNumber && !canEditNote && (
					<Grid item>
						<Avatar
							small
							align="left"
							employeeNumber={employeeNumber}
						/>
					</Grid>
				)}
				{canEditNote && (
					<Grid item style={{ minWidth: 84, textAlign: 'right' }}>
						<Tooltip title="Edit Note">
							<IconButton
								size="small"
								aria-label="Edit Note"
								onClick={onEditNote}
							>
								<Icon path={mdiPencil} size={0.8} />
							</IconButton>
						</Tooltip>
					</Grid>
				)}
			</Grid>
			<Grid item sx={theme => ({
				fontSize: theme.typography.body2.fontSize,
				padding: theme.spacing(1, 0),
				color: '#000000',
			})}>
				<span>{content}</span>
			</Grid>
		</Grid>
	);
};
