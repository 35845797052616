import {
	mdiCardAccountMailOutline,
	mdiHomeCityOutline,
	mdiOpenInNew,
	mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';
import {
	ContactViewData,
	JobContactData,
	JobDetail,
	LookupTypes,
} from '../../../core';
import { ApplicationEvent } from '../../ApplicationEvent';
import { ConfirmDialog } from '../../common';
import { EmailCard, PhoneCard } from '../../contacts';
import { useLookupTypes } from '../../lookups';
import { useRecentItems } from '../../recent';
import ContactSelection from "../../selection/ContactSelection";

type Props = {
	canEditJob?: boolean;
	job?: JobDetail;
	onCountChanged: (count: number) => void;
};

export const JobOtherContactList = (props: Props) => {
	const theme = useTheme();
	const { api } = useAppContext();
	const navigate = useNavigate();
	const { addRecentContact } = useRecentItems();
	const { job, onCountChanged, canEditJob } = props;
	const [removeConfirmation, setRemoveConfirmation] =
		React.useState<JobContactData>();
	const [contactTypes] = useLookupTypes([LookupTypes.JobsiteContactType]);
	const [contacts, setContacts] = useState<JobContactData[]>([]);
	const jobId = job?.JobID;

	const loadContacts = React.useCallback(async () => {
		if (jobId) {
			const contacts = await api.job.queryJobOtherContacts(jobId);
			setContacts(contacts);
			onCountChanged(contacts.length);
		}
	}, [api.job, jobId, onCountChanged]);

	useEffect(() => {
		loadContacts();
	}, [loadContacts]);

	useEffect(() => {
		const unsubscribe = ApplicationEvent.OnReloadJob(loadContacts);
		return () => unsubscribe();
	}, [loadContacts]);

	const getContactType = (id: number) => {
		return contactTypes?.find((x: any) => x.ID === id)?.Name || '';
	};

	const handleRemoveContact = async () => {
		if (removeConfirmation) {
			try {
				await api.job.removeJobOtherContact(
					removeConfirmation.JobID,
					removeConfirmation.ContactID
				);
				loadContacts();
				setRemoveConfirmation(undefined);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleAddOtherContact = async (data: ContactViewData | null) => {
		if (job?.JobID && data) {
			try {
				await api.job.addJobOtherContact({
					ContactID: data.ContactID,
					JobID: job.JobID,
				});
				loadContacts();
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleOpenContact = (data: JobContactData) => {
		if (data.ContactID) {
			addRecentContact({
				contactType: 'Contact',
				id: data.ContactID,
				name: data.ContactName,
				lookupType: getContactType(data.ContactTypeID),
			});
			navigate(`/contacts/${data.ContactID}`);
		}
	};

	if (!job) return null;

	return (
		<>
			{canEditJob && (
				<>
					<Toolbar disableGutters sx={{ padding: theme.spacing(1) }}>
						<div style={{ flexGrow: 1 }}>
							<ContactSelection
								title="Add Contact"
								onSelected={(contact) => {
									handleAddOtherContact(contact || null)
								}}
								disabled={!canEditJob}
							/>
						</div>
					</Toolbar>
				</>
			)}
			{contacts.map((item) => (
				<Box key={`${item.Type}-${item.ID}`} sx={{
					display: 'flex',
					flexDirection: 'column',
					borderBottom: '1px solid rgba(0,0,0,0.3)',
				}}>
					<Box sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						padding: theme.spacing(1, 1, 0, 1),
					}}>
						<Tooltip title={`${item.Type} Contact`}>
							<Icon
								path={
									item.Type === 'Jobsite'
										? mdiCardAccountMailOutline
										: mdiHomeCityOutline
								}
								size={1}
							/>
						</Tooltip>
						<Box sx={{
							flexGrow: 1,
							fontWeight: 'bold',
							padding: theme.spacing(1),
						}}>{item.ContactName}</Box>
						<Box>
							<Tooltip title="Contact Type">
								<span>
									{getContactType(item.ContactTypeID)}
								</span>
							</Tooltip>
						</Box>
						{item.ContactID && (
							<Tooltip title="Open Contact">
								<IconButton
									size="small"
									aria-label="Open Contact"
									onClick={() => handleOpenContact(item)}
								>
									<Icon path={mdiOpenInNew} size={0.8} />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title="Remove Contact">
							<IconButton
								size="small"
								aria-label="Remove Contact"
								onClick={() => setRemoveConfirmation(item)}
								style={{ marginLeft: '10px' }}
							>
								<Icon path={mdiTrashCanOutline} size={0.8} />
							</IconButton>
						</Tooltip>
					</Box>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						padding: theme.spacing(0, 1, 2, 1),
					}}>
						{item.PhoneNumber && (
							<PhoneCard phone={item.PhoneNumber} />
						)}
						{item.EmailAddress && (
							<EmailCard address={item.EmailAddress} />
						)}
					</Box>
				</Box>
			))}
			{!!removeConfirmation && (
				<ConfirmDialog
					isOpen={true}
					title="Remove Contact?"
					question={`Are you sure you want to remove ${removeConfirmation.ContactName} from Other Contacts?`}
					onCancel={() => setRemoveConfirmation(undefined)}
					onConfirm={handleRemoveContact}
				/>
			)}
		</>
	);
};
