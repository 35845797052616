import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LookupTypes } from '../../core';
import { AutoComplete, AutoCompleteLookup, Chip, Container } from '../common';
import { ComponentProps } from './shared';
import { useTaskTemplates } from '../taskTemplates/useTaskTemplates';

export const Job = (props: ComponentProps) => {
	const { state, setState, validationErrors } = props;
	const taskTemplates = useTaskTemplates();

	const flags = [
		{
			key: 'isInsuranceJob',
			label: 'Private Job',
			selected: state.isInsuranceJob === false,
		},
		{
			key: 'isInsuranceJob',
			label: 'Insurance Job',
			selected: state.isInsuranceJob === true,
		},
		{
			key: 'isEmergency',
			label: 'Emergency',
			selected: state.isEmergency === true,
		},
	];

	const taskTemplateOptions = taskTemplates.map((item) => ({
		label: item.name,
		value: item.id,
	}));

	const handleStateChange = (
		key: string,
		value: string | boolean | number | object | null | undefined
	) => {
		setState((s) => ({ ...s, [key]: value }));
	};

	return (
		<Container component="AddLeadJobContent" sx={{
			display: 'flex',
			flexDirection: 'column',
		}}>
			<Grid
				container
				direction="row"
				wrap="wrap"
				spacing={2}
				sx={theme => ({ padding: theme.spacing(0, 1), marginTop: 0 })}
			>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<TextField
						label="Job Number"
						aria-label="Job Number"
						title="Job Number"
						value={state.JobNumber || ''}
						onChange={(e) =>
							handleStateChange('JobNumber', e.target.value)
						}
						InputLabelProps={{ shrink: true }}
						variant="outlined"
						margin="dense"
						fullWidth
						required
						sx={{ backgroundColor: '#ffffff' }}
						error={!!validationErrors['JobNumber']}
					/>
				</Grid>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<TextField
						label="Job Name"
						aria-label="Job Name"
						title="Job Name"
						value={state.JobName || ''}
						onChange={(e) =>
							handleStateChange('JobName', e.target.value)
						}
						InputLabelProps={{ shrink: true }}
						variant="outlined"
						margin="dense"
						fullWidth
						required
						sx={{ backgroundColor: '#ffffff' }}
						error={!!validationErrors['JobName']}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				direction="row"
				wrap="wrap"
				spacing={2}
				sx={theme => ({ padding: theme.spacing(0, 1), marginTop: 0 })}
			>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<AutoComplete
						label="Task Template"
						placeholder="Task Template"
						autoOpen
						required
						value={state.TaskTemplateID || null}
						options={taskTemplateOptions}
						error={!!validationErrors['TaskTemplateID']}
						onChange={(value) =>
							handleStateChange('TaskTemplateID', value || null)
						}
					/>
				</Grid>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<AutoCompleteLookup
						lookupType={LookupTypes.JobType}
						label="Loss Type"
						placeholder="Loss Type"
						autoOpen
						required
						value={state.LossTypeID || null}
						error={!!validationErrors['LossTypeID']}
						onChange={(value) =>
							handleStateChange('LossTypeID', value || undefined)
						}
					/>
				</Grid>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<AutoCompleteLookup
						lookupType={LookupTypes.BuildingType}
						label="Building Type"
						placeholder="Building Type"
						autoOpen
						required
						value={state.BuildingTypeID || null}
						error={!!validationErrors['BuildingTypeID']}
						onChange={(value) =>
							handleStateChange(
								'BuildingTypeID',
								value || undefined
							)
						}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				direction="row"
				wrap="wrap"
				spacing={2}
				sx={theme => ({ padding: theme.spacing(0, 1), marginTop: 0 })}
			>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<AutoCompleteLookup
						lookupType={LookupTypes.RepairProgram}
						label="Repair Program"
						placeholder="Repair Program"
						autoOpen
						value={state.RepairProgramID || null}
						error={!!validationErrors['RepairProgramID']}
						onChange={(value) =>
							handleStateChange(
								'RepairProgramID',
								value || undefined
							)
						}
					/>
				</Grid>
				<Grid item sx={{ flexGrow: 1, width: 'initial', maxWidth: 120 }}>
					<TextField
						label="Year Built"
						aria-label="Year Built"
						title="Year Built"
						value={state.YearBuilt || ''}
						onChange={(e) =>
							handleStateChange('YearBuilt', e.target.value)
						}
						InputLabelProps={{ shrink: true }}
						variant="outlined"
						margin="dense"
						fullWidth
						required
						sx={{ backgroundColor: '#ffffff' }}
						error={!!validationErrors['YearBuilt']}
					/>
				</Grid>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<AutoCompleteLookup
						lookupType={LookupTypes.County}
						label="County"
						placeholder="County"
						autoOpen
						emptyOption={{ value: -1, label: 'None' }}
						value={state.CountyID || null}
						error={!!validationErrors['CountyID']}
						required
						onChange={(value) =>
							handleStateChange(
								'CountyID',
								!!value && value > 0 ? value : undefined
							)
						}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				direction="row"
				wrap="wrap"
				spacing={2}
				sx={theme => ({ padding: theme.spacing(0, 1), marginTop: 0 })}
			>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<TextField
						label="Loss Description"
						aria-label="Loss Description"
						title="Loss Description"
						value={state.DamageDesc || ''}
						onChange={(e) =>
							handleStateChange('DamageDesc', e.target.value)
						}
						InputLabelProps={{ shrink: true }}
						variant="outlined"
						margin="dense"
						fullWidth
						sx={{ backgroundColor: '#ffffff' }}
						multiline
						minRows={3}
						error={!!validationErrors['DamageDesc']}
					/>
				</Grid>
			</Grid>
			<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
				<Grid
					container
					direction="row"
					wrap="wrap"
					justifyContent="space-evenly"
					alignItems="center"
					sx={theme => ({ padding: theme.spacing(2) })}
				>
					{flags.map((flag) => (
						<Grid item key={flag.label}>
							<Chip
								label={flag.label}
								selected={flag.selected === true || false}
								onClick={() =>
									handleStateChange(
										flag.key,
										(state as any)[flag.key] === true
											? false
											: true
									)
								}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Container>
	);
};
