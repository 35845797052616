import { mdiChevronDown, mdiChevronUp, mdiPlusCircleOutline } from '@mdi/js';
import { useCallback, useEffect, useState } from 'react';
import { api } from '../../api';
import {
	CompanyAddressViewData,
	PrimaryStreetAddressData,
} from '../../core';
import { AddAddressDialog, EditAddressDialog } from '../addresses';
import { Card, ConfirmDialog, InfoDialog } from '../common';
import { AddressListItem } from '../contacts';
import { useWindowOpen } from '../useWindowOpen';
import { useTheme } from '@mui/material';

type CompanyAddressListProps = {
	companyId: number;
	canEdit?: boolean;
};

type DialogAction = {
	title: string;
	text: string;
	action: () => void;
};

export const CompanyAddressList = (props: CompanyAddressListProps) => {
	const theme = useTheme();
	const { companyId, canEdit } = props;
	const [addresses, setAddresses] = useState<CompanyAddressViewData[]>([]);
	const [confirm, setConfirm] = useState<DialogAction>();
	const [info, setInfo] = useState<DialogAction>();
	const [showAddAddress, setShowAddAddress] = useState(false);
	const [editAddress, setEditAddress] = useState<PrimaryStreetAddressData>();
	const [count, setCount] = useState(1);
	const open = useWindowOpen();

	const loadAddresses = useCallback(() => {
		api.company
			.queryAddresses(companyId)
			.then(setAddresses)
			.catch(console.error);
	}, [companyId]);

	useEffect(() => {
		loadAddresses();
	}, [companyId, loadAddresses]);

	const handleAddressAdded = useCallback(
		(address: CompanyAddressViewData) => {
			setShowAddAddress(false);
			// setAddresses((s) => {
			// 	const ns = [...s];
			// 	ns.push(address);
			// 	return orderBy(ns, ['isPrimary'], ['desc']);
			// });
			loadAddresses();
		},
		[loadAddresses]
	);

	const handleOnEdit = (data: PrimaryStreetAddressData) => {
		setEditAddress(data);
	};

	const handleAddressUpdated = (data: PrimaryStreetAddressData) => {
		setEditAddress(undefined);
		// setAddresses((s) => {
		// 	const ns = [...s];
		// 	const index = ns.findIndex((x) => x.AddressID === data.AddressID);
		// 	if (index !== -1) {
		// 		const item = ns[index];
		// 		if (item) {
		// 			ns[index] = { ...item, ...data };
		// 		}
		// 	}
		// 	return orderBy(ns, ['isPrimary'], ['desc']);
		// });
		loadAddresses();
	};

	const handleSetPrimary = useCallback(
		(data: CompanyAddressViewData) => {
			if (!data.isPrimary) {
				setConfirm({
					title: 'Set Primary Address',
					text: `Are you sure you want to set '${data.AddressLine1}' as the primary address?`,
					action: () => {
						api.company
							.setPrimaryAddress(companyId, data.AddressID)
							.then(() => {
								setConfirm(undefined);
								loadAddresses();
							})
							.catch((error) => {
								setConfirm(undefined);
								console.error(error);
							});
					},
				});
			}
		},
		[companyId, loadAddresses]
	);

	const handleOnRemove = useCallback(
		(data: CompanyAddressViewData) => {
			if (!data.isPrimary) {
				setConfirm({
					title: 'Remove Address?',
					text: `Are you sure you want to remove '${data.AddressLine1}' from this company?`,
					action: () => {
						api.company
							.removeAddress(companyId, data.AddressID)
							.then(() => {
								setConfirm(undefined);
								loadAddresses();
							})
							.catch((error) => {
								setConfirm(undefined);
								console.error(error);
							});
					},
				});
			} else {
				setInfo({
					title: 'Cannot Remove the Primary Address',
					text: 'Please set a different address as the primary address before removing this address.',
					action: () => setInfo(undefined),
				});
			}
		},
		[companyId, loadAddresses]
	);

	const handleOnOpen = (data: PrimaryStreetAddressData) => {
		const link = [];
		if (data?.AddressLine1) link.push(encodeURI(data.AddressLine1));
		if (data?.AddressLine2) link.push(encodeURI(data.AddressLine2));
		if (data?.City) link.push(encodeURI(data.City));
		if (data?.State) link.push(encodeURI(data.State));
		if (data?.ZIP) link.push(encodeURI(data.ZIP));
		const url = `http://maps.google.com/maps?q=${link.join(',')}`;
		open(url);
	};

	return (
		<Card
			label={`Addresses (${addresses.length})`}
			actions={[
				{
					key: 'addaddress',
					label: 'Add Address',
					icon: mdiPlusCircleOutline,
					onClick: () => setShowAddAddress(true),
					visible: canEdit,
				},
				{
					key: 'collapse',
					label: 'Show Primary Only',
					icon: mdiChevronUp,
					onClick: () => setCount(1),
					visible: addresses.length > 1,
				},
				{
					key: 'expand',
					label: 'Expand All',
					icon: mdiChevronDown,
					onClick: () => setCount(addresses.length),
					visible: addresses.length > 1,
				},
			]}
			sx={theme => ({ margin: theme.spacing(1, 0, 0, 0) })}
		>
			{addresses.length === 0 && <p>None</p>}
			{addresses.slice(0, count).map((address, index) => (
				<AddressListItem
					canEdit={canEdit}
					key={address.AddressID}
					address={address}
					onSetPrimary={(addy) =>
						handleSetPrimary({ ...address, ...addy })
					}
					onRemove={(addy) => handleOnRemove({ ...address, ...addy })}
					onEdit={handleOnEdit}
					onOpen={handleOnOpen}
					sx={{
						backgroundColor: index % 2 ? 'transparent' : 'rgba(0, 0, 0, 0.1)',
						padding: theme.spacing(1, 0.5),
					}}
				/>
			))}
			{!!confirm && (
				<ConfirmDialog
					isOpen={true}
					title={confirm.title}
					question={confirm.text}
					onCancel={() => setConfirm(undefined)}
					onConfirm={() => confirm.action()}
				/>
			)}
			{!!info && (
				<InfoDialog
					isOpen={true}
					title={info.title}
					text={info.text}
					onClose={() => info.action()}
				/>
			)}
			{showAddAddress && (
				<AddAddressDialog
					onAddressAdded={(data) =>
						handleAddressAdded(data as CompanyAddressViewData)
					}
					onClose={() => setShowAddAddress(false)}
					parentId={companyId}
					parentType="Company"
					autoComplete
				/>
			)}
			{!!editAddress && (
				<EditAddressDialog
					parentId={companyId}
					parentType="Company"
					defaultAddress={editAddress}
					onClose={() => setEditAddress(undefined)}
					onAddressUpdated={handleAddressUpdated}
				/>
			)}
		</Card>
	);
};
