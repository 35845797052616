/* eslint-disable no-console */
import { ReportData } from '../core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Lib
import type { RootState } from './index';

export interface ReportsState {
	main: ReportData[];
	accounting: ReportData[];
}

const initialState: ReportsState = {
	main: [],
	accounting: [],
};

export const slice = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		loadMainReports: (state, action: PayloadAction<ReportData[]>) => {
			state.main = action.payload;
		},
		loadAccountingReports: (state, action: PayloadAction<ReportData[]>) => {
			state.accounting = action.payload;
		},
	},
});

export const { loadMainReports, loadAccountingReports } = slice.actions;

export const selectMainReports = (state: RootState) => state.reports.main;

export const selectAccountingReports = (state: RootState) =>
	state.reports.accounting;

export default slice.reducer;
