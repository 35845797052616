import { mdiAccount, mdiAccountCancelOutline, mdiAccountOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import MuiAvatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useEmployee } from '../employees';

type AvatarProps = {
  employeeNumber: string;
  small?: boolean;
  large?: boolean;
  align?: 'left' | 'right' | undefined;
}

export const Avatar = (props: AvatarProps) => {
  const { employeeNumber, small, align } = props;
  const employee = useEmployee(employeeNumber);

  const isActive = employee ? employee.isActive : false;

  const icon = employee
    ? isActive ? mdiAccount : mdiAccountCancelOutline
    : mdiAccountOutline;

  return (
    <Tooltip title={employee ? employee.EmployeeName : 'Loading...'}>
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: align === 'left' ? 'row' : 'column',
          order: align === 'left' ? 1 : 0,
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <MuiAvatar
          variant="rounded"
          alt={employeeNumber}
          sx={theme => ({
            width: small ? theme.spacing(3) : theme.spacing(7),
            height: small ? theme.spacing(3) : theme.spacing(7),
            flexDirection: 'row',
            order: align === 'left' ? 1 : 0,
          })}>
          <Icon path={icon} size={1} />
        </MuiAvatar>
        <Typography variant="caption" color="initial" sx={theme => ({
          fontSize: theme.typography.caption.fontSize,
          padding: theme.spacing(0, 1),
          color: 'rgba(0,0,0,0.9)',
          order: align === 'left' ? 0 : 1,
        })}>
          {employeeNumber}
        </Typography>
      </Box>
    </Tooltip>
  );
}