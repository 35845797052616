import { mdiAccountCowboyHat } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEmployee } from './useEmployee';

type EmployeeCardProps = {
	employeeNumber?: string;
	label?: string | null | undefined;
	onClick: () => void;
};

export const EmployeeCard = (props: EmployeeCardProps) => {
	const { employeeNumber, label, onClick } = props;
	const employee = useEmployee(employeeNumber);
	const name = employee ? employee.EmployeeName : '';

	const handleOpen = () => {
		if (employee) {
			onClick();
		}
	};

	return (
		<TextField
			label={label}
			aria-label={name || ''}
			title={name}
			value={name || ''}
			onFocus={(e) => e.target.select()}
			variant="outlined"
			margin="dense"
			style={{ width: '100%' }}
			InputLabelProps={{
				sx: {
					'&[data-shrink=false]': {
						color: 'rgba(0,0,0,0.2)',
					},
				},
				shrink: true,
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment
						position="start"
						style={{ marginLeft: -6, paddingRight: 8 }}
					>
						<IconButton
							aria-label="Open"
							onClick={handleOpen}
							size="small"
							disabled={!employee}
						>
							<Icon path={mdiAccountCowboyHat} size={0.75} />
						</IconButton>
						<Typography
							variant="body2"
							color="primary"
							style={{ fontWeight: 'bold' }}
						>
							{employeeNumber}
						</Typography>
					</InputAdornment>
				),
			}}
		/>
	);
};
