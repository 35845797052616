import { Box, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import { Dialog } from './Dialog';
import { ErrorMessage } from './ErrorMessage';
import { Loading } from './Loading';

export type ValidationResult = {
	valid: boolean;
	text: string;
};

export type InputDialogAction = {
	title: string;
	text: string;
	defaultValue?: string;
	validate: (value: string) => ValidationResult;
	action: (value: string) => Promise<any>;
};

type Props = {
	isOpen: boolean;
	title: string;
	question: string;
	defaultValue?: string;
	validate: (value: string) => ValidationResult;
	onCancel: () => void;
	onConfirm: (value: string) => Promise<any>;
};

type State = {
	value: string;
	busy: boolean;
	error?: any;
};

export const InputDialog = ({
	isOpen,
	title,
	question,
	onCancel,
	onConfirm,
	defaultValue = '',
	validate,
}: Props) => {
	const [state, setState] = React.useState<State>({
		value: defaultValue,
		busy: false,
	});
	const validationResult = validate(state.value);

	const handleConfirmAction = async () => {
		setState((s) => ({ ...s, busy: true, error: undefined }));
		try {
			const result = await onConfirm(state.value);
			console.log('InputDialog Result', result);
			setState((s) => ({ ...s, busy: false }));
		} catch (error) {
			setState((s) => ({ ...s, busy: false, error }));
		}
	};

	return (
		<Dialog
			isOpen={isOpen}
			title={title}
			text={question}
			onClose={onCancel}
			cancelAction={{
				text: 'Cancel',
				color: 'inherit',
				onClick: onCancel,
			}}
			positiveAction={{
				text: 'Continue',
				color: 'secondary',
				onClick: handleConfirmAction,
				isDisabled: () => !validationResult.valid,
			}}
		>
			<Box sx={theme => ({
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				padding: theme.spacing(2),
			})}>
				<ErrorMessage
					error={state.error}
					onRetry={() =>
						setState((s) => ({ ...s, error: undefined }))
					}
				/>
				{state.busy && <Loading />}
				{!state.busy && (
					<>
						<TextField
							label=""
							aria-label="Input"
							title=""
							value={state.value || ''}
							onChange={(e) =>
								setState((s) => ({
									...s,
									value: e.target.value || '',
								}))
							}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
						{!!validationResult.text && (
							<Typography
								sx={theme => ({ marginTop: theme.spacing(3) })}
								variant="body2"
								color={
									validationResult.valid ? 'primary' : 'error'
								}
							>
								{validationResult.text}
							</Typography>
						)}
					</>
				)}
			</Box>
		</Dialog>
	);
};
