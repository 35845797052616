import React from 'react';

// Components
import { Dialog } from './Dialog';

export type ConfirmDialogAction = {
	title: string;
	text: string;
	action: () => void;
};

type ConfirmDialogProps = {
	isOpen: boolean;
	title: string;
	question: string;
	onCancel: () => void;
	onConfirm: () => void;
};

export const ConfirmDialog = (props: ConfirmDialogProps) => {
	const { isOpen, title, question, onCancel, onConfirm } = props;

	return (
		<Dialog
			isOpen={isOpen}
			title={title}
			text={question}
			onClose={onCancel}
			cancelAction={{
				text: 'Cancel',
				color: 'inherit',
				onClick: onCancel,
			}}
			positiveAction={{
				text: 'Continue',
				color: 'secondary',
				onClick: onConfirm,
			}}
		/>
	);
};
