import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton, useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { JobData, JobDetail, LookupTypes, formatDate } from '../../../core';
import { ApplicationEvent } from "../../ApplicationEvent";
import { Card, DatePicker } from '../../common';
import NumberField from "../../common/NumberField";
import { useLookupTypes } from '../../lookups';
import { OnHoldDialog } from './OnHoldDialog';

const dates = [
	{ title: 'Contacted', bool: 'isContacted', dt: 'ContactedDate' },
	{ title: 'Inspected', bool: 'isInspected', dt: 'InspectedDate' },
	{ title: 'Est. Submitted', bool: 'isEstimated', dt: 'EstimatedDate' },
	{ title: 'Sold', bool: 'isOutcome', dt: 'OutcomeDate' },
	{ title: 'Preliminary', bool: 'isPreliminary', dt: 'PreliminaryDate' },
	{ title: 'Prod Started', bool: 'isProdStarted', dt: 'ProdStartedDate' },
	{ title: 'Prod Complete', bool: 'isProdComplete', dt: 'ProdCompleteDate' },
	{
		title: 'Customer COC',
		bool: 'isCustomerApproval',
		dt: 'CustomerApprovalDate',
	},
	{ title: 'Invoiced', bool: 'isInvoiced', dt: 'InvoicedDate' },
	{ title: 'Paid in Full', bool: 'isPaidInFull', dt: 'PaidInFullDate' },
	{ title: 'Job Dead', bool: 'isJobKilled', dt: 'KillDate' },
];

type JobStatusProps = {
	canEditJob?: boolean;
	job?: JobDetail;
	onChange: (data: Partial<JobData>) => void;
};

export const JobStatus = ({ job, onChange, canEditJob }: JobStatusProps) => {
	const theme = useTheme();
	const [showOnHoldDialog, setShowOnHoldDialog] = useState(false);
	const [onHoldTypes] = useLookupTypes([LookupTypes.OnHoldReasonType]);

	const handleJobOnHoldUpdated = () => {
		if (job) {
			ApplicationEvent.ReloadJob(job.JobID);
		}
		setShowOnHoldDialog(false);
	}

	if (!job) return null;

	return (
		<>
			<Card sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
			}} label="Job Status">
				<Grid container direction="row" spacing={4}>
					<Grid item style={{ flexGrow: 1 }}>
						<Grid container direction="column">
							{dates.map((item) => (
								<FormControl
									key={item.dt}
									sx={{ backgroundColor: '#fff' }}
									margin="dense"
									variant='standard'
								>
									<InputLabel
										htmlFor={`status_${item.dt}`}
										style={{
											paddingTop: theme.spacing(0.5),
											paddingLeft: theme.spacing(1),
										}}
									>
										{item.title}
									</InputLabel>
									<Input
										id={`status_${item.dt}`}
										value={
											(job as any)[item.dt]
												? formatDate((job as any)[item.dt])
												: ''
										}
										sx={{ paddingLeft: theme.spacing(0.5) }}
										readOnly
										startAdornment={
											<InputAdornment position="start">
												<Icon
													path={
														(job as any)[item.bool] ===
															true
															? mdiCheckboxMarked
															: mdiCheckboxBlankOutline
													}
													size={1}
												/>
											</InputAdornment>
										}
									/>
								</FormControl>
							))}
						</Grid>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<Grid container direction="column">
							<DatePicker
								label="Received"
								disabled={!canEditJob}
								value={
									job?.LeadDate ? formatDate(job.LeadDate) : ''
								}
								onChange={(value) =>
									onChange({
										...job,
										LeadDate: value
											? new Date(value)
											: undefined,
									})
								}
							/>
							<DatePicker
								label="Date Of Loss"
								disabled={!canEditJob}
								value={
									job?.DateofLoss
										? formatDate(job.DateofLoss)
										: ''
								}
								onChange={(value) =>
									onChange({
										...job,
										DateofLoss: value
											? new Date(value)
											: undefined,
									})
								}
							/>
							<NumberField
								label="Est. Amount"
								value={job?.BidAmount || undefined}
								disabled={!canEditJob}
								onChange={(value) =>
									onChange({
										...job,
										BidAmount: value || undefined,
									})
								}
							/>
							<TextField
								label="Lead Probability"
								value={job?.LeadProbability || 'N/A'}
								disabled={!canEditJob}
								sx={{ backgroundColor: '#fff' }}
								variant="outlined"
								margin="dense"
								select
								onChange={(e) =>
									onChange({
										...job,
										LeadProbability: e.target.value || 'N/A',
									})
								}
								InputLabelProps={{ shrink: true }}
							>
								{[
									'N/A',
									'25%',
									'50%',
									'70%',
									'80%',
									'90%',
									'99%',
								].map((item) => (
									<MenuItem key={item} value={item}>
										{item}
									</MenuItem>
								))}
							</TextField>
							<DatePicker
								label="Est. Start"
								value={
									job?.EstimatedStartDate
										? formatDate(job.EstimatedStartDate)
										: ''
								}
								disabled={!canEditJob}
								onChange={(value) =>
									onChange({
										...job,
										EstimatedStartDate: value
											? new Date(value)
											: undefined,
									})
								}
							/>
							<DatePicker
								label="Est. Completion"
								value={
									job?.EstimatedCompletionDate
										? formatDate(job.EstimatedCompletionDate)
										: ''
								}
								disabled={!canEditJob}
								onChange={(value) =>
									onChange({
										...job,
										EstimatedCompletionDate: value
											? new Date(value)
											: undefined,
									})
								}
							/>

							<FormControl
								sx={{ backgroundColor: '#fff', marginBottom: theme.spacing(1) }}
								margin="dense"
								variant='standard'
								fullWidth
							>
								<InputLabel
									htmlFor={`on_hold`}
									style={{
										paddingTop: theme.spacing(0.5),
										paddingLeft: theme.spacing(2),
									}}
								>
									On Hold
								</InputLabel>
								<Input
									id={`on_hold`}
									value={
										job?.OnHoldDate
											? `${formatDate(job.OnHoldDate)}: ${job.OnHoldReasonID ? onHoldTypes.find(x => x.ID === job.OnHoldReasonID)?.Name || 'Unknown' : ''}`
											: ''
									}
									readOnly
									startAdornment={
										<InputAdornment position="start">
											<IconButton onClick={() => setShowOnHoldDialog(true)}>
												<Icon
													path={
														job?.IsOnHold === true
															? mdiCheckboxMarked
															: mdiCheckboxBlankOutline
													}
													size={1}
												/>
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>

							<DatePicker
								label="Warranty Date"
								value={
									job?.WarrentyExpiresDate
										? formatDate(job.WarrentyExpiresDate)
										: ''
								}
								disabled={!canEditJob}
								onChange={(value) =>
									onChange({
										...job,
										WarrentyExpiresDate: value
											? new Date(value)
											: undefined,
									})
								}
							/>
							<DatePicker
								label="Lien Rights Date"
								value={
									job?.LienRightsDate
										? formatDate(job.LienRightsDate)
										: ''
								}
								disabled={!canEditJob}
								onChange={(value) =>
									onChange({
										...job,
										LienRightsDate: value
											? new Date(value)
											: undefined,
									})
								}
							/>
							<DatePicker
								label="Act Lien Date"
								value={
									job?.LienDate ? formatDate(job.LienDate) : ''
								}
								disabled={!canEditJob}
								onChange={(value) =>
									onChange({
										...job,
										LienDate: value
											? new Date(value)
											: undefined,
									})
								}
							/>
							<DatePicker
								label="Lien Rel Date"
								value={
									job?.LienReleasedDate
										? formatDate(job.LienReleasedDate)
										: ''
								}
								disabled={!canEditJob}
								onChange={(value) =>
									onChange({
										...job,
										LienReleasedDate: value
											? new Date(value)
											: undefined,
									})
								}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Card>
			{showOnHoldDialog && (
				<OnHoldDialog
					job={job}
					onCancel={() => setShowOnHoldDialog(false)}
					onJobUpdated={handleJobOnHoldUpdated}
				/>
			)}
		</>
	);
};
