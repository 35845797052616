import {
	mdiFilePdfBox,
	mdiImageOutline,
	mdiMicrosoftExcel,
	mdiMicrosoftPowerpoint,
	mdiMicrosoftWord,
	mdiPaperclip,
	mdiVideoOutline,
} from '@mdi/js';
import { max, orderBy, uniqBy } from 'lodash';
import { ThumbnailToken, getThumbnailKey } from '../../core';
import { ValidationResult } from '../common/InputDialog';
import { S3BrowserView, S3Object, defaultFolders } from './types';

export const SupportedImageTypes = [
	'png',
	'jpg',
	'jpeg',
	'jfif',
	'pjpeg',
	'pjp',
	'svg',
	'webp',
	'gif',
	'avif',
	'apng',
];

export const SupportedVideoTypes = [
	'mp4',
	'avi',
	'mov',
	'webm',
	'3gp',
	'mpg',
	'mpeg',
	'ogg',
];

export const SupportedMediaTypes = [
	...SupportedImageTypes,
	...SupportedVideoTypes,
];

export type MediaType = 'image' | 'video' | 'file';

export type FileData = {
	key: string;
	contentType: string;
	name: string;
	ext: string;
	size: number;
	file?: File;
};

export type StorageStatus = 'idle' | 'loading' | 'complete' | 'error';



// Keys

// 14252/25452WX_TBA-EMS-BILLING.pdf
export const getFolder = (key: string): string => {
	if (key.endsWith('/')) return key;
	const folder = key.split('/').slice(0, -1).join('/');
	return folder.endsWith('/') ? folder : `${folder}/`;
};

export const getParentFolder = (key: string): string => {
	const offset = key.endsWith('/') ? -3 : -2;
	const folder = key.split('/').slice(0, offset).join('/');
	return `${folder}/`;
};

export const getSubFolder = (parentKey: string, name: string): string => {
	const f = getFolder(parentKey);
	return `${f}${name}/`;
};

export const getKeyIsBottomLevel = (parent: string, key: string): boolean => {
	if (key.startsWith(parent)) {
		const segments = key.split(parent);
		if (segments.length > 1 && segments[1]) {
			return segments[1].split('/').filter((x) => !!x).length === 1;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const getKeyName = (key: string): string => {
	let data = key;
	if (data.endsWith('/')) {
		data = data.substring(0, data.length - 1);
		// console.log('Folder Name strip trailing /', data);
	}
	const name = data.split('/').pop();
	return name || 'Job';
};

export const getFolderPath = (key: string): string[] => {
	const f = getFolder(key);

	const segments = f.split('/').filter((x) => !!x);

	const results: string[] = segments[0] ? [segments[0]] : [];
	segments.reduce((prev, current) => {
		const path = `${prev}/${current}`;
		results.push(path);
		return path;
	});

	return results.map((x) => `${x}/`);
};

export const isSystemFolder = (root: string, key: string): boolean => {
	if (root === key) return true;
	const f = getFolder(key).replace(root, '');
	if (!f) return true;
	return defaultFolders.includes(f);
};

export const isFolderEmpty = (all: string[], key: string): boolean => {
	const folder = getFolder(key);
	return all.filter((x) => x.startsWith(folder)).length === 0;
};

export const getSubFolders = (all: string[], key: string): string[] => {
	const folder = getFolder(key);
	return all
		.filter((x) => x.startsWith(folder))
		.filter((x) => getKeyIsBottomLevel(key, x))
		.map(getFolder)
		.filter((x) => x !== folder && x !== '/');
};

export const getSubFolderObjects = (
	all: S3Object[],
	key: string
): S3Object[] => {
	const folder = getFolder(key);
	const subFolderKeys = all
		.filter((x) => x.key.startsWith(folder))
		.filter((x) => getKeyIsBottomLevel(key, x.key))
		.map((x) => x.key);

	return getObjectsFromKeys(all, subFolderKeys);
};

export const getSubFileObjects = (
	all: S3Object[],
	folder: string
): S3Object[] => {
	const keys = all
		.filter((x) => x.key.startsWith(folder))
		.filter((x) => getKeyIsBottomLevel(folder, x.key))
		.map((x) => x.key);

	return getObjectsFromKeys(all, keys);
};

export const getFileCount = (all: string[], key: string): number => {
	const folder = getFolder(key);
	const result = all.filter(
		(x) =>
			x.startsWith(folder) &&
			x !== folder &&
			x !== '/' &&
			!x.endsWith('/') &&
			!x.includes(ThumbnailToken)
	);
	// console.log('getFileCount', folder, result.length);
	return result.length;
};

export const getFolderLastModified = (all: S3Object[], key: string): Date => {
	const folder = getFolder(key);
	const dates = all
		.filter((x) => x.key.startsWith(folder))
		.map((x) => x.lastModified.toISOString());
	const dt = max(dates);
	return dt ? new Date(dt) : new Date('1900-01-01');
};

export const mapS3Folders = (
	rootFolder: string,
	all: S3Object[]
): S3Object[] => {
	const allKeys = all.map((x) => x.key);
	const folders: S3Object[] = [];
	const files = all.filter((x) => x.type === 'file');
	const root = rootFolder.endsWith('/') ? rootFolder : `${rootFolder}/`;
	const totalSize = getFileCount(allKeys, root);
	folders.push({
		key: root,
		size: totalSize,
		name: getKeyName(root),
		lastModified: getFolderLastModified(all, root),
		type: 'folder',
	});
	files.forEach((file) => {
		const key = getFolder(file.key);
		const size = getFileCount(allKeys, key);
		folders.push({
			key,
			size,
			name: getKeyName(key),
			lastModified: getFolderLastModified(all, key),
			type: 'folder',
		});
	});
	const folderKeys = folders.map(x => x.key);
	all
		.filter(x => x.type === 'folder')
		.filter(x => !folderKeys.includes(x.key))
		.forEach(x => {
			folders.push({
				key: x.key,
				size: getFileCount(allKeys, x.key),
				name: getKeyName(x.key),
				lastModified: getFolderLastModified(all, x.key),
				type: 'folder',
			});
		})
	// console.log('MAP FOLDERS', folders, uniqBy(folders, (x) => x.key));
	return orderBy(
		uniqBy(folders, (x) => x.key),
		['key'],
		['asc']
	);
};

export const parseObjectList = (
	root: string,
	data: S3Object[]
): [S3Object[], S3Object[], number] => {
	const items = data;
	const folders = mapS3Folders(root, items);
	// console.warn('mapFoldersResult', folders);

	const fullFiles = items.filter((x) => x.type === 'file' && !x.isThumbnail);
	const thumbFiles = items.filter(
		(x) => x.type === 'file' && x.isThumbnail === true
	);

	const files: S3Object[] = [];
	fullFiles.forEach((f) => {
		// console.log('Parsing File', f);
		const thumbnailKey = getThumbnailKey(f.key);
		const thumb = thumbFiles.find((x) => x.key === thumbnailKey);
		if (thumb) {
			// console.log(`Found thumbnail for ${f.key}`);
			files.push(thumb);
		} else {
			files.push(f);
		}
	});

	const count = fullFiles.length;
	return [folders, files, count];
};

export const getObjectsFromKeys = (
	all: S3Object[],
	keys: string[]
): S3Object[] => {
	return all.filter((x) => keys.includes(x.key));
};

export const toggleS3BrowserView = (current: S3BrowserView): S3BrowserView => {
	return current === 'list' ? 'thumbnail' : 'list';
};

export const getFileIcon = (key: string): string => {
	const name = getKeyName(key);
	const segments = name.split('.');
	if (segments.length > 1) {
		const ext = segments.pop()?.toLowerCase();
		if (!ext) return mdiPaperclip;
		switch (ext) {
			case 'pdf':
				return mdiFilePdfBox;
			case 'xls':
				return mdiMicrosoftExcel;
			case 'xlsx':
				return mdiMicrosoftExcel;
			case 'doc':
				return mdiMicrosoftWord;
			case 'docx':
				return mdiMicrosoftWord;
			case 'ppt':
				return mdiMicrosoftPowerpoint;
			case 'pptx':
				return mdiMicrosoftPowerpoint;
			case 'png':
				return mdiImageOutline;
			case 'jpg':
				return mdiImageOutline;
			case 'jpeg':
				return mdiImageOutline;
			case 'jfif':
				return mdiImageOutline;
			case 'pjpeg':
				return mdiImageOutline;
			case 'pjp':
				return mdiImageOutline;
			case 'svg':
				return mdiImageOutline;
			case 'webp':
				return mdiImageOutline;
			case 'gif':
				return mdiImageOutline;
			case 'avif':
				return mdiImageOutline;
			case 'apng':
				return mdiImageOutline;
			case 'mp4':
				return mdiVideoOutline;
			case 'avi':
				return mdiVideoOutline;
			case 'mov':
				return mdiVideoOutline;
			case 'webm':
				return mdiVideoOutline;
			case '3gp':
				return mdiVideoOutline;
			case 'mpg':
				return mdiVideoOutline;
			case 'mpeg':
				return mdiVideoOutline;
			case 'ogg':
				return mdiVideoOutline;

			default:
				return mdiPaperclip;
		}
	}
	return mdiPaperclip;
};

// export const createFilePath = (jobId: number, file: FileData): string => {
// 	return `${jobId}/${file.name}.${file.ext}`;
// };

export const createFileData = (
	prefix: string,
	folder: string,
	file: File
): FileData => {
	const s = file.name.toLowerCase().split('.');
	const ext = s.pop() || 'txt';
	const name = s.join('_');

	const segments = new Date().toISOString().split('.');
	const d = segments[0] ? segments[0].replaceAll(':', '-') : '';
	const f = getFolder(folder);
	const key = `${f}${prefix}_${d}_${name}.${ext}`;

	return {
		key,
		contentType: file.type,
		name,
		size: file.size,
		ext,
		file,
	};
};

export const validateAddSubFolder = (
	root: string | undefined,
	objects: S3Object[],
	parent: S3Object,
	value: string
): ValidationResult => {
	const name = value.trim();

	if (!name) {
		return {
			valid: false,
			text: 'Please enter the name of the new folder to add.',
		};
	}

	if (name === root) {
		return {
			valid: false,
			text: 'Please choose a name that is not already part of the folder path.',
		};
	}

	if (!/^[a-zA-Z0-9 ]*$/.test(name)) {
		return {
			valid: false,
			text: 'The name should contain letters, numbers, and spaces only.',
		};
	}

	if (defaultFolders.map((x) => getKeyName(x)).includes(name)) {
		return {
			valid: false,
			text: 'Please choose a name that is not included in our default folder structure.',
		};
	}

	const key = getSubFolder(parent.key, name);

	if (objects.map((x) => x.key).includes(key)) {
		return {
			valid: false,
			text: `The folder path '${key}' already exists`,
		};
	}

	if (objects.map((x) => getKeyName(x.key)).includes(key)) {
		return {
			valid: false,
			text: 'Please choose a name that is not already part of the folder path.',
		};
	}

	return {
		valid: true,
		text: `The following folder will be created: '${key}'.`,
	};
};

export const validateRenameFolder = (
	root: string | undefined,
	objects: S3Object[],
	parent: S3Object,
	name: string
): ValidationResult => {
	// console.log('validateRenameFolder', root, parent.key, name);
	return {
		valid: false,
		text: 'The feature is not yet supported',
	};
};

export const validateRenameFile = (
	root: string | undefined,
	objects: S3Object[],
	parent: S3Object,
	name: string
): ValidationResult => {
	// console.log('validateRenameFile', root, parent.key, name);
	return {
		valid: false,
		text: 'The feature is not yet supported',
	};
};

export function downloadBlob(url: string, filename: string) {
	// const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = filename || 'download';
	const clickHandler = () => {
		setTimeout(() => {
			URL.revokeObjectURL(url);
			a.removeEventListener('click', clickHandler);
		}, 150);
	};
	a.addEventListener('click', clickHandler, false);
	a.click();
	return a;
}

export const isMediaFile = (name: string): boolean => {
	const s = name.split('.');
	const ext = s.pop();
	return ext ? SupportedMediaTypes.includes(ext.toLowerCase()) : false;
};
