import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useAppContext } from '../../contexts/AppContext';

type JobTitleButtonProps = {
	title: string;
	tooltip: string;
	onClick: () => void;
	count: number;
	iconPath: string;
};

export const ToolbarButton = (props: JobTitleButtonProps) => {
	const { isWideLayout } = useAppContext();
	const {
		title,
		tooltip,
		onClick,
		count,
		iconPath,
	} = props;

	return (
		<Tooltip title={tooltip}>
			<Button
				size="small"
				sx={{ 
					flexDirection: 'column', 
					alignItems: 'flex-end',
				}}
				onClick={onClick}
			>
				<Badge
					badgeContent={count}
					color="secondary"
					overlap="rectangular"
					sx={{
						top: 6,
						padding: '0 2px',
						color: '#fff',
						alignSelf: 'center'
					}}
				>
					<Icon path={iconPath} size={0.9} style={{ alignSelf: 'center' }} />
				</Badge>
				{isWideLayout && (
					<Typography variant="caption" sx={{ color: '#fff', marginTop: '8px', alignSelf: 'center' }}>
						<strong>{title}</strong>
					</Typography>
				)}
			</Button>
		</Tooltip>
	);
};
