import { mdiCheckboxOutline, mdiLockReset } from '@mdi/js';
import Icon from '@mdi/react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { User } from '../../core';
import { useAppSelector } from '../../store';
import { SearchField } from '../common/SearchField';
import { selectAllEmployees } from '../employees';
import { AddUserDialog } from './AddUserDialog';
import { ResetUserPasswordDialog } from './ResetUserPasswordDialog';
import { UpdateUserDialog } from './UpdateUserDialog';

/**
 * TABLES
 * Employee
 * UserEmployee
 *
 * Show Active/InActive Users
 *
 * EDIT
 * EmployeeNumber readonly
 * Separate Set Password
 *
 * ADD
 * EmployeeNumber Unique
 */

const applyFilter = (
	employees: User[],
	text: string,
	includeInActive: boolean
): User[] => {
	if (!text) {
		return includeInActive === true
			? employees
			: employees.filter((x) => x.isActive === true);
	}
	const v = text.toLowerCase();
	console.log('filterText', v);
	const results = employees.filter(
		(x) =>
			x.EmployeeNumber.toLowerCase().includes(v) ||
			(x.EmployeeName &&
				(x.EmployeeName || '').toLowerCase().includes(v)) ||
			(x.UserRoleName && (x.UserRoleName || '').toLowerCase().includes(v))
	);
	return includeInActive === true
		? results
		: results.filter((x) => x.isActive === true);
};

export const UserManagement = () => {
	const employees = useAppSelector(selectAllEmployees);
	const { isWideLayout } = useAppContext();
	const [editUser, setEditUser] = React.useState<User>();
	const [editPassword, setEditPassword] = React.useState<User>();
	const [addUser, setAddUser] = React.useState(false);
	const [includeInActive, setIncludeInactive] = React.useState(false);
	const [filterText, setFilterText] = React.useState('');

	const items = applyFilter(employees, filterText, includeInActive);

	return (
		<>
			<Toolbar
				variant="dense"
				disableGutters
				style={{ padding: 0, marginBottom: '8px', marginLeft: '-4px' }}
			>
				<SearchField
					placeholder="search by number, name, and role"
					text={filterText}
					onChange={setFilterText}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={includeInActive}
							onChange={(_event, checked) =>
								setIncludeInactive(checked)
							}
							name="checkedB"
							color="secondary"
						/>
					}
					label="Show Inactive"
					style={{
						minWidth: '150px',
					}}
				/>
				<Button
					color="secondary"
					variant="contained"
					onClick={() => setAddUser(true)}
					style={{
						minWidth: '120px',
						margin: '8px',
						whiteSpace: 'nowrap',
					}}
				>
					Add User
				</Button>
			</Toolbar>
			<TableContainer component={Paper} style={{ paddingBottom: '16px' }}>
				<Table
					sx={{
						minWidth: 650
					}}
					size="small"
					stickyHeader
					aria-label="a dense table"
				>
					<TableHead>
						<TableRow>
							<TableCell>Number</TableCell>
							<TableCell align="right">Name</TableCell>
							<TableCell align="right">Role</TableCell>
							{isWideLayout && (
								<>
									<TableCell align="right">EST</TableCell>
									<TableCell align="right">PM</TableCell>
									<TableCell align="right">LEVEL 5</TableCell>
									<TableCell align="right">LEAD</TableCell>
									<TableCell align="right">ADMIN</TableCell>
									<TableCell align="right">ACCT</TableCell>
									<TableCell align="right">DRM</TableCell>
									<TableCell align="right">OPEN</TableCell>
									<TableCell align="right">MKT</TableCell>
								</>
							)}
							<TableCell align="right">Active</TableCell>
							<TableCell align="right">&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((item, index) => (
							<TableRow
								hover
								key={`${item.EmployeeNumber}_${index}`}
								sx={{ cursor: 'pointer' }}
							>
								<TableCell
									component="th"
									scope="row"
									onClick={() => setEditUser(item)}
								>
									{item.EmployeeNumber}
								</TableCell>
								<TableCell
									align="right"
									onClick={() => setEditUser(item)}
								>
									{item.EmployeeName}
								</TableCell>
								<TableCell
									align="right"
									onClick={() => setEditUser(item)}
								>
									{item.UserRoleName}
								</TableCell>
								{isWideLayout && (
									<>
										<TableCell
											align="right"
											onClick={() => setEditUser(item)}
										>
											{item.usr1 && (
												<Icon
													path={mdiCheckboxOutline}
													size={1}
												/>
											)}
											&nbsp;
										</TableCell>
										<TableCell
											align="right"
											onClick={() => setEditUser(item)}
										>
											{item.usr2 && (
												<Icon
													path={mdiCheckboxOutline}
													size={1}
												/>
											)}
											&nbsp;
										</TableCell>
										<TableCell
											align="right"
											onClick={() => setEditUser(item)}
										>
											{item.usr3 && (
												<Icon
													path={mdiCheckboxOutline}
													size={1}
												/>
											)}
											&nbsp;
										</TableCell>
										<TableCell
											align="right"
											onClick={() => setEditUser(item)}
										>
											{item.usr4 && (
												<Icon
													path={mdiCheckboxOutline}
													size={1}
												/>
											)}
											&nbsp;
										</TableCell>
										<TableCell
											align="right"
											onClick={() => setEditUser(item)}
										>
											{item.usr5 && (
												<Icon
													path={mdiCheckboxOutline}
													size={1}
												/>
											)}
											&nbsp;
										</TableCell>
										<TableCell
											align="right"
											onClick={() => setEditUser(item)}
										>
											{item.usr6 && (
												<Icon
													path={mdiCheckboxOutline}
													size={1}
												/>
											)}
											&nbsp;
										</TableCell>
										<TableCell
											align="right"
											onClick={() => setEditUser(item)}
										>
											{item.usr7 && (
												<Icon
													path={mdiCheckboxOutline}
													size={1}
												/>
											)}
											&nbsp;
										</TableCell>
										<TableCell
											align="right"
											onClick={() => setEditUser(item)}
										>
											{item.usr8 && (
												<Icon
													path={mdiCheckboxOutline}
													size={1}
												/>
											)}
											&nbsp;
										</TableCell>
										<TableCell
											align="right"
											onClick={() => setEditUser(item)}
										>
											{item.usr9 && (
												<Icon
													path={mdiCheckboxOutline}
													size={1}
												/>
											)}
											&nbsp;
										</TableCell>
									</>
								)}
								<TableCell
									align="right"
									onClick={() => setEditUser(item)}
								>
									{item.isActive ? 'Active' : 'Inactive'}
								</TableCell>
								<TableCell align="right">
									<IconButton
										onClick={() => setEditPassword(item)}
									>
										<Icon path={mdiLockReset} size={1} />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{editUser && (
				<UpdateUserDialog
					user={editUser}
					onClose={() => setEditUser(undefined)}
				/>
			)}
			{editPassword && (
				<ResetUserPasswordDialog
					user={editPassword}
					onClose={() => setEditPassword(undefined)}
				/>
			)}
			{addUser && <AddUserDialog onClose={() => setAddUser(false)} />}
		</>
	);
};
