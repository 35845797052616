import { mdiCheckCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import MuiChip from '@mui/material/Chip';

type ChipProps = {
	label: string;
	selected: boolean;
	disabled?: boolean;
	onClick?: () => void;
};

export const Chip = (props: ChipProps) => {
	const { label, selected, onClick, disabled } = props;

	return (
		<MuiChip
			size="small"
			icon={
				selected ? (
					<Icon path={mdiCheckCircleOutline} size={0.75} />
				) : undefined
			}
			label={label}
			clickable
			disabled={disabled}
			color={selected ? 'secondary' : 'primary'}
			variant="outlined"
			sx={theme => ({
				margin: theme.spacing(1),
				flexGrow: 1,
				display: 'flex',
				'&.MuiChip-colorSecondary': {
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.secondary.contrastText,
				},
				'&:hover.MuiChip-colorSecondary': {
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.secondary.contrastText,
				},
				'&:focus.MuiChip-colorSecondary': {
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.secondary.contrastText,
				},
			})}
			onClick={onClick}
			style={{ fontWeight: selected ? 'bold' : 'normal' }}
			tabIndex={-1}
		/>
	);
};
