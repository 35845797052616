import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { LookupTypes, StreetAddressData } from '../../core';
import { useLookupTypes } from '../lookups';

export type AddressListItemProps = {
	address?: StreetAddressData | Partial<StreetAddressData>;
	style?: React.CSSProperties;
	className?: string;
	props?: any;
};

export const AddressListItem = (props: AddressListItemProps) => {
	const [types] = useLookupTypes([LookupTypes.AddressType]);
	const { address, style, className } = props;
	const type = address?.AddressTypeID
		? types?.find((x: any) => x.ID === address?.AddressTypeID)
		: undefined;

	console.log('AddressListItem', address)

	return (
		<ListItem
			sx={theme => ({
				fontSize: theme.typography.caption.fontSize,
				fontWeight: 500,
				color: theme.palette.secondary.main,
			})}
			style={style}
			className={className}
			component="div"
			{...props.props}
		>
			<ListItemText
				primary={type?.Name || 'Not Found'}
				secondary={`${address?.AddressLine1 || ''} ${address?.AddressLine2 || ''
					} ${address?.City || ''} ${address?.State || ''} ${address?.ZIP || ''
					}`}
			/>
		</ListItem>
	);
};
