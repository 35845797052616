import { Box } from '@mui/material';
import List from '@mui/material/List';
import { FileListItem } from './FileListItem';
import { FileThumbnail } from './FileThumbnail';
import { useS3FileBrowserContext } from './S3FileBrowserContext';
import { FileOpenedEvent, S3BrowserView, S3Object } from './types';
import { getSubFileObjects } from './utils';

type Props = {
	folder: string;
	view: S3BrowserView;
	fileFilter: (objects: S3Object[]) => S3Object[];
	onFileOpen?: FileOpenedEvent;
	readOnly?: boolean;
	canDelete?: boolean;
};

export const S3Files = ({
	folder,
	view,
	fileFilter,
	onFileOpen,
	readOnly,
	canDelete,
}: Props) => {
	const { files: allFiles } = useS3FileBrowserContext();
	const files = fileFilter(getSubFileObjects(allFiles, folder));

	if (view === 'list') {
		return (
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
			}}>
				<List>
					{files.map((item) => (
						<FileListItem
							readOnly={readOnly}
							canDelete={canDelete}
							key={item.key}
							file={item}
							onFileOpen={onFileOpen}
						/>
					))}
				</List>
			</Box>
		);
	}

	return (
		<Box sx={theme => ({
			display: 'flex',
			flexFlow: 'row wrap',
			padding: theme.spacing(0.5),
			marginRight: theme.spacing(1.5),
		})}>
			{files.map((item) => (
				<FileThumbnail
					readOnly={readOnly}
					canDelete={canDelete}
					key={item.key}
					file={item}
					onFileOpen={onFileOpen}
					size={0}
					thumbnail={true}
				/>
			))}
		</Box>
	);
};
