import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { AppBar, Box, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { isFunction } from 'lodash';
import React from 'react';
import { useAppContext } from '../../contexts/AppContext';

// const Transition = React.forwardRef(function Transition(
// 	props: TransitionProps & { children?: React.ReactElement<any, any> },
// 	ref: React.Ref<unknown>
// ) {
// 	const { children } = props;
// 	return <Slide direction="up" ref={ref} {...props}>{children}</Slide>;
// });

type DialogAction = {
	text: string;
	onClick: () => void;
	isDisabled?: boolean | (() => boolean);
	color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
};

type DialogProps = {
	isOpen: boolean;
	title: string;
	text?: string;
	fullHeight?: boolean;
	fullScreen?: boolean;
	onClose?: () => void;
	cancelAction?: DialogAction;
	positiveAction?: DialogAction;
	auxAction?: DialogAction;
	negativeAction?: DialogAction;
	children?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
	forPrinting?: boolean;
};

const isDisabled = (action: DialogAction): boolean => {
	if (action.isDisabled === true) return true;
	if (isFunction(action.isDisabled)) return action.isDisabled();
	return false;
};

export const Dialog = (props: DialogProps) => {
	const { isMobileLayout } = useAppContext();
	const {
		children,
		isOpen,
		title,
		text,
		cancelAction,
		positiveAction,
		auxAction,
		negativeAction,
		onClose,
		fullHeight = false,
		fullScreen = false,
		forPrinting = false,
	} = props;

	const hasNoAction = !cancelAction && !positiveAction && !negativeAction;

	return (
		<MuiDialog
			open={isOpen}
			// TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			sx={theme => ({
				'& .MuiDialogContent-root': {
					padding: 0,
				},
				'& .MuiDialogTitle-root': {
					padding: theme.spacing(1, 1.5, 0.25, 1.5),
				},
				'& .MuiDialog-paperScrollPaper': {
					minWidth: isMobileLayout ? '100%' : 600,
					minHeight: fullHeight ? 'calc(100% - 64px)' : undefined,
				},
				$content: {
					minWidth: isMobileLayout ? '100%' : undefined,
				},
			})}
			fullScreen={isMobileLayout || fullScreen}
		>
			<AppBar
				position="relative"
				className={forPrinting ? 'hide-for-printing' : undefined}
			>
				<Toolbar variant="dense">
					<span style={{ flexGrow: 1, fontWeight: 500 }}>
						{title}
					</span>
					<IconButton
						size="small"
						edge="end"
						aria-label="Close"
						onClick={onClose}
					>
						<Icon path={mdiClose} size={1} color="#fff" />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent sx={theme => ({
				position: 'relative',
				display: forPrinting === true ? 'block' : 'flex',
				flexDirection: 'column',
				minHeight: forPrinting !== true ? '200px' : undefined,
				marginTop: theme.spacing(2),
				marginX: forPrinting === true ? theme.spacing(2) : undefined,
				marginY: forPrinting === true ? theme.spacing(2) : undefined,
			})}>
				{text && (
					<DialogContentText id="alert-dialog-slide-description">
						<Box component="span" sx={theme => ({
							display: 'block',
							padding: theme.spacing(2),
							fontSize: theme.typography.body1.fontSize,
						})}>{text}</Box>
					</DialogContentText>
				)}
				{children}
			</DialogContent>
			<Divider className={forPrinting ? 'hide-for-printing' : undefined} />
			<DialogActions className={forPrinting ? 'hide-for-printing' : undefined}>
				{cancelAction && (
					<Button
						variant="text"
						onClick={cancelAction.onClick}
						sx={{
							color: cancelAction.color || 'default'
						}}
						disabled={isDisabled(cancelAction)}
					>
						{cancelAction.text || 'Cancel'}
					</Button>
				)}
				<span className="flex" />
				{negativeAction && (
					<Button
						variant="contained"
						onClick={negativeAction.onClick}
						color={negativeAction.color || 'secondary'}
						disabled={isDisabled(negativeAction)}
					>
						{negativeAction.text || 'No'}
					</Button>
				)}
				{positiveAction && (
					<Button
						variant="contained"
						onClick={positiveAction.onClick}
						color={positiveAction.color || 'primary'}
						disabled={isDisabled(positiveAction)}
					>
						{positiveAction.text || 'Okay'}
					</Button>
				)}
				{auxAction && (
					<Button
						variant="contained"
						onClick={auxAction.onClick}
						color={auxAction.color || 'primary'}
						disabled={isDisabled(auxAction)}
					>
						{auxAction.text || 'Okay'}
					</Button>
				)}
				{hasNoAction && (
					<Button
						variant="contained"
						onClick={onClose}
						color="primary"
					>
						Okay
					</Button>
				)}
			</DialogActions>
		</MuiDialog>
	);
};
