import { Box, SxProps, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BottomSpace } from './BottomSpace';

export type ContainerProps = {
	component: string;
	children: React.ReactNode;
	direction?: 'row' | 'column';
	scrollable?: boolean;
	sx?: SxProps<Theme>;
	onErrorReset?: () => void;
	error?: any;
	loading?: boolean;
	working?: boolean;
};

export type ErrorFallbackProps = {
	error?: any;
	onReset?: () => void;
};

const ErrorFallback = (props: ErrorFallbackProps) => {
	const { error, onReset } = props;
	if (!error) return null;
	return (
		<Box role="alert" sx={theme => ({
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			padding: theme.spacing(2),
			'& pre': {
				whiteSpace: 'normal',
			},
			'& button': {
				maxWidth: '180px',
			},
		})}>
			<p>Something went wrong:</p>
			<pre>{`${error}` || 'Unknown Error'}</pre>
			{onReset && (
				<Button variant="contained" color="primary" onClick={onReset}>
					Try Again
				</Button>
			)}
		</Box>
	);
};

export const Container = (props: ContainerProps) => {
	const {
		component,
		children,
		scrollable,
		onErrorReset,
		direction = 'column',
		sx = {},
		error,
		loading = false,
		working = false,
	} = props;

	let styles: SxProps<Theme> = {
		position: 'relative',
		display: 'flex',
		flexGrow: 1,
		height: '100%',
		width: '100%',
		overflow: 'hidden',
	}

	if (scrollable) {
		styles = {
			...styles,
			overflowY: 'auto',
		}
	}

	if (direction === 'column') {
		styles = {
			...styles,
			flexDirection: 'column',
		}
	}
	if (direction === 'row') {
		styles = {
			...styles,
			flexDirection: 'row',
		}
	}

	return (
		<Box
			id={component}
			data-component={component}
			sx={{
				...styles,
				...sx,
			}}
		>
			{(loading || working) && <LinearProgress color="secondary" />}
			<ErrorBoundary
				FallbackComponent={ErrorFallback}
				onReset={onErrorReset}
			>
				<ErrorFallback error={error} onReset={onErrorReset} />
				{!error && !loading && !working && <>{children}</>}
				{scrollable && <BottomSpace />}
			</ErrorBoundary>
		</Box>
	);
};
