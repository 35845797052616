import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

type CaptionProps = {
	id?: string;
	text: string;
	sx?: any;
};

export const Caption = (props: CaptionProps) => {
	const { text, sx = {}, id } = props;

	return (
		<Box id={id} sx={theme => ({
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			padding: theme.spacing(1),
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			...sx,
		})}>
			<Typography variant="caption" color="initial">
				{text}
			</Typography>
		</Box>
	);
};
