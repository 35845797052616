import { ListItemButton, useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';

type Props = {
    primary: string;
    secondary?: string;
    onChanged: (checked: boolean) => void;
    checked: boolean;
    disabled?: boolean;
};

export default function SwitchItem({ primary, secondary, onChanged, checked, disabled }: Props) {
    const theme = useTheme();
    return (
        <Paper variant="outlined" style={{ borderRadius: 0, padding: theme.spacing(0, 0, 0, 1) }}>
            <List disablePadding style={{ marginBottom: '12px', marginBlockEnd: 0 }}>
                <ListItemButton onClick={() => onChanged(!checked)} disabled={disabled}>
                    <ListItem
                        secondaryAction={(
                            <Switch
                                edge="end"
                                checked={checked === true}
                                disabled={disabled}
                                onChange={(_e, isChecked) => onChanged(isChecked)}
                            />
                        )}
                    >
                        <ListItemText primary={primary} secondary={secondary} />
                    </ListItem>
                </ListItemButton>
            </List>
        </Paper>
    )
}