import { Dispatch, useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

// Always assumes that the value is a serialized object
export const getLocalStorageItem = <T>(key: string, defaultValue: T): T => {
    if (window.localStorage) {
        const data = window.localStorage.getItem(key);
        if (data) {
            try {
                return (JSON.parse(data) as T) || defaultValue;
            } catch (error) {
                console.error(error);
                return defaultValue;
            }
        } else {
            return defaultValue;
        }
    } else {
        console.warn('Local Storage is not supported');
        return defaultValue;
    }
}

// Always assumes that the value is a serialized object
export const setLocalStorageItem = <T>(key: string, value: T) => {
    if (window.localStorage) {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(error);
        }
    } else {
        console.warn('Local Storage is not supported');
    }
}

// Borrowed from https://github.com/dance2die/react-use-localstorage/blob/master/src/index.ts
// extended to support Types and serialization
export const useLocalStorage = <T>(
    key: string,
    initialValue: T
): [T, Dispatch<T>] => {
    const [value, setValue] = useState<T>(
        () => getLocalStorageItem<T>(key, initialValue)
    );

    const setItem = (newValue: T) => {
        setValue(newValue);
        setLocalStorageItem(key, newValue);
    };

    // Ensure the storage is setup for the first use
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const newValue = getLocalStorageItem<T>(key, initialValue);
        if (value !== newValue) {
            setValue(newValue || initialValue);
        }
    });

    const handleStorage = useCallback(
        (event: StorageEvent) => {
            try {
                const newSerializedValue = event.newValue
                    ? JSON.parse(event.newValue) as T
                    : null;

                if (event.key === key && !isEqual(newSerializedValue, value)) {
                    setValue(newSerializedValue || initialValue);
                }
            } catch (error) {
                console.error(error);
            }
        },
        [initialValue, key, value]
    );

    useEffect(() => {
        window.addEventListener('storage', handleStorage);
        return () => window.removeEventListener('storage', handleStorage);
    }, [handleStorage]);

    return [value, setItem];
}