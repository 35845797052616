import { ListItemButton } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { CompanySummary } from '../../core';

type CompanyListItemProps = {
	company: CompanySummary;
	onClick: (company: CompanySummary) => void;
	isCurrentCompany: boolean;
};

export const CompanyListItem = (props: CompanyListItemProps) => {
	const { company, onClick, isCurrentCompany } = props;

	return (
		<ListItemButton
			onClick={() => onClick(company)}
			selected={isCurrentCompany}
			sx={theme => ({
				margin: theme.spacing(0.25, 0),
				flexShrink: 0,
			})}
		>
			<ListItem>
				<ListItemText
					sx={{
						position: 'relative',
						display: 'block',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
					}}
					primary={company.CompanyName}
					secondary={<span>{company.CompanyTypeName}</span>}
				/>
			</ListItem>
		</ListItemButton>
	);
};
