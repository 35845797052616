import { Box } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { CompanyData, UserPermissions } from '../../core';
import { BottomSpace, Container, Sidebar } from '../common';
import { ChangeState } from '../useChangeState';
import { CompanyAddressList } from './CompanyAddressList';
import { CompanyContactList } from './CompanyContactList';
import { CompanyDetail } from './CompanyDetail';
import { CompanyFields } from './CompanyFields';
import { CompanyJobList } from './CompanyJobList';
import { CompanyJobSummary } from './CompanyJobSummary';
import { CompanyNoteList } from './CompanyNoteList';
import { CompanySalesReps } from './CompanySalesReps';
import { CompanyTitle } from './CompanyTitle';
import { VendorServices } from './vendor/VendorServices';

const RelatedContents = {
	Notes: 'Notes',
	Jobs: 'Jobs',
	Fields: 'Fields',
	Sales: 'Sales',
} as const;
type RelatedContent = keyof typeof RelatedContents;

type Props = {
	state: ChangeState<CompanyData>;
}

export const CompanyLayout = ({ state }: Props) => {
	const { hasPermission } = useAppContext();
	const { id } = useParams<{ id: string }>();
	const [relatedContent, setRelatedContent] = useState<RelatedContent>();
	const [noteCount, setNoteCount] = useState(0);
	const [jobCount, setJobCount] = useState(0);
	const companyId = parseInt(id || '0', 10);
	const canEdit = hasPermission([UserPermissions.EditCompanies]);

	return (
		<Container
			component="CompanyContainer"
			error={state.error}
			onErrorReset={state.onErrorReset}
		>
			<CompanyTitle
				company={state.item}
				noteCount={noteCount}
				jobCount={jobCount}
				onRelatedItemClick={(key: string) =>
					setRelatedContent(key as RelatedContent)
				}
			/>
			<Box sx={theme => ({
				display: 'flex',
				flexDirection: 'row',
				flexGrow: 1,
				flexShrink: 1,
				height: `calc(100% - ${theme.mixins.toolbar.minHeight
					}px - ${theme.spacing(1)}px)`,
				[theme.breakpoints.down('md')]: {
					height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
				},
				overflow: 'hidden',
			})}>
				<Box
					sx={theme => ({
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						height: `100%`,
						width: '100%',
						overflowY: 'auto',
						overflowX: 'hidden',
						transition: theme.transitions.create('margin', {
							easing: !!relatedContent ? theme.transitions.easing.easeOut : theme.transitions.easing.sharp,
							duration: !!relatedContent ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
						}),
						marginRight: 0,
					})}
				>
					<CompanyDetail
						canEdit={canEdit}
						company={state.item}
						onChange={(data) => state.onChange(data as CompanyData)}
					/>
					<CompanyJobSummary companyId={companyId} />
					{state.item?.CompanyTypeID === 33 && (
						<VendorServices
							canEdit={canEdit}
							companyId={companyId}
						/>
					)}
					<CompanyContactList
						canEdit={canEdit}
						companyId={companyId}
					/>
					<CompanyAddressList
						canEdit={canEdit}
						companyId={companyId}
					/>
					<BottomSpace />
				</Box>
				<Sidebar
					variant="persistent"
					anchor="right"
					resizeEnabled
					minWidth={350}
					open={!!relatedContent}
					onClose={() => setRelatedContent(undefined)}
					title={relatedContent}
					tools={null}
				>
					<CompanyNoteList
						companyId={companyId}
						onCountChanged={setNoteCount}
						show={relatedContent === RelatedContents.Notes}
					/>
					<CompanyJobList
						companyId={companyId}
						onCountChanged={setJobCount}
						show={relatedContent === RelatedContents.Jobs}
					/>
					{relatedContent === RelatedContents.Fields && (
						<CompanyFields
							canEdit={canEdit}
							company={state.item}
							onChange={(data) =>
								state.onChange(data as CompanyData)
							}
						/>
					)}
					{relatedContent === RelatedContents.Sales && (
						<CompanySalesReps
							canEdit={canEdit}
							company={state.item}
							onChange={(data) =>
								state.onChange(data as CompanyData)
							}
						/>
					)}
				</Sidebar>
			</Box>
		</Container>
	);
};
