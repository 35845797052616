import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {
	PrimaryEmailAddressData,
	LookupTypes,
	validateEmail,
} from '../../core';
import { AutoCompleteLookup } from '../common';
import SwitchItem from '../common/SwitchItem';

type EmailAddressFormProps = {
	emailAddress: Partial<PrimaryEmailAddressData>;
	onChanged: (data: Partial<PrimaryEmailAddressData>) => void;
	autoComplete?: boolean;
	required: boolean;
	style?: React.CSSProperties;
	className?: string;
	disablePrimary?: boolean;
};

export const EmailAddressForm = (props: EmailAddressFormProps) => {
	const { emailAddress, onChanged, className, style = {}, required, disablePrimary } = props;

	return (
		<Grid
			container
			direction="column"
			spacing={1}
			className={className}
			style={style}
		>
			<Grid item>
				<Grid container spacing={1} direction="row">
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							title="Email Address"
							label="Email Address"
							value={emailAddress?.EmailAddress || ''}
							onChange={(e) =>
								onChanged({
									...emailAddress,
									EmailAddress: e.target.value,
								})
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							required={required}
							fullWidth
							InputLabelProps={{ shrink: true }}
							error={
								(required && !emailAddress.EmailAddress) ||
								(!!emailAddress.EmailAddress &&
									!validateEmail(emailAddress.EmailAddress))
							}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={1} direction="row">
					<Grid item style={{ minWidth: '200px' }}>
						<AutoCompleteLookup
							lookupType={LookupTypes.EmailType}
							label="Email Type"
							placeholder="Email Type"
							required={required}
							value={emailAddress.EmailTypeID || null}
							onChange={(value) =>
								onChanged({
									...emailAddress,
									EmailTypeID: value || undefined,
								})
							}
							error={
								(required && !emailAddress.EmailTypeID) ||
								(!!emailAddress.EmailAddress &&
									!emailAddress.EmailTypeID)
							}
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							label="Description"
							aria-label="Description"
							title="Description"
							value={emailAddress?.EmailDesc || ''}
							onChange={(e) =>
								onChanged({
									...emailAddress,
									EmailDesc: e.target.value,
								})
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<TextField
					aria-label="Notes"
					title="Notes"
					label="Notes"
					value={emailAddress?.Note || ''}
					onChange={(e) =>
						onChanged({ ...emailAddress, Note: e.target.value })
					}
					onFocus={(e) => e.target.select()}
					variant="outlined"
					margin="dense"
					multiline
					fullWidth
					minRows={3}
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
			<Grid item>
				<SwitchItem
					primary={disablePrimary ? 'This Email Address is the Primary' : 'Set as primary Email Address'}
					checked={emailAddress.isPrimary === true}
					disabled={disablePrimary}
					onChanged={(checked) => onChanged({ ...emailAddress, isPrimary: checked })}
				/>
			</Grid>
		</Grid>
	);
};
