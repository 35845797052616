import Box from '@mui/material/Box';
import React from 'react';
import { CompanyViewData, ContactData } from '../../core';
import { useAppContext } from '../../contexts/AppContext';
import { ErrorMessage, FlexColumn, Loading } from '../common';
import { Dialog } from '../common/Dialog';
import CompanySelection from '../selection/CompanySelection';

type Props = {
	contact: ContactData | null | undefined;
	onClose: () => void;
	onChange: (contact: ContactData) => void;
};

export const ChangeCompanyDialog = ({ contact, onClose, onChange }: Props) => {
	const { api } = useAppContext();
	const [company, setCompany] = React.useState<CompanyViewData | undefined>();
	const [working, setWorking] = React.useState(false);
	const [error, setError] = React.useState<any>();

	const handleContinue = async () => {
		if (company && contact) {
			setWorking(true);
			try {
				await api.company.addContact(company.CompanyID, {
					CompanyID: company.CompanyID,
					ContactID: contact.ContactID,
					isPrimary: false,
				});
				onChange({ ...contact, CurrentCompanyID: company.CompanyID });
			} catch (error) {
				setWorking(false);
				setError(error);
			}
		}
	};

	return (
		<Dialog
			isOpen={true}
			title="Change Company"
			onClose={onClose}
			cancelAction={{
				text: 'Cancel',
				color: 'inherit',
				onClick: onClose,
			}}
			positiveAction={{
				text: 'Change',
				color: 'secondary',
				onClick: handleContinue,
				isDisabled: !company || working || error,
			}}
		>
			<Box style={{ padding: '16px' }}>
				<FlexColumn>
					<ErrorMessage
						error={error}
						onRetry={() => setError(undefined)}
					/>
					{working && <Loading />}
					{!working && !error && (
						<CompanySelection
							title="Company"
							companyId={company?.CompanyID}
							error={!company}
							onSelected={(data) => setCompany(data)}
						/>
					)}
				</FlexColumn>
			</Box>
		</Dialog>
	);
};
