import Box from '@mui/material/Box';
import { useState } from 'react';
import { PrimaryEmailAddressData } from '../../core';
import { Dialog } from '../common';
import { EmailAddressForm } from './EmailAddressForm';

type EditEmailDialogProps = {
	isOpen: boolean;
	emailAddress: PrimaryEmailAddressData;
	onCancel: () => void;
	onSaveChanges: (data: PrimaryEmailAddressData) => void;
};

export const EditEmailDialog = (props: EditEmailDialogProps) => {
	const { isOpen, emailAddress, onCancel, onSaveChanges } = props;
	const [isPrimary] = useState(emailAddress.isPrimary === true);
	const [changes, setChanges] = useState<PrimaryEmailAddressData>(emailAddress);

	const handleOnChanged = (data: Partial<PrimaryEmailAddressData>) => {
		setChanges({ ...changes, ...data });
	};

	return (
		<Dialog
			isOpen={isOpen}
			title="Edit Email Address"
			cancelAction={{
				text: 'Cancel',
				onClick: onCancel,
			}}
			positiveAction={{
				text: 'Save Changes',
				onClick: () => {
					onSaveChanges(changes);
				},
			}}
			onClose={onCancel}
		>
			<Box
				data-component="AddEmailAddressContent"
				sx={theme => ({
					backgroundColor: '#fff',
					padding: theme.spacing(2),
					// @ts-ignore
					'& .MuiOutlinedInput-root': {
						backgroundColor: '#fff',
					},
				})}
			>
				<EmailAddressForm
					required
					emailAddress={changes}
					disablePrimary={isPrimary}
					onChanged={handleOnChanged}
					style={{ padding: 12 }}
				/>
			</Box>
		</Dialog>
	);
};
