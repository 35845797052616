import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { JobTaskWithJobData, UserMarketingTasks } from '../../core';
import { useAppDispatch, useAppSelector } from '../../store';
import { loadJobTasks, selectJobTasks } from '../../store/jobTasks';
import {
	loadMarketingTasks,
	selectMarketingTasks,
} from '../../store/marketingTasks';
import { ApplicationEvent } from '../ApplicationEvent';
import { useRecentItems } from '../recent';

type Props = {
	onNavigate: (url: string) => void;
};

export const useEmployeeTasks = ({ onNavigate }: Props) => {
	const dispatch = useAppDispatch();
	const { user, api } = useAppContext();
	const { addRecentJob, addRecentContact } = useRecentItems();
	const jobTasks = useAppSelector(selectJobTasks);
	const marketingTasks = useAppSelector(selectMarketingTasks);
	const [busy, setBusy] = useState(false);

	const loadTasks = useCallback(async () => {
		setBusy(true);
		try {
			const [loadedJobTasks, loadedMarketingTasks] = await Promise.all([
				api.employee.queryJobTasks(user.EmployeeNumber),
				api.employee.queryMarketingTasks(user.EmployeeNumber),
			]);
			dispatch(loadJobTasks(loadedJobTasks));
			dispatch(loadMarketingTasks(loadedMarketingTasks));
			setBusy(false);
		} catch (error) {
			console.error(error);
			dispatch(loadJobTasks([]));
			dispatch(loadMarketingTasks([]));
			setBusy(false);
		}
	}, [api.employee, user.EmployeeNumber, dispatch]);

	useEffect(() => {
		if (jobTasks.length === 0 && marketingTasks.length === 0) {
			loadTasks();
		}
	}, [jobTasks.length, loadTasks, marketingTasks.length]);

	useEffect(() => {
		const unsubscribe = ApplicationEvent.OnReloadMyTasks(loadTasks);
		return () => unsubscribe();
	}, [loadTasks]);

	const handleOpenJobTask = useCallback(
		(item: JobTaskWithJobData) => {
			const url = `/jobs/${item.JobID}`;
			addRecentJob({
				id: item.JobID,
				name: item.JobName,
				number: item.JobNumber,
				desc: item.TaskDescription,
			});
			onNavigate(url);
		},
		[addRecentJob, onNavigate]
	);

	const handleOpenMarketingTask = useCallback(
		(item: UserMarketingTasks) => {
			const url = `/contacts/${item.ContactID}`;
			addRecentContact({
				id: item.ContactID,
				name: item.FullName,
				contactType: 'Contact',
				lookupType: '',
			});
			onNavigate(url);
		},
		[addRecentContact, onNavigate]
	);

	return {
		handleOpenJobTask,
		handleOpenMarketingTask,
		busy,
		jobTasks: jobTasks || [],
		marketingTasks: marketingTasks || [],
	};
};
