import { useCallback, useState } from "react";
import { CompanyData, CompanyViewData } from "../../core";
import { FlexColumn } from '../common';
import { CompanyAutoComplete } from './CompanyAutoComplete';
import SelectionCard from "./SelectionCard";
import useCompany from "../companies/useCompany";
import CompanyListItem from "./CompanyListItem";
import { useRecentItems } from "../recent";
import { useWindowOpen } from "../useWindowOpen";
import { AddCompanyDialog } from "../companies/AddCompanyDialog";
import { api } from "../../api";

type Props = {
    title: string;
    companyId?: number;
    companyTypeIds?: number[];
    onSelected?: (company?: CompanyViewData) => void;
    onAddClick?: () => void;
    error?: boolean;
    disabled?: boolean;
};

export default function CompanySelection({
    title,
    companyTypeIds,
    companyId,
    onSelected,
    onAddClick,
    error,
    disabled,
}: Props) {
    const company = useCompany(companyId);
    const { addRecentCompany } = useRecentItems();
    const open = useWindowOpen();
    const [showAddCompanyDialog, setShowAddCompanyDialog] = useState(false);

    const handleOpenInNew = () => {
        if (company && company.CompanyID) {
            addRecentCompany({
                id: company.CompanyID,
                name: company.CompanyName || company.OfficeName || '',
                type: company.companytype || '',
            });
            open(`/companies/${company.CompanyID}`);
        }
    };

    const handleSelection = useCallback(async (target?: CompanyViewData | null) => {
        if (onSelected) {
            if (!target) {
                onSelected();
            } else {
                onSelected(target);
            }
        }
    }, [onSelected]);

    const handleCompanyAdded = async (company: CompanyData) => {
        try {
            const view = await api.company.getView(company.CompanyID);
            handleSelection(view);
        } catch (error) {
            console.error(error);
        }
        setShowAddCompanyDialog(false);
    };

    return (
        <>
            <SelectionCard
                title={title}
                mode={!!company ? 'render' : 'selection'}
                onClearSelection={() => {
                    handleSelection(undefined);
                }}
                disabled={disabled ? 'disabled' : undefined}
                onOpenSelection={handleOpenInNew}
                render={<CompanyListItem company={company} />}
                selection={
                    <FlexColumn style={{ padding: '8px' }}>
                        <CompanyAutoComplete
                            label="Company"
                            onChange={handleSelection}
                            companyId={companyId}
                            typeIds={companyTypeIds}
                            onAddClick={() => setShowAddCompanyDialog(true)}
                            error={error}
                            disabled={disabled}
                        />
                    </FlexColumn>
                }
            />
            {showAddCompanyDialog && (
                <AddCompanyDialog
                    onClose={() => setShowAddCompanyDialog(false)}
                    onCompanyAdded={handleCompanyAdded}
                />
            )}
        </>
    );
}
