import {
	PhoneSearchOptions,
	stringifySearchOptions,
	PhoneNumberData,
} from '../core';

import { ApiClientInterface } from './client';

export default class PhoneApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
		this.count = this.count.bind(this);
		this.update = this.update.bind(this);
	}

	public async query(
		options: PhoneSearchOptions
	): Promise<PhoneNumberData[]> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<PhoneNumberData[]>(
			`/phone?${search}`
		);
		return response;
	}

	public async count(options: PhoneSearchOptions): Promise<number> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<number>(
			`/phone?${search}&count=true`
		);
		return response;
	}

	public async update(phone: PhoneNumberData): Promise<PhoneNumberData> {
		const response = await this.client.put<
			PhoneNumberData,
			PhoneNumberData
		>(`/phone/${phone.PhoneID}`, phone);
		return response;
	}
}
