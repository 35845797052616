import { Box, useTheme } from '@mui/material';

type Props = {
	children: React.ReactNode;
};

export default function FieldBase({ children }: Props) {
	const theme = useTheme();
	return (
		<Box
			style={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'nowrap',
				alignItems: 'center',
				width: '100%',
				padding: theme.spacing(0.5),
				backgroundColor: '#ffffff',
				borderRadius: '4px',
			}}
		>
			{children}
		</Box>
	);
}
