import React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

type ErrorMessageProps = {
	error?: any | null;
	onRetry?: () => void;
	style?: React.CSSProperties;
};

export const ErrorMessage = ({
	error,
	style = {},
	onRetry,
}: ErrorMessageProps) => {
	const theme = useTheme();
	if (!error) return null;

	const err = error as any;
	console.log('ErrorMessage', JSON.stringify(err));
	const message =
		err?.originalError?.message ||
		err?.message ||
		`${err}` ||
		'Unknown Error';
	const status = err?.originalError?.status || err?.status || 500;

	return (
		<Box style={{ padding: theme.spacing(2), ...style }}>
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<Typography variant="h6">An error occurred</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						({status}) {message}
					</Typography>
				</Grid>
				{!!onRetry && (
					<Grid item>
						<Button
							size="small"
							variant="contained"
							onClick={onRetry}
						>
							Try Again
						</Button>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
