import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js';
import Icon from '@mdi/react';
import { ListItem, ListItemButton, ListItemText, Autocomplete as MuiAutocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { isArray } from 'lodash';
import { useLookupTypes } from '../lookups';
import { AutoCompleteOptionOf } from './AutoComplete';

type AutoCompleteLookupProps = {
	label?: string;
	placeholder?: string;
	lookupType: string;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	autoOpen?: boolean;
	value: number | number[] | null;
	emptyOption?: AutoCompleteOptionOf<number>;
	onChange: (value: number | number[] | null) => void;
	typesFilter?: string[];
};

export const AutoCompleteLookupMultiple = (props: AutoCompleteLookupProps) => {
	const {
		lookupType,
		label,
		value,
		onChange,
		required,
		error,
		disabled,
		emptyOption,
		typesFilter,
	} = props;
	const [types] = useLookupTypes([lookupType]);

	const filteredTypes =
		typesFilter && typesFilter.length > 0
			? types?.filter((x: any) => typesFilter.includes(x.Name)) || []
			: types || [];

	const options: AutoCompleteOptionOf<number>[] = filteredTypes.map(
		(type: any) => ({
			label: type.Name,
			value: type.ID,
		})
	);

	let currentOptions: AutoCompleteOptionOf<number>[] | null = [];
	if (isArray(value)) {
		currentOptions = options.filter(
			(x) => x.value !== null && value.includes(x.value)
		);
	} else {
		const opt = options.find((x) => x.value !== null && x.value === value);
		currentOptions = !!opt ? [opt] : null;
	}

	if (emptyOption) {
		options.splice(0, 0, emptyOption);
	}

	const onOptionSelected = (
		items:
			| AutoCompleteOptionOf<number>
			| AutoCompleteOptionOf<number>[]
			| null
	) => {
		console.log('onOptionSelected', items);
		if (isArray(items)) {
			const itemVals = items.map((x) => x.value);

			const vals = options
				.map((x) => x.value)
				.filter((x) => itemVals.includes(x));

			if (vals.includes(-1)) {
				onChange(null);
				return;
			}

			if (vals.length === 0) {
				onChange(null);
			} else if (vals.length === 1 && vals[0]) {
				onChange(vals[0]);
			} else {
				onChange(vals);
			}
		} else {
			if (!items) {
				onChange(null);
			} else {
				onChange(items.value);
			}
		}
	};

	return (
		<MuiAutocomplete
			getOptionLabel={(option) => option.label}
			fullWidth
			freeSolo={false}
			autoComplete={false}
			multiple={true}
			disabled={disabled}
			// disableCloseOnSelect
			value={currentOptions || undefined}
			onChange={(_event, option: any) => onOptionSelected(option)}
			options={
				options.length ? options : [{ label: 'loading', value: -1 }]
			}
			style={{ minWidth: '250px' }}
			renderOption={(props, option, state) => (
				<ListItemButton {...(props as any)}>
					<ListItem 
						sx={theme => ({ paddingLeft: theme.spacing(1), width: '100%'})}
						secondaryAction={(
							<Checkbox
								icon={<Icon path={mdiCheckboxBlankOutline} size={1} />}
								checkedIcon={<Icon path={mdiCheckboxMarked} size={1} />}
								style={{ marginRight: 4, paddingLeft: 0 }}
								checked={state.selected}
							/>
						)}
					>
						<ListItemText primary={option.label} sx={{ flexGrow: 1 }}/>
					</ListItem>
				</ListItemButton>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					autoComplete="off"
					variant="outlined"
					size="small"
					margin="dense"
					fullWidth
					required={required}
					disabled={disabled}
					error={error}
					onFocus={(event) => event.target.select()}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						...params.InputProps,
						autoComplete: 'off',
						required: required,
						disabled: disabled,
						error: error,
					}}
				/>
			)}
		/>
	);
};
