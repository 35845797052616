import { CompanyData } from '../../core';
import { Container, Dialog } from '../common';
import { AddCompany } from './AddCompany';
import { useAddCompany } from './useAddCompany';

type Props = {
	onCompanyAdded: (company: CompanyData) => void;
	onClose: () => void;
};

export const AddCompanyDialog = ({ onClose, onCompanyAdded }: Props) => {

	const controller = useAddCompany();
	const { working, error, isCompanyValid, createCompany, resetData } =
		controller;

	return (
		<Dialog
			fullScreen
			isOpen={true}
			title="Add Company"
			negativeAction={{
				text: 'Clear',
				onClick: resetData,
				isDisabled: () => working,
			}}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
				isDisabled: () => working,
			}}
			positiveAction={{
				text: 'Add Company',
				isDisabled: () => working || !!error || !isCompanyValid,
				onClick: createCompany,
			}}
			onClose={onClose}
		>
			<Container
				component="AddCompany"
				scrollable
				sx={theme => ({
					paddingLeft: theme.spacing(0.5),
				})}
			>
				<AddCompany
					controller={controller}
					onCompanyAdded={onCompanyAdded}
				/>
			</Container>
		</Dialog>
	);
};
