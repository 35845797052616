import Grid from '@mui/material/Grid';
import { LookupTypes } from '../../core';
import { AutoCompleteLookup, Container } from '../common';
import CompanySelection from "../selection/CompanySelection";
import ContactSelection from "../selection/ContactSelection";
import { ComponentProps, isContactCompany } from './shared';

export const Referral = (props: ComponentProps) => {
	const { state, setState, validationErrors } = props;

	const handleStateChange = (
		key: string,
		value: string | boolean | number | object | null | undefined
	) => {
		console.log('Referral.handleStateChange', key, value);
		setState((s) => ({ ...s, [key]: value }));
	};

	return (
		<Container component="AddLeadReferralContent" sx={theme => ({ padding: theme.spacing(2, 1) })}>
			<Grid
				container
				direction="row"
				wrap="wrap"
				justifyContent="space-around"
				spacing={2}
			>
				<Grid item style={{ flexGrow: 1 }}>
					<AutoCompleteLookup
						lookupType={LookupTypes.ReportedByType}
						label="Reported By"
						placeholder="Reported By"
						required
						autoOpen
						error={!!validationErrors['ReportedBy']}
						emptyOption={{ value: -1, label: 'None' }}
						value={state.ReportedBy || null}
						onChange={(value) =>
							handleStateChange(
								'ReportedBy',
								!!value && value > 0 ? value : undefined
							)
						}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}>
					<AutoCompleteLookup
						lookupType={LookupTypes.ReferredByType}
						label="Referral Type"
						placeholder="Referral Type"
						required
						autoOpen
						error={!!validationErrors['ReferrelTypeID']}
						emptyOption={{ value: -1, label: 'None' }}
						value={state.ReferrelTypeID || null}
						onChange={(value) =>
							handleStateChange(
								'ReferrelTypeID',
								!!value && value > 0 ? value : undefined
							)
						}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				direction="row"
				wrap="wrap"
				justifyContent="space-around"
				spacing={2}
				sx={theme => ({ padding: theme.spacing(2.5, 0) })}
			>
				<Grid item style={{ flexGrow: 1 }}>
					<CompanySelection
						title="Referral Company"
						companyId={state.referralCompany?.CompanyID}
						companyTypeIds={[378, 588]}
						error={!!validationErrors.referralCompany}
						onAddClick={() => { }}
						onSelected={company => handleStateChange('referralCompany', company)}
					/>
					{/* <CompanyCard
						label="Referral Company"
						readOnly={isContactCompany(
							state.referralContact,
							state.referralCompany
						)}
						error={!!validationErrors.referralCompany}
						company={state.referralCompany}
						onCompanySelected={(company) =>
							handleStateChange('referralCompany', company)
						}
						onClearCompany={() =>
							handleStateChange('referralCompany', undefined)
						}
						className={classes.card}
					/> */}
				</Grid>
				<Grid item style={{ flexGrow: 1 }}>
					<ContactSelection
						title="Referral Contact"
						contactId={state.referralContact?.ContactID}
						companyId={state.referralCompany?.CompanyID}
						onSelected={(contact, company) => {
							handleStateChange('referralContact', contact);
							if (company) {
								handleStateChange('referralCompany', company);
							} else if (
								!!state.referralCompany &&
								!isContactCompany(
									contact,
									state.referralCompany
								)
							) {
								handleStateChange('referralCompany', undefined);
							}
						}}
						error={!!validationErrors.referralContact}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
