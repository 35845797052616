import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { TaskTemplateData } from '../core';
import { uniqBy } from 'lodash';

interface TaskTemplateState {
	data: TaskTemplateData[];
}

const initialState: TaskTemplateState = {
	data: [],
};

export const slice = createSlice({
	name: 'taskTemplates',
	initialState,
	reducers: {
		loadTaskTemplates: (state, action: PayloadAction<TaskTemplateData[]>) => {
			state.data = action.payload;
		},
	},
});

export const { loadTaskTemplates } = slice.actions;

export const selectTaskTemplates = (state: RootState) => uniqBy(state.taskTemplates.data.map(x => ({ id: x.id, name: x.name })), x => x.id);

export default slice.reducer;
