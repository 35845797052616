import React from 'react';
import { useAppContext } from '../../../../contexts/AppContext';
import { SellData } from '../../../../core';
import { Container, Dialog, ErrorMessage, Loading } from '../../../common';
import { SellForm } from './SellForm';

export type SellMode = 'job' | 'supplement' | 'order' | 'edit';

type SellJobType = { mode: SellMode; type: string };

const SellJobTypes: SellJobType[] = [
	{ mode: 'job', type: '**ORIGINAL**' },
	{ mode: 'supplement', type: 'Supplemental Estimate' },
	{ mode: 'order', type: 'Change Order' },
	{ mode: 'edit', type: 'Edit Job' },
];

type State = {
	working: boolean;
	error?: any;
	data: Partial<SellData>;
};

const initialState: State = {
	working: false,
	data: {
		approvedDate: new Date(),
		changeDate: new Date(),
		sentDate: new Date(),
	},
};

type Props = {
	jobId: number;
	salesDataId?: number;
	mode: SellMode;
	onSave: (data: SellData) => void;
	onClose: () => void;
};

export const SellJobDialog = ({
	mode,
	jobId,
	salesDataId = 0,
	onClose,
	onSave,
}: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>(initialState);

	// const resetData = () => {
	// 	setState(initialState);
	// };

	const handleSaveData = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			const type = SellJobTypes.find((x) => x.mode === mode)?.type;
			if (type) {
				const data: Partial<SellData> = {
					...state.data,
					type,
					jobId,
					salesDataId,
				};
				await api.job.sellJob(data);
				onSave(data as SellData);
			}
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const loadSalesRecord = React.useCallback(
		async (id: number) => {
			try {
				setState((s) => ({
					...s,
					working: true,
					error: undefined,
				}));
				const data = await api.job.getSalesRecord(jobId, id);
				setState((s) => ({
					...s,
					working: false,
					data,
				}));
			} catch (error) {
				setState((s) => ({ ...s, working: false, error }));
			}
		},
		[api.job, jobId]
	);

	React.useEffect(() => {
		if (salesDataId > 0 && mode === 'edit') {
			loadSalesRecord(salesDataId);
		}
	}, [salesDataId, mode, loadSalesRecord]);

	const isValid =
		!!state.data.description &&
		(!!state.data.saleAmount || !!state.data.salesTax) &&
		!!state.data.sentDate &&
		!!state.data.changeDate &&
		!!state.data.approvedDate;

	let title = 'Sell Job';
	if (mode === 'supplement') {
		title = 'Sell Supplement';
	}
	if (mode === 'order') {
		title = 'Sell Change Order';
	}
	if (mode === 'edit') {
		title = 'Edit Job';
	}

	return (
		<Dialog
			isOpen={true}
			title={title}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
				isDisabled: () => state.working,
			}}
			positiveAction={{
				text: title,
				isDisabled: () => state.working || !!state.error || !isValid,
				onClick: handleSaveData,
			}}
			onClose={onClose}
		>
			<ErrorMessage
				error={state.error}
				onRetry={() => setState((s) => ({ ...s, error: undefined }))}
			/>
			{state.working && <Loading />}
			{!state.error && !state.working && (
				<Container
					component="SellJob"
					scrollable
					sx={theme => ({ padding: theme.spacing(2) })}
				>
					<SellForm
						data={state.data}
						onChanged={(data) => setState((s) => ({ ...s, data }))}
					/>
				</Container>
			)}
		</Dialog>
	);
};
