import { mdiClose, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, IconButton, Paper, Tooltip, Typography, lighten } from "@mui/material";
import { ReactNode } from "react";
import { FlexColumn } from "../common/FlexColumn";
import { FlexRow } from "../common/FlexRow";

type Props = {
    title: string;
    mode: 'selection' | 'render';
    render: ReactNode;
    selection: ReactNode;
    onClearSelection?: () => void;
    onOpenSelection?: () => void;
    style?: React.CSSProperties;
    disabled?: string;
    height?: number;
    openIcon?: string;
    actions?: React.ReactNode;
    error?: boolean;
}

export default function SelectionCard({ title, mode, render, selection, onClearSelection, onOpenSelection, style, disabled, height, openIcon, actions, error }: Props) {
    return (
        <Paper variant="outlined" style={{ ...style, borderColor: error ? 'red' : 'rgba(0,0,0,0.26)' }}>
            <FlexColumn style={{
                padding: '8px',
                backgroundColor: '#ffffff',
                minHeight: height ? `${height}px` : '200px',
                opacity: !!disabled ? 0.7 : 1,
            }}>
                <FlexRow nowrap>
                    <Typography
                        variant="caption"
                        style={{
                            fontWeight: 'bold',
                            color: lighten('#000000', 0.1)
                        }}>
                        {title}
                    </Typography>
                    <span style={{ flexGrow: 1 }} />
                    {!disabled && mode === 'render' && (
                        <>
                            {!!onClearSelection && (
                                <Tooltip title="Clear">
                                    <span>
                                        <IconButton
                                            size="small"
                                            aria-label="Clear"
                                            onClick={onClearSelection}
                                            tabIndex={-1}
                                            style={{ marginRight: '8px' }}
                                        >
                                            <Icon path={mdiClose} size={0.8} />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                            {!!onOpenSelection && (
                                <Tooltip title="Open">
                                    <span>
                                        <IconButton
                                            size="small"
                                            aria-label="Open"
                                            onClick={onOpenSelection}
                                            tabIndex={-1}
                                            style={{ marginRight: '8px' }}
                                        >
                                            <Icon path={openIcon || mdiOpenInNew} size={0.8} />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        </>
                    )}
                </FlexRow>
                {!disabled && mode === 'selection' && selection}
                {!disabled && mode === 'render' && <Box style={{ padding: '8px' }}>{render}</Box>}
                {!!disabled && <Box style={{ padding: '8px' }}>{disabled}</Box>}
                {!!actions && mode === 'selection' && <FlexRow nowrap style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                    {actions}
                </FlexRow>}
            </FlexColumn>
        </Paper>
    )
}