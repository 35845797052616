import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useCallback, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
	AddressSearchOptions,
	LookupDetail,
	LookupTypes,
	StreetAddressData,
} from '../../core';
import {
	AutoCompleteLookup,
	Container,
	QueryPage,
	QueryStatus,
	SearchField,
} from '../common';
import { useLookupTypes } from '../lookups';
import useQuery from '../useQuery';
import { ListItemButton } from '@mui/material';

const getDefaultType = (
	types: LookupDetail[],
	defaultType?: string | null
): number | undefined => {
	if (!defaultType) return undefined;
	return types.find((x) => x.Name === defaultType)?.ID;
};

type AddressListProps = {
	parentId?: number;
	parentType?: 'Company' | 'Contact';
	onSelected: (data: Partial<StreetAddressData>) => void;
	defaultType?: string | null;
};

export const AddressList = (props: AddressListProps) => {
	const { api } = useAppContext();
	const [addressTypes] = useLookupTypes([LookupTypes.AddressType]);

	const { parentId, parentType, onSelected, defaultType } = props;

	const [options, setOptions] = useState<AddressSearchOptions>({
		parentId,
		parentType,
		search: '',
		typeId: getDefaultType(addressTypes || [], defaultType),
	});

	const query = useQuery<StreetAddressData>({
		queryCallback: (limit: number, offset: number) =>
			api.address.query({ ...options, limit, offset }),
		queryCountCallback: () => api.address.count(options),
		shouldExecute: (args: any[]) => true,
		arguments: [options],
		limit: 50,
		delay: 500,
	});

	const getTypeName = useCallback(
		(id: number) => {
			const type = addressTypes?.find((x: any) => x.ID === id);
			return type?.Name;
		},
		[addressTypes]
	);

	return (
		<Container component="AddressList">
			<Grid
				container
				spacing={1}
				direction="row"
				wrap="wrap"
				sx={theme => ({
					display: 'flex',
					padding: theme.spacing(0, 1),
					borderBottom: `1px solid ${theme.palette.divider}`,
				})}
			>
				<Grid item style={{ flexGrow: 1 }}>
					<SearchField
						text={options.search || ''}
						placeholder="Search street name/number"
						onChange={(text) =>
							setOptions({ ...options, search: text })
						}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}>
					<AutoCompleteLookup
						lookupType={LookupTypes.AddressType}
						label="Address Type"
						placeholder="Address Type"
						value={options.typeId || null}
						onChange={(value) =>
							setOptions({
								...options,
								typeId: value || undefined,
							})
						}
					/>
				</Grid>
			</Grid>
			<QueryStatus
				status={query.status}
				idle="Start adding your address to see matching results here"
				noresults="No matches found. Clear your search criteria and try again."
				scrollable
				error={query.error}
				sx={theme => ({
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					height: '100%',
					padding: theme.spacing(2),
					backgroundColor: '#fff',
				})}
			>
				{query.results.map((item) => (
					<ListItemButton
						key={item.AddressID}
						onClick={() => onSelected(item)}
					>
						<ListItem>
							<ListItemText
								sx={theme => ({
									'& .MuiListItemText-primary': {
										fontSize: theme.typography.caption.fontSize,
										fontWeight: 500,
										color: theme.palette.secondary.main,
									},
								})}
								primary={
									<strong>
										{getTypeName(item.AddressTypeID) || ''}
									</strong>
								}
								secondary={
									<>
										{item.AddressName && (
											<span style={{ display: 'block' }}>
												Name: {item.AddressName}
											</span>
										)}
										<span>{item?.AddressLine1} </span>
										{item?.AddressLine2 && (
											<span>{item.AddressLine2} </span>
										)}
										<span>
											{item?.City} {item?.State} {item?.ZIP}{' '}
											{item?.Country}
										</span>
									</>
								}
							/>
							{item?.Note && <p>{item.Note}</p>}
						</ListItem>
					</ListItemButton>
				))}
			</QueryStatus>
			<QueryPage queryResult={query} />
		</Container>
	);
};
