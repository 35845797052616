import { ReportData } from '../core';

import { ApiClientInterface } from './client';

export default class ReportsApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
	}

	public async query(type: string): Promise<ReportData[]> {
		const response = await this.client.get<ReportData[]>(
			`/reports/${type}`
		);
		return response;
	}

	public async hit(path: string): Promise<ReportData> {
		const response = await this.client.patch<void, ReportData>(
			`/reports/${path}`
		);
		return response;
	}
}
