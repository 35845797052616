import { Box } from '@mui/material';
import { useRef } from 'react';
import Draggable from 'react-draggable';

type VerticalResizeProps = {
    enabled: boolean;
    onChange: (x: number) => void;
}

export const VerticalResize = (props: VerticalResizeProps) => {
    const resizer = useRef(null);
    const { enabled, onChange } = props;

    if (!enabled) return null;

    return (
        <Draggable ref={resizer}
            axis="x"
            position={{ x: 0, y: 0 }}
            onStop={(_e, data) => onChange(data.x)}
        >
            <Box
                ref={resizer}
                sx={theme => ({
                    cursor: 'col-resize',
                    position: 'relative',
                    height: '100%',
                    width: '8px',
                    backgroundColor: 'transparent',
                    zIndex: 1201,
                    opacity: 0,
                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        opacity: 1,
                        transition: theme.transitions.create('opacity', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                    },
                    '&:active': {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        opacity: 1,
                        transition: theme.transitions.create('opacity', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                    }
                })}
            />
        </Draggable>
    );
}