import React from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mdi/react';
import { mdiDomain } from '@mdi/js';
import { LookupTypes } from "../../core";
import { useRecentItems } from "../recent";
import useCompany from "./useCompany";
import { useLookupTypes } from "../lookups";
import { useNavigate } from 'react-router-dom';

type Props = {
	label?: string;
	companyId?: number;
	className?: string;
	style?: React.CSSProperties;
};

export const CompanyCard = ({ label, companyId, className, style }: Props) => {
	const company = useCompany(companyId);
	const { addRecentCompany } = useRecentItems();
	const [companyTypes] = useLookupTypes([LookupTypes.CompanyType]);
	const navigate = useNavigate();

	if (!company) return null;

	const handleOpenCompany = () => {
		if (company) {
			addRecentCompany({
				id: company.CompanyID,
				name: company.CompanyName,
				type:
					companyTypes?.find(
						(x: any) => x.ID === company.CompanyTypeID
					)?.Name || '',
			});
			navigate(`/companies/${company.CompanyID}`);
		}
	};

	return (
		<TextField
			fullWidth
			aria-label={label}
			label={label}
			title={label}
			value={company.CompanyName || company.OfficeName}
			onChange={() => {}}
			onFocus={(e) => e.target.select()}
			variant="outlined"
			margin="dense"
			className={className}
			style={style}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Tooltip title="Open Company">
							<span>
								<IconButton
									aria-label="Open Company"
									onClick={handleOpenCompany}
									size="small"
								>
									<Icon
										path={mdiDomain}
										size={1}
									/>
								</IconButton>
							</span>
						</Tooltip>
					</InputAdornment>
				),
			}}
		/>
	);
};
