import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { ContactData, LookupTypes } from '../../core';
import { AutoCompleteLookup, Chip } from '../common';

export type ContactFormProps = {
	contact: Partial<ContactData>;
	onChanged: (data: Partial<ContactData>) => void;
	style?: React.CSSProperties;
	className?: string;
};

export const ContactForm = (props: ContactFormProps) => {
	const { contact, onChanged, className, style = {} } = props;

	return (
		<Grid
			container
			direction="column"
			spacing={1}
			className={className}
			style={style}
		>
			<Grid item>
				<Grid container spacing={1} direction="row" wrap="wrap">
					<Grid item style={{ flexGrow: 1 }}>
						<AutoCompleteLookup
							label="Contact Type"
							placeholder="Contact Type"
							lookupType={LookupTypes.ContactType}
							value={contact.ContactTypeID || null}
							onChange={(value) =>
								onChanged({
									...contact,
									ContactTypeID: value || undefined,
								})
							}
							required
							error={!contact?.ContactTypeID}
						/>
					</Grid>
					<Grid
						item
						style={{
							minWidth: '150px',
							alignItems: 'center',
							display: 'flex',
						}}
					>
						<Chip
							label="Active"
							selected={(contact as any).isActive === true}
							onClick={() =>
								onChanged({
									...contact,
									isActive: !(contact as any).isActive,
								})
							}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container spacing={1} direction="row" wrap="wrap">
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							label="First Name"
							aria-label="First Name"
							title="First Name"
							value={contact.FirstName || ''}
							onChange={(e) =>
								onChanged({
									...contact,
									FirstName: e.target.value || '',
								})
							}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							label="Last Name"
							aria-label="Last Name"
							title="Last Name"
							value={contact.LastName || ''}
							onChange={(e) =>
								onChanged({
									...contact,
									LastName: e.target.value || '',
								})
							}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
							error={!contact?.LastName}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container spacing={1} direction="row" wrap="wrap">
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							label="Title"
							aria-label="Title"
							title="Title"
							value={contact.Title || ''}
							onChange={(e) =>
								onChanged({
									...contact,
									Title: e.target.value || '',
								})
							}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							label="Position"
							aria-label="Position"
							title="Position"
							value={contact.Position || ''}
							onChange={(e) =>
								onChanged({
									...contact,
									Position: e.target.value || '',
								})
							}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<TextField
					label="Preferences"
					aria-label="Preferences"
					title="Preferences"
					value={contact.ShortNote || ''}
					onChange={(e) =>
						onChanged({
							...contact,
							ShortNote: e.target.value || '',
						})
					}
					variant="outlined"
					margin="dense"
					multiline
					minRows={3}
					fullWidth
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
		</Grid>
	);
};
