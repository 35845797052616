import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AutoComplete, ErrorMessage, FlexColumn, Loading } from '../../common';
import { Dialog } from '../../common/Dialog';
import { useAppContext } from '../../../contexts/AppContext';
import { useTaskTemplates } from '../../taskTemplates/useTaskTemplates';

type Props = {
	jobId: number;
	onCancel: () => void;
	onJobCloned: (jobId: number) => void;
};

type State = {
	working: boolean;
	error?: any;
	name?: string;
	number?: string;
	taskTemplateId?: number;
};

export const CloneJobDialog = ({ jobId, onCancel, onJobCloned }: Props) => {
	const { api } = useAppContext();
	const taskTemplates = useTaskTemplates();
	const [state, setState] = React.useState<State>({ working: false });

	const handleCloneJob = async () => {
		setState({ working: true, error: undefined });
		try {
			const newJobId = await api.job.clone({
				jobId,
				name: state.name || '',
				number: state.number || '',
				taskTemplateId: state.taskTemplateId || 0,
			});
			onJobCloned(newJobId);
		} catch (error) {
			setState({ working: false, error });
		}
	};

	const handleOnChange = (property: string, value: any) => {
		setState((s) => ({
			...s,
			[property]: value,
		}));
	};

	const isValid =
		!!state.name && !!state.number && (state.taskTemplateId || 0) > 0;

	return (
		<Dialog
			isOpen={true}
			title="Clone Job"
			onClose={onCancel}
			cancelAction={{
				text: 'Cancel',
				color: 'inherit',
				onClick: onCancel,
				isDisabled: state.working,
			}}
			positiveAction={{
				text: 'Clone Job',
				color: 'secondary',
				onClick: handleCloneJob,
				isDisabled: !isValid || state.working || !!state.error,
			}}
		>
			<Box style={{ padding: '16px' }}>
				{state.working && <Loading />}
				<ErrorMessage error={state.error} />
				{!state.error && !state.working && (
					<FlexColumn>
						<TextField
							label="New Job Number"
							aria-label="New Job Number"
							title="New Job Number"
							value={state.number || ''}
							onChange={(e) =>
								handleOnChange('number', e.target.value || '')
							}
							variant="outlined"
							margin="dense"
							fullWidth
							required
							error={!state.number}
							InputLabelProps={{ shrink: true }}
						/>
						<TextField
							label="New Job Name"
							aria-label="New Job Name"
							title="New Job Name"
							value={state.name || ''}
							onChange={(e) =>
								handleOnChange('name', e.target.value || '')
							}
							variant="outlined"
							margin="dense"
							fullWidth
							required
							error={!state.name}
							InputLabelProps={{ shrink: true }}
						/>

						<AutoComplete
							label="Task Template"
							placeholder="Task Template"
							required
							value={state.taskTemplateId || null}
							options={taskTemplates.map((item) => ({
								label: item.name,
								value: item.id,
							}))}
							error={!state.taskTemplateId}
							onChange={(value) =>
								handleOnChange('taskTemplateId', value || null)
							}
						/>
					</FlexColumn>
				)}
			</Box>
		</Dialog>
	);
};
