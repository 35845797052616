import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

// Lib
import { RootState } from '.';
import { User } from '../core';
import { orderBy } from 'lodash';

interface EmployeeLookupState {
	employees: User[];
}

const initialState: EmployeeLookupState = {
	employees: [],
};

export const employees = createSlice({
	name: 'employees',
	initialState,
	reducers: {
		loadEmployeeData: (state, action: PayloadAction<User[]>) => {
			state.employees = action.payload;
		},
		addEmployee: (state, action: PayloadAction<User>) => {
			state.employees.push(action.payload);
		},
		updateEmployee: (state, action: PayloadAction<User>) => {
			const index = state.employees.findIndex(
				(x) => x.EmployeeNumber === action.payload.EmployeeNumber
			);
			if (index !== -1) {
				state.employees.splice(index, 1, action.payload);
			}
		},
	},
});

export const { loadEmployeeData, addEmployee, updateEmployee } =
	employees.actions;

export const selectAllEmployees = (state: RootState) =>
	state.employees.employees;

export const selectEmployee = createSelector(
	[
		selectAllEmployees,
		(_state: RootState, employeeNumber: string) => employeeNumber,
	],
	(employees, employeeNumber) =>
		employees.find((x: any) => x.EmployeeNumber === employeeNumber)
);

export const selectActiveEmployees = createSelector(
	[selectAllEmployees, (_state: RootState) => _state],
	(employees) =>
		orderBy(
			employees.filter((x: any) => !!x && x.isActive),
			['EmployeeNumber'],
			['asc']
		)
);

export const selectActiveMarketingEmployees = createSelector(
	[selectActiveEmployees, (_state: RootState) => _state],
	(employees) =>
		orderBy(
			employees.filter((x) => (x.usr9 as unknown) === 1),
			['EmployeeNumber'],
			['asc']
		)
);

export default employees.reducer;
