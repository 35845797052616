import { createTheme } from '@mui/material/styles';

export const themeOverrides = createTheme({
	components: {
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontWeight: 'bold',
					color: '#000000',
					'&.Mui-disabled': {
						opacity: 1,
						color: '#000000',
					}
				},

			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					color: '#000000',
					fontWeight: 400,
					'&.Mui-disabled': {
						color: '#000000',
					},
					padding: 0,
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				margin: 'dense',
				fullWidth: true,
				size: 'small',
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			}
		},
		MuiList: {
			defaultProps: {
				dense: true,
			}
		},
		MuiListItemButton: {
			defaultProps: {
				disableGutters: true,
			}
		},
		MuiListItem: {
			defaultProps: {
				disableGutters: true,
				disablePadding: true,
			}
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: '40px',
				}
			}
		}
	},
});
