import { mdiPrinterOutline, mdiSortAscending, mdiSortDescending } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton, Tooltip } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { orderBy } from 'lodash';
import { useMeasure } from 'react-use';
import { JobTaskWithJobData, UserMarketingTasks } from "../../core";
import { AutoComplete, Dialog, FlexColumn, FlexRow } from '../common';
import useSortOptions, { SortOption } from '../useSortOptions';
import { JobTaskList } from './JobTaskList';
import { MarketingTaskList } from './MarketingTaskList';
import { useEmployeeTasks } from './useEmployeeTasks';
import { useState } from 'react';
import { JobTaskListForPrint } from './JobTaskListForPrint';
import { MarketingTaskListForPrint } from './MarketingTaskListForPrint';

type Props = {
	onNavigate: (url: string) => void;
	show: boolean;
};

const jobTaskSortOptions: SortOption[] = [
	{ label: 'Due Date', value: 'TaskDueDate', direction: 'Descending' },
	{ label: 'Start Date', value: 'TaskStartDate', direction: 'Descending' },
	{ label: 'Job Name', value: 'JobName', direction: 'Ascending' },
	{ label: 'Job Number', value: 'JobNumber', direction: 'Ascending' },
];

const marketingTaskSortOptions: SortOption[] = [
	{ label: 'Client', value: 'FullName', direction: 'Ascending' },
	{ label: 'Start Date', value: 'StartDate', direction: 'Descending' },
	{ label: 'Due Date', value: 'DateDue', direction: 'Descending' },
	{ label: 'Description', value: 'TaskDesc', direction: 'Ascending' },
];

export const EmployeeTasks = ({ onNavigate, show }: Props) => {
	const [ref, { width }] = useMeasure<HTMLDivElement>();
	const [tabIndex, setTabIndex] = useState(0);
	const [showPrintDialog, setShowPrintDialog] = useState(false);

	const jobSort = useSortOptions(jobTaskSortOptions);
	const marketingSort = useSortOptions(marketingTaskSortOptions);
	const tasks = useEmployeeTasks({ onNavigate });

	const isWide = width >= 800;

	if (!show) return null;

	const jobItems = orderBy(
		tasks.jobTasks,
		[jobSort.value],
		[jobSort.direction === 'Ascending' ? 'asc' : 'desc']
	);

	const marketingItems = orderBy(
		tasks.marketingTasks,
		[marketingSort.value],
		[marketingSort.direction === 'Ascending' ? 'asc' : 'desc']
	);

	console.log('Job Task Items', jobItems);
	console.log('Marketing Task Items', marketingItems);

	return (
		<div
			ref={ref}
			data-component="employee-tasks"
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				overflow: 'hidden',
			}}
		>
			<Tabs
				value={tabIndex}
				indicatorColor="secondary"
				textColor="primary"
				onChange={(_event, index) => setTabIndex(index)}
			>
				<Tab
					value={0}
					label={`Job Tasks (${tasks.jobTasks.length})`}
				/>
				<Tab
					value={1}
					label={`Marketing Tasks (${tasks.marketingTasks.length})`}
				/>
				<span style={{ flexGrow: 1 }} />
				<IconButton
					onClick={() => setShowPrintDialog(true)}
					sx={theme => ({ marginRight: theme.spacing(1) })}
				>
					<Icon
						path={mdiPrinterOutline}
						size={1}
					/>
				</IconButton>
			</Tabs>
			<FlexRow
				nowrap
				style={{
					paddingTop: '16px',
					paddingBottom: '16px',
					paddingLeft: '16px',
					paddingRight: '0px',
					alignItems: 'center',
				}}
			>
				<AutoComplete
					label="Sort"
					placeholder="Sort"
					autoOpen
					value={tabIndex === 0 ? jobSort.value : marketingSort.value}
					onChange={(value) => {
						if (tabIndex === 0) {
							jobSort.onChangeSortOption(value || '');
						} else {
							marketingSort.onChangeSortOption(value || '');
						}
					}}
					options={
						tabIndex === 0 ? jobSort.options : marketingSort.options
					}
					autoSelect
				/>
				<Tooltip
					title={
						tabIndex === 0
							? jobSort.direction
							: marketingSort.direction
					}
				>
					<IconButton
						aria-label="Direction"
						onClick={() => {
							if (tabIndex === 0) {
								jobSort.onToggleSortDirection(jobSort.value);
							} else {
								marketingSort.onToggleSortDirection(
									marketingSort.value
								);
							}
						}}
						tabIndex={-1}
						style={{ marginRight: '8px', marginLeft: '8px' }}
					>
						<Icon
							path={
								(tabIndex === 0
									? jobSort.direction
									: marketingSort.direction) === 'Ascending'
									? mdiSortDescending
									: mdiSortAscending
							}
							size={0.8}
						/>
					</IconButton>
				</Tooltip>
			</FlexRow>
			<FlexColumn fill scroll hide={tabIndex !== 0}>
				<JobTaskList
					mode={isWide ? 'table' : 'list'}
					items={jobItems as JobTaskWithJobData[]}
					onOpenJobTask={tasks.handleOpenJobTask}
				/>
			</FlexColumn>
			<FlexColumn fill scroll hide={tabIndex !== 1}>
				<MarketingTaskList
					mode={isWide ? 'table' : 'list'}
					items={marketingItems as UserMarketingTasks[]}
					onOpenMarketingTask={tasks.handleOpenMarketingTask}
				/>
			</FlexColumn>
			<Dialog
				fullScreen
				fullHeight
				forPrinting
				isOpen={showPrintDialog === true}
				title="Print Tasks"
				onClose={() => setShowPrintDialog(false)}
				positiveAction={{
					text: 'Print',
					onClick: () => window.print(),
				}}
				cancelAction={{
					text: 'Close',
					onClick: () => setShowPrintDialog(false),
				}}
			>
				<div>
					<FlexColumn fill scroll hide={tabIndex !== 0}>
						<JobTaskListForPrint
							mode={isWide ? 'table' : 'list'}
							items={jobItems as JobTaskWithJobData[]}
						/>
					</FlexColumn>
					<FlexColumn fill scroll hide={tabIndex !== 1}>
						<MarketingTaskListForPrint
							mode={isWide ? 'table' : 'list'}
							items={marketingItems as UserMarketingTasks[]}
						/>
					</FlexColumn>
				</div>
			</Dialog>
		</div>
	);
};
