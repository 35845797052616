import React from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

// Components
import { NotFound } from './shell';
import { Dashboard } from './dashboard';
import { JobContainer } from './jobs';
import { ContactContainer } from './contacts';
import { CompanyContainer } from './companies';
import { AddLeadContainer } from './leads/AddLeadContainer';
import { AddCompanyContainer } from './companies/AddCompanyContainer';
import { AddContactContainer } from './contacts/AddContactContainer';
import { S3FileBrowserProvider } from './browser/S3FileBrowserContext';
import { useAppContext } from '../contexts/AppContext';
import { Permissions } from '../lib';
import { UserManagement } from './admin/UserManagement';

export default function Routes() {
	const { hasPermission } = useAppContext();
	const canEditJob = hasPermission([Permissions.EditJobs]);
	const canDelete = hasPermission([Permissions.AdminJobs]);
	return (
		<ReactRoutes>
			<Route path="/" Component={Dashboard} />
			<Route
				path="/jobs/:id"
				element={
					<S3FileBrowserProvider
						readOnly={!canEditJob}
						canDelete={canDelete}
					>
						<JobContainer />
					</S3FileBrowserProvider>}
			/>
			<Route path="/contacts/:id" element={<ContactContainer />} />
			<Route path="/companies/:id" element={<CompanyContainer />} />
			<Route path="/lead" element={<AddLeadContainer />} />
			<Route path="/add/company" element={<AddCompanyContainer />} />
			<Route path="/add/contact" element={<AddContactContainer />} />
			<Route path="/admin/users" element={<UserManagement />} />
			<Route Component={NotFound} />
		</ReactRoutes>
	);
}
