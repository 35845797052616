import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
	CompanySummary,
	ContactData
} from '../../core';
import { Container, Toolbar } from '../common';
import { CompanyListItem } from '../companies';
import { ChangeCompanyDialog } from './ChangeCompanyDialog';
import { useAppContext } from '../../contexts/AppContext';
import { useRecentItems } from '../recent';
import { useNavigate } from 'react-router-dom';

type ContactJobListProps = {
	contact: ContactData | null | undefined;
	onCountChanged: (count: number) => void;
	onChange: (data: Partial<ContactData>) => void;
	show: boolean;
};

export const ContactCompanyList = (props: ContactJobListProps) => {
	const { api } = useAppContext();
	const navigate = useNavigate();
	const { addRecentCompany } = useRecentItems();
	const { contact, onCountChanged, onChange, show } = props;
	const [companies, setCompanies] = useState<CompanySummary[]>([]);
	const [showChangeCompany, setShowChangeCompany] = useState(false);

	const loadCompanies = React.useCallback(
		async (id: number) => {
			try {
				const results = await api.contact.queryCompanies(id);
				setCompanies(results);
			} catch (error) {
				console.error(error);
			}
		},
		[api.contact]
	);

	const handleCompanyChange = (data: ContactData) => {
		if (contact?.ContactID) {
			onChange(data);
			setTimeout(() => {
				setShowChangeCompany(false);
				loadCompanies(contact.ContactID);
			}, 1500);
		}
	};

	useEffect(() => {
		if (contact?.ContactID) {
			loadCompanies(contact.ContactID);
		} else {
			setCompanies([]);
		}
	}, [contact?.ContactID, loadCompanies]);

	useEffect(() => {
		onCountChanged(companies.length);
	}, [companies.length, onCountChanged]);

	if (!show) return null;

	const currentCompany = companies.find(
		(x) => x.CompanyID === contact?.CurrentCompanyID
	);
	const otherCompanies = currentCompany
		? companies.filter((x) => x.CompanyID !== contact?.CurrentCompanyID)
		: companies;

	return (
		<>
			<Toolbar>
				<Button
					size="small"
					color="secondary"
					variant="contained"
					onClick={() => setShowChangeCompany(true)}
				>
					Change Company
				</Button>
			</Toolbar>
			<Container component="ContactCompanyList" scrollable>
				{!!currentCompany && (
					<>
						<Typography
							variant="caption"
							style={{
								fontWeight: 'bold',
								marginBottom: '6px',
								marginTop: '10px',
							}}
						>
							Current Company
						</Typography>
						<CompanyListItem
							company={currentCompany}
							isCurrentCompany={false}
							onClick={() => {
								addRecentCompany({
									id: currentCompany.CompanyID,
									name: currentCompany.CompanyName,
									type: currentCompany.CompanyTypeName,
								});
								navigate(
									`/companies/${currentCompany.CompanyID}`
								);
							}}
						/>
					</>
				)}
				{currentCompany && (
					<Typography
						variant="caption"
						style={{
							fontWeight: 'bold',
							marginBottom: '6px',
							marginTop: '10px',
						}}
					>
						Other Companies
					</Typography>
				)}
				{otherCompanies.map((company) => (
					<CompanyListItem
						key={company.CompanyID}
						company={company}
						isCurrentCompany={
							contact?.CurrentCompanyID === company.CompanyID
						}
						onClick={() => {
							addRecentCompany({
								id: company.CompanyID,
								name: company.CompanyName,
								type: company.CompanyTypeName,
							});
							navigate(`/companies/${company.CompanyID}`);
						}}
					/>
				))}
			</Container>
			{showChangeCompany && (
				<ChangeCompanyDialog
					contact={contact}
					onClose={() => setShowChangeCompany(false)}
					onChange={handleCompanyChange}
				/>
			)}
		</>
	);
};
