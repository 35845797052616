import React from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

// Icons
import Icon from '@mdi/react';
import { mdiFilterPlus } from '@mdi/js';

// Components
import { AutoCompleteOptionOf } from '../common/AutoComplete';
import { FlexRow } from '../common';

export type FilterMenuItem = {
	key: string;
	label: string;
};

const menuItems: FilterMenuItem[] = [
	{ key: 'usr1', label: 'EST' },
	{ key: 'usr2', label: 'PM' },
	{ key: 'usr3', label: 'LEVEL5' },
	{ key: 'usr4', label: 'LEAD' },
	{ key: 'usr5', label: 'ADMIN' },
	{ key: 'usr6', label: 'ACCT' },
	{ key: 'usr7', label: 'DRM' },
	{ key: 'usr8', label: 'OPEN' },
	{ key: 'usr9', label: 'MKTING' },
	{ key: 'division', label: 'Division' },
	// { key: 'claim', label: 'Claim Number' },
];

const options: AutoCompleteOptionOf<string | null>[] = [
	{ label: 'Any', value: '' },
	{ label: 'Not Contacted', value: 'Not Contacted' },
	{ label: 'Contacted', value: 'Contacted' },
	{ label: 'Inspected', value: 'Inspected' },
	{ label: 'Est. Submitted', value: 'Est. Submitted' },
	{ label: 'On Hold', value: 'On Hold' },
	{ label: 'Sold', value: 'Sold' },
	{ label: 'Preliminary', value: 'Preliminary' },
	{ label: 'Prod Started', value: 'Prod Started' },
	{ label: 'Prod Complete', value: 'Prod Complete' },
	{ label: 'Customer Approval', value: 'Customer Approval' },
	{ label: 'Invoiced', value: 'Invoiced' },
	{ label: 'Paid in Full', value: 'Paid in Full' },
	{ label: 'Closed Jobs', value: 'Closed Jobs' },
];

type Props = {
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	value: string | null;
	onChange: (value: string | null) => void;
	onMenuItemClick: (key: string) => void;
	menuFilter: (options: FilterMenuItem[]) => FilterMenuItem[];
};

export const JobListJobStatus = ({
	label,
	placeholder,
	value,
	onChange,
	onMenuItemClick,
	disabled,
	menuFilter,
}: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	// Menu
	const handleOpenFilterMenu = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseFilterMenu = () => {
		setAnchorEl(null);
	};

	const filteredItems = menuFilter(menuItems);

	return (
		<FlexRow nowrap style={{ alignItems: 'center' }}>
			<MuiAutocomplete
				fullWidth
				disabled={disabled}
				value={
					options.find((x) => x.value === value) || {
						label: 'Any',
						value: '',
					}
				}
				onChange={(_event, option: any) =>
					onChange(option?.value || null)
				}
				options={options}
				style={{ minWidth: '250px' }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
						autoComplete="off"
						variant="outlined"
						size="small"
						margin="dense"
						fullWidth
						disabled={disabled}
						onFocus={(event) => event.target.select()}
						InputLabelProps={{ shrink: true }}
						InputProps={{
							...params.InputProps,
							autoComplete: 'off',
							disabled: disabled,
						}}
					/>
				)}
			/>
			<div style={{ marginLeft: '8px' }}>
				<Tooltip title="Add Filter">
					<IconButton
						aria-label="add filter"
						size="small"
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={handleOpenFilterMenu}
						disabled={filteredItems.length === 0}
					>
						<Icon path={mdiFilterPlus} size={1} />
					</IconButton>
				</Tooltip>
			</div>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleCloseFilterMenu}
			>
				{filteredItems.map((item: FilterMenuItem) => (
					<MenuItem
						key={item.key}
						onClick={() => {
							setAnchorEl(null);
							onMenuItemClick(item.key);
						}}
					>
						{item.label}
					</MenuItem>
				))}
			</Menu>
		</FlexRow>
	);
};
