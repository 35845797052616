import * as React from 'react';
import { useDebounce } from 'react-use';
import { useSnackbar } from 'notistack';
import { UpdateRequest } from "../core";
import { useAppContext } from "../contexts/AppContext";

export interface ChangeState<T> {
	loading: boolean;
	working: boolean;
	error?: any;
	onChange: (data: T) => void;
	onErrorReset: () => void;
	reload: () => void;
	item: T | undefined;
	validations: string[];
}

export interface ChangeStateOptions<T> {
	key: any;
	onSaveChanges: (request: UpdateRequest) => Promise<void>;
	onValidateChanges: (data: T) => Promise<string[]>;
	onLoadItem: () => Promise<T>;
	onItemLoaded?: (data: T) => void;
	delay?: number;
}

type State<T> = {
	initial: T | undefined;
	working: boolean;
	loading: boolean;
	validations: string[];
	error?: any;
}

const getValueOrNull = (value: any) => {
	return (value === null || value === undefined) ? null : value;
}

export const useChangeState = <T>({
	key,
	onLoadItem,
	onSaveChanges,
	onValidateChanges,
	onItemLoaded,
	delay = 1000,
}: ChangeStateOptions<T>): ChangeState<T> => {
	const snackbar = useSnackbar();
	const { user } = useAppContext();
	const [changes, setChanges] = React.useState<T>();

	const [state, setState] = React.useState<State<T>>({
		initial: undefined,
		working: false,
		loading: true,
		validations: [],
	});

	const notify = React.useCallback((message: string, error: boolean = false) => {
		snackbar.enqueueSnackbar(message, {
			variant: error ? 'error' : 'success',
			autoHideDuration: 2000,
			preventDuplicate: false,
		});
	}, [snackbar]);

	const loadItem = React.useCallback(async (load: () => Promise<T>) => {
		console.log('useChangeState: loadItem');
		try {
			const data = await load();
			setTimeout(() => {
				setState({
					initial: { ...data },
					loading: false,
					working: false,
					validations: []
				});
				setChanges({ ...data });
				if (onItemLoaded) {
					onItemLoaded({ ...data });
				}
			}, 500);
		} catch (err) {
			setState(s => ({ ...s, error: err, loading: false }));
		}
	}, [onItemLoaded]);

	const saveChanges = React.useCallback(
		async () => {
			if (changes && state.validations.length === 0) {
				console.log('useChangeState: saveChanges');
				setState(s => ({ ...s, working: true, error: undefined }));
				try {

					const request: UpdateRequest = {
						id: key,
						employee: user.EmployeeNumber,
						data: {}
					};

					Object.keys(changes).forEach(key => {
						if ((state.initial as any)[key] !== (changes as any)[key]) {
							const from = getValueOrNull((state.initial as any)[key]);
							const to = getValueOrNull((changes as any)[key]);
							request.data[key] = { from, to };
						}
					});

					if (Object.keys(request.data).length > 0) {
						console.log('useChangeState: onSaveChanges', request);
						await onSaveChanges(request);
						setState(s => ({
							...s,
							initial: { ...changes as T },
							error: undefined,
							working: false
						}));
						notify('Changes Saved');
					} else {
						console.log('useChangeState: saveChanges: no changes');
					}

				} catch (err) {
					setState(s => ({ ...s, error: err, working: false }));
					notify(`Error: ${err}`, true);
				}
			} else {
				console.log('useChangeState: saveChanges skipped');
			}
		},
		[key, notify, onSaveChanges, changes, state.initial, state.validations.length, user.EmployeeNumber]
	);

	const onValidate = React.useCallback(async () => {
		if (changes) {
			try {
				const validations = await onValidateChanges(changes);
				setState(s => ({ ...s, validations }));
			} catch (error) {
				console.error(error);
				setState(s => ({ ...s, validations: [] }));
			}
		}
	}, [changes, onValidateChanges]);

	useDebounce(saveChanges, delay, [changes]);
	useDebounce(onValidate, 200, [changes]);

	React.useEffect(() => {
		if (key) {
			setState({
				initial: undefined,
				working: false,
				loading: true,
				validations: [],
			})
			loadItem(onLoadItem);
		}
		// Only run when id changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key])

	return {
		loading: state.loading,
		working: state.working,
		error: state.error,
		onChange: async (data) => {
			console.log('useChangeState: onChange', data);
			setChanges(data);
		},
		onErrorReset: () => setState(s => ({ ...s, error: undefined })),
		item: changes,
		validations: state.validations,
		reload: () => {
			console.log('useChangeState: reload');
			setState(s => ({ ...s, loading: true }));
			loadItem(onLoadItem);
		}
	};
};
