import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

import { User } from '../../core';

import { Dialog } from '../common/Dialog';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
	user: User;
	onClose: () => void;
};

export const ResetUserPasswordDialog = ({ user, onClose }: Props) => {
	const { api } = useAppContext();
	const [password, setPassword] = React.useState('');
	const [busy, setBusy] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const snackbar = useSnackbar();

	const handleDialogAction = async () => {
		setBusy(true);
		try {
			await api.employee.resetPassword(user.EmployeeNumber, password);
			snackbar.enqueueSnackbar(
				`Password Reset for ${user.EmployeeName}`,
				{
					variant: 'success',
				}
			);
			onClose();
		} catch (error) {
			setBusy(false);
			setErrorMessage(`${error}`);
		}
	};

	React.useEffect(() => {
		// Their password requirements are terrible
		if (!password) {
			setErrorMessage('Please provide a password.');
		} else if (password.length < 6) {
			setErrorMessage(
				'The password must be at lease 6 characters in length.'
			);
		} else {
			setErrorMessage('');
		}
	}, [password]);

	return (
		<Dialog
			isOpen={true}
			title={`Reset Password: ${user.EmployeeNumber} - ${user.EmployeeName}`}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
			}}
			positiveAction={{
				text: 'Reset Password',
				isDisabled: () => busy || !!errorMessage,
				onClick: handleDialogAction,
			}}
			onClose={onClose}
		>
			<Grid
				container
				direction="column"
				style={{ padding: '32px', flexGrow: 1 }}
			>
				<Grid
					item
					style={{ alignItems: 'center', justifyContent: 'center' }}
				>
					<TextField
						label="Password"
						aria-label="Password"
						title="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value || '')}
						onFocus={(e) => e.target.select()}
						variant="outlined"
						margin="dense"
						fullWidth
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
				{errorMessage && (
					<Grid
						item
						style={{
							padding: '8px',
							textAlign: 'right',
							color: '#b71c1c',
						}}
					>
						<strong>{errorMessage}</strong>
					</Grid>
				)}
			</Grid>
		</Dialog>
	);
};
