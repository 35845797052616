import { CompanyViewData } from "../../core";
import { useCallback, useEffect, useState } from "react";
import { api } from "../../api";

export default function useCompany(companyId?: number) {
    const [company, setCompany] = useState<CompanyViewData>();

    const getCompany = useCallback(async (id: number) => {
        try {
            const result = await api.company.getView(id);
            setCompany(result);
        } catch (error) {
            setCompany(undefined);
            console.error(error);
        }
    }, []);

    useEffect(() => {
        if (companyId) {
            if (!company || company.CompanyID !== companyId) {
                getCompany(companyId);
            }
        } else {
            setCompany(undefined);
        }
    }, [company, companyId, getCompany])

    return company;
}