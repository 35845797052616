import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import { CssBaseline } from '@mui/material';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import { ThemeProvider, createTheme, lighten } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AppContext } from './contexts/AppContext';
import { NetworkStatus } from './contexts/NetworkStatusContext';
import { getAmplifyConfig } from './core';
import App from './features/shell/App';
import { themeOverrides } from './lib';
import { store } from './store';

const awsConfig = getAmplifyConfig('beta');
console.log('AWS CONFIG', awsConfig);
Amplify.configure(awsConfig);

const theme = createTheme({
  ...themeOverrides,
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: red[900],
    },
    background: {
      default: '#f6f6f6',
      paper: '#fff',
    },
    action: {
      hover: lighten(red[900], 0.9),
      // active: lighten(red[900], 0.8),
      focus: lighten(red[900], 0.9),
      selected: lighten(red[900], 0.7),
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <form id="form" onSubmit={(e) => e.preventDefault()} autoComplete="off">
      <ReduxProvider store={store}>
        <BrowserRouter>
          <NetworkStatus>
            <ThemeProvider theme={theme}>
              <AppContext region={awsConfig.aws_user_files_s3_bucket_region} bucket={awsConfig.aws_user_files_s3_bucket}>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <App />
                </LocalizationProvider>
              </AppContext>
            </ThemeProvider>
          </NetworkStatus>
        </BrowserRouter>
      </ReduxProvider>
    </form>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
