import { useParams } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { CompanyData } from '../../core';
import { Loading } from '../common';
import { useChangeState } from '../useChangeState';
import { CompanyLayout } from "./CompanyLayout";

const validateCompany = (contact: Partial<CompanyData>): Promise<string[]> => {
	const errors: string[] = [];
	if (!contact.CompanyTypeID) {
		errors.push('ContactTypeID');
	}
	if (!contact.CompanyName) {
		errors.push('CompanyName');
	}
	return Promise.resolve(errors);
};

export const CompanyContainer = () => {
	const { api } = useAppContext();
	const { id } = useParams<{ id: string }>();
	const companyId = Number(id);

	const state = useChangeState<CompanyData>({
		key: companyId,
		onLoadItem: () => api.company.get(companyId),
		onSaveChanges: api.company.update,
		onValidateChanges: validateCompany,
	});

	return (
		<>
			{state.loading && <Loading />}
			{!state.loading && <CompanyLayout state={state} />}
		</>
	);
};
