import React from 'react';
import { FlexColumn, Loading, NothingHere } from '../common';
import { useEmployeeTasks } from '../tasks/useEmployeeTasks';
import { useNavigate } from 'react-router-dom';

export function Dashboard() {
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(true);
	const { jobTasks, handleOpenJobTask } = useEmployeeTasks({
		onNavigate: navigate,
	});

	React.useEffect(() => {
		if (jobTasks.length > 0) {
			const task = jobTasks[0];
			if (task) {
				handleOpenJobTask(task);
			}
		}
		setLoading(false);
	}, [handleOpenJobTask, jobTasks]);

	return (
		<FlexColumn fill style={{ padding: '16px' }}>
			{loading && <Loading />}
			{!loading && <NothingHere text="You currently have no job tasks" />}
		</FlexColumn>
	);
}
