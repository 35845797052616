import { mdiMapMarker } from "@mdi/js";
import { useState } from "react";
import { CompanyAddressViewData, ContactAddressViewData, StreetAddressData } from "../../core";
import { AddAddressDialog } from "../addresses";
import { AddressListItem } from "../addresses/AddressListItem";
import useAddress from "../addresses/useAddress";
import { FlexColumn } from '../common';
import { useWindowOpen } from "../useWindowOpen";
import { AddressAutoComplete } from "./AddressAutoComplete";
import SelectionCard from "./SelectionCard";

type Props = {
    title: string;
    addressId?: number;
    addressTypeId?: number;
    parentId?: number;
    parentType: 'Company' | 'Contact';
    onSelected?: (address?: StreetAddressData) => void;
    error?: boolean;
    style?: React.CSSProperties;
    disabled?: string;
    actions?: React.ReactNode;
};

export default function AddressSelection({
    title,
    addressId,
    addressTypeId,
    parentId,
    parentType,
    onSelected,
    error,
    style,
    disabled,
    actions,
}: Props) {
    const address = useAddress(addressId);
    const [showAddAddressDialog, setShowAddAddressDialog] = useState(false);
    const open = useWindowOpen();

    const handleOpenMap = () => {
        if (address) {
            const link = [];
            if (address.AddressLine1) link.push(encodeURI(address.AddressLine1));
            if (address.AddressLine2) link.push(encodeURI(address.AddressLine2));
            if (address.City) link.push(encodeURI(address.City));
            if (address.State) link.push(encodeURI(address.State));
            if (address.ZIP) link.push(encodeURI(address.ZIP));
            const url = `http://maps.google.com/maps?q=${link.join(',')}`;
            open(url);
        }
    };

    const handleAddressAdded = (data: ContactAddressViewData | CompanyAddressViewData) => {
        if (onSelected) {
            onSelected(data);
        }
        setShowAddAddressDialog(false)
    }

    const handleSelection = (data?: StreetAddressData) => {
        if (onSelected) {
            onSelected(data);
        }
    }

    return (
        <>
            <SelectionCard
                height={150}
                style={style}
                title={title}
                disabled={disabled}
                openIcon={mdiMapMarker}
                actions={actions}
                mode={!!address ? 'render' : 'selection'}
                onClearSelection={() => handleSelection(undefined)}
                onOpenSelection={handleOpenMap}
                render={<AddressListItem address={address} />}
                selection={
                    <FlexColumn style={{ padding: '8px' }}>
                        <AddressAutoComplete
                            label="Address"
                            onChange={data => handleSelection(data || undefined)}
                            parentId={parentId}
                            parentType={parentType}
                            typeId={addressTypeId}
                            onAddClick={() => setShowAddAddressDialog(true)}
                            error={error}
                            disabled={!!disabled}
                        />
                    </FlexColumn>
                }
            />
            {showAddAddressDialog && parentId && (
                <AddAddressDialog
                    autoComplete
                    parentId={parentId}
                    parentType={parentType}
                    onAddressAdded={handleAddressAdded}
                    onClose={() => setShowAddAddressDialog(false)}
                />
            )}
        </>

    );
}
