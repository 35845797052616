import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>
) {
	// TODO: Make sure this works
	// @ts-ignore
	return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
	error: Error | undefined;
	onClose: () => void;
};

export const ErrorDialog = ({ error, onClose }: Props) => {
	if (error) {
		console.error('Error Dialog', error);
	}

	return (
		<Dialog
			open={!!error}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title">
				{'An error occured'}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					{error
						? error.message || 'Unknown'
						: 'Invalid error object'}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
