import React from 'react';
import { useAppContext } from '../../contexts/AppContext';

export type Props = {
	children: React.ReactNode;
	permissions: string[];
};

export const HasPermission = ({ children, permissions }: Props) => {
	const { hasPermission } = useAppContext();

	if (!hasPermission(permissions)) {
		return null;
	}

	return <>{children}</>;
};
