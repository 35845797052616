import { AddLead } from '../core';

import { ApiClientInterface } from './client';

export default class LeadApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.add = this.add.bind(this);
	}

	public async add(lead: Partial<AddLead>): Promise<{ JobID: number }> {
		const response = await this.client.post<
			Partial<AddLead>,
			{ JobID: number }
		>(`/lead`, lead);
		return response;
	}
}
