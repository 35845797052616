import {
	mdiCardAccountMailOutline,
	mdiCheckboxBlankOutline,
	mdiCheckboxOutline,
	mdiClose,
	mdiHomeCityOutline,
	mdiTrafficCone,
	mdiTrashCan,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Button, IconButton, ListItemButton, Toolbar, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import { NothingHere } from '../common';
import { RecentItem, RecentItemType, RecentItemTypes } from './types';
import { useRecentItems } from './useRecentItems';

const getIcon = (type: RecentItemType): string => {
	if (type === RecentItemTypes.Contact) return mdiCardAccountMailOutline;
	if (type === RecentItemTypes.Job) return mdiHomeCityOutline;
	return mdiTrafficCone;
};

type SidebarProps = {
	onNavigate: (url: string) => void;
	show: boolean;
};

export function RecentItemsSidebar({ onNavigate, show }: SidebarProps) {
	const theme = useTheme();
	const { items, addRecentItem, removeRecentItems, clearRecentItems } =
		useRecentItems();
	const [showSelectItems, setShowSelectItems] = useState(false);
	const [selectedItems, setSelectedItems] = useState<string[]>([]);

	useEffect(() => {
		if (showSelectItems === true) {
			setSelectedItems([]);
		}
	}, [showSelectItems]);

	const handleOpenItem = (item: RecentItem) => {
		addRecentItem(item); // this pushes the item to the top
		if (item.type === RecentItemTypes.Job) {
			onNavigate(`/jobs/${item.id}`);
		}
		if (item.type === RecentItemTypes.Contact) {
			onNavigate(`/contacts/${item.id}`);
		}
	};

	if (!show) return null;

	return (
		<Box
			data-component="recent-items"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				overflow: 'hidden',
			}}
		>
			{!items.length && (
				<NothingHere text="Opening jobs, contacts, or tasks will create a history so you can easily find things later" />
			)}
			{!!items.length && (
				<>
					{!showSelectItems && (
						<List>
							<ListItemButton
								onClick={() => setShowSelectItems(true)}
								disabled={items.length === 0}
								sx={{ paddingLeft: '8px'}}
							>
								<ListItem>
									<ListItemIcon>
										<Icon path={mdiTrashCan} size={1} />
									</ListItemIcon>
									<ListItemText primary="Remove Items" />
								</ListItem>
							</ListItemButton>
						</List>
					)}
					{showSelectItems && (
						<Toolbar
							variant="dense"
							style={{ paddingRight: '8px' }}
						>
							<Button
								size="small"
								variant="contained"
								color="secondary"
								style={{ marginRight: theme.spacing(1) }}
								startIcon={
									<Icon path={mdiTrashCan} size={0.75} />
								}
								onClick={() => {
									removeRecentItems(selectedItems);
									setShowSelectItems(false);
								}}
							>
								Selected
							</Button>
							<Button
								size="small"
								variant="contained"
								color="secondary"
								style={{ marginRight: theme.spacing(1) }}
								startIcon={
									<Icon path={mdiTrashCan} size={0.75} />
								}
								onClick={() => {
									clearRecentItems();
									setShowSelectItems(false);
								}}
							>
								All
							</Button>
							<span style={{ flexGrow: 1 }} />
							<IconButton
								edge="end"
								aria-label="Cancel"
								onClick={() => setShowSelectItems(false)}
							>
								<Icon path={mdiClose} size={1} />
							</IconButton>
						</Toolbar>
					)}
				</>
			)}
			<Divider />
			<Box
				style={{
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					overflowX: 'hidden',
					overflowY: 'auto',
				}}
			>
				<List>
					{items.map((item) => (
						<ListItemButton
							key={item.key}
							sx={{ paddingLeft: '8px'}}
							onClick={() => {
								if (showSelectItems) {
									setSelectedItems((s) => {
										const ns = [...s];
										const index = ns.findIndex(
											(x) => x === item.key
										);
										if (index !== -1) {
											ns.splice(index, 1);
										} else {
											ns.push(item.key);
										}
										return ns;
									});
								} else {
									handleOpenItem(item);
								}
							}}
						>
							<ListItem
								alignItems="flex-start"
							>
								<ListItemIcon sx={theme => ({ minWidth: theme.spacing(5) })}>
									{!showSelectItems && (
										<Icon path={getIcon(item.type)} size={1} />
									)}
									{showSelectItems && (
										<Icon
											path={
												selectedItems.includes(item.key)
													? mdiCheckboxOutline
													: mdiCheckboxBlankOutline
											}
											size={1}
										/>
									)}
								</ListItemIcon>
								<ListItemText
									primary={item.name}
									secondary={item.description}
									sx={{
										position: 'relative',
										display: 'block',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										overflow: 'hidden',
									}}
								/>
							</ListItem>
						</ListItemButton>
					))}
				</List>
			</Box>
		</Box>
	);
}
