import { Box } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { ContactData, UserPermissions } from '../../core';
import { BottomSpace, Container, Sidebar } from '../common';
import { ChangeState } from '../useChangeState';
import { ContactAddressList } from './ContactAddressList';
import { ContactCompanyList } from './ContactCompanyList';
import { ContactDetail } from './ContactDetail';
import { ContactEmailList } from './ContactEmailList';
import { ContactFields } from './ContactFields';
import { ContactJobList } from './ContactJobList';
import { ContactJobSummary } from './ContactJobSummary';
import { ContactNoteList } from './ContactNoteList';
import { ContactPhoneList } from './ContactPhoneList';
import { ContactSalesReps } from './ContactSalesReps';
import { ContactTaskList } from './ContactTaskList';
import { ContactTitle } from './ContactTitle';

const RelatedContents = {
	Tasks: 'Tasks',
	Notes: 'Notes',
	Jobs: 'Jobs',
	Companies: 'Companies',
	Fields: 'Fields',
	SalesReps: 'SalesReps',
} as const;
type RelatedContent = keyof typeof RelatedContents;

type Props = {
	state: ChangeState<ContactData>;
}

export const ContactLayout = ({ state }: Props) => {
	const { hasPermission } = useAppContext();
	const { id } = useParams<{ id: string }>();
	const [relatedContent, setRelatedContent] = useState<RelatedContent>();
	const [taskCount, setTaskCount] = useState(0);
	const [noteCount, setNoteCount] = useState(0);
	const [jobCount, setJobCount] = useState(0);
	const [companyCount, setCompanyCount] = useState(0);
	const contactId = Number(id);
	const canEdit = hasPermission([UserPermissions.EditContacts]);

	return (
		<Container
			component="ContactContainer"
			error={state.error}
			onErrorReset={state.onErrorReset}
		>
			<ContactTitle
				contact={state.item}
				taskCount={taskCount}
				noteCount={noteCount}
				jobCount={jobCount}
				companyCount={companyCount}
				onRelatedItemClick={(key: string) =>
					setRelatedContent(key as RelatedContent)
				}
			/>

			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				flexGrow: 1,
				flexShrink: 1,
				position: 'relative',
				height: '100%',
				overflow: 'hidden',
			}}>
				<Box
					sx={theme => ({
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						height: `100%`,
						width: '100%',
						position: 'relative',
						overflowY: 'auto',
						overflowX: 'hidden',
						transition: theme.transitions.create('margin', {
							easing: !!relatedContent ? theme.transitions.easing.easeOut : theme.transitions.easing.sharp,
							duration: !!relatedContent ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
						}),
						marginRight: 0,
						[theme.breakpoints.down('xs')]: {
							marginRight: !!relatedContent ? 0 : undefined,
						},
					})}
				>
					{/* <LoadingLinear enabled={state.loading || state.working} /> */}
					<ContactDetail
						disabled={!canEdit}
						contact={state.item}
						onChange={(data) => state.onChange(data as ContactData)}
						validations={state.validations}
					/>
					<ContactJobSummary contactId={contactId} />
					<ContactPhoneList canEdit={canEdit} contactId={contactId} />
					<ContactEmailList canEdit={canEdit} contactId={contactId} />
					<ContactAddressList
						canEdit={canEdit}
						contactId={contactId}
					/>
					<BottomSpace />
				</Box>
				<Sidebar
					variant="persistent"
					anchor="right"
					resizeEnabled
					minWidth={350}
					open={!!relatedContent}
					onClose={() => setRelatedContent(undefined)}
					title={relatedContent}
					tools={null}
					sx={theme => ({
						marginLeft: theme.spacing(1),
						[theme.breakpoints.down('sm')]: {
							marginLeft: 0,
						},
					})}
				>
					<ContactTaskList
						contactId={contactId}
						onCountChanged={setTaskCount}
						show={relatedContent === RelatedContents.Tasks}
					/>
					<ContactNoteList
						contactId={contactId}
						onCountChanged={setNoteCount}
						show={relatedContent === RelatedContents.Notes}
					/>
					<ContactJobList
						contactId={contactId}
						onCountChanged={setJobCount}
						show={relatedContent === RelatedContents.Jobs}
					/>
					<ContactCompanyList
						contact={state.item}
						onCountChanged={setCompanyCount}
						show={relatedContent === RelatedContents.Companies}
						onChange={(data) =>
							state.onChange(data as ContactData)
						}
					/>
					{relatedContent === RelatedContents.Fields && (
						<ContactFields
							canEdit={canEdit}
							contact={state.item}
							onChange={(data) =>
								state.onChange(data as ContactData)
							}
						/>
					)}
					{relatedContent === RelatedContents.SalesReps && (
						<ContactSalesReps
							canEdit={canEdit}
							contact={state.item}
							onChange={(data) =>
								state.onChange(data as ContactData)
							}
						/>
					)}
				</Sidebar>
			</Box>
		</Container>
	);
};
