import Grid from '@mui/material/Grid';
import { ContactViewData, formatPhoneNumber } from '../../core';

type Props = {
	contact: Partial<ContactViewData>;
};

export const ContactCardContent = ({ contact }: Props) => {
	let phoneNumber = '';
	if (contact) {
		if (contact.phonenumber && !contact.phoneext) {
			phoneNumber = formatPhoneNumber(contact.phonenumber);
		} else if (contact.phonenumber && contact.phoneext) {
			phoneNumber = `${formatPhoneNumber(contact.phonenumber)} x: ${contact.phoneext
				}`;
		}
	}

	let name = '';
	if (contact?.firstname && contact.lastname) {
		name = `${contact.lastname}, ${contact.firstname}`;
	} else if (!contact?.firstname && !!contact?.lastname) {
		name = contact.lastname;
	} else if (!!contact?.firstname && !contact?.lastname) {
		name = contact.firstname;
	}

	return (
		<Grid container direction="row" sx={theme => ({
			padding: theme.spacing(0.25, 0),
			paddingRight: theme.spacing(0.75),
		})}>
			<Grid container direction="column">
				{contact.ContactType && (
					<Grid item sx={theme => ({
						flexGrow: 1,
						fontSize: theme.typography.caption.fontSize,
						fontWeight: 500,
						color: theme.palette.secondary.main,
					})}>
						{contact.ContactType}
					</Grid>
				)}
				{name && (
					<Grid item sx={{ flexGrow: 1 }}>
						<strong>{name}</strong>
					</Grid>
				)}
				{contact.CurrentCompany && (
					<Grid item sx={{ flexGrow: 1 }}>
						Company: {contact.CurrentCompany}
					</Grid>
				)}
				<Grid item sx={{ flexGrow: 1 }}>
					{contact.AddressLine1} {contact.AddressLine2}
				</Grid>
				<Grid item sx={{ flexGrow: 1 }}>
					{contact.City} {contact.State} {contact.ZIP}
				</Grid>
			</Grid>
			<Grid container direction="column" alignItems="flex-start">
				<Grid
					item
					sx={{ flexGrow: 1 }}
					style={{ textAlign: 'right' }}
				>
					{phoneNumber}
				</Grid>
				<Grid
					item
					sx={{ flexGrow: 1 }}
					style={{ textAlign: 'right' }}
				>
					{contact.emailaddress}
				</Grid>
			</Grid>
		</Grid>
	);
};
