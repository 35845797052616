import { ListItemButton } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import {
	CompanyViewData,
	ContactViewData,
	JobDetail,
	LookupTypes,
	PrimaryStreetAddressData,
	RenameJobRequest,
} from '../../../core';
import { SelectAddressDialog } from '../../addresses';
import { ErrorMessage, FlexColumn, Loading } from '../../common';
import { Dialog } from '../../common/Dialog';
import { useLookupType } from '../../lookups/useLookupType';
import AddressSelection from '../../selection/AddressSelection';
import ContactSelection from '../../selection/ContactSelection';

type Props = {
	job: JobDetail;
	onCancel: () => void;
	onComplete: () => void;
};

type State = {
	working: boolean;
	error?: any;
};

export const RenameJobDialog = ({ job, onCancel, onComplete }: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>({ working: false });
	const [selectAddressType, setSelectAddressType] = React.useState('');
	const [name, setName] = React.useState(job.JobName);
	const [number, setNumber] = React.useState(job.JobNumber);
	const [customerId, setCustomerId] = React.useState<number | undefined>(
		job.CustomerID
	);
	const [companyId, setCompanyId] = React.useState<number | undefined>(
		job.CompanyCustomerID
	);
	const [jobsiteId, setJobsiteId] = React.useState<number | undefined>(
		job.JobsiteAddressID
	);
	const [billingId, setBillingId] = React.useState<number | undefined>(
		job.BillingAddressID
	);

	const adjusterCustomerTypeId = useLookupType(
		LookupTypes.ContactType,
		'Adjuster/Customer'
	).ID;
	const customerTypeId = useLookupType(
		LookupTypes.ContactType,
		'Customer'
	).ID;
	const propertyManagerTypeId = useLookupType(
		LookupTypes.ContactType,
		'Property Manager'
	).ID;
	const customerCompanyTypeId = useLookupType(
		LookupTypes.CompanyType,
		'Property Manager'
	).ID;
	const jobsiteTypeId = useLookupType(LookupTypes.AddressType, 'Jobsite').ID;
	const billingTypeId = useLookupType(LookupTypes.AddressType, 'Billing').ID;

	const handleSelectAddress = React.useCallback(
		(address: Partial<PrimaryStreetAddressData>) => {
			if (selectAddressType === 'Billing') {
				setBillingId(address.AddressID);
			} else {
				setJobsiteId(address.AddressID);
			}
			setSelectAddressType('');
		},
		[selectAddressType]
	);

	const handleUseExistingJobsite = (existing: boolean) => {
		if (existing) {
			setJobsiteId(job.JobsiteAddressID);
		} else {
			setJobsiteId(undefined);
		}
	};

	const handleContactChanged = (
		contact?: ContactViewData,
		company?: CompanyViewData
	) => {
		if (!contact) {
			setCustomerId(undefined);
			setJobsiteId(undefined);
			setBillingId(undefined);
		} else {
			setCustomerId(contact?.ContactID || undefined);
			setCompanyId(company?.CompanyID || undefined);
		}
	};

	const handleRenameJob = async () => {
		if (name && number && customerId && jobsiteId && billingId) {
			setState({ working: true, error: undefined });
			try {
				const request: RenameJobRequest = {
					jobId: job.JobID,
					name,
					number,
					billingId,
					jobsiteId,
					customerId,
					companyId,
				};
				console.log(
					'RENAME REQUEST',
					JSON.stringify(request, null, '\t')
				);
				await api.job.rename(request);

				onComplete();
			} catch (error) {
				setState({ working: false, error });
			}
		}
	};

	const isExistingJobsite = job.JobsiteAddressID === jobsiteId;
	const jobsiteLabel = isExistingJobsite
		? 'Existing Jobsite Address'
		: 'Customer Jobsite Address';

	// TODO: Check for duplicate job number
	// TODO: Validate company/customer
	// TODO: Validate addresses
	const isValid =
		!!name && !!number && !!customerId && !!jobsiteId && !!billingId;

	return (
		<Dialog
			isOpen={true}
			title="Rename Job"
			onClose={onCancel}
			cancelAction={{
				text: 'Cancel',
				color: 'inherit',
				onClick: onCancel,
				isDisabled: state.working,
			}}
			positiveAction={{
				text: 'Rename Job',
				color: 'secondary',
				onClick: handleRenameJob,
				isDisabled: !isValid || state.working || !!state.error,
			}}
		>
			<Box style={{ padding: '16px' }}>
				{state.working && <Loading />}
				<ErrorMessage
					error={state.error}
					onRetry={() => {
						setState({ working: false, error: undefined });
					}}
				/>
				{!state.error && !state.working && (
					<FlexColumn>
						<TextField
							label="New Job Number"
							aria-label="New Job Number"
							title="New Job Number"
							value={number || ''}
							onChange={(e) => setNumber(e.target.value || '')}
							variant="outlined"
							margin="dense"
							fullWidth
							required
							error={!number}
							InputLabelProps={{ shrink: true }}
							style={{ marginBottom: '12px' }}
						/>
						<TextField
							label="New Job Name"
							aria-label="New Job Name"
							title="New Job Name"
							value={name || ''}
							onChange={(e) => setName(e.target.value || '')}
							variant="outlined"
							margin="dense"
							fullWidth
							required
							error={!name}
							InputLabelProps={{ shrink: true }}
							style={{ marginBottom: '12px' }}
						/>
						<ContactSelection
							title="Customer"
							companyTitle="PM"
							contactId={customerId}
							contactTypeIds={[
								customerTypeId,
								adjusterCustomerTypeId,
								propertyManagerTypeId,
							]}
							companyTypeIds={[customerCompanyTypeId]}
							onSelected={handleContactChanged}
							style={{ marginBottom: '12px' }}
						/>
						<List style={{ marginBottom: '12px' }}>
							<ListItemButton
								onClick={() =>
									handleUseExistingJobsite(!isExistingJobsite)
								}
								disabled={!customerId}
							>
								<ListItem
									secondaryAction={
										<Switch
											edge="end"
											name="jobsite"
											checked={isExistingJobsite}
											disabled={!customerId}
											onChange={(_e, checked) =>
												handleUseExistingJobsite(checked)
											}
											inputProps={{ 'aria-label': 'jobsite' }}
										/>
									}
								>
									<ListItemText primary="Copy Existing Jobsite Address" />
								</ListItem>
							</ListItemButton>
						</List>
						<AddressSelection
							title={jobsiteLabel}
							addressId={jobsiteId}
							parentId={customerId}
							parentType="Contact"
							addressTypeId={jobsiteTypeId}
							onSelected={(address) =>
								setJobsiteId(address?.AddressID)
							}
							disabled={
								!customerId
									? 'Please choose a customer'
									: undefined
							}
							style={{ marginBottom: '12px' }}
						/>
						<AddressSelection
							title="Customer Billing Address"
							addressId={billingId}
							parentId={customerId}
							parentType="Contact"
							addressTypeId={billingTypeId}
							onSelected={(address) =>
								setBillingId(address?.AddressID)
							}
							disabled={
								!customerId
									? 'Please choose a customer'
									: undefined
							}
							style={{ marginBottom: '12px' }}
						/>
					</FlexColumn>
				)}
			</Box>
			{!!selectAddressType && customerId && (
				<SelectAddressDialog
					parentId={customerId}
					parentType="Contact"
					onClose={() => setSelectAddressType('')}
					onSelectAddress={handleSelectAddress}
				/>
			)}
		</Dialog>
	);
};
