import { ThumbnailToken } from "../constants";

export const getThumbnailKey = (s3key: string) => {
	// INPUT = public/14784/Pictures/25452WX_BARN99_2022-10-13T14-45-35_img_0564.png
	// OUTPUT = public/14784/Pictures/25452WX_BARN99_2022-10-13T14-45-35_img_0564__thumbnail.png

	const filename = s3key.split('.').slice(0, -1).join('.');
	const ext = s3key.split('.').pop();

	if (!filename || !ext) {
		throw new Error('Could not find the file extension');
	}

	return `${filename}${ThumbnailToken}.${ext}`;
};