import {
	mdiChevronDown,
	mdiClose,
	mdiUnfoldMoreHorizontal,
	mdiViewAgendaOutline,
	mdiViewDayOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { groupBy, orderBy } from 'lodash';
import React from 'react';
import { ReportData } from '../../core';
import { getLocalStorageItem, setLocalStorageItem } from '../../storage';
import { useOpenReport } from './useOpenReport';
import { ListItemButton } from '@mui/material';

export type Props = {
	reports?: ReportData[];
	type: string;
};

type ExpansionIndex = Record<string, boolean>;

const OPTIONS_STORAGE = 'rlinc-reports-options';

type State = {
	expansion: ExpansionIndex;
	mode: 'any' | 'one';
};

const getState = (type: string) => {
	return getLocalStorageItem<State>(`${OPTIONS_STORAGE}-${type}`, {
		expansion: {},
		mode: 'one',
	});
};

export const removeExtension = (name: string) => {
	return name.replace('.rpt', '').replace('.Rpt', '').replace('.RPT', '');
};

export const ReportList = ({ reports, type }: Props) => {
	// const classes = useStyles();
	const theme = useTheme();
	const openReport = useOpenReport();
	const [state, setState] = React.useState<State>(getState(type));

	const data = groupBy(reports, (x) => x.group);
	const groups = orderBy(Object.keys(data)).filter((x) =>
		// x !== 'reports' &&
		Object.values((data[x] as ReportData[])?.length !== 0)
	);

	const hasSingleExpansion =
		state.mode === 'one' &&
		Object.values(state.expansion).find((x) => x === true);

	const handleChangeMode = () => {
		if (state.mode === 'any') {
			setState((s) => ({
				mode: 'one',
				expansion: {},
			}));
		} else {
			setState((s) => ({
				...s,
				mode: 'any',
			}));
		}
	};

	const handleToggleGroup = (group: string, expanded: boolean) => {
		if (state.mode === 'any') {
			setState((s) => ({
				...s,
				expansion: { ...s.expansion, [group]: expanded },
			}));
		} else {
			setState((s) => ({ ...s, expansion: { [group]: expanded } }));
		}
	};

	const handleToggleExpansionAll = () => {
		if (Object.values(state.expansion).find((x) => x === true)) {
			setState((s) => ({ ...s, expansion: {} }));
		} else {
			const value: Record<string, boolean> = {};
			groups.forEach((group) => {
				value[group] = true;
			});
			setState((s) => ({ ...s, expansion: value }));
		}
	};

	const handleCollapseAll = () => {
		setState((s) => ({ ...s, expansion: {} }));
	};

	React.useEffect(() => {
		setLocalStorageItem(`${OPTIONS_STORAGE}-${type}`, state);
	}, [state, type]);

	if (!reports) return null;

	return (
		<>
			<Toolbar
				variant="dense"
				disableGutters
				style={{
					paddingLeft: theme.spacing(1.5),
					paddingRight: theme.spacing(4),
				}}
			>
				<Button
					size="small"
					onClick={handleChangeMode}
					startIcon={
						<Icon
							path={
								state.mode === 'one'
									? mdiViewDayOutline
									: mdiViewAgendaOutline
							}
							size={0.75}
						/>
					}
				>
					<Typography variant="caption">
						{state.mode === 'any'
							? 'Expansion: Free Form'
							: 'Expansion: One at a time'}
					</Typography>
				</Button>
				<span style={{ flexGrow: 1 }} />
				{hasSingleExpansion && (
					<IconButton
						size="small"
						edge="end"
						onClick={handleCollapseAll}
					>
						<Icon path={mdiClose} size={0.85} />
					</IconButton>
				)}
				{state.mode === 'any' && (
					<IconButton
						size="small"
						edge="end"
						onClick={handleToggleExpansionAll}
					>
						<Icon path={mdiUnfoldMoreHorizontal} size={0.85} />
					</IconButton>
				)}
			</Toolbar>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					position: 'relative',
					// height: '100%',
					// overflowY: 'scroll',
					paddingTop: theme.spacing(1),
					// @ts-ignore
					'& .MuiAccordion-root.Mui-expanded': {
						marginTop: 0,
						marginBottom: 0,
					},
					'& .MuiAccordionSummary-root.Mui-expanded': {
						minHeight: '48px',
					},
				}}
			>
				{groups.map((group) => (
					<Accordion
						key={group}
						square
						expanded={state.expansion[group] === true}
						onChange={(_event, expanded) =>
							handleToggleGroup(group, expanded)
						}
						elevation={0}
					>
						<AccordionSummary
							expandIcon={<Icon path={mdiChevronDown} size={1} />}
							style={{ backgroundColor: '#f6f6f6' }}
						>
							<Typography
								variant="caption"
								style={{ fontWeight: 'bold' }}
							>
								{group === 'reports' ? 'Other Reports' : group}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<List disablePadding style={{ flexGrow: 1 }}>
								{(data[group] as ReportData[])
									.filter(
										(x) =>
											x.report !== 'Lead_Sheet_TEST.rpt'
									)
									.map((report) => (
										<ListItemButton
											key={report.path}
											onClick={() => openReport(report)}
										>
											<ListItem
												disableGutters
												style={{ width: '100%' }}
											>
												<ListItemText
													primary={removeExtension(
														report.report
													)}
													primaryTypographyProps={{
														style: {
															fontSize: theme.typography.caption.fontSize,
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															textOverflow: 'ellipsis',
														},
													}}
												/>
											</ListItem>
										</ListItemButton>
									))}
							</List>
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</>
	);
};
