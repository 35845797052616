import {
	mdiAlertCircleOutline,
	mdiBellCircleOutline,
	mdiCheckCircleOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import {
	LookupTypes,
	MarketingTaskData,
	formatAmount,
	formatDate,
	getDateFromString,
} from '../../core';
import { FlexRow } from '../common';
import { useLookupTypes } from '../lookups';

const isPastDue = (dt?: Date | null): boolean => {
	if (!dt) return false;
	const val = getDateFromString(`${dt}`);
	if (!val) return false;
	return val.getTime() <= new Date().getTime();
};

const getIcon = (task: MarketingTaskData): string => {
	if (task.CompletedDate) {
		return mdiCheckCircleOutline;
	}
	if (isPastDue(task.DateDue)) {
		return mdiAlertCircleOutline;
	}
	return mdiBellCircleOutline;
};

type Props = {
	task: MarketingTaskData;
	className?: string;
	style?: React.CSSProperties;
	mode?: 'table' | 'list';
	isEmployeeTasks: boolean;
	onCompleteTaskClick: () => void;
	forPrinting?: boolean;
};

export const MarketingTaskListItem = ({
	task,
	className,
	style = {},
	mode = 'list',
	isEmployeeTasks,
	onCompleteTaskClick,
	forPrinting = false,
}: Props) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const [types] = useLookupTypes([LookupTypes.MarketingType]);
	const itemTypeName = types?.find(
		(x: any) => x.ID === task.MarketingType
	)?.Name;
	console.log(itemTypeName);
	const isOverDue = isPastDue(task.DateDue);

	const handleClose = (event: MouseEvent | TouchEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	return (
		<Grid
			container
			direction="column"
			sx={{
				cursor: 'pointer',
				display: 'flex',
				flexDirection: 'column',
				padding: theme.spacing(1, 2, 2, 2),
				borderBottom: '1px solid rgba(0,0,0,0.2)',
				paddingBottom: forPrinting === true ? theme.spacing(4) : undefined,
				marginBottom: forPrinting === true ? theme.spacing(2) : undefined,
				'&:hover': {
					'& $metadata': {
						opacity: 1,
					},
				},
			}}
			style={style}
			onClick={() =>
				!task.CompletedDate
					? setOpen((prevOpen) => !prevOpen)
					: () => { }
			}
		>
			<Grid
				container
				direction="row"
				wrap="nowrap"
				alignItems="center"
				sx={{
					opacity: 0.7,
					'&:hover': {
						opacity: 1,
					},
				}}
				aria-controls={open ? 'split-button-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-label="task menu"
				aria-haspopup="menu"
			>
				<Grid item sx={{
					width: '30px',
					display: 'flex',
					alignItems: 'center',
				}} ref={anchorRef}>
					<Icon
						path={getIcon(task)}
						size={0.8}
						color={
							task.CompletedDate
								? theme.palette.success.main
								: isOverDue
									? theme.palette.error.main
									: theme.palette.info.main
						}
					/>
				</Grid>
				{isEmployeeTasks !== true && (
					<Grid item sx={theme => ({
						fontSize: theme.typography.caption.fontSize,
						fontWeight: 500,
						flexGrow: 1,
						whiteSpace: 'nowrap',
					})}>
						{task.EmployeeNumber}
					</Grid>
				)}
				{isEmployeeTasks === true && (task as any).FullName && (
					<Grid item sx={theme => ({
						fontSize: theme.typography.caption.fontSize,
						fontWeight: 500,
						flexGrow: 1,
						whiteSpace: 'nowrap',
					})}>
						<strong>{(task as any).FullName}</strong>
					</Grid>
				)}
				{itemTypeName && (
					<Grid item sx={theme => ({
						fontSize: theme.typography.caption.fontSize,
						fontWeight: 500,
						whiteSpace: 'nowrap',
						paddingRight: theme.spacing(2),
					})}>
						<strong>{itemTypeName}</strong>
					</Grid>
				)}
				{mode === 'table' && (
					<Grid item sx={theme => ({
						fontSize: theme.typography.body2.fontSize,
						color: '#000000',
						justifyContent: 'flex-end',
					})}>
						<span>{task.TaskDesc}</span>
					</Grid>
				)}
				<Grid
					item
					sx={theme => ({
						fontSize: theme.typography.caption.fontSize,
						paddingLeft: theme.spacing(1),
						textAlign: 'right',
						minWidth: '80px',
					})}
					style={{
						fontWeight: isOverDue ? 600 : 'unset',
						color: task.CompletedDate
							? '#555555'
							: isOverDue
								? theme.palette.error.main
								: !!task.DateDue
									? theme.palette.info.main
									: '#555555',
					}}
				>
					{formatDate(task.DateDue) || 'No Due Date'}
				</Grid>
				{isEmployeeTasks !== true && (
					<Grid
						item
						sx={theme => ({
							fontSize: theme.typography.caption.fontSize,
							paddingLeft: theme.spacing(1),
							textAlign: 'right',
							minWidth: '80px',
						})}
						style={{
							fontWeight: task.CompletedDate ? 600 : 'unset',
							color: task.CompletedDate
								? theme.palette.success.main
								: 'unset',
						}}
					>
						{formatDate(task.CompletedDate) || 'Incomplete'}
					</Grid>
				)}
			</Grid>
			{forPrinting !== true && (
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					style={{ zIndex: 9999 }}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === 'bottom'
										? 'center top'
										: 'center bottom',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu">
										<MenuItem onClick={onCompleteTaskClick}>
											<ListItemIcon
												style={{ minWidth: '32px' }}
											>
												<Icon
													path={mdiCheckCircleOutline}
													size={1}
												/>
											</ListItemIcon>
											<ListItemText primary="Mark Complete" />
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			)}
			{(mode !== 'table' || forPrinting === true) && (
				<>
					<Grid item sx={theme => ({
						fontSize: theme.typography.body2.fontSize,
						padding: theme.spacing(1, 0),
						color: '#000000',
					})}>
						<FlexRow>
							<Typography
								variant="caption"
								style={{ flexGrow: 1 }}
							>
								Amount {formatAmount(task.Amount)}
							</Typography>
							<Typography variant="caption">
								Entered By: {task.EnteredByEmployeeNumber}
							</Typography>
						</FlexRow>
					</Grid>
					<Grid item sx={theme => ({
						fontSize: theme.typography.body2.fontSize,
						padding: theme.spacing(1, 0),
						color: '#000000',
					})}>
						<span>
							<strong>{task.TaskDesc}</strong>
						</span>
					</Grid>
					<Grid
						item
						sx={theme => ({
							fontSize: theme.typography.body2.fontSize,
							padding: theme.spacing(1, 0),
							color: '#000000',
						})}
						style={{ paddingTop: 0 }}
					>
						<span>{task.TaskNotes}</span>
					</Grid>
				</>
			)}
		</Grid>
	);
};
