import { useTheme } from '@mui/material/styles';
import React from 'react';
import { TOOLBAR_HEIGHT } from '../constants';

type Props = {
	children: React.ReactNode;
};

export const Toolbar = ({ children }: Props) => {
	const theme = useTheme();
	return (
		<div
			style={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'row',
				flexShrink: 0,
				alignItems: 'center',
				height: TOOLBAR_HEIGHT,
				width: '100%',
				overflow: 'hidden',
				padding: theme.spacing(0, 1.5),
				borderBottom: `1px solid ${theme.palette.divider}`,
			}}
		>{children}</div>
	);
};
