import {
	mdiCheckboxMarkedCircle,
	mdiPencil,
	mdiPhone,
	mdiTrashCan,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import {
	LookupTypes,
	PrimaryPhoneNumberData,
	formatPhoneNumber,
} from '../../core';
import { Chip } from '../common';
import { useLookupTypes } from '../lookups';

type PhoneNumberListItemProps = {
	phoneNumber: PrimaryPhoneNumberData;
	onSetPrimary: (data: PrimaryPhoneNumberData) => void;
	onRemove: (data: PrimaryPhoneNumberData) => void;
	onEdit: (data: PrimaryPhoneNumberData) => void;
	onOpen: (data: PrimaryPhoneNumberData) => void;
	sx: any;
	canEdit?: boolean;
};

export const PhoneNumberListItem = (props: PhoneNumberListItemProps) => {
	const {
		phoneNumber,
		onSetPrimary,
		onRemove,
		onOpen,
		onEdit,
		sx = {},
		canEdit,
	} = props;
	const [phoneTypes] = useLookupTypes([LookupTypes.PhoneType]);

	const type = phoneTypes?.find((x: any) => x.ID === phoneNumber.PhoneTypeID);

	return (
		<Box
			sx={theme => ({
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				borderLeft: phoneNumber.isPrimary ? `4px solid ${theme.palette.secondary.main}` : undefined,
				...sx,
			})}
		>
			<Grid container direction="column">
				<Grid item style={{ flexGrow: 1 }}>
					<Grid
						container
						direction="row"
						alignItems="center"
						spacing={1}
						style={{ flexGrow: 1 }}
					>
						<Grid item>
							<Tooltip title="Call Phone Number">
								<IconButton
									aria-label="Call Phone Number"
									onClick={() => onOpen(phoneNumber)}
								>
									<Icon path={mdiPhone} size={1} />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item style={{ flexGrow: 1 }}>
							<strong>{`${formatPhoneNumber(
								phoneNumber.PhoneNumber
							)}`}</strong>{' '}
							{`${phoneNumber.PhoneExt
									? `Ext. ${phoneNumber.PhoneExt}`
									: ''
								}`}
						</Grid>
						<Grid item>{phoneNumber.PhoneDesc}</Grid>
						<Grid item>
							<Chip label={type?.Name || ''} selected={false} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Toolbar variant="dense" disableGutters>
						<span style={{ flexGrow: 1 }} />
						<Tooltip title="Set Primary">
							<IconButton
								aria-label="Set Primary"
								onClick={
									canEdit
										? () => onSetPrimary(phoneNumber)
										: undefined
								}
								sx={theme => ({
									color: phoneNumber.isPrimary ? theme.palette.secondary.main : undefined,
								})}
							>
								<Icon path={mdiCheckboxMarkedCircle} size={1} />
							</IconButton>
						</Tooltip>
						{canEdit && (
							<>
								<Tooltip title="Remove Phone Number">
									<IconButton
										aria-label="Remove Phone Number"
										onClick={() => onRemove(phoneNumber)}
									>
										<Icon path={mdiTrashCan} size={1} />
									</IconButton>
								</Tooltip>
								<Tooltip title="Edit Phone Number">
									<IconButton
										aria-label="Edit Phone Number"
										onClick={() => onEdit(phoneNumber)}
									>
										<Icon path={mdiPencil} size={1} />
									</IconButton>
								</Tooltip>
							</>
						)}
					</Toolbar>
				</Grid>
			</Grid>
		</Box>
	);
};
