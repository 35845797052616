import {
	AddressSearchOptions,
	StreetAddressData,
	stringifySearchOptions,
} from '../core';

import { ApiClientInterface } from './client';

export default class AddressApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
		this.count = this.count.bind(this);
		this.update = this.update.bind(this);
		this.get = this.get.bind(this);
	}

	public async get(id: number): Promise<StreetAddressData> {
		const response = await this.client.get<StreetAddressData>(
			`/address/${id}`
		);
		return response;
	}

	public async query(
		options: AddressSearchOptions
	): Promise<StreetAddressData[]> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<StreetAddressData[]>(
			`/address?${search}`
		);
		return response;
	}

	public async count(options: AddressSearchOptions): Promise<number> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<number>(
			`/address?${search}&count=true`
		);
		return response;
	}

	public async update(
		address: StreetAddressData
	): Promise<StreetAddressData> {
		const response = await this.client.put<
			StreetAddressData,
			StreetAddressData
		>(`/address/${address.AddressID}`, address);
		return response;
	}
}
