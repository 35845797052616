export const NavigationItems = {
	HOME: 'Home',
	RECENT: 'Recent',
	ADD: 'Add',
	TASKS: 'My Tasks',
	JOBS: 'Jobs',
	CONTACTS: 'Contacts',
	COMPANIES: 'Companies',
	REPORTS: 'Reports',
	ADMIN: 'Admin',
	SETTINGS: 'Profile',
};
