import React from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
	ContactData,
	EmailAddressData,
	LookupTypes,
	PhoneNumberData,
	PrimaryEmailAddressData,
	PrimaryPhoneNumberData,
	PrimaryStreetAddressData,
	StreetAddressData,
	isContactInformationValid,
	isEmailInformationValid,
	isPhoneInformationValid,
	isStreetAddressValid,
} from '../../core';
import { useLookupTypes } from '../lookups';
import useClient from '../useClient';

export interface AddContactData {
	contact: Partial<ContactData>;
	jobsite: Partial<PrimaryStreetAddressData>;
	billing: Partial<PrimaryStreetAddressData>;
	email: Partial<PrimaryEmailAddressData>[];
	phone: Partial<PrimaryPhoneNumberData>[];
}

export interface AddContactController {
	data: AddContactData;
	contact: ContactData | undefined;
	isContactValid: boolean;
	working: boolean;
	error?: any;
	onContactChange: (contact: Partial<ContactData>) => void;
	onJobsiteChange: (jobsite: Partial<StreetAddressData>) => void;
	onBillingChange: (billing: Partial<StreetAddressData>) => void;
	onEmailChange: (index: number, email: Partial<EmailAddressData>) => void;
	onPhoneChange: (index: number, phone: Partial<PhoneNumberData>) => void;
	resetError: () => void;
	resetData: () => void;
	createContact: () => void;
	onAddAnotherEmail: () => void;
	onAddAnotherPhone: () => void;
	onRemoveEmail: (index: number) => void;
	onRemovePhone: (index: number) => void;
}

type Props = {
	contactDefaults?: Partial<ContactData>;
};

const validate = (data: AddContactData): boolean => {
	if (data.billing.AddressLine1 && !isStreetAddressValid(data.billing)) return false;
	if (data.jobsite.AddressLine1 && !isStreetAddressValid(data.jobsite)) return false;
	if (!isContactInformationValid(data.contact)) return false;
	if (data.phone.filter(x => !isPhoneInformationValid(x)).length > 1) return false;
	if (data.email.filter(x => !!x.EmailAddress && !isEmailInformationValid(x)).length > 1) return false;
	// if (!isPhoneInformationValid(data.phone)) return false;
	// if (!!data.email?.EmailAddress && !isEmailInformationValid(data.email))
	// 	return false;
	return true;
};

export const useAddContact = ({
	contactDefaults,
}: Props): AddContactController => {
	const { api } = useAppContext();

	const [addressTypes] = useLookupTypes([LookupTypes.AddressType]);
	const billingAddressType = addressTypes?.find(
		(x: any) => x.Name === 'Billing'
	);
	const jobsiteAddressType = addressTypes?.find(
		(x: any) => x.Name === 'Jobsite'
	);

	const [addContactData, setAddContactData] = React.useState<AddContactData>({
		contact: { isActive: true, ...contactDefaults },
		jobsite: { AddressTypeID: jobsiteAddressType?.ID, State: 'OH', isPrimary: true, },
		billing: { AddressTypeID: billingAddressType?.ID, State: 'OH', isPrimary: true, },
		email: [{ isPrimary: true }],
		phone: [{ isPrimary: true }],
	});

	const { data, execute, working, error, resetError } =
		useClient<ContactData>({
			error: 'An error occurred creating the contact.  Please try again.',
		});

	const handleCreateContact = () => {
		execute(() => api.contact.add(addContactData));
	};

	const onContactChange = (contact: Partial<ContactData>) => {
		setAddContactData((s) => ({ ...s, contact }));
	};
	const onJobsiteChange = (jobsite: Partial<StreetAddressData>) => {
		setAddContactData((s) => ({ ...s, jobsite }));
	};
	const onBillingChange = (billing: Partial<StreetAddressData>) => {
		setAddContactData((s) => ({ ...s, billing }));
	};
	const onEmailChange = (index: number, email: Partial<PrimaryEmailAddressData>) => {
		setAddContactData((s) => {
			const ns = { ...s };
			const emails = [...ns.email];
			let canChangePrimary = true;
			if (email.isPrimary) {
				emails.forEach(x => x.isPrimary = false);
			} else {
				canChangePrimary = !!emails.find((item, pos) => pos !== index && item.isPrimary === true);
			}
			emails[index] = {
				...email,
				isPrimary: emails.length <= 1 ? true : canChangePrimary ? email.isPrimary : true,
			};
			ns.email = emails;
			return ns;
		});
	};
	const onPhoneChange = (index: number, phone: Partial<PrimaryPhoneNumberData>) => {
		setAddContactData((s) => {
			const ns = { ...s };
			const phones = [...ns.phone];
			let canChangePrimary = true;
			if (phone.isPrimary) {
				phones.forEach(x => x.isPrimary = false);
			} else {
				canChangePrimary = !!phones.find((item, pos) => pos !== index && item.isPrimary === true);
			}
			phones[index] = {
				...phone,
				isPrimary: phones.length <= 1 ? true : canChangePrimary ? phone.isPrimary : true,
			};
			ns.phone = phones;
			return ns;
		});
	};

	const onAddAnotherPhone = () => {
		setAddContactData((s) => {
			const ns = { ...s };
			const phones = [...ns.phone];
			phones.push({});
			ns.phone = phones;
			return ns;
		});
	}

	const onAddAnotherEmail = () => {
		setAddContactData((s) => {
			const ns = { ...s };
			const emails = [...ns.email];
			emails.push({});
			ns.email = emails;
			return ns;
		});
	}

	const onRemovePhone = (index: number) => {
		setAddContactData((s) => {
			const ns = { ...s };
			const phones = [...ns.phone];
			const removed = phones.splice(index, 1);
			if (removed[0]?.isPrimary && phones[0]) {
				phones[0].isPrimary = true;
			}
			ns.phone = phones;
			return ns;
		});
	}

	const onRemoveEmail = (index: number) => {
		setAddContactData((s) => {
			const ns = { ...s };
			const emails = [...ns.email];
			const removed = emails.splice(index, 1)
			if (removed[0]?.isPrimary && emails[0]) {
				emails[0].isPrimary = true;
			}
			ns.email = emails;
			return ns;
		});
	}

	return {
		data: addContactData,
		contact: data,
		working,
		error,
		resetError,
		onContactChange,
		onJobsiteChange,
		onBillingChange,
		onEmailChange,
		onPhoneChange,
		onAddAnotherPhone,
		onAddAnotherEmail,
		onRemovePhone,
		onRemoveEmail,
		isContactValid: validate(addContactData),
		createContact: () => handleCreateContact(),
		resetData: () =>
			setAddContactData({
				contact: { isActive: true, ...contactDefaults },
				jobsite: { AddressTypeID: jobsiteAddressType?.ID, State: 'OH', isPrimary: true, },
				billing: { AddressTypeID: billingAddressType?.ID, State: 'OH', isPrimary: true, },
				email: [{ isPrimary: true }],
				phone: [{ isPrimary: true }],
			}),
	};
};
