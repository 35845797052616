import { mdiDomain, mdiHomeCityOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, ListItemButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';

type AddSidebarProps = {
	onClose: () => void;
	show: boolean;
};

export function AddSidebar({ onClose, show }: AddSidebarProps) {

	const navigate = useNavigate();
	const { hasPermission } = useAppContext();

	const items = [
		{
			key: 'New Lead',
			icon: mdiHomeCityOutline,
			url: '/lead',
			canView: hasPermission(['NewLead']),
		},
		{
			key: 'New Company',
			icon: mdiDomain,
			url: '/add/company',
			canView: hasPermission(['EditCompanies']),
		},
		// {
		// 	key: 'New Contact',
		// 	icon: mdiAccountBoxOutline,
		// 	url: '/add/contact',
		// 	canView: hasPermission(['EditContacts']),
		// },
	];

	if (!show) return null;

	return (
		<Box sx={theme => ({
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			height: '100%',
			overflow: 'hidden',
			overflowY: 'auto',
			position: 'relative',
			paddingLeft: theme.spacing(1)
		})}>
			<List>
				{items
					.filter((x) => x.canView)
					.map((item) => (
						<ListItemButton
							key={item.key}
							onClick={() => {
								onClose();
								navigate(item.url);
							}}
							sx={{ paddingLeft: '8px'}}
						>
							<ListItem>
								<ListItemIcon>
									<Icon
										path={item.icon}
										size={1}
										color="#555555"
									/>
								</ListItemIcon>
								<ListItemText primary={item.key} />
							</ListItem>
						</ListItemButton>
					))}
			</List>
		</Box>
	);
}
