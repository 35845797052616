import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { StreetAddressData } from '../../core';
import { Container, Dialog, Loading } from '../common';
import { AddressForm } from './AddressForm';
import { AddressList } from './AddressList';

type SelectAddressDialogProps = {
	onSelectAddress: (address: Partial<StreetAddressData>) => void;
	onClose: () => void;
	defaultType?: string;
	parentId: number;
	parentType: 'Company' | 'Contact';
};

export const SelectAddressDialog = (props: SelectAddressDialogProps) => {
	const { api } = useAppContext();
	const [busy, setBusy] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	const [address, setAddress] = useState<Partial<StreetAddressData>>({});

	const { onSelectAddress, onClose, defaultType, parentType, parentId } =
		props;

	useEffect(() => {
		setAddress({});
	}, [tabIndex]);

	const isAddressValid =
		!!address.AddressTypeID &&
		!!address.AddressLine1 &&
		!!address.City &&
		!!address.State &&
		!!address.ZIP;

	const handleAddAddress = async () => {
		setBusy(true);
		try {
			const added =
				parentType === 'Company'
					? await api.company.addAddress(parentId, address)
					: await api.contact.addAddress(parentId, address);

			onSelectAddress(added);
			onClose();
		} catch (error) {
			console.error(error);
			setBusy(false);
		}
	};

	const handleSelectAddress = () => {
		if (address.AddressID) {
			onSelectAddress(address);
		} else {
			console.error('The selected address must be an existing address');
		}
	};

	const handleDialogAction = async () => {
		try {
			if (tabIndex === 0) {
				handleSelectAddress();
			} else if (tabIndex === 1) {
				await handleAddAddress();
			}
			onClose();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Dialog
			isOpen={true}
			title={`${tabIndex === 0 ? 'Select ' : 'Add '
				} ${parentType} Address`}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
			}}
			positiveAction={{
				text: tabIndex === 0 ? 'Select Address' : 'Add Address',
				isDisabled: () => busy || !isAddressValid,
				onClick: handleDialogAction,
			}}
			onClose={onClose}
		>
			<Toolbar variant="dense">
				<Tabs
					value={tabIndex}
					indicatorColor="secondary"
					textColor="primary"
					onChange={(_event, index) => setTabIndex(index)}
				>
					<Tab value={0} label="Select Existing Address" />
					<Tab value={1} label="Add New Address" />
				</Tabs>
			</Toolbar>
			<Container
				component="SelectAddressContent"
				scrollable
				sx={theme => ({
					backgroundColor: 'rgba(0,0,0,0.1)',
					padding: tabIndex === 0 ? 0 : theme.spacing(2),
					'& .MuiOutlinedInput-root': {
						backgroundColor: '#fff',
					},
					minHeight: '400px'
				})}
			>
				{busy && <Loading />}
				{!busy && tabIndex === 0 && (
					<AddressList
						parentId={parentId}
						parentType={parentType}
						defaultType={defaultType}
						onSelected={(data) => setAddress(data)}
					/>
				)}
				{!busy && tabIndex === 1 && (
					<AddressForm
						address={address}
						onChanged={(data) => setAddress(data)}
					/>
				)}
			</Container>
		</Dialog>
	);
};
