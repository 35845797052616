import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

type AddNoteProps = {
	onAddNote: (text: string) => void;
	onClose?: () => void;
};

export const AddNote = (props: AddNoteProps) => {
	const { onAddNote, onClose } = props;
	const [noteText, setNoteText] = useState('');

	const canSaveNote = !!noteText;

	const handleAddNote = () => {
		onAddNote(noteText);
		setNoteText('');
	};

	return (
		<Box sx={theme => ({
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			padding: theme.spacing(1),
			marginLeft: theme.spacing(-1),
		})}>
			<TextField
				id="note"
				label="New Note"
				variant="outlined"
				color="primary"
				margin="none"
				size="small"
				value={noteText}
				multiline
				minRows={5}
				onChange={(e) => setNoteText(e.target.value)}
				sx={theme => ({ margin: theme.spacing(1), position: 'relative' })}
				InputLabelProps={{ shrink: true }}
				InputProps={{
					style: {
						alignItems: 'flex-end',
					},
				}}
			/>
			<Grid container alignItems="center" justifyContent="flex-end">
				<Grid item>
					<Button
						size="small"
						aria-label="Close"
						onClick={onClose ? onClose : () => { }}
					>
						Cancel
					</Button>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}></Grid>
				<Grid item>
					<Button
						size="small"
						aria-label="Add Note"
						color="secondary"
						disabled={!canSaveNote}
						onClick={handleAddNote}
					>
						Add Note
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};
