import { Box, SxProps, Theme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import React, { useCallback, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { DialogToolbar } from './DialogToolbar';
import { VerticalResize } from './VerticalResize';

export type SidebarProps = {
	tools: React.ReactNode;
	children: React.ReactNode;
	title?: string;
	open?: boolean;
	anchor?: 'right' | 'left';
	variant?: 'persistent' | 'temporary' | 'permanent';
	resizeEnabled?: boolean;
	defaultWidth?: number;
	minWidth?: number;
	maxWidth?: number;
	onResized?: () => void;
	onClose?: () => void;
	onGoBack?: () => void;
	sx?: SxProps<Theme>;
};

const getInitialWidth = (def: number, min: number, max: number) => {
	if (def < min) return min;
	if (def > max) return max;
	return def;
};

export const Sidebar = (props: SidebarProps) => {
	const { isMobileLayout } = useAppContext();
	const {
		tools,
		children,
		title,
		anchor = 'left',
		variant = 'temporary',
		resizeEnabled = true,
		defaultWidth = 400,
		minWidth = 200,
		maxWidth = 800,
		open,
		onClose,
		onGoBack,
	} = props;
	const [sidebarWidth, setSidebarWidth] = useState(
		getInitialWidth(defaultWidth, minWidth, maxWidth)
	);

	const calculateWidth = useCallback(
		(x: number) => {
			const w =
				anchor === 'left'
					? sidebarWidth + x < minWidth
						? minWidth
						: sidebarWidth + x
					: sidebarWidth - x < minWidth
						? minWidth
						: sidebarWidth - x;
			const nw = w < minWidth ? minWidth : w > maxWidth ? maxWidth : w;
			console.log('nw', nw);
			return nw;
		},
		[anchor, minWidth, maxWidth, sidebarWidth]
	);

	return (
		<Drawer
			variant={variant}
			anchor={anchor}
			ModalProps={{
				keepMounted: true,
			}}
			sx={theme => ({
				width: !!open ? sidebarWidth : 0,
				[theme.breakpoints.down('md')]: {
					width: !!open ? '100%' : undefined,
					minWidth: !!open ? '100%' : undefined,
					maxWidth: !!open ? '100%' : undefined,
				},
				maxWidth: !!open ? undefined : 0,
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				height: '100%',
				overflow: 'hidden',
				marginTop: 0,
				'& .MuiDrawer-paper': {
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					flexShrink: 0,
					height: `calc(100% - 48px)`,
					overflow: 'hidden',
					width: !!open ? sidebarWidth : 0,
					[theme.breakpoints.down('md')]: {
						width: !!open ? '100%' : undefined,
						minWidth: !!open ? '100%' : undefined,
						maxWidth: !!open ? '100%' : undefined,
					},
					maxWidth: !!open ? undefined : 0,
				},
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: !!open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
				}),
			})}
			open={!!open}
		>
			<DialogToolbar title={title} onClose={onClose} onGoBack={onGoBack}>
				{tools}
			</DialogToolbar>
			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				flexGrow: 1,
				flexShrink: 1,
				height: '100%',
				overflow: 'hidden',
				backgroundColor: '#ffffff',
			}}>
				{!isMobileLayout &&
					resizeEnabled &&
					open &&
					anchor === 'right' && (
						<Hidden mdDown>
							<VerticalResize
								enabled={true}
								onChange={(x) =>
									setSidebarWidth(calculateWidth(x))
								}
							/>
						</Hidden>
					)}
				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					width: '100%',
					height: '100%',
					overflow: 'hidden',
					position: 'relative',
					backgroundColor: 'transparent',
				}}>
					{children}
				</Box>
				{!isMobileLayout && resizeEnabled && open && anchor === 'left' && (
					<Hidden smDown>
						<VerticalResize
							enabled={true}
							onChange={(x) => setSidebarWidth(calculateWidth(x))}
						/>
					</Hidden>
				)}
			</Box>
		</Drawer>
	);
};
