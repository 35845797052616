import {
	mdiAccountCowboyHat,
	mdiAccountMultiple,
	mdiCalendarMultipleCheck,
	mdiCurrencyUsd,
	mdiHomeCityOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useAppContext } from '../../../contexts/AppContext';

export type NavSectionBarProps = {
	jobId: number;
	onClose: () => void;
	transparent?: boolean;
};

export const NavSectionBar = ({
	jobId,
	onClose,
	transparent = false,
}: NavSectionBarProps) => {
	const theme = useTheme();
	const { isMobileLayout } = useAppContext();

	const navigate = (key: string) => {
		const element = document.getElementById(key);
		if (element) {
			if (isMobileLayout) {
				onClose();
			}
			setTimeout(() => {
				element.scrollIntoView(true);
				if (window) {
					window.scrollTo({
						top: 0,
						behavior: 'smooth',
					});
				}
			});
		}
	};

	return (
		<Toolbar
			sx={{
				display: 'flex',
				flexDirection: 'row',
				flexGrow: 1,
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: transparent === true ? 'transparent' : theme.palette.grey[300],
				color: transparent === true ? 'initial' : theme.palette.grey[900],
				padding: theme.spacing(1),
			}}
			disableGutters
		>
			<Tooltip title="Job Information">
				<IconButton
					size="medium"
					aria-label="Job Information"
					onClick={() => navigate('lossdescription')}
				>
					<Icon path={mdiHomeCityOutline} size={1} />
				</IconButton>
			</Tooltip>
			<Tooltip title="Job Roles">
				<IconButton
					size="medium"
					aria-label="Job Roles"
					onClick={() => navigate('jobroles')}
				>
					<Icon path={mdiAccountCowboyHat} size={1} />
				</IconButton>
			</Tooltip>
			<Tooltip title="Referral / Stakeholders">
				<IconButton
					size="medium"
					aria-label="Referral / Stakeholders"
					onClick={() => navigate('referralstakeholders')}
				>
					<Icon path={mdiAccountMultiple} size={1} />
				</IconButton>
			</Tooltip>
			<Tooltip title="Job Status">
				<IconButton
					size="medium"
					aria-label="Job Status"
					onClick={() => navigate('jobstatus')}
				>
					<Icon path={mdiCalendarMultipleCheck} size={1} />
				</IconButton>
			</Tooltip>
			<Tooltip title="Accounting Information">
				<IconButton
					size="medium"
					aria-label="Accounting Information"
					onClick={() => navigate('accountinginformation')}
				>
					<Icon path={mdiCurrencyUsd} size={1} />
				</IconButton>
			</Tooltip>
			{/* <Tooltip title="Sales Data">
				<IconButton
					size="medium"
					aria-label="Sales Data"
					onClick={() => navigate('salesdata')}
				>
					<Icon path={mdiTable} size={1} />
				</IconButton>
			</Tooltip> */}
		</Toolbar>
	);
};
