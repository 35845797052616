import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Reducers
import jobTasks from './jobTasks';
import marketingTasks from './marketingTasks';
import reports from './reports';
import recent from './recent';
import lookup from './lookup';
import employees from './employees';
import taskTemplates from './taskTemplates';

// Store
export const store = configureStore({
	reducer: {
		jobTasks,
		marketingTasks,
		reports,
		recent,
		lookup,
		employees,
		taskTemplates,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type
type AppDispatch = typeof store.dispatch;

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
