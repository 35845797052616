import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

// Monorepo
import { LookupDetail } from '../core';

// Lib
import { RootState } from '.';

interface LookupState {
	index: Record<string, LookupDetail[]>;
	status: 'idle' | 'loading' | 'succeeded' | 'failed';
	error: string | null;
}

const initialState: LookupState = {
	index: {},
	status: 'idle',
	error: null,
};

export const lookup = createSlice({
	name: 'lookup',
	initialState,
	reducers: {
		loadLookupData: (state, action: PayloadAction<LookupDetail[]>) => {
			const data = action.payload;
			const types = uniq(data.map((x) => x.LookupType));
			types.forEach((type) => {
				const items = data.filter((x) => x.LookupType === type);
				state.index[type] = items;
			});
		},
	},
});

export const { loadLookupData } = lookup.actions;

export const selectLookupTypesIndex = (state: RootState) => state.lookup.index;

export const selectLookupTypes = createSelector(
	[selectLookupTypesIndex, (_state: RootState, types: string[]) => types],
	(index, types) => types.map((type: any) => (index[type] ? index[type] : []))
);

export default lookup.reducer;
