import { User } from '../core';

import { ApiClientInterface } from './client';

export default class UserApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.get = this.get.bind(this);
	}

	public async get(): Promise<User> {
		return this.client.get<User>(`/user`);
	}
}
