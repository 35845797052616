import { AccountingDataInvoice } from '../core';

import { ApiClientInterface } from './client';

export default class AccountingApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.getIssuedInvoices = this.getIssuedInvoices.bind(this);
	}

	public async getIssuedInvoices(
		accountingNumber: string
	): Promise<AccountingDataInvoice[]> {
		const response = await this.client.get<AccountingDataInvoice[]>(
			`/accounting/invoices/${accountingNumber}`
		);
		return response;
	}
}
