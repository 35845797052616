import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { CompanyData, LookupTypes } from '../../core';
import { AutoCompleteLookup, Card, Chip } from '../common';

const flags = [
	{ key: 'isActive', label: 'Active' },
	{ key: 'isManagedRepairProgram', label: 'Managed Repair Program' },
];

type CompanyDetailProps = {
	company?: Partial<CompanyData>;
	onChange: (data: Partial<CompanyData>) => void;
	canEdit?: boolean;
};

export const CompanyDetail = (props: CompanyDetailProps) => {
	const { company, onChange, canEdit } = props;

	if (!company) return null;

	const handleOnChange = (key: string, value: any) => {
		onChange({ ...company, [key]: value });
	};

	return (
		<Card label="Company Information" sx={theme => ({ margin: theme.spacing(1, 0, 0, 0) })}>
			<Grid container direction="column">
				<Grid container direction="row" wrap="wrap" spacing={1}>
					<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
						<TextField
							label="Company Name"
							aria-label="Company Name"
							title="Company Name"
							value={company.CompanyName || ''}
							disabled={!canEdit}
							onChange={(e) =>
								handleOnChange(
									'CompanyName',
									e.target.value || null
								)
							}
							variant="outlined"
							margin="dense"
							sx={{ flexGrow: 1, display: 'flex' }}
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
						<AutoCompleteLookup
							label="Company Type"
							placeholder="Company Type"
							lookupType={LookupTypes.CompanyType}
							value={company.CompanyTypeID || null}
							disabled={!canEdit}
							onChange={(value) =>
								handleOnChange('CompanyTypeID', value || null)
							}
							required
						/>
					</Grid>
				</Grid>

				<Grid container direction="row" wrap="wrap" spacing={1}>
					<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
						<TextField
							label="Office Name"
							aria-label="Office Name"
							title="Office Name"
							value={company.OfficeName || ''}
							disabled={!canEdit}
							onChange={(e) =>
								handleOnChange(
									'OfficeName',
									e.target.value || null
								)
							}
							variant="outlined"
							margin="dense"
							sx={{ flexGrow: 1, display: 'flex' }}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
						<TextField
							label="Website"
							aria-label="Website"
							title="Website"
							value={company.Website || ''}
							disabled={!canEdit}
							onChange={(e) =>
								handleOnChange(
									'Website',
									e.target.value || null
								)
							}
							variant="outlined"
							margin="dense"
							sx={{ flexGrow: 1, display: 'flex' }}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
				</Grid>

				<Grid
					container
					direction="row"
					wrap="wrap"
					justifyContent="center"
					alignItems="center"
				>
					{flags.map((flag) => (
						<Grid item key={flag.key}>
							<Chip
								label={flag.label}
								selected={(company as any)[flag.key] === true}
								onClick={
									canEdit
										? () =>
											handleOnChange(
												flag.key,
												!(company as any)[flag.key]
											)
										: undefined
								}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Card>
	);
};
