/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import { useDebounce } from 'react-use';

export type QueryContext = {
	limit?: number | null;
	offset?: number | null;
	continuation?: string | null;
};

export interface QueryResult<T> extends QueryContext {
	status: 'idle' | 'busy' | 'results' | 'noresults' | 'error';
	error?: Error | null;
	results: T[];
	count: number;
	limit: number;
	offset: number;
	next: () => void;
	previous: () => void;
}

export interface QueryProps<T> {
	queryCallback: (limit: number, offset: number) => Promise<T[]>;
	queryCountCallback?: () => Promise<number>;
	shouldExecute: (args: any[]) => boolean;
	arguments: any[];
	delay: number;
	limit: number;
	offset?: number | null;
}

export default function useQuery<T>(props: QueryProps<T>): QueryResult<T> {
	const [limit] = useState(props.limit || 50);
	const [offset, setOffset] = useState(props.offset || 0);
	const [state, setState] = useState<QueryResult<T>>({
		status: 'idle',
		results: [],
		count: -1,
		limit,
		offset,
		next: () => {},
		previous: () => {},
	});
	// console.log('useQuery:render', state, limit, offset);

	const query = useCallback(props.queryCallback, props.arguments);
	const queryCount = useCallback(
		props.queryCountCallback ? props.queryCountCallback : () => -1,
		props.arguments
	);

	const next = useCallback(() => {
		// console.log('useQuery:next', state, limit, offset);
		setOffset(offset + props.limit);
	}, [...props.arguments, props.limit, offset]);

	const previous = useCallback(() => {
		// console.log('useQuery:previous', state, limit, offset);
		setOffset(offset - props.limit);
	}, [...props.arguments, props.limit, offset]);

	const load = useCallback(() => {
		if (props.shouldExecute(props.arguments)) {
			// console.log('useQuery:load', state, props.limit, offset);
			setState({ ...state, status: 'busy' });
			Promise.all([queryCount(), query(props.limit, offset)])
				.then((data) => {
					const [count, results] = data;
					setState({
						...state,
						status: results.length > 0 ? 'results' : 'noresults',
						results,
						count,
					});
				})
				.catch((error) => {
					setState({
						...state,
						status: 'error',
						error,
						results: [],
						count: -1,
					});
				});
		}
	}, [...props.arguments, props.limit, offset]);

	useDebounce(() => load(), props.delay, [
		...props.arguments,
		props.limit,
		offset,
	]);

	useEffect(() => {
		setOffset(props.offset || 0);
	}, [...props.arguments]);

	return { ...state, limit: props.limit, offset, next, previous };
}
