import * as React from 'react';
import { useDebounce } from 'react-use';
import { useSnackbar } from 'notistack';

import { User } from '../../core';

import { Dialog } from '../common/Dialog';
import { UserForm } from './UserForm';
import { validateUser } from './validateUser';
import { useAppContext } from '../../contexts/AppContext';
import { useAppDispatch } from '../../store';
import { updateEmployee } from '../employees';

type Props = {
	user: User;
	onClose: () => void;
};

export const UpdateUserDialog = ({ user, onClose }: Props) => {
	const { api } = useAppContext();
	const [userData, setUserData] = React.useState<Partial<User>>(user);
	const [busy, setBusy] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const snackbar = useSnackbar();
	const dispatch = useAppDispatch();

	const handleOnChange = (data: Partial<User>) => {
		setUserData(data);
	};

	const handleDialogAction = async () => {
		if (!!userData) {
			setBusy(true);
			try {
				const updated = await api.employee.update(userData);
				console.log(updated);
				dispatch(updateEmployee(updated));
				snackbar.enqueueSnackbar(
					`Updated Employee ${user.EmployeeName}`,
					{
						variant: 'success',
					}
				);
				onClose();
			} catch (error) {
				setBusy(false);
				setErrorMessage(`${error}`);
			}
		}
	};

	React.useEffect(() => {
		if (user && !userData.EmployeeNumber) {
			console.log('Setting User Data');
			setUserData(user);
		}
	}, [user, userData.EmployeeNumber]);

	const validate = React.useCallback(async (data: Partial<User>) => {
		setBusy(true);
		try {
			const message = await validateUser(data);
			setErrorMessage(message || '');
		} catch (error) {
			setErrorMessage(`${error}`);
		} finally {
			setBusy(false);
		}
	}, []);

	useDebounce(() => validate(userData), 700, [userData]);

	return (
		<Dialog
			isOpen={true}
			title="Update User"
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
			}}
			positiveAction={{
				text: 'Save Changes',
				isDisabled: () => busy || !!errorMessage,
				onClick: handleDialogAction,
			}}
			onClose={onClose}
		>
			<div style={{ padding: '8px' }}>
				{userData && (
					<UserForm
						mode="update"
						user={userData}
						onChanged={handleOnChange}
						employeeNumberError={
							errorMessage ===
							'The employee number must be unique'
						}
					/>
				)}
				{errorMessage && (
					<div
						style={{
							padding: '8px',
							textAlign: 'right',
							color: '#b71c1c',
						}}
					>
						<strong>{errorMessage}</strong>
					</div>
				)}
			</div>
		</Dialog>
	);
};
