import {
	mdiAlertOutline,
	mdiCloseCircleOutline,
	mdiEmailArrowRightOutline,
	mdiPlusCircleOutline,
	mdiSendCircleOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { isArray } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
	JobData,
	JobDetail,
	JobNote,
	LookupTypes,
	formatDate,
	formatDateTime,
} from '../../core';
import { ApplicationEvent } from '../ApplicationEvent';
import { BottomSpace, Dialog } from '../common';
import { AutoCompleteLookupMultiple } from '../common/AutoCompleteLookupMultiple';
import { useLookupTypes } from '../lookups';
import { useWindowOpen } from '../useWindowOpen';
import { AddNote } from './AddNote';
import { NoteListItem } from './NoteListItem';

type EditDialog = {
	id: number;
	type: 'Important Note' | 'Note';
	value: string;
};

type Props = {
	job?: JobDetail;
	onChange: (data: Partial<JobData>) => void;
	onCountChanged: (count: number) => void;
	show: boolean;
};

export const JobNoteList = ({ job, onChange, onCountChanged, show }: Props) => {
	const { api, user } = useAppContext();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const [noteTypes] = useLookupTypes([LookupTypes.NoteType]);
	const [sendNotes, setSendNotes] = useState(false);
	const [showAddNote, setShowAddNote] = useState(false);
	const [editDialog, setEditDialog] = useState<EditDialog>();
	const [selectedNotes, setSelectedNotes] = useState<number[]>([]);
	const [typeIds, setTypeIds] = useState<number[]>([]);
	const [notes, setNotes] = useState<JobNote[]>([]);
	const jobId = job?.JobID;
	const open = useWindowOpen();

	const loadJobNotes = React.useCallback(() => {
		if (jobId) {
			api.job
				.queryNotes(jobId)
				.then((results) => {
					setNotes(results);
					onCountChanged(results.length);
				})
				.catch((error) => console.error(error));
		}
	}, [api.job, jobId, onCountChanged]);

	useEffect(() => {
		loadJobNotes();
	}, [jobId, loadJobNotes]);

	useEffect(() => {
		const unsubscribe = ApplicationEvent.OnReloadJob(loadJobNotes);
		return () => unsubscribe();
	}, [loadJobNotes]);

	if (!show || !job) return null;

	const filteredNotes =
		typeIds.length > 0
			? notes.filter((x) => typeIds.includes(x.NoteTypeID))
			: notes;

	const getNoteType = (id: number): string | null => {
		const type = noteTypes?.find((x: any) => x.ID === id);
		return type ? type.Name : null;
	};

	const handleSendNotes = () => {
		if (selectedNotes.length > 0) {
			const email = !!job.CustomerName
				? `${job.CustomerName} <${job.CustomerEmailAddress}>`
				: job.CustomerEmailAddress;

			const subject = `Job (${job.JobNumber}) (${job.JobName
				}) update for ${formatDate(new Date())}`;
			const selected = notes.filter((x) =>
				selectedNotes.includes(x.JobNoteID)
			);
			const body: string[] = [];
			selected.forEach((x) => {
				body.push(`${formatDateTime(x.NoteDate)}\n${x.NoteText}`);
			});
			open(
				`mailto:${email}?subject=${subject}&body=${encodeURIComponent(
					body.join('\n\n')
				)}`
			);
		}
	};

	const handleToggleNoteSelection = (id: number) => {
		setSelectedNotes((s) => {
			const result = [...s];
			const index = result.findIndex((x) => x === id);
			if (index !== -1) {
				result.splice(index, 1);
			} else {
				result.push(id);
			}
			return result;
		});
	};

	const handleAddNote = (text: string, typeId: number) => {
		if (user && text && typeId) {
			const note: JobNote = {
				JobID: job.JobID,
				JobNoteID: 0,
				EmployeeNumber: user.EmployeeNumber,
				NoteDate: new Date(),
				NoteText: text,
				NoteTypeID: typeId,
				IncludeInEmail: false,
			};
			api.job
				.addNote(note)
				.then((result) => {
					if (result) {
						enqueueSnackbar('Note Added', { variant: 'success' });
						setNotes((s) => {
							const ns = [...s];
							ns.splice(0, 0, result);
							return ns;
						});
						setShowAddNote(false);
					}
				})
				.catch((error) => {
					enqueueSnackbar(error.message, { variant: 'error' });
				});
		}
	};

	const handleNoteChanged = (id: number, text: string) => {
		const noteIndex = notes.findIndex((x) => x.JobNoteID === id);
		if (noteIndex !== -1) {
			const note = { ...notes[noteIndex], NoteText: text };
			api.job
				.updateNote(note as JobNote)
				.then((result) => {
					console.log('Update Job Note', result);
					setNotes((s) => {
						const ns = [...s];
						ns.splice(noteIndex, 1, note as JobNote);
						return ns;
					});
				})
				.catch(console.error);
		}
	};

	const availableNoteTypes = notes.map((x) => x.NoteTypeID);

	return (
		<>
			{!showAddNote && (
				<Toolbar disableGutters sx={{ padding: theme.spacing(1) }}>
					{!sendNotes && (
						<AutoCompleteLookupMultiple
							label="Filter by Type"
							placeholder="Filter by Type"
							emptyOption={{ value: -1, label: 'None' }}
							lookupType={LookupTypes.NoteType}
							value={typeIds || null}
							onChange={(val) => {
								if (!val) {
									setTypeIds([]);
								} else if (isArray(val)) {
									setTypeIds(val);
								} else {
									setTypeIds([val]);
								}
							}}
							typesFilter={noteTypes
								?.filter((x: any) =>
									availableNoteTypes.includes(x.ID)
								)
								.map((x: any) => x.Name)}
						/>
					)}
					{!sendNotes && (
						<Tooltip
							title="Edit Important Note"
							style={{ margin: theme.spacing(0, 1) }}
						>
							<IconButton
								size="small"
								onClick={() =>
									setEditDialog({
										id: 0,
										type: 'Important Note',
										value: job.ImportantNote,
									})
								}
							>
								<Icon path={mdiAlertOutline} size={1} />
							</IconButton>
						</Tooltip>
					)}
					{!sendNotes && (
						<Tooltip
							title="Select notes to send"
							style={{ margin: theme.spacing(0, 1) }}
						>
							<IconButton
								size="small"
								onClick={() => setSendNotes(true)}
							>
								<Icon
									path={mdiEmailArrowRightOutline}
									size={1}
								/>
							</IconButton>
						</Tooltip>
					)}
					{!sendNotes && (
						<Tooltip
							title="Add Note"
							style={{ margin: theme.spacing(0, 1) }}
						>
							<IconButton
								size="small"
								onClick={() => setShowAddNote(true)}
							>
								<Icon path={mdiPlusCircleOutline} size={1} />
							</IconButton>
						</Tooltip>
					)}
					{sendNotes && (
						<>
							<span style={{ flexGrow: 1 }} />
							<Tooltip title="Cancel">
								<IconButton
									size="small"
									onClick={() => setSendNotes(false)}
								>
									<Icon
										path={mdiCloseCircleOutline}
										size={1}
									/>
								</IconButton>
							</Tooltip>
							<Tooltip title="Send selected to email">
								<span>
									<IconButton
										size="small"
										onClick={() => handleSendNotes()}
										disabled={selectedNotes.length === 0}
									>
										<Icon
											path={mdiSendCircleOutline}
											size={1}
										/>
									</IconButton>
								</span>
							</Tooltip>
						</>
					)}
				</Toolbar>
			)}
			{/* <Divider /> */}
			{showAddNote && !sendNotes && (
				<AddNote
					onAddNote={handleAddNote}
					onClose={() => setShowAddNote(false)}
				/>
			)}
			{!showAddNote && (
				<List sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					height: '100%',
					position: 'relative',
					overflowY: 'auto',
				}}>
					{filteredNotes.map((note) => (
						<NoteListItem
							key={note.JobNoteID}
							date={note.NoteDate}
							content={note.NoteText}
							type={getNoteType(note.NoteTypeID)}
							employeeNumber={note.EmployeeNumber}
							showCheckbox={sendNotes}
							onToggleSelection={() =>
								handleToggleNoteSelection(note.JobNoteID)
							}
							selected={selectedNotes.includes(note.JobNoteID)}
							onEditNote={() =>
								setEditDialog({
									id: note.JobNoteID,
									type: 'Note',
									value: note.NoteText,
								})
							}
						/>
					))}
					<BottomSpace />
				</List>
			)}
			<Divider />
			{!!editDialog && (
				<Dialog
					isOpen={true}
					title={`Edit ${editDialog.type}`}
					cancelAction={{
						text: 'Cancel',
						onClick: () => setEditDialog(undefined),
					}}
					positiveAction={{
						text: 'Save Changes',
						onClick: () => {
							if (editDialog.type === 'Important Note') {
								console.log('Changing Important Note', { ...job, ImportantNote: editDialog.value })
								onChange({ ...job, ImportantNote: editDialog.value });
							} else {
								handleNoteChanged(
									editDialog.id,
									editDialog.value
								);
							}
							setTimeout(() => setEditDialog(undefined), 500);
						},
					}}
					onClose={() => setEditDialog(undefined)}
				>
					<TextField
						aria-label={editDialog.type}
						title={editDialog.type}
						style={{ padding: '16px' }}
						size="small"
						value={editDialog.value || ''}
						onChange={(e) =>
							setEditDialog({
								...editDialog,
								value: e.target.value,
							})
						}
						InputLabelProps={{ shrink: true }}
						variant="outlined"
						multiline
						margin="dense"
						minRows={5}
						fullWidth
					/>
				</Dialog>
			)}
		</>
	);
};
