import * as React from 'react';
import Box from '@mui/material/Box';

type Props = {
	name?: string;
	children: React.ReactNode;
	style?: React.CSSProperties;
	fill?: boolean;
	scroll?: boolean;
	hide?: boolean;
};

const fillSx: React.CSSProperties = {
	flexGrow: 1,
	height: '100%',
	overflow: 'hidden',
};

const scrollSx: React.CSSProperties = {
	...fillSx,
	overflowY: 'auto',
};

export const FlexColumn = ({
	name,
	children,
	fill,
	scroll,
	hide,
	style = {},
}: Props) => {
	let styles: React.CSSProperties = {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
	};

	if (fill) {
		styles = {
			...styles,
			...fillSx,
		};
	}

	if (scroll) {
		styles = {
			...styles,
			...scrollSx,
		};
	}

	if (hide) {
		styles = {
			...styles,
			display: 'none',
		};
	}

	return (
		<Box data-component={name} sx={{ ...styles, ...style }}>
			{children}
		</Box>
	);
};
