import { Box } from '@mui/material';
import React from 'react';
import { CompanyData } from '../../core';
import { AddressForm } from '../addresses/AddressForm';
import { BottomSpace, Card, ErrorMessage, Loading } from '../common';
import { CompanyForm } from './CompanyForm';
import { AddCompanyController } from './useAddCompany';

type Props = {
	controller: AddCompanyController;
	onCompanyAdded: (company: CompanyData) => void;
};

export const AddCompany = ({ controller, onCompanyAdded }: Props) => {
	const {
		data,
		company,
		working,
		error,
		onCompanyChange,
		onAddressChange,
		resetError,
	} = controller;

	React.useEffect(() => {
		if (company) {
			onCompanyAdded(company);
		}
	}, [company, onCompanyAdded]);

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			height: '100%',
			overflow: 'hidden',
			overflowY: 'auto',
		}}>
			<ErrorMessage error={error} onRetry={resetError} />
			{working && <Loading />}
			{!working && !error && (
				<Card label="Company Information" sx={theme => ({ margin: theme.spacing(2, 0) })}>
					<CompanyForm
						company={data.company}
						onChange={(attr, value) =>
							onCompanyChange({ ...data.company, [attr]: value })
						}
					/>
				</Card>
			)}
			{!working && !error && (
				<Card label="Address" sx={theme => ({ margin: theme.spacing(2, 0) })}>
					<AddressForm
						address={data.address}
						onChanged={onAddressChange}
						autoComplete
					/>
				</Card>
			)}
			<BottomSpace />
		</Box>
	);
};
