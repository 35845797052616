import { isDate, isString } from 'lodash';

export const dateIsValid = (date: Date) => {
	// https://www.geeksforgeeks.org/how-to-check-a-date-is-valid-or-not-using-javascript/
 	// eslint-disable-next-line no-self-compare
	return date.getTime() === date.getTime();
}

export const getUTCDateNow = (): Date => new Date(new Date().toUTCString());

export const getDateFromString = (
	value?: string | Date | null
): Date | null => {
	if (!value) return null;
	if (isString(value)) {
		const parts: string[] = value.split('-');
		if (parts.length === 3) {
			const dt = new Date(
				parseInt(parts[0] || '', 10),
				parseInt(parts[1] || '', 10) - 1,
				parseInt(parts[2] || '', 10)
			);
			return dateIsValid(dt) ? dt : null;
		} else {
			const dt = new Date(value);
			return dateIsValid(dt) ? dt : null;
		}
	} else if (isDate(value)) {
		return dateIsValid(value) ? value : null;
	} else {
		return null;
	}
};

export const toDateTimeString = (value?: Date | null): string | null => {
	if (!value) return null;
	const dt = new Date(value);
	return dateIsValid(dt) ? dt.toISOString() : null;
};

export const fromDateTimeString = (value?: string | null): Date | null => {
	return getDateFromString(value);
};

export const formatDate = (value: any): string | null => {
	const dt = getDateFromString(value);
	if (!dt) return null;
	return dt.toLocaleDateString();
};

export const formatDateTime = (value: any) => {
	const dt = getDateFromString(value);
	if (!dt) return null;
	// 'MM/DD/YYYY hh:mm A'
	return dt.toLocaleTimeString([], {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
};
