// Monorepo
import {
	ContactViewData,
	CompanyViewData,
	StreetAddressData,
	AddLead,
} from '../../core';

// Lib
export const assignmentItems1 = [
	{ key: 'usr1', label: 'EST' },
	{ key: 'usr3', label: 'LEVEL5' },
	{ key: 'usr5', label: 'ADMIN' },
	{ key: 'usr7', label: 'DRM' },
	{ key: 'usr9', label: 'MKTING' },
];

export const assignmentItems2 = [
	{ key: 'usr2', label: 'PM' },
	{ key: 'usr4', label: 'LEAD' },
	{ key: 'usr6', label: 'ACCT' },
	{ key: 'usr8', label: 'OPEN' },
];

export const assignmentRequired = ['usr1', 'usr2', 'usr6'];

export interface AddLeadState {
	JobNumber?: string;
	JobName?: string;
	LeadDate?: string;
	LeadTaker?: string;
	insuranceCompany?: Partial<CompanyViewData>;
	agentContact?: Partial<ContactViewData>;
	adjusterContact?: Partial<ContactViewData>;
	iadjusterContact?: Partial<ContactViewData>;
	customerContact?: Partial<ContactViewData>;
	customerCompany?: Partial<CompanyViewData>;
	LossTypeID?: number;
	BuildingTypeID?: number;
	TaskTemplateID?: number;
	jobsiteAddress?: Partial<StreetAddressData>;
	billingAddress?: Partial<StreetAddressData>;
	InsClaimNumber?: string;
	InsPolicyNumber?: string;
	InsDeductableAmount?: string;
	StormID?: number;
	DateofLoss?: string;
	DamageDesc?: string;
	YearBuilt?: string;
	isEmergency?: boolean;
	isInsuranceJob?: boolean;
	ReferrelTypeID?: number;
	CountyID?: number;
	usr1?: string;
	usr2?: string;
	usr3?: string;
	usr4?: string;
	usr5?: string;
	usr6?: string;
	usr7?: string;
	usr8?: string;
	usr9?: string;
	DivisionID?: number;
	referralCompany?: Partial<CompanyViewData>;
	referralContact?: Partial<ContactViewData>;
	ReportedBy?: number;
	RepairProgramID?: number;
}

export const mapAddLead = (state: AddLeadState): AddLead => {
	return {
		JobNumber: state.JobNumber,
		JobName: state.JobName,
		LeadDate: state.LeadDate,
		LeadTaker: state.LeadTaker,
		InsuranceCompanyID: state.insuranceCompany?.CompanyID,
		AgentID: state.agentContact?.ContactID,
		AdjusterID: state.adjusterContact?.ContactID,
		IAAdjusterID: state.iadjusterContact?.ContactID,
		CustomerID: state.customerContact?.ContactID,
		CompanyCustomerID: state.customerCompany?.CompanyID,
		LossTypeID: state.LossTypeID,
		BuildingTypeID: state.BuildingTypeID,
		TaskTemplateID: state.TaskTemplateID,
		JobsiteAddressID: state.jobsiteAddress?.AddressID,
		BillingAddressID: state.billingAddress?.AddressID,
		InsClaimNumber: state.InsClaimNumber,
		InsPolicyNumber: state.InsPolicyNumber,
		InsDeductableAmount: state.InsDeductableAmount,
		StormID: state.StormID,
		DateofLoss: state.DateofLoss,
		DamageDesc: state.DamageDesc,
		YearBuilt: state.YearBuilt,
		isEmergency: state.isEmergency,
		isInsuranceJob: state.isInsuranceJob,
		ReferrelTypeID: state.ReferrelTypeID,
		CountyID: state.CountyID,
		usr1: state.usr1,
		usr2: state.usr2,
		usr3: state.usr3,
		usr4: state.usr4,
		usr5: state.usr5,
		usr6: state.usr6,
		usr7: state.usr7,
		usr8: state.usr8,
		usr9: state.usr9,
		DivisionID: state.DivisionID,
		RefCompany: state.referralCompany?.CompanyID,
		RefContact: state.referralContact?.ContactID,
		ReportedBy: state.ReportedBy,
		RepairProgramID: state.RepairProgramID,
	};
};

export const initialLeadState: AddLeadState = {
	isInsuranceJob: false,
	LeadDate: new Date().toISOString(),
	usr1: 'THOM04',
	usr5: 'DAVE01',
	usr6: 'BOLD01',
};

export const getValidationErrors = (
	state: AddLeadState
): Record<string, string> => {
	const errors: Record<string, string> = {};

	if (!state.JobName) errors['JobName'] = 'Job name is required';
	if (!state.JobNumber) errors['JobNumber'] = 'Job number is required';
	if (!state.LeadDate) errors['LeadDate'] = 'Lead date is required';
	// if (!state.DateofLoss) errors['DateofLoss'] = 'Date of loss is required';
	if (!state.LeadTaker) errors['LeadTaker'] = 'Lead taker is required';
	if (!state.customerContact?.ContactID)
		errors['customerContact'] = 'Customer is required';
	if (!state.LossTypeID) errors['LossTypeID'] = 'Loss type is required';
	if (!state.BuildingTypeID)
		errors['BuildingTypeID'] = 'Building type is required';
	if (!state.TaskTemplateID)
		errors['TaskTemplateID'] = 'Task template is required';
	if (!state.jobsiteAddress?.AddressID)
		errors['jobsiteAddress'] = 'Jobsite address is required';
	if (!state.billingAddress?.AddressID)
		errors['billingAddress'] = 'Billing address is required';
	if (!state.DamageDesc)
		errors['DamageDesc'] = 'Loss description is required';
	if (!state.ReferrelTypeID)
		errors['ReferrelTypeID'] = 'Referral type is required';
	if (!state.ReportedBy) errors['ReportedBy'] = 'Reported by is required';
	if (!state.usr1) errors['usr1'] = 'Est is required';
	if (!state.usr2) errors['usr2'] = 'PM is required';
	if (!state.usr5) errors['usr5'] = 'ADMIN is required';
	if (!state.usr6) errors['usr6'] = 'Act is required';
	if (!state.DivisionID) errors['DivisionID'] = 'Division is required';
	if (!state.insuranceCompany)
		errors['insuranceCompany'] = 'Insurance Company is required';
	if (!state.CountyID) errors['CountyID'] = 'County is required';
	if (!state.YearBuilt) errors['YearBuilt'] = 'YearBuilt is required';

	return errors;
};

export const isContactCompany = (
	contact?: Partial<ContactViewData> | null,
	company?: Partial<CompanyViewData> | null
): boolean => {
	if (!contact || !company) return false;
	if (!contact.CurrentCompanyID) return false;
	if (!company.CompanyID) return false;
	return contact.CurrentCompanyID === company.CompanyID;
};

export interface ComponentProps {
	state: AddLeadState;
	setState: React.Dispatch<React.SetStateAction<AddLeadState>>;
	validationErrors: Record<string, string>;
}
