import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';

// Monorepo
import {
	LookupTypes,
	PrimaryStreetAddressData,
	StateOptions,
} from '../../core';

// Components
import { AutoComplete, AutoCompleteLookup } from '../common';
import SwitchItem from '../common/SwitchItem';

type AddressFormProps = {
	address: Partial<PrimaryStreetAddressData>;
	onChanged: (data: Partial<PrimaryStreetAddressData>) => void;
	autoComplete?: boolean;
	style?: React.CSSProperties;
	className?: string;
	disablePrimary?: boolean;
};

export const AddressForm = (props: AddressFormProps) => {
	const { address, onChanged, className, style = {}, disablePrimary } = props;

	const handleChange = (key: string, value?: string | number | null) => {
		onChanged({ ...address, [key]: value });
	};

	return (
		<Grid
			container
			direction="column"
			spacing={1}
			className={className}
			style={style}
		>
			<Grid item>
				<Grid container spacing={1} direction="row">
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							label="Address Name"
							aria-label="Address Name"
							title="Address Name"
							value={address?.AddressName || ''}
							onChange={(e) =>
								handleChange('AddressName', e.target.value)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<AutoCompleteLookup
							lookupType={LookupTypes.AddressType}
							label="Address Type"
							placeholder="Address Type"
							required
							value={address?.AddressTypeID || null}
							onChange={(value) =>
								handleChange('AddressTypeID', value)
							}
							error={!address?.AddressTypeID}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				{/* {!!autoComplete && (
					<AutoCompleteAddress
						label="Address Line 1"
						placeholder="Address Line 1"
						value={address?.AddressID || null}
						onSelect={handleSelectAddress}
						required
					/>
				)} */}

				<TextField
					aria-label="Address Line 1"
					title="Address Line 1"
					label="Address Line 1"
					value={address?.AddressLine1 || ''}
					onChange={(e) =>
						handleChange('AddressLine1', e.target.value)
					}
					onFocus={(e) => e.target.select()}
					variant="outlined"
					margin="dense"
					required
					error={!address?.AddressLine1}
					fullWidth
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
			<Grid item>
				<TextField
					aria-label="Address Line 2"
					title="Address Line 2"
					label="Address Line 2"
					value={address.AddressLine2 || ''}
					onChange={(e) =>
						handleChange('AddressLine2', e.target.value)
					}
					onFocus={(e) => e.target.select()}
					variant="outlined"
					margin="dense"
					fullWidth
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>

			<Grid item>
				<Grid container direction="row" spacing={1}>
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							aria-label="City"
							title="City"
							label="City"
							value={address.City || ''}
							onChange={(e) =>
								handleChange('City', e.target.value)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							required
							fullWidth
							error={!address.City}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item>
						<AutoComplete
							label="State"
							placeholder="State"
							autoOpen
							value={address.State}
							onChange={(value) => handleChange('State', value)}
							required
							options={StateOptions}
							error={!address.State}
						/>
					</Grid>
					<Grid item>
						<TextField
							aria-label="Zip"
							title="Zip"
							label="Zip"
							value={address.ZIP || ''}
							onChange={(e) =>
								handleChange('ZIP', e.target.value)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							required
							fullWidth
							error={!address.ZIP}
							style={{ maxWidth: 120 }}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<TextField
					aria-label="Notes"
					title="Notes"
					label="Notes"
					value={address.Note || ''}
					onChange={(e) => handleChange('Note', e.target.value)}
					onFocus={(e) => e.target.select()}
					variant="outlined"
					margin="dense"
					multiline
					fullWidth
					minRows={4}
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
			<Grid item style={{ marginTop: '8px' }}>
				<SwitchItem
					primary={disablePrimary ? 'This Address is the Primary' : 'Set as primary Address'}
					checked={address.isPrimary === true}
					disabled={disablePrimary}
					onChanged={(checked) => onChanged({ ...address, isPrimary: checked })}
				/>
			</Grid>
		</Grid>
	);
};
