import { UserRolePermission } from '../core';

import { ApiClientInterface } from './client';

export default class PermissionApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
	}

	public async query(): Promise<UserRolePermission[]> {
		const response = await this.client.get<UserRolePermission[]>(
			`/permission`
		);
		return response || [];
	}
}
