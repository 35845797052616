import {
	mdiCalendarCheck,
	mdiCardAccountMailOutline,
	mdiChartBar,
	mdiCogOutline,
	mdiHistory,
	mdiHomeCityOutline,
	mdiLockOutline,
	mdiPlusCircleOutline,
} from '@mdi/js';
import { Permissions } from '../../lib';

import { NavigationItems } from './constants';

type SidebarItem = {
	key: string;
	icon: string;
	canView: boolean;
};

export const createNavigation = (
	hasPermission: (values: string[]) => boolean
): [SidebarItem[], SidebarItem[]] => {
	const SidebarItems: SidebarItem[] = [
		{ key: NavigationItems.TASKS, icon: mdiCalendarCheck, canView: true },
		{ key: NavigationItems.RECENT, icon: mdiHistory, canView: true },
		{
			key: NavigationItems.JOBS,
			icon: mdiHomeCityOutline,
			canView: hasPermission([
				Permissions.ViewJobs,
				Permissions.EditJobs,
			]),
		},
		{
			key: NavigationItems.CONTACTS,
			icon: mdiCardAccountMailOutline,
			canView: hasPermission([
				Permissions.ViewContacts,
				Permissions.EditContacts,
				Permissions.ViewAddressBook,
			]),
		},
		{
			key: NavigationItems.COMPANIES,
			icon: mdiCardAccountMailOutline,
			canView: hasPermission([
				Permissions.ViewCompanies,
				Permissions.EditCompanies,
				Permissions.ViewAddressBook,
			]),
		},
		{
			key: NavigationItems.REPORTS,
			icon: mdiChartBar,
			canView: hasPermission([
				Permissions.RunReports,
				Permissions.AccountingJobs,
			]),
		},
		{
			key: NavigationItems.ADD,
			icon: mdiPlusCircleOutline,
			canView: hasPermission([
				Permissions.EditCompanies,
				Permissions.EditContacts,
				// Permissions.NewLead,
			]),
		}
		// {
		// 	key: NavigationItems.ADDCOMPANY,
		// 	icon: mdiPlusCircleOutline,
		// 	canView: hasPermission([
		// 		Permissions.EditCompanies,
		// 		Permissions.EditContacts,
		// 		// Permissions.NewLead,
		// 	]),
		// },
		// {
		// 	key: NavigationItems.ADDLEAD,
		// 	icon: mdiPlusCircleOutline,
		// 	canView: hasPermission([
		// 		Permissions.EditCompanies,
		// 		Permissions.EditContacts,
		// 		Permissions.NewLead,
		// 	]),
		// },
	];

	const SidebarBottomItems: SidebarItem[] = [
		{
			key: NavigationItems.ADMIN,
			icon: mdiCogOutline,
			canView: hasPermission([Permissions.SYSADMIN]),
		},
		{
			key: NavigationItems.SETTINGS,
			icon: mdiLockOutline,
			canView: true,
		},
	];

	return [
		SidebarItems.filter((x) => x.canView),
		SidebarBottomItems.filter((x) => x.canView),
	];
};
