import React from 'react';
import { DateField } from '@mui/x-date-pickers/DateField';
import { dateIsValid } from '../../core';

type DatePickerProps = {
	label?: string;
	value?: string | null;
	onChange: (value?: string | null) => void;
	style?: React.CSSProperties;
	className?: string;
	error?: boolean;
	disabled?: boolean;
	required?: boolean;
};

export const DatePicker = (props: DatePickerProps) => {
	const {
		label,
		value,
		onChange,
		className,
		style = {},
		disabled = false,
		required = false,
	} = props;

	return (
		<DateField


			fullWidth
			InputLabelProps={{ shrink: true }}

			format="MM/dd/yyyy"
			margin="dense"
			label={label}
			// placeholder={label}
			value={value ? new Date(value) : null}
			onChange={(value) => {
				if (value && dateIsValid(value)) {
					onChange(value.toISOString());
				} else {
					onChange(null);
				}
			}}
			// error={error}
			disabled={disabled}
			required={required}
			className={className}
			style={style}
		/>
	);
};
