import {
	mdiCloseOctagonOutline,
	mdiContentCopy,
	mdiListBoxOutline,
	mdiPercent,
	mdiRename,
	mdiRestart,
} from '@mdi/js';
import Icon from '@mdi/react';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';
import { JobData, JobDetail, LookupTypes } from '../../../core';
import { Permissions } from '../../../lib';
import { ApplicationEvent } from "../../ApplicationEvent";
import { AddressCard } from "../../addresses";
import {
	AutoCompleteLookup,
	Card,
	Chip,
	ConfirmDialog,
	EmployeeDropDown,
} from '../../common';
import { HasPermission } from '../../common/HasPermission';
import { CompanyCard } from "../../companies/CompanyCard";
import { EmailCard, PhoneCard } from '../../contacts';
import { ContactCard } from "../../contacts/ContactCard";
import { useLeadReport } from '../../reports/useLeadReport';
import { useOpenReport } from '../../reports/useOpenReport';
import { CloneJobDialog } from './CloneJobDialog';
import { KillJobDialog } from './KillJobDialog';
import { RenameJobDialog } from "./RenameJobDialog";

type JobInfoProps = {
	canEditJob?: boolean;
	job?: JobDetail;
	onChange: (data: Partial<JobData>) => void;
};

export const JobInfo = ({ job, onChange, canEditJob }: JobInfoProps) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const { api } = useAppContext();
	const leadSheetReport = useLeadReport();
	const openReport = useOpenReport();
	const [cloneJob, setCloneJob] = React.useState<number>();
	const [killJob, setKillJob] = React.useState<number>();
	const [renameJob, setRenameJob] = React.useState<number>();
	const [unkillJob, setUnKillJob] = React.useState(false);

	if (!job) return null;

	const flags = [
		{
			key: 'PrivateJob',
			prop: 'isInsuranceJob',
			label: 'Private Job',
			selected: !job.isInsuranceJob,
		},
		{
			key: 'Insurance',
			label: 'Insurance Job',
			prop: 'isInsuranceJob',
			selected: job.isInsuranceJob,
		},
		{
			key: 'Emergency',
			prop: 'isEmergency',
			label: 'Emergency',
			selected: job.isEmergency,
		},
	];

	const handleOpenLeadSheet = () => {
		if (leadSheetReport) {
			openReport(leadSheetReport, job.JobNumber);
		}
	};

	const handleOpenNewJob = (id: number) => {
		setCloneJob(undefined);
		navigate(`/jobs/${id}`);
	};

	const handleJobRenamed = () => {
		setRenameJob(undefined);
		ApplicationEvent.ReloadJob(job.JobID);
	};

	const handleUnKillJob = async () => {
		if (job) {
			try {
				await api.job.unkill(job.JobID);
			} catch (error) {
				console.error(error);
			} finally {
				window.location.reload();
			}
		}
	};

	return (
		<Card
			label="Job Information"
			actions={[
				{
					key: 'rename',
					label: 'Rename',
					onClick: () => setRenameJob(job.JobID),
					icon: mdiRename,
					visible: canEditJob && !job.isJobKilled,
				},
				{
					key: 'leadsheet',
					label: 'Open Lead Sheet',
					onClick: handleOpenLeadSheet,
					icon: mdiListBoxOutline,
					visible: !!leadSheetReport,
				},
				{
					key: 'clone',
					label: 'Clone Job',
					iconSize: 0.85,
					onClick: () => setCloneJob(job.JobID),
					icon: mdiContentCopy,
					visible: canEditJob,
				},
				{
					key: 'kill',
					label: 'Kill Job',
					onClick: () => setKillJob(job.JobID),
					icon: mdiCloseOctagonOutline,
					visible: canEditJob && !job.isJobKilled,
				},
				{
					key: 'unkill',
					label: 'Un-Kill Job',
					onClick: () => setUnKillJob(true),
					icon: mdiRestart,
					visible: canEditJob && job.isJobKilled,
				},
			]}
		>
			<Grid
				container
				direction="row"
				wrap="wrap"
				spacing={1}
				style={{ marginLeft: '-8px', marginBottom: '8px' }}
			>
				<Grid item style={{ flexGrow: 1 }}>
					<TextField
						label="Job Number"
						aria-label="Job Number"
						title="Job Number"
						value={job.JobNumber || ''}
						onChange={(e) =>
							onChange({ ...job, JobNumber: e.target.value })
						}
						variant="outlined"
						margin="dense"
						fullWidth
						disabled
						aria-readonly={true}
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}>
					<TextField
						label="Job Name"
						aria-label="Job Name"
						title="Job Name"
						value={job.JobName || ''}
						onChange={(e) =>
							onChange({ ...job, JobName: e.target.value })
						}
						variant="outlined"
						margin="dense"
						fullWidth
						disabled
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
			</Grid>

			<Grid container direction="row" wrap="wrap">
				<Grid
					container
					direction="column"
					justifyContent="flex-start"
					style={{ minWidth: '49%', flexGrow: 1, width: 'initial' }}
				>
					<Grid item style={{ flexGrow: 1 }}>
						<ContactCard 
							label="Customer" 
							contactId={job.CustomerID} 
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<PhoneCard
							label="Customer Phone Number"
							phone={job.CustomerPhoneNumber || ''}
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<EmailCard
							label="Customer Email Address"
							address={job.CustomerEmailAddress || ''}
							noError
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<CompanyCard label="Customer Company" companyId={job.CompanyCustomerID} />
					</Grid>
				</Grid>
				<Grid
					container
					direction="column"
					sx={{ flexGrow: 1, width: 'initial' }}
					justifyContent="flex-start"
				>
					<Grid
						item
						style={{
							flexGrow: 1,
							padding: theme.spacing(1, 0, 2, 2),
						}}
					>
						<AddressCard
							label="Jobsite Address"
							name={job.JobsiteAddressName}
							line1={job.JobsiteAddressLine1}
							line2={job.JobsiteAddressLine2}
							city={job.JobsiteAddressCity}
							state={job.JobsiteAddressState}
							zip={job.JobsiteAddressZip}
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1, paddingBottom: '20px' }}>
						<Grid
							container
							direction="row"
							wrap="wrap"
							justifyContent="space-evenly"
							alignItems="center"
						>
							{flags.map((flag) => (
								<Grid item key={flag.key}>
									<Chip
										label={flag.label}
										selected={flag.selected}
										onClick={() =>
											canEditJob &&
											onChange({
												...job,
												[flag.prop]:
													flag.key === 'PrivateJob'
														? flag.selected
														: !flag.selected,
											})
										}
									/>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container direction="row" wrap="wrap" spacing={1}>
				<Grid item style={{ flexGrow: 1 }}>
					<AutoCompleteLookup
						label="Loss Type"
						placeholder="Loss Type"
						lookupType={LookupTypes.JobType}
						value={job.LossTypeID || null}
						disabled={!canEditJob}
						onChange={(value) =>
							onChange({
								...job,
								LossTypeID: value || undefined,
							})
						}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}>
					<AutoCompleteLookup
						label="Building Type"
						placeholder="Building Type"
						lookupType={LookupTypes.BuildingType}
						value={job.BuildingTypeID || null}
						disabled={!canEditJob}
						onChange={(value) =>
							onChange({
								...job,
								BuildingTypeID: value || undefined,
							})
						}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}>
					<AutoCompleteLookup
						label="Repair Program"
						placeholder="Repair Program"
						lookupType={LookupTypes.RepairProgram}
						value={job.RepairProgramID || null}
						disabled={!canEditJob}
						onChange={(value) =>
							onChange({
								...job,
								RepairProgramID: value || undefined,
							})
						}
					/>
				</Grid>
				<HasPermission permissions={[Permissions.SYSADMIN]}>
					<Grid item style={{ flexGrow: 1, marginBottom: theme.spacing(1) }}>
						<EmployeeDropDown
							label="Lead Taker"
							placeholder="Lead Taker"
							value={job.LeadTaker}
							onChange={(value) =>
								onChange({
									...job,
									LeadTaker: value || undefined,
								})
							}
							filter={(employees) =>
								employees.filter((x) => x.isActive === true)
							}
						/>
					</Grid>
				</HasPermission>
			</Grid>

			<Grid container direction="row" spacing={1}>
				<Grid item style={{ flexGrow: 1, marginBottom: theme.spacing(1) }}>
					<AutoCompleteLookup
						label="Access"
						placeholder="Access"
						lookupType={LookupTypes.AccessType}
						value={job.JobsiteAccessTypeID || null}
						disabled={!canEditJob}
						onChange={(value) =>
							onChange({
								...job,
								JobsiteAccessTypeID: value || undefined,
							})
						}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}>
					<AutoCompleteLookup
						label="County"
						placeholder="County"
						lookupType={LookupTypes.County}
						value={job.CountyID || null}
						disabled={!canEditJob}
						onChange={(value) =>
							onChange({
								...job,
								CountyID: value || undefined,
							})
						}
					/>
				</Grid>
				<Grid item style={{ maxWidth: 120 }}>
					<TextField
						label="Sales Tax"
						aria-label="Sales Tax"
						title="Sales Tax"
						value={job.SalesTax || ''}
						disabled={!canEditJob}
						type="number"
						inputProps={{
							onWheel: (e) =>
								(e.target as HTMLInputElement).blur(),
						}}
						onChange={(e) =>
							onChange({
								...job,
								SalesTax:
									parseFloat(e.target.value) || undefined,
							})
						}
						variant="outlined"
						margin="dense"
						InputLabelProps={{ shrink: true }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon
										path={mdiPercent}
										size={0.6}
										color="rgba(0,0,0,.5)"
									/>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item style={{ maxWidth: 120 }}>
					<TextField
						label="Year Built"
						aria-label="Year Built"
						title="Year Built"
						value={job.YearBuilt || ''}
						disabled={!canEditJob}
						onChange={(e) =>
							onChange({
								...job,
								YearBuilt: e.target.value,
							})
						}
						variant="outlined"
						margin="dense"
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
			</Grid>

			<Grid container direction="column">
				<Grid item style={{ flexGrow: 1 }}>
					<TextField
						label="Access Description"
						aria-label="Access Description"
						title="Access Description"
						value={job.JobsiteAccessDesc || ''}
						disabled={!canEditJob}
						onChange={(e) =>
							onChange({
								...job,
								JobsiteAccessDesc: e.target.value,
							})
						}
						variant="outlined"
						margin="dense"
						fullWidth
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
			</Grid>
			{cloneJob && (
				<CloneJobDialog
					jobId={cloneJob}
					onJobCloned={handleOpenNewJob}
					onCancel={() => setCloneJob(undefined)}
				/>
			)}
			{killJob && (
				<KillJobDialog
					jobId={killJob}
					onJobKilled={() => window.location.reload()}
					onCancel={() => setKillJob(undefined)}
				/>
			)}
			{unkillJob && (
				<ConfirmDialog
					isOpen={true}
					title="Un-Kill Job"
					question="Do you want to unkill this job?"
					onCancel={() => setUnKillJob(false)}
					onConfirm={handleUnKillJob}
				/>
			)}
			{renameJob && (
				<RenameJobDialog
					job={job}
					onComplete={handleJobRenamed}
					onCancel={() => setRenameJob(undefined)}
				/>
			)}
		</Card>
	);
};
