import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

// Monorepo
import { CompanyViewData, LookupTypes } from '../../core';

// Components
import { Box, ListItemButton } from '@mui/material';
import { useLocation } from 'react-use';
import { useAppContext } from '../../contexts/AppContext';
import {
	AutoCompleteLookup,
	QueryPage,
	QueryStatus,
	SearchField,
} from '../common';
import { useLookupTypes } from '../lookups';
import { useRecentItems } from '../recent';
import useQuery from '../useQuery';
import { CompanyCardContent } from './CompanyCardContent';

type Props = {
	onNavigate: (url: string) => void;
	show: boolean;
};

export const CompanyList = ({ onNavigate, show }: Props) => {
	const { api, isMobileLayout } = useAppContext();
	const theme = useTheme();
	const location = useLocation();
	const [companyTypes] = useLookupTypes([LookupTypes.CompanyType]);
	const { addRecentCompany } = useRecentItems();

	const [loaded, setLoaded] = useState(false);
	const [search, setSearch] = useState('');
	const [typeId, setType] = useState<number | undefined>();
	const [serviceTypeId, setServiceType] = useState<number | undefined>();

	const query = useQuery<CompanyViewData>({
		queryCallback: (limit: number, offset: number) =>
			api.company.query({
				search,
				typeIds: typeId ? [typeId] : undefined,
				serviceTypeId,
				limit,
				offset,
			}),
		queryCountCallback: () =>
			api.company.count({ search, typeIds: typeId ? [typeId] : undefined, serviceTypeId }),
		shouldExecute: () => loaded,
		arguments: [search, typeId, serviceTypeId, loaded],
		limit: 50,
		delay: 500,
	});

	useEffect(() => {
		if (show) {
			setLoaded(true);
		}
	}, [show]);

	const handleOpenCompany = (company: CompanyViewData) => {
		const url = `/companies/${company.CompanyID}`;
		addRecentCompany({
			id: company.CompanyID,
			name: company.CompanyName,
			type:
				companyTypes?.find((x: any) => x.ID === company.CompanyTypeID)
					?.Name || '',
		});
		onNavigate(url);
	};

	if (!show) return null;

	return (
		<Box
			data-component="company-list"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				overflow: 'hidden',
			}}
		>
			<Box sx={theme => ({
				display: 'flex',
				padding: theme.spacing(0),
			})}>
				<SearchField
					placeholder="Search by name"
					text={search}
					onChange={setSearch}
				/>
			</Box>
			<Box sx={theme => ({
				display: 'flex',
				padding: theme.spacing(0, 1),
			})}>
				<AutoCompleteLookup
					placeholder="Company Type"
					required
					lookupType={LookupTypes.CompanyType}
					value={typeId || null}
					onChange={(value) => setType(value || undefined)}
				/>
			</Box>
			{typeId === 33 && (
				<Box sx={theme => ({
					display: 'flex',
					padding: theme.spacing(0, 1),
				})}>
					<AutoCompleteLookup
						label="Service Type"
						placeholder="Service Type"
						lookupType={LookupTypes.ServiceType}
						value={serviceTypeId || null}
						emptyOption={{ value: -1, label: 'None' }}
						onChange={(value) => setServiceType(value || undefined)}
					/>
				</Box>
			)}
			{isMobileLayout && (
				<QueryPage queryResult={query} />
			)}
			<QueryStatus
				status={query.status}
				idle="Enter your search criteria to see results"
				noresults="No results match your search criteria"
				scrollable
				error={query.error}
				sx={{ padding: theme.spacing(1) }}
			>
				<List>
					{query.results.map((company, index) => (
						<ListItemButton
							key={company.CompanyID}
							selected={location.pathname?.includes(
								`company/${company.CompanyID}`
							)}
							onClick={() => handleOpenCompany(company)}
						>
							<ListItem>
								<CompanyCardContent company={company} />
							</ListItem>
						</ListItemButton>
					))}
				</List>
				{isMobileLayout && (
					<div style={{ marginBottom: '200px' }}>&nbsp;</div>
				)}
			</QueryStatus>
			{!isMobileLayout && (
				<QueryPage queryResult={query} />
			)}
		</Box>
	);
};
