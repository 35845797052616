import React from 'react';
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import { LookupTypes } from '../../core';
import { mdiMapMarker } from '@mdi/js';
import { FlexColumn } from "../common";
import SelectionCard from "../selection/SelectionCard";
import { useLookupTypes } from '../lookups';
import { useWindowOpen } from '../useWindowOpen';
import { Box, Typography } from '@mui/material';

type AddressProps = {
	label?: string;
	name?: string;
	line1?: string;
	line2?: string;
	city?: string;
	state?: string;
	zip?: string;
	country?: string;
	note?: string;
	typeId?: number;
	onSelectAddress?: () => void;
	onClearAddress?: () => void;
	onCopyAddress?: () => void;
	copyButtonText?: string;
	disabled?: boolean;
	error?: boolean;
	height?: number;
};

export const AddressCard = (props: AddressProps) => {
	const [types] = useLookupTypes([LookupTypes.AddressType]);
	const {
		label = 'Address',
		name,
		line1,
		line2,
		city,
		state,
		zip,
		country,
		note,
		typeId,
		onSelectAddress,
		onClearAddress,
		onCopyAddress,
		copyButtonText = 'Copy',
		disabled = false,
		error = false,
		height = 100,
	} = props;

	const open = useWindowOpen();

	const handleOpenMap = () => {
		const link = [];
		if (line1) link.push(encodeURI(line1));
		if (line2) link.push(encodeURI(line2));
		if (city) link.push(encodeURI(city));
		if (state) link.push(encodeURI(state));
		if (zip) link.push(encodeURI(zip));
		const url = `http://maps.google.com/maps?q=${link.join(',')}`;
		open(url);
	};

	const type = typeId
		? (types || []).find((x: any) => x.ID === typeId)
		: undefined;

	const hasAddress = !!line1 && !!city && !!state && !!zip;

	return (
		<SelectionCard
			title={label}
			height={height}
			mode={hasAddress ? 'render' : 'selection'}
			disabled={disabled ? 'disabled' : undefined}
			onOpenSelection={handleOpenMap}
			onClearSelection={onClearAddress}
			error={error || !hasAddress}			
			actions={
				<>
			 		{onCopyAddress && !disabled && (
			 			<Tooltip title="Copy Jobsite Address">
			 				<Button
			 					size="small"
			 					variant="text"
			 					onClick={onCopyAddress}
			 					tabIndex={-1}
			 				>
			 					{copyButtonText}
			 				</Button>
			 			</Tooltip>
			 		)}
			 		{onSelectAddress && !disabled && (
			 			<Tooltip title="Select Address">
			 				<Button
			 					size="small"
			 					variant="text"
			 					onClick={onSelectAddress}
			 					tabIndex={-1}
			 				>
			 					Select
			 				</Button>
			 			</Tooltip>
			 		)}
				</>
			}
			openIcon={mdiMapMarker}
			render={
				<FlexColumn>
					{type && <Typography variant="caption">{type.Name}</Typography>}
					{name && (
						<p>
							<strong>{name}</strong>
						</p>
					)}
					<p>{line1}</p>
					{line2 && <p>{line2}</p>}
					<p>
						{city}&nbsp;{state}&nbsp;{zip}&nbsp;{country}
					</p>
					{note && <p>{note}</p>}
				</FlexColumn>
			}
			selection={<Box style={{ display: 'flex', flexGrow: 1 }}></Box>}
		/>
	);
};
