import Grid from '@mui/material/Grid';
import { CompanyViewData, formatPhoneNumber } from '../../core';

type Props = {
	company: Partial<CompanyViewData>;
};

export const CompanyCardContent = ({ company }: Props) => {
	let phoneNumber = '';
	if (company) {
		if (company.phonenumber && !company.phoneext) {
			phoneNumber = formatPhoneNumber(company.phonenumber);
		} else if (company.phonenumber && company.phoneext) {
			phoneNumber = `${formatPhoneNumber(company.phonenumber)} x: ${company.phoneext
				}`;
		}
	}

	return (
		<Grid container direction="row" sx={theme => ({
			padding: theme.spacing(0.25, 0),
			paddingRight: theme.spacing(0.75),
		})}>
			<Grid container direction="column">
				{company.companytype && (
					<Grid item sx={theme => ({
						flexGrow: 1,
						fontSize: theme.typography.caption.fontSize,
						fontWeight: 500,
						color: theme.palette.secondary.main,
					})}>
						{company.companytype}
					</Grid>
				)}
				{company.CompanyName && (
					<Grid item sx={{ flexGrow: 1 }}>
						<strong>{company.CompanyName}</strong>
					</Grid>
				)}
				<Grid item sx={{ flexGrow: 1 }}>
					{company.AddressLine1} {company.AddressLine2}
				</Grid>
				<Grid item sx={{ flexGrow: 1 }}>
					{company.City} {company.State} {company.ZIP}
				</Grid>
			</Grid>
			<Grid container direction="column" alignItems="flex-start">
				<Grid
					item
					sx={{ flexGrow: 1, textAlign: 'right' }}
				>
					{phoneNumber}
				</Grid>
			</Grid>
		</Grid>
	);
};
