import React, { useState } from 'react';
import {
	Autocomplete as MuiAutocomplete,
	AutocompleteRenderOptionState,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useDebounce } from 'react-use';
import { AutoCompleteOptionOf, errorOption } from './AutoComplete';

type AutoCompleteAsyncProps<T> = {
	label?: string;
	placeholder?: string;
	fixed?: boolean;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
	autoOpen?: boolean;
	emptyOption?: boolean;
	delay?: number;
	value?: T | null;
	renderOption?: (
		props: any,
		option: any,
		state: AutocompleteRenderOptionState
	) => React.ReactNode;
	onChange: (value: T | null) => void;
	onLoadAsync: (text: string) => Promise<AutoCompleteOptionOf<T | null>[]>;
};

type OptionState<T> = {
	loading: boolean;
	options: AutoCompleteOptionOf<T | null>[];
};

export const AutoCompleteAsync = <T extends unknown>(
	props: AutoCompleteAsyncProps<T>
) => {
	const {
		label,
		fixed = true,
		required = false,
		disabled = false,
		error = false,
		readOnly = false,
		autoOpen = false,
		emptyOption = false,
		delay = 0,
		value,
		renderOption,
		onChange,
		onLoadAsync,
	} = props;
	const [state, setState] = useState<OptionState<T>>({
		loading: true,
		options: [],
	});
	const [textValue, setTextValue] = useState('');

	const handleLoadOptions = async () => {
		try {
			setState((s) => ({ ...s, loading: true }));
			const options = (await onLoadAsync(textValue)) || [];
			if (emptyOption) {
				options.splice(0, 0, {
					value: null,
					label: 'None',
				});
			}
			setState((s) => ({
				...s,
				loading: false,
				options,
			}));
		} catch (error) {
			setState((s) => ({
				...s,
				loading: false,
				options: [errorOption],
			}));
		}
	};

	const onOptionSelected = (item: AutoCompleteOptionOf<T>) => {
		const option = state.options.find((x) => x.value === item?.value);
		onChange(option?.value || null);
	};

	useDebounce(handleLoadOptions, delay, [textValue]);

	return (
		<MuiAutocomplete
			// getOptionSelected={(option: any, value: any) =>
			// 	option?.value === value?.value
			// }
			// filterOptions={(x) => x}
			// getOptionLabel={(option) => option.label}
			autoHighlight
			freeSolo={!fixed}
			autoComplete={false}
			autoSelect={false}
			clearOnEscape
			fullWidth
			disabled={disabled}
			openOnFocus={autoOpen && !value}
			value={state.options.find((x) => x.value === value) || null}
			onChange={(_event, option: any) => onOptionSelected(option)}
			options={state.options}
			loading={state.loading}
			onInputChange={(_event, newInputValue) => {
				console.log(
					`Setting Text Value, ${textValue} -> ${newInputValue}`
				);
				setTextValue(newInputValue);
			}}
			renderOption={(props, option, state) =>
				renderOption ? (
					renderOption(props, option, state)
				) : (
					<li {...props} key={`${option.value}`}>{option.label}</li>
				)
			}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					// placeholder={placeholder || label}
					autoComplete="off"
					variant="outlined"
					size="small"
					margin="dense"
					fullWidth
					required={required}
					disabled={disabled}
					tabIndex={-1}
					error={error}
					onFocus={(event) => event.target.select()}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						...params.InputProps,
						autoComplete: 'off',
						required: required,
						disabled: disabled,
						readOnly: readOnly,
						error: error,
					}}
				/>
			)}
		/>
	);
};
