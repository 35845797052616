import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { JobData, JobDetail } from '../../../core';
import { Card } from '../../common';

type JobInfoProps = {
	canEditJob?: boolean;
	job?: JobDetail;
	onChange: (data: Partial<JobData>) => void;
};

export const DamageDesc = ({ job, onChange, canEditJob }: JobInfoProps) => {
	if (!job) return null;

	return (
		<Card label="Loss Description">
			<Grid container direction="row" wrap="wrap" spacing={1}>
				<Grid item style={{ flexGrow: 1 }}>
					<TextField
						label="Loss Description"
						aria-label="Loss Description"
						title="Loss Description"
						value={job.DamageDesc || ''}
						onChange={(e) =>
							onChange({ ...job, DamageDesc: e.target.value })
						}
						variant="outlined"
						margin="dense"
						disabled={!canEditJob}
						fullWidth
						multiline
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
			</Grid>
		</Card>
	);
};
