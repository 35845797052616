import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { CompanyViewData } from '../../core';
import { AutoCompleteAsync, AutoCompleteOptionOf, FlexRow } from '../common';
import FieldBase from "../common/FieldBase";
import CompanyListItem from "./CompanyListItem";

export type CompanyAutoCompleteProps = {
	label: string;
	onChange: (company: CompanyViewData | null) => void;
	onAddClick?: () => void;
	companyId?: number;
	typeIds?: number[];
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
};

export const CompanyAutoComplete = (props: CompanyAutoCompleteProps) => {
	const { api } = useAppContext();
	const {
		label,
		onChange,
		onAddClick,
		required = false,
		disabled = false,
		error = false,
		typeIds,
	} = props;

	const selectedValue: number | null = null;
	const [results, setResults] = React.useState<CompanyViewData[]>([]);

	const handleSearchCompanies = async (
		search: string
	): Promise<AutoCompleteOptionOf<number | string>[]> => {
		try {
			const companies = await api.company.query({
				search,
				typeIds: typeIds ? typeIds : undefined,
				limit: 200,
				offset: 0,
			});
			setResults(companies);
			return companies.map((x) => ({
				value: x.CompanyID,
				label: x.OfficeName,
			}));
		} catch (error) {
			console.error(error);
			return [{ value: 0, label: 'Error searching companies' }];
		}
	};

	return (
		<FieldBase>
			<FlexRow nowrap style={{ width: '100%', alignItems: 'center' }}>
				<AutoCompleteAsync
					label={label}
					autoOpen={false}
					required={required}
					disabled={disabled}
					delay={700}
					fixed={false}
					renderOption={(props, option) => (
						<CompanyListItem props={props} company={results.find((x) => x.CompanyID === option?.value)} />
					)}
					error={error}
					value={selectedValue}
					onLoadAsync={handleSearchCompanies}
					onChange={(value) => onChange(results.find((x) => x.CompanyID === value) || null)}
				/>
				{!!onAddClick && (
					<Tooltip title="Add Company">
						<IconButton size="small" style={{ marginLeft: '8px' }} onClick={onAddClick}>
							<Icon path={mdiPlus} size={1} />
						</IconButton>
					</Tooltip>
				)}
			</FlexRow>
		</FieldBase>
	);
};
