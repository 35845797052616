import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { UserMarketingTasks } from '../../core';
import { MarketingTaskListItem } from '../contacts/MarketingTaskListItem';
import { ListItemButton } from '@mui/material';

type Props = {
	mode: 'table' | 'list';
	items: UserMarketingTasks[];
	onOpenMarketingTask: (task: UserMarketingTasks) => void;
};

export const MarketingTaskList = ({
	mode,
	items,
	onOpenMarketingTask,
}: Props) => {
	return (
		<List>
			{items.map((item) => (
				<ListItemButton
					key={item.MarketingTaskID}
					onClick={() => onOpenMarketingTask(item)}
				>
					<ListItem>
						<MarketingTaskListItem
							key={item.MarketingTaskID}
							task={item}
							isEmployeeTasks={true}
							onCompleteTaskClick={() => { }}
							mode={mode}
						/>
					</ListItem>
				</ListItemButton>
			))}
		</List>
	);
};
