import { ApiClientInterface } from './client';

export default class ManagementApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.get = this.get.bind(this);
	}

	public async get(): Promise<any> {
		const response = await this.client.get<any>(`/management`);
		return response;
	}
}
