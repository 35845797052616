import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';
import { JobData, JobDetail } from '../../../core';
import { ApplicationEvent } from '../../ApplicationEvent';
import { Dialog, Loading } from "../../common";
import { useChangeState } from '../../useChangeState';
import { JobLayout } from "./JobLayout";

const validateJob = (job: Partial<JobData>): Promise<string[]> => {
	const errors: string[] = [];
	if (!job.JobNumber) {
		errors.push('JobNumber');
	}
	if (!job.JobName) {
		errors.push('JobName');
	}
	return Promise.resolve(errors);
};

export function JobContainer() {
	const { api } = useAppContext();
	const { id } = useParams<{ id: string }>();
	const jobId = Number(id);
	const [showImportantNote, setShowImportantNote] = useState(false);

	const state = useChangeState<JobDetail>({
		key: jobId,
		onLoadItem: () => api.job.get(jobId),
		onSaveChanges: api.job.update,
		onValidateChanges: validateJob,
		onItemLoaded: (item: JobDetail) => setShowImportantNote(!!item.ImportantNote),
	});

	const importantNote = state.item?.ImportantNote
		? state.item.ImportantNote.trim()
		: '';

	useEffect(() => {
		const unsubscribe = ApplicationEvent.OnReloadJob(state.reload);
		return () => unsubscribe();
	}, [state]);

	return (
		<>
			{state.loading && <Loading />}
			<JobLayout show={!state.loading} state={state} />
			{importantNote && showImportantNote && (
				<Dialog
					isOpen={true}
					title="Important"
					text={importantNote}
					onClose={() => setShowImportantNote(false)}
				/>
			)}
		</>
	);
}
