import FieldBase from "../common/FieldBase";
import { FlexRow } from '../common';
import { useAppContext } from '../../contexts/AppContext';
import { AutoCompleteAsyncText } from "../common/AutoCompleteAsyncText";

export type Props = {
	label: string;
	onChange: (name: string | null) => void;
	typeIds?: number[];
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
};

export const CompanyNamesAutoComplete = (props: Props) => {
	const { api } = useAppContext();
	const {
		label,
		onChange,
		required = false,
		disabled = false,
		error = false,
		typeIds,
	} = props;

	const selectedValue: number | null = null;

	const handleSearchCompanyNames = async (
		search: string
	): Promise<string[]> => {
		try {
			const names = await api.company.queryNames({
				search,
				typeIds: typeIds ? typeIds : undefined,
				limit: 1000,
				offset: 0,
			});
			return names;
		} catch (error) {
			console.error(error);
			return ['Error'];
		}
	};

	return (
		<FieldBase>
			<FlexRow nowrap style={{ width: '100%', alignItems: 'center' }}>
				<AutoCompleteAsyncText
					label={label}
					required={required}
					disabled={disabled}
					delay={700}
					error={error}
					value={selectedValue}
					onLoadAsync={handleSearchCompanyNames}
					onChange={onChange}
				/>
			</FlexRow>
		</FieldBase>
	);
};
