import React from 'react';
import Box from '@mui/material/Box';
import { EmployeeDropDown, ErrorMessage, Loading } from '../../common';
import { Dialog } from '../../common/Dialog';

type ConfirmDialogProps = {
	isOpen: boolean;
	onCancel: () => void;
	onContinue: (employeeNumber: string) => Promise<void>;
};

type State = {
	working: boolean;
	error?: any;
};

export const ReassignJobTaskDialog = ({
	isOpen,
	onCancel,
	onContinue,
}: ConfirmDialogProps) => {
	const [employeeNumber, setEmployeeNumber] = React.useState<string>();
	const [state, setState] = React.useState<State>({ working: false });

	const handleOnContinue = async () => {
		if (employeeNumber) {
			setState({ working: true });
			try {
				await onContinue(employeeNumber);
				setState({ working: false });
			} catch (error) {
				setState({ working: false, error });
			}
		}
	};

	React.useEffect(() => {
		if (isOpen) {
			setState({ working: false, error: undefined });
		}
	}, [isOpen]);

	return (
		<Dialog
			isOpen={isOpen}
			title="Reassign Job Task"
			onClose={onCancel}
			cancelAction={{
				text: 'Cancel',
				color: 'inherit',
				onClick: onCancel,
				isDisabled: state.working,
			}}
			positiveAction={{
				text: 'Continue',
				color: 'secondary',
				onClick: handleOnContinue,
				isDisabled: !employeeNumber || state.working || !!state.error,
			}}
		>
			<Box style={{ padding: '16px' }}>
				{state.working && <Loading />}
				<ErrorMessage error={state.error} />
				{!state.error && !state.working && (
					<EmployeeDropDown
						label="Reassign To"
						placeholder="Reassign To"
						value={employeeNumber || ''}
						required={true}
						onChange={(value) =>
							setEmployeeNumber(value || undefined)
						}
						error={!employeeNumber}
						filter={(users) => users.filter((x) => x.isActive)}
					/>
				)}
			</Box>
		</Dialog>
	);
};
