import { mdiExclamationThick } from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';

export function NotFound() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1
        }}>
            <Icon path={mdiExclamationThick} size={3} color="#555555" />
            <p style={{ fontSize: '1.25rem' }}>This page could not be found.</p>
            <p style={{ paddingBottom: '12px' }}>{location.pathname}</p>
            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
                Go Back
            </Button>
        </Box>
    )
}