import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import { JobData, JobDetail, JobTaskWithJobData } from '../../../core';
import { ApplicationEvent } from '../../ApplicationEvent';
import { ConfirmDialog } from '../../common';
import { JobTaskListItem } from './JobTaskListItem';
import { ReassignJobTaskDialog } from './ReassignJobTaskDialog';

type JobTaskListProps = {
	job?: JobDetail;
	onChange: (data: Partial<JobData>) => void;
	onCountChanged: (count: number) => void;
	show: boolean;
};

export const JobTaskList = ({
	job,
	onCountChanged,
	show,
}: JobTaskListProps) => {
	const theme = useTheme();
	const { api } = useAppContext();
	const [jobTasks, setJobTasks] = useState<JobTaskWithJobData[]>([]);
	const [completeTaskId, setCompleteTaskId] = useState<number>();
	const [reassignTaskId, setReassignTaskId] = useState<number>();
	const jobId = job?.JobID;

	const handleCompleteTask = async () => {
		if (!!jobId && !!completeTaskId) {
			try {
				await api.job.completeTask(jobId, completeTaskId);
				handleLoadJobTasks();
				setCompleteTaskId(undefined);
				ApplicationEvent.ReloadMyTasks();
			} catch (error) {
				console.error(error);
				throw error;
			}
		}
	};

	const handleReassignTask = async (employeeNumber: string) => {
		if (!!jobId && !!reassignTaskId && !!employeeNumber) {
			try {
				await api.job.reassignTask(
					jobId,
					reassignTaskId,
					employeeNumber
				);
				handleLoadJobTasks();
				setReassignTaskId(undefined);
				ApplicationEvent.ReloadMyTasks();
			} catch (error) {
				console.error(error);
				throw error;
			}
		}
	};

	const handleLoadJobTasks = React.useCallback(() => {
		if (jobId) {
			api.job
				.queryTasks(jobId)
				.then((items) => {
					setJobTasks(items);
					onCountChanged(items.length);
				})
				.catch(console.error);
		}
	}, [api.job, jobId, onCountChanged]);

	useEffect(() => {
		handleLoadJobTasks();
	}, [handleLoadJobTasks]);

	useEffect(() => {
		const unsubscribe = ApplicationEvent.OnReloadJob(handleLoadJobTasks);
		return () => unsubscribe();
	}, [handleLoadJobTasks]);

	if (!show || !job) return null;

	return (
		<>
			<List disablePadding sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				height: '100%',
				overflowX: 'hidden',
				overflowY: 'auto',
				marginTop: theme.spacing(1),
			}}>
				{jobTasks.map((item) => (
					<JobTaskListItem
						key={item.JobTaskID}
						task={item}
						isEmployeeTasks={false}
						onCompleteTaskClick={() =>
							setCompleteTaskId(item.JobTaskID)
						}
						onReassignTaskClick={() =>
							setReassignTaskId(item.JobTaskID)
						}
					/>
				))}
			</List>
			<ConfirmDialog
				isOpen={!!completeTaskId}
				onCancel={() => setCompleteTaskId(undefined)}
				onConfirm={handleCompleteTask}
				title="Complete Task"
				question="Are you sure you want to complete this task?"
			/>
			<ReassignJobTaskDialog
				isOpen={!!reassignTaskId}
				onCancel={() => setReassignTaskId(undefined)}
				onContinue={handleReassignTask}
			/>
		</>
	);
};
