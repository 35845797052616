import { useAppContext } from '../../contexts/AppContext';
import { JobSummary } from '../common/JobSummary';

export type Props = {
	contactId: number;
};

export const ContactJobSummary = ({ contactId }: Props) => {
	const { api } = useAppContext();

	return <JobSummary id={contactId} execute={api.contact.queryJobSummary} />;
};
