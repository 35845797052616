import Box from '@mui/material/Box';
import React from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import { JobDetail, LookupTypes, formatDate } from '../../../core';
import { AutoCompleteLookup, DatePicker, ErrorMessage, FlexColumn, Loading } from '../../common';
import { Dialog } from '../../common/Dialog';

type Props = {
	job: JobDetail;
	onCancel: () => void;
	onJobUpdated: () => void;
};

type State = {
	working: boolean;
	error?: any;
	isOnHold?: boolean;
	date?: Date | null;
	reason?: number | null;
};

export const OnHoldDialog = ({ job, onCancel, onJobUpdated }: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>({
		working: false,
		isOnHold: job.IsOnHold === true ? false : true,
		date: job.IsOnHold === true ? job.OnHoldDate : new Date(),
		reason: job.IsOnHold === true ? job.OnHoldReasonID : undefined
	});

	const handleSetJobOnHold = async () => {
		setState({ working: true, error: undefined });
		try {
			if (job.IsOnHold !== true) {
				await api.job.onHold({
					jobId: job.JobID,
					isJobOnHold: true,
					onHoldDate: state.date ? state.date.toISOString() : new Date().toISOString(),
					OnHoldReasonID: state.reason || null
				});
			} else {
				await api.job.onHold({
					jobId: job.JobID,
					isJobOnHold: false,
					onHoldDate: null,
					OnHoldReasonID: null
				});
			}
			onJobUpdated();
		} catch (error) {
			setState({ working: false, error });
		}
	};

	const handleOnChange = (property: string, value: any) => {
		setState((s) => ({
			...s,
			[property]: value,
		}));
	};

	const isValid = job?.IsOnHold !== true
		? !!state.date && !!state.reason
		: true;

	return (
		<Dialog
			isOpen={true}
			title="Set Job On Hold"
			onClose={onCancel}
			cancelAction={{
				text: 'Cancel',
				color: 'inherit',
				onClick: onCancel,
				isDisabled: state.working,
			}}
			positiveAction={{
				text: job?.IsOnHold !== true ? 'Set Job On Hold' : 'Remove On Hold',
				color: 'secondary',
				onClick: handleSetJobOnHold,
				isDisabled: !isValid || state.working || !!state.error,
			}}
		>
			<Box style={{ padding: '16px' }}>
				{state.working && <Loading />}
				<ErrorMessage error={state.error} />
				{!state.error && !state.working && (
					<FlexColumn>
						<DatePicker
							label="On Hold Date"
							value={
								state.date
									? formatDate(state.date)
									: ''
							}
							required
							onChange={(value) => handleOnChange('date', value ? new Date(value) : null)}
							error={!state.date}
							disabled={state.working || job?.IsOnHold === true}
						/>
						{/* <TextField
							label="Reason"
							aria-label="Reason"
							title="Reason"
							value={state.reason || ''}
							onChange={(e) =>
								handleOnChange('reason', e.target.value || '')
							}
							variant="outlined"
							margin="dense"
							fullWidth
							required
							disabled={state.working || job?.IsOnHold === true}
							error={!state.reason}
							InputLabelProps={{ shrink: true }}
						/> */}
						<AutoCompleteLookup
							lookupType={LookupTypes.OnHoldReasonType}
							label="On Hold Reason"
							placeholder="On Hold Reason"
							value={state.reason || null}
							onChange={(value) =>
								handleOnChange('reason', value || null)
							}
						/>
					</FlexColumn>
				)}
			</Box>
		</Dialog>
	);
};
