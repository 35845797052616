import { ReportData } from '../core';

export const getReportUrl = (
	report: ReportData,
	jobId?: any,
	clientIp?: string
): string => {
	const ip =
		clientIp && clientIp.includes('74.218.83.98')
			? '10.1.1.15'
			: '74.218.83.98';

	// const ip = '35.83.229.8';
	// const ip = '';

	if (jobId !== undefined && jobId !== null) {
		return encodeURI(
			`http://${ip}/RunReport.aspx?client=48cbeedb99cb9c6db05abac2c690b7de&type=${report.type}&group=${report.group}&rpt=${report.report}&jobId=${jobId}`
		);
	}

	return encodeURI(
		`http://${ip}/RunReport.aspx?client=48cbeedb99cb9c6db05abac2c690b7de&type=${report.type}&group=${report.group}&rpt=${report.report}`
	);
};
