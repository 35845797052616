import { mdiClose, mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import Button from '@mui/material/Button';
import { useAppContext } from '../../contexts/AppContext';

type SidebarButtonProps = {
	isOpen: boolean;
	onClick: () => void;
};

const LogoIcon = () => (
	<img src="/favicon-32x32.png" alt="Home" width="24" height="24" />
);

const MenuIcon = ({ isOpen }: { isOpen: boolean }) => {
	const { isWideLayout } = useAppContext();
	if (isWideLayout) return null;
	return <Icon path={isOpen ? mdiClose : mdiMenu} size={1} />;
};

export const SidebarButton = ({ onClick, isOpen }: SidebarButtonProps) => {
	return (
		<Button
			sx={theme => ({
				marginLeft: theme.spacing(-1),
				textTransform: 'none',
				fontSize: theme.typography.h6.fontSize,
				fontWeight: 'bold',
			})}
			variant="contained"
			color="primary"
			onClick={onClick}
			startIcon={<LogoIcon />}
			endIcon={<MenuIcon isOpen={isOpen} />}
			style={{
				border: 'none',
				boxShadow: 'none',
			}}
		>
			<span style={{ position: 'relative', top: '-2px' }}>rLinc</span>
		</Button>
	);
};
