import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ContactData, LookupTypes } from '../../core';
import { AutoCompleteLookup, Card, Chip } from '../common';

type ContactDetailProps = {
	disabled: boolean;
	contact: Partial<ContactData> | null | undefined;
	onChange: (data: Partial<ContactData>) => void;
	validations: string[];
};

export const ContactDetail = ({
	contact,
	onChange,
	disabled,
	validations,
}: ContactDetailProps) => {
	if (!contact) return null;

	return (
		<Card label="Contact Information" sx={theme => ({ margin: theme.spacing(1, 0, 0, 0) })}>
			<Grid container direction="column">
				<Grid container direction="row" wrap="wrap">
					<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
						<AutoCompleteLookup
							label="Contact Type"
							placeholder="Contact Type"
							required
							lookupType={LookupTypes.ContactType}
							value={contact.ContactTypeID || null}
							disabled={disabled}
							error={validations.includes('ContactTypeID')}
							onChange={(value) => {
								onChange({
									...contact,
									ContactTypeID: value || undefined,
								});
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							label="Active"
							selected={(contact as any).isActive === true}
							disabled={disabled}
							onClick={() =>
								onChange({
									...contact,
									isActive: !(contact as any).isActive,
								})
							}
						/>
					</Grid>
				</Grid>

				<Grid container direction="row" spacing={1}>
					<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
						<TextField
							label="First Name"
							aria-label="First Name"
							title="First Name"
							value={contact.FirstName || ''}
							disabled={disabled}
							onChange={(e) =>
								onChange({
									...contact,
									FirstName: e.target.value || '',
								})
							}
							error={validations.includes('FirstName')}
							variant="outlined"
							margin="dense"
							sx={{
								flexGrow: 1,
								display: 'flex',
								minWidth: '300px',
							}}
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
						<TextField
							label="Last Name"
							aria-label="Last Name"
							title="Last Name"
							value={contact.LastName || ''}
							disabled={disabled}
							onChange={(e) =>
								onChange({
									...contact,
									LastName: e.target.value || '',
								})
							}
							error={validations.includes('LastName')}
							variant="outlined"
							margin="dense"
							sx={{
								flexGrow: 1,
								display: 'flex',
								minWidth: '300px',
							}}
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
				</Grid>

				<Grid container direction="row" spacing={1}>
					<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
						<TextField
							label="Title"
							aria-label="Title"
							title="Title"
							value={contact.Title || ''}
							disabled={disabled}
							onChange={(e) =>
								onChange({
									...contact,
									Title: e.target.value || '',
								})
							}
							error={validations.includes('Title')}
							variant="outlined"
							margin="dense"
							sx={{
								flexGrow: 1,
								display: 'flex',
								minWidth: '300px',
							}}
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>

					<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
						<TextField
							label="Position"
							aria-label="Position"
							title="Position"
							value={contact.Position || ''}
							disabled={disabled}
							onChange={(e) =>
								onChange({
									...contact,
									Position: e.target.value || '',
								})
							}
							error={validations.includes('Position')}
							variant="outlined"
							margin="dense"
							sx={{
								flexGrow: 1,
								display: 'flex',
								minWidth: '300px',
							}}
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
				</Grid>

				<Grid container direction="row">
					<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
						<TextField
							label="Preferences"
							aria-label="Preferences"
							title="Preferences"
							value={contact.ShortNote || ''}
							disabled={disabled}
							onChange={(e) =>
								onChange({
									...contact,
									ShortNote: e.target.value || '',
								})
							}
							error={validations.includes('ShortNote')}
							variant="outlined"
							margin="dense"
							multiline
							minRows={3}
							sx={{
								flexGrow: 1,
								display: 'flex',
								minWidth: '300px',
							}}
							fullWidth
						/>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};
