import {
	mdiAccountCowboyHat,
	mdiFormTextbox,
	mdiHomeCityOutline,
	mdiNoteOutline,
} from '@mdi/js';
import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import { CompanyData, LookupTypes } from '../../core';
import { ToolbarButton } from '../common/ToolbarButton';
import { useLookupTypes } from '../lookups';

type CompanyTitleProps = {
	company?: CompanyData;
	noteCount: number;
	jobCount: number;
	onRelatedItemClick: (content: string) => void;
};

export const CompanyTitle = (props: CompanyTitleProps) => {
	const { company, noteCount, jobCount, onRelatedItemClick } = props;
	const [companyTypes] = useLookupTypes([LookupTypes.CompanyType]);
	const type = companyTypes?.find(
		(x: any) => x.ID === company?.CompanyTypeID
	);

	if (!company) return null;

	return (
		<AppBar
			position="relative"
			sx={theme => ({
				zIndex: theme.zIndex.drawer + 1,
				'& h6': {
					marginRight: theme.spacing(2),
				},
				transition: theme.transitions.create(['margin', 'width'], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
			})}
			color="secondary"
		>
			<Toolbar>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Box sx={theme => ({ fontSize: theme.typography.h6.fontSize })}>
						<strong>{company.CompanyName}</strong>
					</Box>
					{type && (
						<Hidden xsDown>
							<Box sx={theme => ({ fontSize: theme.typography.body2.fontSize })}>{type.Name}</Box>
						</Hidden>
					)}
				</Box>
				<span className="flex" />

				<ToolbarButton
					title="Notes"
					tooltip="Notes"
					count={noteCount}
					iconPath={mdiNoteOutline}
					onClick={() => onRelatedItemClick('Notes')}
				/>
				<ToolbarButton
					title="Jobs"
					tooltip="Jobs"
					count={jobCount}
					iconPath={mdiHomeCityOutline}
					onClick={() => onRelatedItemClick('Jobs')}
				/>
				<ToolbarButton
					title="Fields"
					tooltip="Fields"
					count={0}
					iconPath={mdiFormTextbox}
					onClick={() => onRelatedItemClick('Fields')}
				/>
				<ToolbarButton
					title="Sales"
					tooltip="Sales"
					count={0}
					iconPath={mdiAccountCowboyHat}
					onClick={() => onRelatedItemClick('Sales')}
				/>
			</Toolbar>
		</AppBar>
	);
};
