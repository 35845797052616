import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { useAppSelector } from "../../store";
import { selectAccountingReports, selectMainReports } from "../../store/reports";
import { ReportList } from "./ReportList";

type Props = {
	show: boolean;
};

export function ReportsSidebar({ show }: Props) {
	const { hasPermission, isMobileLayout } = useAppContext();
	const mainReports = useAppSelector(selectMainReports);
	const accountingReports = useAppSelector(selectAccountingReports);
	const canAccessAccounting = hasPermission(['AccountingJobs']);

	const [tabIndex, setTabIndex] = React.useState(0);

	if (!show) return null;

	console.log(accountingReports)

	return (
		<div
			data-component="reports-list"
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				overflow: 'hidden',
			}}
		>
			{canAccessAccounting && (
				<Tabs
					value={tabIndex}
					variant="fullWidth"
					indicatorColor="secondary"
					textColor="primary"
					onChange={(_event, index) => setTabIndex(index)}
				>
					<Tab value={0} label="Reports" />
					<Tab value={1} label="Accounting" />
				</Tabs>
			)}
			<Box
				data-component="reports-list-content"
				style={{
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					overflowX: 'hidden',
					overflowY: 'auto',
				}}
			>
				{tabIndex === 0 && <ReportList reports={mainReports} type="main" />}
				{tabIndex === 1 && <ReportList reports={accountingReports} type="accounting" />}
				{isMobileLayout && (
					<div style={{ marginBottom: '200px' }}>&nbsp;</div>
				)}
			</Box>
		</div>
	);
}
