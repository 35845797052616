import { Hub } from 'aws-amplify/utils';

export type UnsubscribeFunction = () => void;

export type ApplicationEventChannel =
    | 'appevent-reload-job'
    | 'appevent-reload-my-tasks'
    ;

export interface ApplicationEvent {
    channel: ApplicationEventChannel;
    payload?: any;
}

export interface EventProvider {
    subscribe: (event: ApplicationEventChannel, handler: (event: ApplicationEvent) => void) => UnsubscribeFunction;
    dispatch: (event: ApplicationEvent) => void;
}

export class HubEventProvider implements EventProvider {
    constructor() {
        this.subscribe = this.subscribe.bind(this);
        this.dispatch = this.dispatch.bind(this);
    }

    public subscribe(channel: ApplicationEventChannel, handler: (event: ApplicationEvent) => void): UnsubscribeFunction {
        // console.log('hub-event-subscribe', channel);
        return Hub.listen(channel, (capsule) => {
            handler({
                channel,
                payload: capsule.payload?.data,
            });
        });
    }

    public dispatch(event: ApplicationEvent): void {
        console.log('hub-event-dispatch', event);
        // Timeout is to allow a tick before dispatch
        // Allows state to catch up if state is set and event in same method
        setTimeout(() => {
            Hub.dispatch(event.channel, {
                data: event.payload,
                event: event.channel,
            })
        });
    }
}