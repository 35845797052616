import Box from '@mui/material/Box';
import { useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
	PrimaryEmailAddressData,
	validateEmail
} from '../../core';
import { Dialog, Loading } from '../common';
import { EmailAddressForm } from './EmailAddressForm';

type Props = {
	contactId: number;
	onEmailAddressAdded: (address: PrimaryEmailAddressData) => void;
	onClose: () => void;
};

export const AddEmailAddressDialog = ({
	contactId,
	onClose,
	onEmailAddressAdded,
}: Props) => {
	const { api } = useAppContext();
	const [busy, setBusy] = useState(false);
	const [emailAddress, setEmailAddress] = useState<Partial<PrimaryEmailAddressData>>(
		{}
	);

	const isValid =
		!!emailAddress.EmailTypeID &&
		!!emailAddress.EmailAddress &&
		validateEmail(emailAddress.EmailAddress);

	const handleAddEmailAddress = async () => {
		setBusy(true);
		try {
			const added = await api.contact.addEmailAddress(
				contactId,
				emailAddress
			);
			onEmailAddressAdded(added);
			onClose();
		} catch (error) {
			console.error(error);
			setBusy(false);
		}
	};

	return (
		<Dialog
			isOpen={true}
			title="Add Email Address"
			negativeAction={{
				text: 'Clear',
				onClick: () => setEmailAddress({}),
			}}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
			}}
			positiveAction={{
				text: 'Add Email Address',
				isDisabled: () => busy || !isValid,
				onClick: handleAddEmailAddress,
			}}
			onClose={onClose}
		>
			<Box
				data-component="AddEmailAddressContent"
				sx={theme => ({
					backgroundColor: '#fff',
					padding: theme.spacing(2),
					// @ts-ignore
					'& .MuiOutlinedInput-root': {
						backgroundColor: '#fff',
					},
				})}
			>
				{busy && <Loading />}
				{!busy && (
					<EmailAddressForm
						required
						autoComplete={true}
						emailAddress={emailAddress}
						onChanged={(data) => setEmailAddress(data)}
						style={{ padding: '12px', overflow: 'hidden' }}
					/>
				)}
			</Box>
		</Dialog>
	);
};
