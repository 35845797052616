export const ThumbnailToken = '__thumbnail';

// TODO: Needs to be data driven
export const LookupTypesArray = [
	'AccessType',
	'AddressType',
	'BuildingType',
	'CompanyType',
	'CompUserDefined1',
	'CompUserDefined2',
	'CompUserDefined3',
	'CompUserDefined4',
	'CompUserDefined5',
	'ContactType',
	'ContUserDefined1',
	'ContUserDefined2',
	'ContUserDefined3',
	'ContUserDefined4',
	'ContUserDefined5',
	'County',
	'CustomForm',
	'Division',
	'EmailType',
	'EquipmentType',
	'Grade',
	'IntervalType',
	'JobsiteContactType',
	'JobType',
	'KillReasonType',
	'Marketingtype ',
	'NoteType',
	'PhoneType',
	'ReferredByType',
	'RelationshipType',
	'RepairProgram',
	'ReportedByType',
	'ServiceType',
	'StormLog',
	'SystemSetting',
	'TaskGroupType',
	'OnHoldReasonType'
];

export const LookupTypes = {
	AccessType: 'AccessType',
	AddressType: 'AddressType',
	BuildingType: 'BuildingType',
	CompanyType: 'CompanyType',
	CompUserDefined1: 'CompUserDefined1',
	CompUserDefined2: 'CompUserDefined2',
	CompUserDefined3: 'CompUserDefined3',
	CompUserDefined4: 'CompUserDefined4',
	CompUserDefined5: 'CompUserDefined5',
	ContactType: 'ContactType',
	ContUserDefined1: 'ContUserDefined1',
	ContUserDefined2: 'ContUserDefined2',
	ContUserDefined3: 'ContUserDefined3',
	ContUserDefined4: 'ContUserDefined4',
	ContUserDefined5: 'ContUserDefined5',
	County: 'County',
	CustomForm: 'CustomForm',
	Division: 'Division',
	EmailType: 'EmailType',
	EquipmentType: 'EquipmentType',
	Grade: 'Grade',
	IntervalType: 'IntervalType',
	JobsiteContactType: 'JobsiteContactType',
	JobType: 'JobType',
	KillReasonType: 'KillReasonType',
	MarketingType: 'MarketingType',
	NoteType: 'NoteType',
	PhoneType: 'PhoneType',
	ReferredByType: 'ReferredByType',
	RelationshipType: 'RelationshipType',
	RepairProgram: 'RepairProgram',
	ReportedByType: 'ReportedByType',
	ServiceType: 'ServiceType',
	StormLog: 'StormLog',
	SystemSetting: 'SystemSetting',
	TaskGroupType: 'TaskGroupType',
	OnHoldReasonType: 'OnHoldReasonType',
};

export const UserRoles = {
	Accounting: 'Accounting',
	ReadOnly: 'ReadOnly',
	Standard: 'Standard',
	SystemAdmin: 'SystemAdmin',
};

export const UserPermissions = {
	AccountingJobs: 'AccountingJobs',
	AddNote: 'AddNote',
	AdminJobs: 'AdminJobs',
	EditCompanies: 'EditCompanies',
	EditContacts: 'EditContacts',
	EditJobs: 'EditJobs',
	NewLead: 'NewLead',
	RunReports: 'RunReports',
	SYSADMIN: 'SYSADMIN',
	ViewAddressBook: 'ViewAddressBook',
	ViewCompanies: 'ViewCompanies',
	ViewContacts: 'ViewContacts',
	ViewJobs: 'ViewJobs',
};

export const hasPermission = (
	desiredPermission: string,
	userPermissions: string[]
): boolean => {
	if (userPermissions.includes(UserPermissions.SYSADMIN)) return true;
	return userPermissions.includes(desiredPermission);
};


export const States = {
	AL: 'AL',
	AK: 'AK',
	AZ: 'AZ',
	AR: 'AR',
	CA: 'CA',
	CO: 'CO',
	CT: 'CT',
	DC: 'DC',
	DE: 'DE',
	FL: 'FL',
	GA: 'GA',
	HI: 'HI',
	ID: 'ID',
	IL: 'IL',
	IN: 'IN',
	IA: 'IA',
	KS: 'KS',
	KY: 'KY',
	LA: 'LA',
	ME: 'ME',
	MD: 'MD',
	MA: 'MA',
	MI: 'MI',
	MN: 'MN',
	MS: 'MS',
	MO: 'MO',
	MT: 'MT',
	NE: 'NE',
	NV: 'NV',
	NH: 'NH',
	NJ: 'NJ',
	NM: 'NM',
	NY: 'NY',
	NC: 'NC',
	ND: 'ND',
	OH: 'OH',
	OK: 'OK',
	OR: 'OR',
	PA: 'PA',
	RI: 'RI',
	SC: 'SC',
	SD: 'SD',
	TN: 'TN',
	TX: 'TX',
	UT: 'UT',
	VT: 'VT',
	VA: 'VA',
	WA: 'WA',
	WV: 'WV',
	WI: 'WI',
	WY: 'WY',
};

export const StatesList = Object.values(States);

export const StateOptions = Object.values(States).map((x) => ({
	value: x,
	label: x,
}));