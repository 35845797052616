export const RecentItemTypes = {
    Job: 'Job',
    Contact: 'Contact'
};

export type RecentItemType = keyof typeof RecentItemTypes;

export type RecentItem = {
    key: string;
    id: string | number,
    type: RecentItemType,
    name: string,
    description: string,
};

export type RecentJob = {
    id: number;
    number: string;
    name: string;
    desc: string;
}

export type RecentContact = {
    id: number;
    name: string;
    contactType: string;
    lookupType: string;
}

export type RecentCompany = {
    id: number;
    name: string;
    type: string;
}