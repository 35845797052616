import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LookupTypes } from '../../core';
import { AutoCompleteLookup, Container } from '../common';
import { useLookupType } from '../lookups/useLookupType';
import CompanySelection from "../selection/CompanySelection";
import ContactSelection from "../selection/ContactSelection";
import { ComponentProps } from './shared';

export const Insurance = (props: ComponentProps) => {
	const { state, setState, validationErrors } = props;

	const agentTypeId = useLookupType(LookupTypes.ContactType, 'Agent').ID;
	const iadjusterTypeId = useLookupType(
		LookupTypes.ContactType,
		'Independant Adjuster'
	).ID;
	const agentCompanyTypeId = useLookupType(
		LookupTypes.CompanyType,
		'Agent Company'
	).ID;
	const iaCompanyTypeId = useLookupType(
		LookupTypes.CompanyType,
		'Independant Adjuster'
	).ID;

	const handleStateChange = (
		key: string,
		value: string | boolean | number | object | null | undefined
	) => {
		setState((s) => ({ ...s, [key]: value }));
	};

	return (
		<Container
			component="AddLeadInsuranceContent"
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Grid
				container
				direction="row"
				wrap="wrap"
				spacing={2}
				sx={theme => ({ padding: theme.spacing(0, 1), marginTop: 0 })}
			>
				<Grid
					container
					direction="row"
					wrap="wrap"
					justifyContent="space-around"
					sx={theme => ({ padding: theme.spacing(2.5, 1) })}
					spacing={2}
				>
					<Grid item style={{ flexGrow: 1 }}>
						<ContactSelection
							title="Adjuster/Property Manager"
							companyTypeIds={[378, 588]}
							companyId={state.insuranceCompany?.CompanyID}
							contactId={state.adjusterContact?.ContactID}
							contactTypeIds={state.insuranceCompany?.CompanyID ? undefined : [14]}
							onSelected={(contact, company) => {
								console.log(
									'Adjuster/Property Manager Selected',
									contact,
									company
								);
								setState((s) => ({
									...s,
									adjusterContact: contact,
									insuranceCompany: company,
									isInsuranceJob: !!company,
								}));
							}}
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<CompanySelection
							title="Insurance/Client Company"
							companyId={state.insuranceCompany?.CompanyID}
							companyTypeIds={[378, 588]}
							error={!!validationErrors.insuranceCompany}
							onAddClick={() => { }}
							onSelected={company => {
								if (company) {
									setState((s) => ({
										...s,
										insuranceCompany: company,
										isInsuranceJob:
											company?.CompanyName !== 'PRIVATE',
									}));
								} else {
									handleStateChange(
										'insuranceCompany',
										undefined
									);
									setState((s) => ({
										...s,
										adjusterContact: undefined,
										insuranceCompany: undefined,
										isInsuranceJob: false,
									}));
								}
							}}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					wrap="wrap"
					justifyContent="space-around"
					sx={theme => ({ padding: theme.spacing(2.5, 1) })}
					spacing={2}
				>
					<Grid item style={{ flexGrow: 1 }}>
						<ContactSelection
							title="Agent"
							contactId={state.agentContact?.ContactID}
							contactTypeIds={[agentTypeId]}
							companyTypeIds={[agentCompanyTypeId]}
							onSelected={(contact) => {
								if (!contact) {
									setState((s) => ({
										...s,
										agentContact: undefined,
										insuranceCompany: undefined,
										isInsuranceJob: false,
									}));
								} else {
									const isVip = contact.fullname
										?.toLowerCase()
										.includes('vip');
									setState((s) => ({
										...s,
										agentContact: contact || undefined,
										TaskTemplateID: isVip
											? 7
											: s.TaskTemplateID,
									}));
								}
							}}
							error={!!validationErrors.agentContact}
						/>
					</Grid>

					<Grid item style={{ flexGrow: 1 }}>
						<ContactSelection
							title="Ind. Adjuster"
							contactId={state.iadjusterContact?.ContactID}
							contactTypeIds={[iadjusterTypeId]}
							companyTypeIds={[iaCompanyTypeId]}
							onSelected={(contact) => {
								handleStateChange('iadjusterContact', contact);
							}}
							error={!!validationErrors.iadjusterContact}
						/>
					</Grid>
				</Grid>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<TextField
						label="Policy Number"
						aria-label="Policy Number"
						title="Policy Number"
						value={state.InsPolicyNumber || ''}
						onChange={(e) =>
							handleStateChange('InsPolicyNumber', e.target.value)
						}
						InputLabelProps={{ shrink: true }}
						variant="outlined"
						margin="dense"
						sx={{ backgroundColor: '#ffffff' }}
						fullWidth
						error={!!validationErrors['InsPolicyNumber']}
					/>
				</Grid>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<TextField
						label="Claim Number"
						aria-label="Claim Number"
						title="Claim Number"
						value={state.InsClaimNumber || ''}
						onChange={(e) =>
							handleStateChange('InsClaimNumber', e.target.value)
						}
						InputLabelProps={{ shrink: true }}
						variant="outlined"
						margin="dense"
						sx={{ backgroundColor: '#ffffff' }}
						fullWidth
						error={!!validationErrors['InsClaimNumber']}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				direction="row"
				wrap="wrap"
				spacing={2}
				sx={theme => ({ padding: theme.spacing(0, 1), marginTop: 0 })}
			>
				<Grid
					item
					sx={{ flexGrow: 1, width: 'initial' }}
					style={{ maxWidth: '300px' }}
				>
					<TextField
						label="Deductible Amount"
						aria-label="Deductible Amount"
						title="Deductible Amount"
						value={state.InsDeductableAmount || ''}
						onChange={(e) =>
							handleStateChange(
								'InsDeductableAmount',
								`${parseInt(e.target.value, 10) || ''}`
							)
						}
						InputLabelProps={{ shrink: true }}
						variant="outlined"
						margin="dense"
						sx={{ backgroundColor: '#ffffff' }}
						fullWidth
						error={!!validationErrors['InsDeductableAmount']}
					/>
				</Grid>
				<Grid item sx={{ flexGrow: 1, width: 'initial' }}>
					<AutoCompleteLookup
						lookupType={LookupTypes.StormLog}
						label="Storm Event"
						placeholder="Storm Event"
						autoOpen
						error={!!validationErrors['StormID']}
						emptyOption={{ value: -1, label: 'None' }}
						value={state.StormID || null}
						onChange={(value) =>
							handleStateChange(
								'StormID',
								!!value && value > 0 ? value : undefined
							)
						}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
