import { TaskTemplateData } from '../core';

import { ApiClientInterface } from './client';

export default class TaskTemplatesApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
	}

	public async query(): Promise<TaskTemplateData[]> {
		const response = await this.client.get<TaskTemplateData[]>(`/taskTemplates`);
		return response;
	}
}
