import Icon from '@mdi/react';
import { SxProps, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { removeNonAlphaNumeric } from '../../core';

type CardAction = {
	key: string;
	label: string;
	onClick: () => void;
	icon: string;
	iconSize?: number;
	visible?: boolean;
};

type CardProps = {
	children: React.ReactNode;
	label: string;
	style?: React.CSSProperties;
	contentStyle?: React.CSSProperties;
	actions?: CardAction[];
	elevation?: number;
	light?: boolean;
	sx?: SxProps<Theme>
};

export const Card = (props: CardProps) => {

	const {
		actions = [],
		label,
		children,
		style,
		contentStyle,
		elevation = 2,
		light = false,
		sx = {},
	} = props;

	return (
		<MuiCard
			sx={theme => ({
				...(sx as any),
				display: 'flex',
				flexDirection: 'column',
				flexShrink: 0,
				borderRadius: 0,
				position: 'relative',
				boxShadow: 'none',
			})}
			style={style}
			elevation={elevation}
			id={removeNonAlphaNumeric(label).toLowerCase()}
		>
			<CardHeader
				sx={theme => ({
					padding: theme.spacing(1),
					'& span': {
						fontSize: theme.typography.body2.fontSize,
						fontWeight: 'bold',
					},
					textTransform: 'uppercase',
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
					'& .MuiCardHeader-action': {
						margin: 0,
					},
				})}
				action={
					<>
						{actions
							.filter((x) => x.visible !== false)
							.map((action) => (
								<Tooltip key={action.key} title={action.label}>
									<Button
										size="small"
										sx={theme => ({
											padding: theme.spacing(0, 1),
											color: theme.palette.primary.contrastText,
										})}
										area-label={action.label}
										onClick={action.onClick}
										variant="contained"
										color="secondary"
										endIcon={
											<Icon
												path={action.icon}
												size={action.iconSize || 1}
											/>
										}
										style={{ marginLeft: '8px' }}
									>
										{action.label}
									</Button>
								</Tooltip>
							))}
					</>
				}
				title={label}
			/>
			<CardContent
				sx={theme => ({
					backgroundColor: light ? '#fff' : 'rgba(0,0,0,0.08)',
					padding: theme.spacing(2),
					'& .MuiOutlinedInput-root': {
						backgroundColor: '#fff',
					},
					'&:last-child': {
						paddingBottom: theme.spacing(2),
					},
					'& p': {
						paddingLeft: theme.spacing(1),
						marginBlockStart: 0,
						marginBlockEnd: 0,
					},
				})}
				style={contentStyle || {}}
			>
				{children}
			</CardContent>
		</MuiCard>
	);
};
