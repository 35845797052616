import { ListAllOutput, list, uploadData, remove, getUrl } from 'aws-amplify/storage';
import { ThumbnailToken } from '../../core';
import { S3Object } from './types';

export const mapS3Object = (item: any): S3Object => {
    const key = item.key as string;
    if (key.endsWith('/')) {
        // Folder
        const segments = item.key.split('/');
        const name = segments[segments.length - 2];
        const obj: S3Object = {
            key: item.key,
            size: item.size || 0,
            name,
            lastModified: item.lastModified
                ? new Date(item.lastModified)
                : new Date('1900-01-01'),
            type: 'folder',
        };
        return obj;
    } else {
        const segments = item.key.split('/');
        const name = segments[segments.length - 1];
        const obj: S3Object = {
            key: item.key,
            size: item.size || 0,
            name,
            lastModified: item.lastModified
                ? new Date(item.lastModified)
                : new Date('1900-01-01'),
            type: 'file',
            isThumbnail: item.key.includes(ThumbnailToken),
        };
        return obj;
    }
}

export const mapS3Objects = (result: ListAllOutput): S3Object[] => {
    const results: S3Object[] = [];

    for (const item of result.items) {
        const obj = mapS3Object(item);
        results.push(obj);        
    }

    console.log('maps3objects', results)
    return results;
};

export class StorageProvider {
    public async list(path: string) {
        try {
            const response = await list({
                prefix: path,
                options: {
                    // accessLevel: 'private',
                    listAll: true,
                },
            });
            return mapS3Objects(response);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async put(path: string, body: any) {
        try {
            const response = await uploadData({
                key: path,
                data: body,
                options: {
                    // accessLevel: 'private',
                }
            });
            const result = await response.result;
            return mapS3Object(result);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async createFolder(path: string) {
        try {
            const response = await uploadData({
                key: path,
                data: new Blob(),
                options: {
                    // accessLevel: 'private',
                }
            });
            const result = await response.result;
            return mapS3Object(result);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async remove(path: string) {
        try {
            await remove({
                key: path,
                options: {
                    // accessLevel: 'private',
                }
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async getDownloadUrl(path: string, useThumbnail?: boolean) {
        let downloadKey = path;
        if (useThumbnail !== true) {
            downloadKey = path.replaceAll(ThumbnailToken, '');
        }
        try {
            const response = await getUrl({
                key: downloadKey,
                options: {
                    // accessLevel: 'private',
                }
            });
            return response.url.toString();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export const storage = new StorageProvider();