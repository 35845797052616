import { mdiAt } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { validateEmail } from '../../core';
import { useWindowOpen } from '../useWindowOpen';

type EmailProps = {
	label?: string;
	address: string | null | undefined;
	sx?: any;
	noError?: boolean;
};

export const EmailCard = (props: EmailProps) => {
	const {
		label = 'Email Address',
		address,
		sx = {},
		noError = false,
	} = props;
	const isValid = !address || noError ? true : validateEmail(address);
	const open = useWindowOpen();

	const handleOpen = () => {
		if (isValid && address) {
			open(`mailto:${address}`);
		}
	};

	return (
		<TextField
			aria-label={label}
			label={label}
			title={label}
			value={address || ''}
			onFocus={(e) => e.target.select()}
			error={!isValid}
			variant="outlined"
			margin="dense"
			sx={sx}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Tooltip title="Open Email">
							<span>
								<IconButton
									aria-label="Open Email"
									onClick={handleOpen}
									size="small"
									disabled={!isValid}
								>
									<Icon path={mdiAt} size={1} />
								</IconButton>
							</span>
						</Tooltip>
					</InputAdornment>
				),
			}}
		/>
	);
};
