import {
	mdiAccountCowboyHat,
	mdiAlertCircleOutline,
	mdiBellCircleOutline,
	mdiCheckCircleOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import {
	JobTaskWithJobData,
	formatDate,
	getDateFromString,
} from '../../../core';

const isPastDue = (dt?: Date | null): boolean => {
	if (!dt) return false;
	const val = getDateFromString(`${dt}`);
	if (!val) return false;
	return val.getTime() <= new Date().getTime();
};

const getIcon = (task: JobTaskWithJobData): string => {
	if (task.isComplete) {
		return mdiCheckCircleOutline;
	}
	if (isPastDue(task.TaskDueDate)) {
		return mdiAlertCircleOutline;
	}
	return mdiBellCircleOutline;
};

type Props = {
	task: JobTaskWithJobData;
	className?: string;
	style?: React.CSSProperties;
	mode?: 'table' | 'list';
	isEmployeeTasks: boolean;
	onCompleteTaskClick?: () => void;
	onReassignTaskClick?: () => void;
	isSelected?: boolean;
	forPrinting?: boolean;
};

export const JobTaskListItem = ({
	task,
	className,
	style = {},
	mode = 'list',
	isEmployeeTasks,
	onCompleteTaskClick,
	onReassignTaskClick,
	forPrinting = false,
}: Props) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const isOverDue = isPastDue(task.TaskDueDate);
	const hasMenu = !!onCompleteTaskClick || !!onReassignTaskClick;

	const handleClose = (event: MouseEvent | TouchEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	return (
		<Tooltip title={`${task.DamageDesc || ''}`}>
			<Grid
				container
				direction="column"
				sx={{
					cursor: 'pointer',
					display: 'flex',
					flexDirection: 'column',
					padding: theme.spacing(0.5, 2, 0, 2),
					borderBottom: '1px solid rgba(0,0,0,0.2)',
					paddingBottom: forPrinting === true ? theme.spacing(4) : undefined,
					marginBottom: forPrinting === true ? theme.spacing(2) : undefined,
					'&:hover': {
						'& $metadata': {
							opacity: 1,
						},
					},
					...style,
				}}
				onClick={() =>
					hasMenu
						? !task.isComplete
							? setOpen((prevOpen) => !prevOpen)
							: () => { }
						: undefined
				}
			>
				<Grid
					container
					direction="row"
					wrap="nowrap"
					alignItems="center"
					sx={{
						opacity: 0.7,
						'&:hover': {
							opacity: 1,
						},
					}}
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label="task menu"
					aria-haspopup="menu"
				>
					<Grid item ref={anchorRef} sx={{
						width: '30px',
						display: 'flex',
						alignItems: 'center',
					}}>
						<Icon
							path={getIcon(task)}
							size={0.8}
							color={
								task.isComplete
									? theme.palette.success.main
									: isOverDue
										? theme.palette.error.main
										: theme.palette.info.main
							}
						/>
					</Grid>
					{isEmployeeTasks === true && (
						<Grid item sx={{
							fontSize: theme.typography.caption.fontSize,
							fontWeight: 500,
							flexGrow: 1,
							whiteSpace: 'nowrap',
						}}>
							<strong>{task.JobNumber}</strong> {task.JobName}
						</Grid>
					)}
					{isEmployeeTasks !== true && (
						<Grid item sx={{
							fontSize: theme.typography.caption.fontSize,
							fontWeight: 500,
							flexGrow: 1,
							whiteSpace: 'nowrap',
						}}>
							{task.EmployeeNumber}
						</Grid>
					)}
					{mode === 'table' && (
						<Grid item sx={{
							fontSize: theme.typography.body2.fontSize,
							color: '#000000',
							justifyContent: 'flex-end',
						}}>
							<span>{task.TaskDescription}</span>
						</Grid>
					)}
					<Grid item sx={{
						fontSize: theme.typography.caption.fontSize,
						paddingLeft: theme.spacing(1),
						textAlign: 'right',
						minWidth: '90px',
					}}>
						{formatDate(task.TaskStartDate)}
					</Grid>
					<Grid
						item
						sx={{
							fontSize: theme.typography.caption.fontSize,
							paddingLeft: theme.spacing(1),
							textAlign: 'right',
							minWidth: '80px',
						}}
						style={{
							fontWeight: isOverDue ? 600 : 'unset',
							color: task.isComplete
								? '#555555'
								: isOverDue
									? theme.palette.error.main
									: !!task.TaskDueDate
										? theme.palette.info.main
										: '#555555',
						}}
					>
						{formatDate(task.TaskDueDate) || 'No Due Date'}
					</Grid>
					{isEmployeeTasks !== true && (
						<Grid
							item
							sx={{
								fontSize: theme.typography.caption.fontSize,
								paddingLeft: theme.spacing(1),
								textAlign: 'right',
								minWidth: '80px',
							}}
							style={{
								fontWeight: task.isComplete ? 600 : 'unset',
								color: task.isComplete
									? theme.palette.success.main
									: 'unset',
							}}
						>
							{formatDate(task.TaskCompletedDate) || 'Incomplete'}
						</Grid>
					)}
				</Grid>
				{forPrinting !== true && (					
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					style={{ zIndex: 9999 }}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === 'bottom'
										? 'center top'
										: 'center bottom',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu">
										<MenuItem onClick={onCompleteTaskClick}>
											<ListItemIcon
												style={{ minWidth: '32px' }}
											>
												<Icon
													path={mdiCheckCircleOutline}
													size={1}
												/>
											</ListItemIcon>
											<ListItemText primary="Mark Complete" />
										</MenuItem>

										<MenuItem onClick={onReassignTaskClick}>
											<ListItemIcon
												style={{ minWidth: '32px' }}
											>
												<Icon
													path={mdiAccountCowboyHat}
													size={1}
												/>
											</ListItemIcon>
											<ListItemText primary="Reassign" />
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
				)}
				{(mode !== 'table' || forPrinting === true) && (
					<Grid item sx={{
						fontSize: theme.typography.caption.fontSize,
						padding: theme.spacing(0.75, 0),
						color: '#000000',
					}}>
						<span>{task.TaskDescription}</span>
					</Grid>
				)}
				{(forPrinting === true) && (
					<Grid item sx={{
						fontSize: theme.typography.caption.fontSize,
						padding: theme.spacing(0.75, 0),
						color: '#000000',
					}}>
						<span>{task.DamageDesc}</span>
					</Grid>
				)}
			</Grid>
		</Tooltip>
	);
};
