import { mdiChevronDown, mdiChevronUp, mdiPlusCircleOutline } from '@mdi/js';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
	PrimaryEmailAddressData,
	validateEmail,
} from '../../core';
import { Card, ConfirmDialog, InfoDialog } from '../common';
import { AddEmailAddressDialog } from '../email/AddEmailAddressDialog';
import { EditEmailDialog } from '../email/EditEmailDialog';
import { useWindowOpen } from '../useWindowOpen';
import { EmailAddressListItem } from './EmailAddressListItem';
import { useTheme } from '@mui/material';

type ContactEmailListProps = {
	contactId: number;
	canEdit?: boolean;
};

type DialogAction = {
	title: string;
	text: string;
	action: () => void;
};

export const ContactEmailList = (props: ContactEmailListProps) => {
	const theme = useTheme();
	const { api } = useAppContext();
	const { contactId, canEdit } = props;
	const [emailAddresses, setEmailAddresses] = useState<
		PrimaryEmailAddressData[]
	>([]);
	const [confirm, setConfirm] = useState<DialogAction>();
	const [info, setInfo] = useState<DialogAction>();
	const [showAddEmail, setShowAddEmail] = useState(false);
	const [editEmail, setEditEmail] = useState<PrimaryEmailAddressData>();
	const [count, setCount] = useState(1);
	const open = useWindowOpen();

	const loadEmailAddresses = useCallback(() => {
		api.contact
			.queryEmailAddresses(contactId)
			.then((results) => {
				setEmailAddresses(results);
			})
			.catch(console.error);
	}, [api.contact, contactId]);

	useEffect(() => {
		loadEmailAddresses();
	}, [contactId, loadEmailAddresses]);

	const handleEmailAddressAdded = useCallback(
		(email: PrimaryEmailAddressData) => {
			setShowAddEmail(false);
			// setEmailAddresses((s) => {
			// 	const ns = [...s];
			// 	ns.push(email);
			// 	return orderBy(ns, ['isPrimary'], ['desc']);
			// });
			loadEmailAddresses();
		},
		[loadEmailAddresses]
	);

	const handleOnEdit = (data: PrimaryEmailAddressData) => {
		setEditEmail(data);
	};

	const handleSaveEmailAddress = async (data: PrimaryEmailAddressData) => {
		try {
			await api.contact.updateEmailAddress(contactId, data);
			loadEmailAddresses();
		} catch (error) {
			console.error(error);
		} finally {
			setEditEmail(undefined);
		}
	};

	const handleSetPrimary = useCallback(
		(data: PrimaryEmailAddressData) => {
			if (!data.isPrimary) {
				setConfirm({
					title: 'Set Primary Email Address',
					text: `Are you sure you want to set '${data.EmailAddress}' as the primary email address?`,
					action: () => {
						api.contact
							.setPrimaryEmailAddress(contactId, data.EmailID)
							.then(() => {
								setConfirm(undefined);
								loadEmailAddresses();
							})
							.catch((error) => {
								setConfirm(undefined);
								console.error(error);
							});
					},
				});
			}
		},
		[api.contact, contactId, loadEmailAddresses]
	);

	const handleOnRemove = useCallback(
		(data: PrimaryEmailAddressData) => {
			if (!data.isPrimary) {
				setConfirm({
					title: 'Remove Email Address?',
					text: `Are you sure you want to remove '${data.EmailAddress}' from this contact?`,
					action: () => {
						api.contact
							.removeEmailAddresses(contactId, data.EmailID)
							.then(() => {
								setConfirm(undefined);
								loadEmailAddresses();
							})
							.catch((error) => {
								setConfirm(undefined);
								console.error(error);
							});
					},
				});
			} else {
				setInfo({
					title: 'Cannot Remove the Primary Email Address',
					text: 'Please set a different email address as the primary before removing this one.',
					action: () => setInfo(undefined),
				});
			}
		},
		[api.contact, contactId, loadEmailAddresses]
	);

	const handleOnOpen = (data: PrimaryEmailAddressData) => {
		if (validateEmail(data.EmailAddress)) {
			open(`mailto:${data.EmailAddress}`);
		} else {
			setInfo({
				title: 'Invalid',
				text: 'The email address is invalid',
				action: () => setInfo(undefined),
			});
		}
	};

	return (
		<Card
			label={`Email Addresses (${emailAddresses.length})`}
			actions={[
				{
					key: 'addemailaddress',
					label: 'Add Email Address',
					icon: mdiPlusCircleOutline,
					onClick: () => setShowAddEmail(true),
					visible: canEdit,
				},
				{
					key: 'collapse',
					label: 'Show Primary Only',
					icon: mdiChevronUp,
					onClick: () => setCount(1),
					visible: emailAddresses.length > 1,
				},
				{
					key: 'expand',
					label: 'Expand All',
					icon: mdiChevronDown,
					onClick: () => setCount(emailAddresses.length),
					visible: emailAddresses.length > 1,
				},
			]}
			sx={theme => ({ margin: theme.spacing(1, 0, 0, 0) })}
		>
			{emailAddresses.length === 0 && <p>None</p>}
			{emailAddresses.slice(0, count).map((email, index) => (
				<EmailAddressListItem
					canEdit={canEdit}
					key={email.EmailID}
					emailAddress={email}
					onSetPrimary={(addy) =>
						handleSetPrimary({ ...email, ...addy })
					}
					onRemove={(addy) => handleOnRemove({ ...email, ...addy })}
					onEdit={handleOnEdit}
					onOpen={handleOnOpen}
					sx={{
						backgroundColor: index % 2 ? 'transparent' : 'rgba(0, 0, 0, 0.1)',
						padding: theme.spacing(1, 0.5),
					}}
				/>
			))}
			{!!confirm && (
				<ConfirmDialog
					isOpen={true}
					title={confirm.title}
					question={confirm.text}
					onCancel={() => setConfirm(undefined)}
					onConfirm={() => confirm.action()}
				/>
			)}
			{!!info && (
				<InfoDialog
					isOpen={true}
					title={info.title}
					text={info.text}
					onClose={() => info.action()}
				/>
			)}
			{showAddEmail && (
				<AddEmailAddressDialog
					contactId={contactId}
					onEmailAddressAdded={handleEmailAddressAdded}
					onClose={() => setShowAddEmail(false)}
				/>
			)}
			{!!editEmail && (
				<EditEmailDialog
					isOpen={true}
					emailAddress={editEmail}
					onCancel={() => setEditEmail(undefined)}
					onSaveChanges={handleSaveEmailAddress}
				/>
			)}
		</Card>
	);
};
