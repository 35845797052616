import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import { isEqual } from 'lodash';
import { useState } from 'react';
import { useDebounce } from 'react-use';
import { CompanyData, LookupTypes } from '../../core';
import { AutoCompleteLookup, BottomSpace, EmployeeDropDown } from '../common';

const fields = [
	{ key: 'SalesRepEmp1', label: 'RC / Estimator' },
	{ key: 'SalesRepEmp2', label: 'Marketer' },
	{ key: 'SalesRepEmp3', label: 'Sales Rep 3' },
	{ key: 'SalesRepEmp4', label: 'Sales Rep 4' },
	{ key: 'SalesRepEmp5', label: 'Sales Rep 5' },
];

type ContactSalesRepsProps = {
	canEdit?: boolean;
	company?: CompanyData;
	onChange: (data: Partial<CompanyData>) => void;
};

export const CompanySalesReps = (props: ContactSalesRepsProps) => {
	const { company, onChange, canEdit } = props;
	const [changed, setChanged] = useState<Partial<CompanyData>>(company || {});

	useDebounce(
		() => {
			if (!isEqual(company, changed)) {
				onChange(changed);
			}
		},
		1000,
		[changed]
	);

	if (!company) return null;

	return (
		<Box sx={theme => ({
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			padding: theme.spacing(1),
			height: '100%',
			overflowX: 'hidden',
			overflowY: 'auto',
			marginTop: theme.spacing(1),
		})}>
			{fields.map((item) => (
				<EmployeeDropDown
					key={item.key}
					label={item.label}
					placeholder={item.label}
					emptyOption={{ value: '', label: 'None' }}
					value={(changed as any)[item.key] || null}
					disabled={!canEdit}
					onChange={(value) =>
						setChanged({
							...changed,
							[item.key]: value || null,
						})
					}
					filter={(employees) =>
						employees.filter((x) => x.isActive && x.usr9)
					}
				/>
			))}
			<Divider />
			<AutoCompleteLookup
				label="Grade"
				placeholder="Grade"
				lookupType={LookupTypes.CompanyType}
				value={changed.GradeID || null}
				disabled={!canEdit}
				onChange={(value) => {
					setChanged({
						...changed,
						GradeID: value || undefined,
					});
				}}
			/>
			<BottomSpace />
		</Box>
	);
};
