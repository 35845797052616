import React, { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { MarketingTaskData } from '../../core';
import { ConfirmDialog, Container } from '../common';
import { MarketingTaskListItem } from './MarketingTaskListItem';
import { useAppContext } from '../../contexts/AppContext';
import { AddMarketingTaskDialog } from './AddMarketingTaskDialog';
import { ApplicationEvent } from '../ApplicationEvent';

type ContactTaskListProps = {
	contactId: number;
	onCountChanged: (count: number) => void;
	show: boolean;
};

export const ContactTaskList = (props: ContactTaskListProps) => {
	const { api } = useAppContext();
	const { contactId, onCountChanged, show } = props;
	const [tasks, setTasks] = useState<MarketingTaskData[]>([]);
	const [completeTaskId, setCompleteTaskId] = useState<number>();
	const [showMarketingTaskDialog, setShowMarketingTaskDialog] =
		useState(false);

	const handleLoadTasks = React.useCallback(
		async (id: number) => {
			try {
				const data = await api.contact.queryMarketingTasks(id);
				onCountChanged(data.length);
				setTasks(data);
			} catch (error) {
				console.error(error);
			}
		},
		[api.contact, onCountChanged]
	);

	const handleCompleteTask = async () => {
		if (!!contactId && !!completeTaskId) {
			try {
				await api.contact.completeTask(contactId, completeTaskId);
				handleLoadTasks(contactId);
				setCompleteTaskId(undefined);
				ApplicationEvent.ReloadMyTasks();
			} catch (error) {
				console.error(error);
				throw error;
			}
		}
	};

	useEffect(() => {
		handleLoadTasks(contactId);
	}, [contactId, handleLoadTasks]);

	const handleTaskAdded = (task: MarketingTaskData) => {
		handleLoadTasks(contactId);
		setShowMarketingTaskDialog(false);
	};

	if (!show) return null;

	return (
		<>
			<Toolbar variant="dense">
				<span style={{ flexGrow: 1 }} />
				<Button
					size="small"
					color="secondary"
					onClick={() => setShowMarketingTaskDialog(true)}
				>
					Add Marketing Task
				</Button>
			</Toolbar>
			<Divider />
			<Container component="ContactJobList" scrollable>
				{tasks.map((item) => (
					<MarketingTaskListItem
						key={item.MarketingTaskID}
						task={item}
						isEmployeeTasks={false}
						onCompleteTaskClick={() =>
							setCompleteTaskId(item.MarketingTaskID)
						}
						mode="list"
					/>
				))}
			</Container>
			{!!contactId && showMarketingTaskDialog && (
				<AddMarketingTaskDialog
					contactId={contactId}
					onClose={() => setShowMarketingTaskDialog(false)}
					onTaskAdded={handleTaskAdded}
				/>
			)}
			<ConfirmDialog
				isOpen={!!completeTaskId}
				onCancel={() => setCompleteTaskId(undefined)}
				onConfirm={handleCompleteTask}
				title="Complete Task"
				question="Are you sure you want to complete this task?"
			/>
		</>
	);
};
