import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, TextField, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState } from 'react';
import { Loading } from '../common';

export type LoginFormProps = {
	signin: (username: string, password: string) => void;
	message?: string;
	busy: boolean;
	show: boolean;
};

export const Authenticate = (props: LoginFormProps) => {
	const theme = useTheme();
	const { signin, message, busy, show } = props;
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const canLogin = !!username && !!password;

	const handleLogin = async () => {
		signin(username, password);
	};

	const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (canLogin && e.key === 'Enter') {
			handleLogin();
		}
	};

	return (
		<Box
			sx={{
				flexDirection: 'column',
				flexGrow: 1,
				position: 'relative',
				height: '100%',
				display: show === true ? 'flex' : 'none',
			}}
		>
			<header className="authenticate">
				<img src="/logo.png" width="100" height="100" alt="rLinc" />
				<h1>rLinc</h1>
				<p>
					<span>Database solutions</span>{' '}
					<span>for your restoration team.</span>
				</p>
			</header>
			{!!busy && <Loading />}
			{!busy && message && (
				<section style={{
					display: 'flex',
					flexDirection: 'column',
					position: 'relative',
					justifyContent: 'flex-start',
					margin: 'auto',
					padding: theme.spacing(2),
				}}>{message}</section>
			)}
			{!busy && (
				<section style={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					position: 'relative',
					justifyContent: 'flex-start',
					minWidth: '300px',
					maxWidth: '700px',
					margin: 'auto',
				}}>
					<Grid
						container
						spacing={2}
						direction="column"
						justifyContent="center"
						alignItems="center"
						alignContent="center"
						sx={theme => ({
							marginTop: theme.spacing(2),
							border: '1px solid #cccccc',
							padding: theme.spacing(2),
							backgroundColor: '#ffffff',
						})}
					>
						<Grid item style={{ width: '100%' }}>
							<TextField
								label="Username"
								InputLabelProps={{ shrink: true }}
								fullWidth
								margin="dense"
								variant="outlined"
								value={username}
								autoComplete="username"
								onChange={(e) =>
									setUsername(
										e.target.value?.toLowerCase() || ''
									)
								}
								onFocus={(e) => e.target.select()}
								onKeyPress={handleOnKeyPress}
							/>
						</Grid>
						<Grid item style={{ width: '100%' }}>
							<TextField
								label="Password"
								InputLabelProps={{ shrink: true }}
								fullWidth
								margin="dense"
								variant="outlined"
								autoComplete="current-password"
								value={password}
								onChange={(e) =>
									setPassword(e.target.value || '')
								}
								onFocus={(e) => e.target.select()}
								onKeyPress={handleOnKeyPress}
								type={!showPassword ? 'password' : 'text'}
								InputProps={{
									endAdornment:
										<InputAdornment position="end">
											<IconButton
												size="small"
												aria-label="toggle password visibility"
												onClick={() =>
													setShowPassword(
														!showPassword
													)
												}
												onMouseDown={(e) =>
													e.preventDefault()
												}
											>
												<Icon
													path={
														showPassword
															? mdiEyeOutline
															: mdiEyeOffOutline
													}
													size={1}
												/>
											</IconButton>
										</InputAdornment>
								}}
							/>
						</Grid>
						<Grid item sx={theme => ({ marginTop: theme.spacing(3) })}>
							<Button
								variant="contained"
								color="secondary"
								disabled={!canLogin}
								onClick={handleLogin}
							>
								Login
							</Button>
						</Grid>
					</Grid>
				</section>
			)}
		</Box>
	);
};
