import { mdiCardAccountMailOutline, mdiDomain } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

type NameVariant = 'company' | 'contact';
type NameCardProps = {
	label?: string | null | undefined;
	name?: string | null | undefined;
	variant?: NameVariant;
	onClick?: () => void;
	sx: any;
};

export const NameCard = (props: NameCardProps) => {
	const { label, name, variant, onClick, sx = {} } = props;
	const icon = variant === 'company' ? mdiDomain : mdiCardAccountMailOutline;

	return (
		<TextField
			label={label}
			aria-label={name || ''}
			title={name || ''}
			value={name || ''}
			onFocus={(e) => e.target.select()}
			variant="outlined"
			margin="dense"
			InputLabelProps={{
				sx: {
					'&[data-shrink=false]': {
						color: 'rgba(0,0,0,0.2)',
					},
					...sx,
				},
				shrink: true,
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Tooltip title="Open">
							<span>
								<IconButton
									aria-label="Open"
									size="small"
									onClick={onClick}
								>
									<Icon path={icon} size={1} />
								</IconButton>
							</span>
						</Tooltip>
					</InputAdornment>
				),
			}}
		/>
	);
};
