import { mdiClose, mdiPin, mdiPinOff } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from '@mui/material/IconButton';
import { useAppContext } from '../../contexts/AppContext';
import { AddSidebar } from '../add';
import { AdminSidebar } from '../admin';
import { AuthSidebar } from '../auth';
import { Toolbar } from '../common';
import { CompanyList } from '../companies';
import { ContactList } from '../contacts';
import { JobsList } from '../jobs';
import { RecentItemsSidebar } from '../recent';
import { ReportsSidebar } from '../reports';
import { EmployeeTasks } from '../tasks/EmployeeTasks';
import { SidebarNavigation } from './SidebarNavigation';
import { NavigationItems } from './constants';

type SidebarProps = {
	title: string;
	pinned: boolean;
	selected: string;
	onNavigate: (url: string) => void;
	onNavigateKey?: (key: string) => void;
	onClick: (key: string) => void;
	onClose: () => void;
	onTogglePin: () => void;
};

export const Sidebar = ({
	selected,
	title,
	pinned,
	onClose,
	onTogglePin,
	onNavigate,
	onNavigateKey,
}: SidebarProps) => {
	const { isWideLayout } = useAppContext();

	return (
		<>
			<Toolbar>
				{title}
				<span style={{ flexGrow: 1 }} />
				{isWideLayout && (
					<IconButton
						size="small"
						aria-label="Toggle Pinned"
						onClick={onTogglePin}
					>
						<Icon path={pinned ? mdiPin : mdiPinOff} size={0.8} />
					</IconButton>
				)}
				<IconButton
					size="small"
					edge="end"
					aria-label="Close Sidebar"
					onClick={onClose}
				>
					<Icon path={mdiClose} size={0.8} />
				</IconButton>
			</Toolbar>
			<RecentItemsSidebar
				show={selected === NavigationItems.RECENT}
				onNavigate={onNavigate}
			/>
			<AddSidebar
				show={selected === NavigationItems.ADD}
				onClose={onClose}
			/>
			<EmployeeTasks
				show={selected === NavigationItems.TASKS}
				onNavigate={onNavigate}
			/>
			<JobsList
				show={selected === NavigationItems.JOBS}
				onNavigate={onNavigate}
			/>
			<ContactList
				show={selected === NavigationItems.CONTACTS}
				onNavigate={onNavigate}
			/>
			<CompanyList
				show={selected === NavigationItems.COMPANIES}
				onNavigate={onNavigate}
			/>
			<ReportsSidebar show={selected === NavigationItems.REPORTS} />
			<AdminSidebar show={selected === NavigationItems.ADMIN} />
			<AuthSidebar show={selected === NavigationItems.SETTINGS} />
			{selected === '' && (
				<SidebarNavigation
					onNavigateKey={onNavigateKey || (() => { })}
					onClose={onClose}
				/>
			)}
		</>
	);
};
