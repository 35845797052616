import React from 'react';

// Components
import { FlexColumn } from '../common';
import { S3Files } from './S3Files';
import { S3Folders } from './S3Folders';
import { S3Toolbar } from './S3Toolbar';
import {
	FileOpenedEvent,
	FolderChangedEvent,
	S3BrowserView,
	S3Object,
	ToggleViewEvent,
} from './types';

type Props = {
	folder: string;
	view: S3BrowserView;
	fileFilter: (objects: S3Object[]) => S3Object[];
	disableViewToggle?: boolean;
	disableGalleryButton?: boolean;
	readOnly?: boolean;
	canDelete?: boolean;
	onToggleView?: ToggleViewEvent;
	onChangeFolder?: FolderChangedEvent;
	onFileOpen?: FileOpenedEvent;
};

export const S3FileBrowser = ({
	folder,
	view,
	fileFilter,
	disableViewToggle,
	disableGalleryButton,
	readOnly,
	canDelete,
	onToggleView = () => {},
	onChangeFolder = () => {},
	onFileOpen = () => {},
}: Props) => {
	return (
		<FlexColumn fill>
			<S3Toolbar
				readOnly={readOnly}
				canDelete={canDelete}
				folder={folder}
				view={view}
				disableViewToggle={disableViewToggle}
				disableGalleryButton={disableGalleryButton}
				onToggleView={onToggleView}
				onChangeFolder={onChangeFolder}
			/>
			<FlexColumn scroll>
				<S3Folders
					readOnly={readOnly}
					canDelete={canDelete}
					folder={folder}
					onChangeFolder={onChangeFolder}
				/>
				<S3Files
					readOnly={readOnly}
					canDelete={canDelete}
					folder={folder}
					view={view}
					fileFilter={fileFilter}
					onFileOpen={onFileOpen}
				/>
			</FlexColumn>
		</FlexColumn>
	);
};
