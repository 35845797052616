import {
	mdiAccountMultiple,
	mdiCalendarCheck,
	mdiCameraOutline,
	mdiFormTextbox,
	mdiNoteOutline,
	mdiPaperclip,
} from '@mdi/js';
import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useAppContext } from '../../../contexts/AppContext';
import { JobDetail } from '../../../core';
import { ToolbarButton } from '../../common/ToolbarButton';
import { getJobStatus } from '../getJobStatus';
import { NavSectionBar } from './NavSectionBar';

type JobTitleProps = {
	job: JobDetail;
	taskCount: number;
	noteCount: number;
	contactCount: number;
	imageCount: number;
	fileCount: number;
	onRelatedItemClick: (content: string) => void;
};

export const JobTitle = (props: JobTitleProps) => {
	const theme = useTheme();
	const { isWideLayout } = useAppContext();
	const {
		job,
		onRelatedItemClick,
		taskCount,
		noteCount,
		contactCount,
		imageCount,
		fileCount,
	} = props;

	const status = getJobStatus(job);

	if (!job || !status) return null;
	const repairProgram =
		job.RepairProgramName === 'None' ? '' : job.RepairProgramName || '';

	return (
		<ThemeProvider theme={status.theme}>
			<AppBar
				position="relative"
				sx={{
					zIndex: theme.zIndex.drawer + 1,
					'& h6': {
						marginRight: theme.spacing(2),
					},
					transition: theme.transitions.create(['margin', 'width'], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
				}}
				color="primary"
			>
				<Toolbar>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
					}}>
						<Box sx={{ fontSize: theme.typography.h6.fontSize }}>
							<strong>{job?.JobNumber}</strong>
							<Hidden xsDown> - {job?.JobName}</Hidden>
							{repairProgram && (
								<Hidden mdDown> - {repairProgram}</Hidden>
							)}
						</Box>
						<Hidden xsDown>
							<Box sx={{ fontSize: theme.typography.body2.fontSize }}>
								<strong>{status.phase}</strong> -{' '}
								{status.status}
							</Box>
						</Hidden>
					</Box>
					{!isWideLayout && <span className="flex" />}
					{isWideLayout && (
						<NavSectionBar
							jobId={job?.JobID}
							onClose={() => onRelatedItemClick('')}
							transparent
						/>
					)}
					<ToolbarButton
						title="Tasks"
						tooltip="Job Tasks"
						count={taskCount}
						iconPath={mdiCalendarCheck}
						onClick={() => onRelatedItemClick('Tasks')}
					/>
					<ToolbarButton
						title="Notes"
						tooltip="Job Notes"
						count={noteCount}
						iconPath={mdiNoteOutline}
						onClick={() => onRelatedItemClick('Notes')}
					/>
					<ToolbarButton
						title="Contacts"
						tooltip="Contacts"
						count={contactCount}
						iconPath={mdiAccountMultiple}
						onClick={() => onRelatedItemClick('Contacts')}
					/>
					<ToolbarButton
						title="Images"
						tooltip="Images"
						count={imageCount}
						iconPath={mdiCameraOutline}
						onClick={() => onRelatedItemClick('Images')}
					/>
					<ToolbarButton
						title="Files"
						tooltip="Files"
						count={fileCount}
						iconPath={mdiPaperclip}
						onClick={() => onRelatedItemClick('Files')}
					/>
					<ToolbarButton
						title="Other"
						tooltip="Generate Forms &amp; Custom Fields"
						count={0}
						iconPath={mdiFormTextbox}
						onClick={() => onRelatedItemClick('Other')}
					/>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
};
