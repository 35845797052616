import { GenerateS3FormRequest } from '../core';

import { ApiClientInterface } from './client';

export default class TemplatesApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.generateForm = this.generateForm.bind(this);
		this.generateInvoice = this.generateInvoice.bind(this);
	}

	public async generateForm(
		jobId: number,
		request: GenerateS3FormRequest
	): Promise<string> {
		const response = await this.client.post<GenerateS3FormRequest, string>(
			`/templates/form/${jobId}`,
			request
		);
		return response;
	}

	public async generateInvoice(
		jobId: number,
		request: GenerateS3FormRequest
	): Promise<string> {
		const response = await this.client.post<GenerateS3FormRequest, string>(
			`/templates/invoice/${jobId}`,
			request
		);
		return response;
	}
}
