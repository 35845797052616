import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { useAppContext } from '../../contexts/AppContext';
import {
	ContactViewData,
	LookupTypes,
	PrimaryStreetAddressData,
	StreetAddressData,
} from '../../core';
import { AddressCard, SelectAddressDialog } from '../addresses';
import { BottomSpace, Card, ErrorMessage, Loading, Toolbar } from '../common';
import { useLookupType } from '../lookups/useLookupType';
import { useRecentItems } from '../recent/useRecentItems';
import ContactSelection from "../selection/ContactSelection";
import { Assignment } from './Assignment';
import { Insurance } from './Insurance';
import { Job } from './Job';
import { Lead } from './Lead';
import { Referral } from './Referral';
import {
	AddLeadState,
	getValidationErrors,
	initialLeadState,
	mapAddLead,
} from './shared';

const findAddress = (
	addresses: StreetAddressData[],
	text: string,
	type: number
): StreetAddressData | undefined => {
	return addresses.find(
		(x) =>
			(!!x.AddressName && x.AddressName.toLowerCase().includes(text)) ||
			x.AddressTypeID === type
	);
};

export const AddLeadContainer = () => {
	const { api, user } = useAppContext();
	const { addRecentJob } = useRecentItems();
	const navigate = useNavigate();
	const [state, setState] = useState<AddLeadState>({
		...initialLeadState,
		LeadTaker: user?.EmployeeNumber,
	});
	const [selectAddressType, setSelectAddressType] = useState('');
	const [validationErrors, setValidationErrors] = useState<
		Record<string, string>
	>({});
	const [error, setError] = useState<any>();
	const [working, setWorking] = useState(false);

	const adjusterCustomerTypeId = useLookupType(
		LookupTypes.ContactType,
		'Adjuster/Customer'
	).ID;
	const customerTypeId = useLookupType(
		LookupTypes.ContactType,
		'Customer'
	).ID;
	const propertyManagerTypeId = useLookupType(
		LookupTypes.ContactType,
		'Property Manager'
	).ID;
	const customerCompanyTypeId = useLookupType(
		LookupTypes.CompanyType,
		'Property Manager'
	).ID;

	const findAddresses = async (
		contact: Partial<ContactViewData>
	): Promise<any> => {
		return api.address
			.query({
				parentId: contact.ContactID,
				parentType: 'Contact',
				limit: 25,
			})
			.then((addresses) => {
				if (addresses && addresses.length === 1) {
					return [addresses[0], addresses[0]];
				} else if (addresses && addresses.length > 1) {
					const jobsite = findAddress(addresses, 'jobsite', 589);
					const billing = findAddress(addresses, 'bill', 590);
					return [jobsite, billing];
				} else {
					return [undefined, undefined];
				}
			})
			.catch(console.error);
	};

	useDebounce(
		() => {
			setValidationErrors(getValidationErrors(state));
		},
		1000,
		[state]
	);

	const handleSelectAddress = useCallback(
		(address: Partial<PrimaryStreetAddressData>) => {
			if (selectAddressType === 'Billing') {
				setState((s) => ({ ...s, billingAddress: address }));
			} else {
				setState((s) => ({ ...s, jobsiteAddress: address }));
			}
			setSelectAddressType('');
		},
		[selectAddressType]
	);

	const handleCreateLead = async () => {
		setWorking(true);
		try {
			const { JobID } = await api.lead.add(mapAddLead(state));
			const job = await api.job.get(JobID);
			addRecentJob({
				id: job.JobID,
				name: job.JobName,
				number: job.JobNumber,
				desc: job.DamageDesc,
			});
			navigate(`/jobs/${job.JobID}`);
		} catch (err) {
			setError(err);
		} finally {
			setWorking(false);
		}
	};

	const canCreateLead = Object.keys(validationErrors).length === 0;

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			height: '100%',
			overflow: 'hidden',
		}}>
			<Toolbar>
				<h4 style={{ flexGrow: 1 }}>Add New Lead</h4>
				<Button
					variant="contained"
					size="small"
					onClick={() => navigate(-1)}
					sx={{ marginRight: '20px' }}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="secondary"
					size="small"
					disabled={!canCreateLead}
					onClick={handleCreateLead}
				>
					Create Lead
				</Button>
			</Toolbar>
			{working && <Loading />}
			{!!error && !working && (
				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					height: '100%',
					overflow: 'hidden',
					overflowY: 'auto',
				}}>
					<ErrorMessage
						error={error}
						onRetry={() => setError(undefined)}
					/>
				</Box>
			)}
			{!error && !working && (
				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					height: '100%',
					overflow: 'hidden',
					overflowY: 'auto',
				}}>
					<Card light label="Lead Information" sx={theme => ({ margin: theme.spacing(2, 0) })}>
						<Grid
							container
							direction="row"
							wrap="wrap"
							// className={classes.content}
							spacing={1}
						>
							<Lead
								state={state}
								setState={setState}
								validationErrors={validationErrors}
							/>
						</Grid>
					</Card>
					<Card light label="Customer" sx={theme => ({ margin: theme.spacing(2, 0) })}>
						<Grid
							container
							direction="row"
							wrap="wrap"
							justifyContent="space-around"
							// className={classes.customer}
							spacing={2}
						>
							<Grid item sx={{ flexGrow: 1 }}>
								<ContactSelection
									title="Customer"
									companyTitle="PM"
									contactId={state.customerContact?.ContactID}
									contactTypeIds={[customerTypeId, adjusterCustomerTypeId, propertyManagerTypeId]}
									companyTypeIds={[customerCompanyTypeId]}
									onSelected={async (contact) => {
										if (!contact) {
											setState((s) => ({
												...s,
												customerContact: undefined,
												jobsiteAddress: undefined,
												billingAddress: undefined,
											}));
										} else {
											const [jobsite, billing] = !!contact
												? await findAddresses(contact)
												: [undefined, undefined];
											setState((s) => ({
												...s,
												customerContact:
													contact || undefined,
												jobsiteAddress: jobsite,
												billingAddress: billing,
											}));
										}
									}}
									error={!!validationErrors.customerContact}
								/>
							</Grid>

							<Grid item sx={{ flexGrow: 1 }}>
								<AddressCard
									height={200}
									label="Jobsite Address"
									name={state.jobsiteAddress?.AddressName}
									line1={state.jobsiteAddress?.AddressLine1}
									line2={state.jobsiteAddress?.AddressLine2}
									city={state.jobsiteAddress?.City}
									state={state.jobsiteAddress?.State}
									zip={state.jobsiteAddress?.ZIP}
									typeId={state.jobsiteAddress?.AddressTypeID}
									note={state.jobsiteAddress?.Note}
									onSelectAddress={() =>
										setSelectAddressType('Jobsite')
									}
									onClearAddress={() =>
										setState((s) => ({
											...s,
											jobsiteAddress: undefined,
										}))
									}
									onCopyAddress={() =>
										setState((s) => ({
											...s,
											jobsiteAddress: {
												...state.billingAddress,
											},
										}))
									}
									copyButtonText="Same as billing"
									disabled={!state.customerContact?.ContactID}
									error={!!validationErrors.jobsiteAddress}
								/>
							</Grid>
							<Grid item style={{ flexGrow: 1 }}>
								<AddressCard
									height={200}
									label="Billing Address"
									name={state.billingAddress?.AddressName}
									line1={state.billingAddress?.AddressLine1}
									line2={state.billingAddress?.AddressLine2}
									city={state.billingAddress?.City}
									state={state.billingAddress?.State}
									zip={state.billingAddress?.ZIP}
									typeId={state.billingAddress?.AddressTypeID}
									note={state.billingAddress?.Note}
									onSelectAddress={() =>
										setSelectAddressType('Billing')
									}
									onClearAddress={() =>
										setState((s) => ({
											...s,
											billingAddress: undefined,
										}))
									}
									onCopyAddress={() =>
										setState((s) => ({
											...s,
											billingAddress: {
												...state.jobsiteAddress,
											},
										}))
									}
									copyButtonText="Same as Jobsite"
									disabled={!state.customerContact?.ContactID}
									error={!!validationErrors.billingAddress}
								/>
							</Grid>
						</Grid>
					</Card>
					<Card
						light
						label="Insurance/Client Information"
						sx={theme => ({ margin: theme.spacing(2, 0) })}
					>
						<Grid
							container
							direction="row"
							wrap="wrap"
							spacing={1}
						>
							<Insurance
								state={state}
								setState={setState}
								validationErrors={validationErrors}
							/>
						</Grid>
					</Card>
					<Card light label="Job Information" sx={theme => ({ margin: theme.spacing(2, 0) })}>
						<Grid
							container
							direction="row"
							wrap="wrap"
							// className={classes.content}
							spacing={1}
						>
							<Job
								state={state}
								setState={setState}
								validationErrors={validationErrors}
							/>
						</Grid>
					</Card>
					<Card
						light
						label="Referral / Stakeholders"
						sx={theme => ({ margin: theme.spacing(2, 0) })}
					>
						<Grid
							container
							direction="row"
							wrap="wrap"
							// className={classes.content}
							spacing={1}
						>
							<Referral
								state={state}
								setState={setState}
								validationErrors={validationErrors}
							/>
						</Grid>
					</Card>
					<Card light label="Lead Assignment" sx={theme => ({ margin: theme.spacing(2, 0) })}>
						<Grid
							container
							direction="row"
							wrap="wrap"
							spacing={1}
						>
							<Assignment
								state={state}
								setState={setState}
								validationErrors={validationErrors}
							/>
						</Grid>
					</Card>
					<BottomSpace />
				</Box>
			)}
			{!!selectAddressType && state.customerContact?.ContactID && (
				<SelectAddressDialog
					parentId={state.customerContact.ContactID}
					parentType="Contact"
					onClose={() => setSelectAddressType('')}
					onSelectAddress={handleSelectAddress}
				/>
			)}
		</Box>
	);
};
