import Grid from '@mui/material/Grid';
import { useAppSelector } from '../../store';
import { AutoComplete, DatePicker } from '../common';
import { selectActiveEmployees } from '../employees';
import { ComponentProps } from './shared';

export const Lead = (props: ComponentProps) => {
	const employees = useAppSelector(selectActiveEmployees);
	const { state, setState, validationErrors } = props;

	const options = employees.map((item: any) => ({
		label: item?.EmployeeName || '',
		value: item?.EmployeeNumber || '',
	}));

	const handleStateChange = (
		key: string,
		value: string | boolean | number | object | null | undefined
	) => {
		setState((s) => ({ ...s, [key]: value }));
	};

	return (
		<Grid container direction="row" wrap="wrap" spacing={2}>
			<Grid item sx={theme => ({
				flexGrow: 1,
				width: 'initial',
				alignItems: 'center',
			})}>
				<AutoComplete
					label="Lead Taken By"
					placeholder="Lead Taken By"
					autoOpen
					value={state.LeadTaker || null}
					onChange={(value) =>
						handleStateChange('LeadTaker', value || null)
					}
					required
					options={options}
				/>
			</Grid>
			<Grid item style={{ minWidth: '200px' }}>
				<DatePicker
					label="Lead Date"
					value={state.LeadDate}
					required
					onChange={(value) => handleStateChange('LeadDate', value)}
					error={!!validationErrors.LeadDate}
					style={{ backgroundColor: '#ffffff' }}
				/>
			</Grid>
			<Grid item style={{ minWidth: '200px' }}>
				<DatePicker
					label="Date Of Loss"
					required
					value={state.DateofLoss}
					onChange={(value) => handleStateChange('DateofLoss', value)}
					error={!!validationErrors.DateofLoss}
					style={{ backgroundColor: '#ffffff' }}
				/>
			</Grid>
		</Grid>
	);
};
