import Grid from '@mui/material/Grid';
import { JobData, JobDetail, LookupTypes, User } from '../../../core';
import { AutoCompleteLookup, Card, EmployeeDropDown } from '../../common';

const rows = [
	{
		left: { key: 'usr1', label: 'EST' },
		right: { key: 'usr2', label: 'PM' },
	},
	{
		left: { key: 'usr3', label: 'LEVEL5' },
		right: { key: 'usr4', label: 'LEAD' },
	},
	{
		left: { key: 'usr5', label: 'ADMIN' },
		right: { key: 'usr6', label: 'ACCT' },
	},
	{
		left: { key: 'usr7', label: 'DRM' },
		right: { key: 'usr8', label: 'OPEN' },
	},
];

const mktg = { key: 'usr9', label: 'MKTING' };

type Props = {
	canEditJob?: boolean;
	job?: JobDetail;
	onChange: (data: Partial<JobData>) => void;
};

const filterEmployees = (employees: User[], job: JobDetail, prop: string) => {
	return employees.filter(
		(x) =>
			(x.isActive ||
				x.EmployeeNumber === (job as any)[prop]?.EmployeeNumber ||
				x.EmployeeNumber === (job as any)[prop]) &&
			!!(x as any)[prop]
	);
};

export const JobRoles = ({ job, onChange, canEditJob }: Props) => {
	if (!job) return null;

	return (
		<Card label="Job Roles">
			{rows.map((item, index) => (
				<Grid
					key={index}
					container
					direction="row"
					sx={{ flexGrow: 1 }}
					wrap="wrap"
					spacing={1}
				>
					<Grid item sx={{ flexGrow: 1 }}>
						<EmployeeDropDown
							label={item.left.label}
							placeholder={item.left.label}
							emptyOption={{ value: '', label: 'None' }}
							value={(job as any)[item.left.key] || null}
							disabled={!canEditJob}
							onChange={(value) =>
								onChange({
									...job,
									[item.left.key]: value || null,
								})
							}
							filter={(employees) =>
								filterEmployees(employees, job, item.left.key)
							}
						/>
					</Grid>
					<Grid item sx={{ flexGrow: 1 }}>
						<EmployeeDropDown
							label={item.right.label}
							placeholder={item.right.label}
							emptyOption={{ value: '', label: 'None' }}
							value={(job as any)[item.right.key] || null}
							disabled={!canEditJob}
							onChange={(value) =>
								onChange({
									...job,
									[item.right.key]: value || null,
								})
							}
							filter={(employees) =>
								filterEmployees(employees, job, item.right.key)
							}
						/>
					</Grid>
				</Grid>
			))}
			<Grid
				container
				direction="row"
				sx={{ flexGrow: 1 }}
				spacing={1}
				wrap="wrap"
			>
				<Grid item sx={{ flexGrow: 1 }}>
					<EmployeeDropDown
						label={mktg.label}
						placeholder={mktg.label}
						emptyOption={{ value: '', label: 'None' }}
						value={(job as any)[mktg.key] || null}
						disabled={!canEditJob}
						onChange={(value) =>
							onChange({
								...job,
								[mktg.key]: value || null,
							})
						}
						filter={(employees) =>
							filterEmployees(employees, job, mktg.key)
						}
					/>
				</Grid>
				<Grid item sx={{ flexGrow: 1 }}>
					<AutoCompleteLookup
						label="Division"
						placeholder="Division"
						lookupType={LookupTypes.Division}
						value={job.DivisionID || null}
						disabled={!canEditJob}
						onChange={(value) =>
							onChange({
								...job,
								DivisionID: value || undefined,
							})
						}
					/>
				</Grid>
			</Grid>
		</Card>
	);
};
