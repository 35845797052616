import {
	CompanyAddressViewData,
	CompanyContactData,
	CompanyContactViewData,
	CompanyData,
	CompanyNoteData,
	CompanySearchOptions,
	CompanyViewData,
	CreateCompanyRequest,
	JobSummary,
	JobTotals,
	PrimaryStreetAddressData,
	UpdateRequest,
	VendorService,
	stringifySearchOptions
} from '../core';

import { ApiClientInterface } from './client';

export default class CompanyApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
		this.count = this.count.bind(this);
		this.add = this.add.bind(this);
		this.update = this.update.bind(this);
		this.get = this.get.bind(this);
		this.getView = this.getView.bind(this);
		this.queryNames = this.queryNames.bind(this);
		this.queryJobs = this.queryJobs.bind(this);
		this.queryAddresses = this.queryAddresses.bind(this);
		this.addAddress = this.addAddress.bind(this);
		this.updateAddress = this.updateAddress.bind(this);
		this.removeAddress = this.removeAddress.bind(this);
		this.setPrimaryAddress = this.setPrimaryAddress.bind(this);
		this.queryContacts = this.queryContacts.bind(this);
		this.addContact = this.addContact.bind(this);
		this.removeContact = this.removeContact.bind(this);
		this.setPrimaryContact = this.setPrimaryContact.bind(this);
		this.queryNotes = this.queryNotes.bind(this);
		this.addNote = this.addNote.bind(this);
		this.updateNote = this.updateNote.bind(this);

		this.queryJobSummary = this.queryJobSummary.bind(this);
	}

	// Company
	public async query(
		options: CompanySearchOptions
	): Promise<CompanyViewData[]> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<CompanyViewData[]>(
			`/company?${search}`
		);
		return response;
	}

	public async count(options: CompanySearchOptions): Promise<number> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<number>(
			`/company?${search}&count=true`
		);
		return response;
	}

	public async get(companyId: number): Promise<CompanyData> {
		const response = await this.client.get<CompanyData>(
			`/company/${companyId}`
		);
		return response;
	}

	public async add(data: CreateCompanyRequest): Promise<CompanyData> {
		const response = await this.client.post<
			CreateCompanyRequest,
			CompanyData
		>(`/company`, data);
		return response;
	}

	// public async update(company: CompanyData): Promise<CompanyData> {
	// 	const response = await this.client.put<CompanyData, CompanyData>(
	// 		`/company/${company.CompanyID}`,
	// 		company
	// 	);
	// 	return response;
	// }

	public async update(request: UpdateRequest): Promise<void> {
		await this.client.patch<UpdateRequest, void>(
			`/company/${request.id}`,
			request
		);
	}

	// Company View ??
	public async getView(companyId: number): Promise<CompanyViewData> {
		const response = await this.client.get<CompanyViewData>(
			`/company/${companyId}/view`
		);
		return response;
	}

	// Names
	public async queryNames(options: CompanySearchOptions): Promise<string[]> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<string[]>(
			`/companyname?${search}`
		);
		return response;
	}

	// Company Jobs
	public async queryJobs(companyId: number): Promise<JobSummary[]> {
		const response = await this.client.get<JobSummary[]>(
			`/company/${companyId}/job`
		);
		return response;
	}

	// Company Addresses
	public async queryAddresses(
		companyId: number
	): Promise<CompanyAddressViewData[]> {
		const response = await this.client.get<CompanyAddressViewData[]>(
			`/company/${companyId}/address`
		);
		return response;
	}

	public async addAddress(
		companyId: number,
		address: Partial<PrimaryStreetAddressData>
	): Promise<CompanyAddressViewData> {
		const response = await this.client.post<
			Partial<PrimaryStreetAddressData>,
			CompanyAddressViewData
		>(`/company/${companyId}/address`, address);
		return response;
	}

	public async updateAddress(
		companyId: number,
		address: PrimaryStreetAddressData
	): Promise<CompanyAddressViewData> {
		const response = await this.client.put<
			PrimaryStreetAddressData,
			CompanyAddressViewData
		>(`/company/${companyId}/address/${address.AddressID}`, address);
		return response;
	}

	public async removeAddress(
		companyId: number,
		addressId: number
	): Promise<void> {
		await this.client.del(
			`/company/${companyId}/address/${addressId}`
		);
	}

	public async setPrimaryAddress(
		companyId: number,
		addressId: number
	): Promise<void> {
		await this.client.patch<undefined, undefined>(
			`/company/${companyId}/address/${addressId}`
		);
	}

	// Company Contact
	public async queryContacts(
		companyId: number
	): Promise<CompanyContactViewData[]> {
		const response = await this.client.get<CompanyContactViewData[]>(
			`/company/${companyId}/contact`
		);
		return response;
	}

	public async addContact(
		companyId: number,
		contact: Partial<CompanyContactData>
	): Promise<CompanyContactData[]> {
		const response = await this.client.post<
			Partial<CompanyContactData>,
			CompanyContactData[]
		>(`/company/${companyId}/contact`, contact);
		return response;
	}

	public async removeContact(
		companyId: number,
		contactId: number
	): Promise<void> {
		await this.client.del(
			`/company/${companyId}/contact/${contactId}`
		);
	}

	public async setPrimaryContact(
		companyId: number,
		contactId: number
	): Promise<void> {
		await this.client.patch<undefined, undefined>(
			`/company/${companyId}/contact/${contactId}`
		);
	}

	// Company Notes
	public async queryNotes(companyId: number): Promise<CompanyNoteData[]> {
		const response = await this.client.get<CompanyNoteData[]>(
			`/company/${companyId}/note`
		);
		return response;
	}

	public async addNote(
		companyId: number,
		note: Partial<CompanyNoteData>
	): Promise<CompanyNoteData> {
		const response = await this.client.post<
			Partial<CompanyNoteData>,
			CompanyNoteData
		>(`/company/${companyId}/note`, note);
		return response;
	}

	public async updateNote(note: CompanyNoteData): Promise<CompanyNoteData> {
		const response = await this.client.put<
			CompanyNoteData,
			CompanyNoteData
		>(`/company/${note.CompanyID}/note/${note.CompanyNoteID}`, note);
		return response;
	}

	// Company Vendor Service
	public async queryVendorServices(
		companyId: number
	): Promise<VendorService[]> {
		const response = await this.client.get<VendorService[]>(
			`/company/${companyId}/vendorservice`
		);
		return response;
	}

	public async addVendorService(
		companyId: number,
		data: Partial<VendorService>
	): Promise<VendorService> {
		const response = await this.client.post<
			Partial<VendorService>,
			VendorService
		>(`/company/${companyId}/vendorservice`, data);
		return response;
	}

	public async updateVendorService(
		data: VendorService
	): Promise<VendorService> {
		const response = await this.client.put<VendorService, VendorService>(
			`/company/${data.VendorID}/vendorservice/${data.VendorServiceID}`,
			data
		);
		return response;
	}

	public async deleteVendorService(data: VendorService): Promise<void> {
		await this.client.put<VendorService, void>(
			`/company/${data.VendorID}/vendorservice/${data.VendorServiceID}`,
			data
		);
	}

	// Job Summary Data
	public async queryJobSummary(companyId: number): Promise<JobTotals[]> {
		const response = await this.client.get<JobTotals[]>(
			`/company/${companyId}/jobsummary`
		);
		return response;
	}
}
