import { Divider, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { UserMarketingTasks } from '../../core';
import { MarketingTaskListItem } from '../contacts/MarketingTaskListItem';

type Props = {
	mode: 'table' | 'list';
	items: UserMarketingTasks[];
};

export const MarketingTaskListForPrint = ({
	mode,
	items,
}: Props) => {
	return (
		<>
			<Typography variant="h5">Marketing Tasks</Typography>
			<Divider sx={theme => ({ marginBottom: theme.spacing(2) })} />
			<List>
				{items.map((item) => (

					<ListItem key={item.MarketingTaskID}>
						<MarketingTaskListItem
							key={item.MarketingTaskID}
							task={item}
							isEmployeeTasks={true}
							onCompleteTaskClick={() => { }}
							mode={mode}
							forPrinting
						/>
					</ListItem>
				))}
			</List>
		</>
	);
};
