import React from 'react';
import { JobDetail } from '../../../core';
import { Card, Container } from '../../common';
import { JobOtherContactList } from './JobOtherContactList';
import { JobsiteContactList } from './JobsiteContactList';

export type Props = {
	canEditJob?: boolean;
	job?: JobDetail;
	onCountChanged: (count: number) => void;
	show: boolean;
};

export const JobContactList = ({
	job,
	onCountChanged,
	show,
	canEditJob,
}: Props) => {
	const [jobsiteContactCount, setJobsiteContactCount] = React.useState(0);
	const [otherContactCount, setOtherContactCount] = React.useState(0);

	React.useEffect(() => {
		onCountChanged(otherContactCount + jobsiteContactCount);
	}, [jobsiteContactCount, onCountChanged, otherContactCount]);

	if (!show || !job) return null;

	return (
		<Container component="JobFormsFields" scrollable>
			<Card
				light
				label="Jobsite Contacts"
				contentStyle={{
					paddingLeft: 0,
					paddingRight: 0,
					paddingTop: 0,
				}}
				sx={{
					margin: 0,
					padding: 0,
					flexGrow: 0,
				}}
			>
				<JobsiteContactList
					canEditJob={canEditJob}
					job={job}
					onCountChanged={setJobsiteContactCount}
				/>
			</Card>
			<Card
				light
				label="Other Contacts"
				sx={{
					margin: 0,
					padding: 0,
					flexGrow: 0,
				}}
				contentStyle={{
					paddingLeft: 0,
					paddingRight: 0,
					paddingTop: 0,
				}}
			>
				<JobOtherContactList
					canEditJob={canEditJob}
					job={job}
					onCountChanged={setOtherContactCount}
				/>
			</Card>
		</Container>
	);
};
