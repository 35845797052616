import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
	LookupTypes,
	PrimaryPhoneNumberData,
	validatePhoneNumber,
} from '../../core';
import { AutoCompleteLookup } from '../common';
import SwitchItem from '../common/SwitchItem';

type PhoneNumberFormProps = {
	phoneNumber: Partial<PrimaryPhoneNumberData>;
	onChanged: (data: Partial<PrimaryPhoneNumberData>) => void;
	required: boolean;
	autoComplete?: boolean;
	style?: React.CSSProperties;
	className?: string;
	disablePrimary?: boolean;
};

export const PhoneNumberForm = (props: PhoneNumberFormProps) => {
	const { isMobileLayout } = useAppContext();
	const { phoneNumber, onChanged, className, style = {}, required, disablePrimary } = props;

	return (
		<Grid container direction="column" className={className} style={style}>
			<Grid item style={{ flexGrow: 1 }}>
				<TextField
					aria-label="Phone Number"
					title="Phone Number"
					label="Phone Number"
					value={phoneNumber?.PhoneNumber || ''}
					onChange={(e) =>
						onChanged({
							...phoneNumber,
							PhoneNumber: e.target.value,
						})
					}
					InputLabelProps={{ shrink: true }}
					onFocus={(e) => e.target.select()}
					variant="outlined"
					margin="dense"
					required={required}
					fullWidth
					error={
						required &&
						!validatePhoneNumber(phoneNumber.PhoneNumber)
					}
				/>
			</Grid>
			<Grid item>
				<Grid
					container
					spacing={1}
					direction={isMobileLayout ? 'column' : 'row'}
				>
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							aria-label="Extension"
							title="Extension"
							label="Extension"
							value={phoneNumber?.PhoneExt || ''}
							onChange={(e) =>
								onChanged({
									...phoneNumber,
									PhoneExt: e.target.value,
								})
							}
							InputLabelProps={{ shrink: true }}
							variant="outlined"
							margin="dense"
							fullWidth
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<AutoCompleteLookup
							lookupType={LookupTypes.PhoneType}
							label="Phone Type"
							required={required || !!phoneNumber?.PhoneNumber}
							value={phoneNumber.PhoneTypeID || null}
							onChange={(value) =>
								onChanged({
									...phoneNumber,
									PhoneTypeID: value || undefined,
								})
							}
							error={
								(required || !!phoneNumber?.PhoneNumber) &&
								!phoneNumber.PhoneTypeID
							}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ flexGrow: 1 }}>
				<TextField
					label="Description"
					aria-label="Description"
					title="Description"
					value={phoneNumber?.PhoneDesc || ''}
					onChange={(e) =>
						onChanged({
							...phoneNumber,
							PhoneDesc: e.target.value,
						})
					}
					InputLabelProps={{ shrink: true }}
					onFocus={(e) => e.target.select()}
					variant="outlined"
					margin="dense"
					fullWidth
				/>
			</Grid>
			<Grid item>
				<TextField
					aria-label="Notes"
					title="Notes"
					label="Notes"
					value={phoneNumber?.Note || ''}
					onChange={(e) =>
						onChanged({ ...phoneNumber, Note: e.target.value })
					}
					onFocus={(e) => e.target.select()}
					variant="outlined"
					margin="dense"
					multiline
					fullWidth
					minRows={3}
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
			<Grid item style={{ marginTop: '8px' }}>
				<SwitchItem
					primary={disablePrimary ? 'This Phone Number is the Primary' : 'Set as primary Phone Number'}
					checked={phoneNumber.isPrimary === true}
					disabled={disablePrimary}
					onChanged={(checked) => onChanged({ ...phoneNumber, isPrimary: checked })}
				/>
			</Grid>
		</Grid>
	);
};
