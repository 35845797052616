import React from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import { VendorService } from '../../../core';
import { Container, Dialog, ErrorMessage } from '../../common';
import { VendorServiceForm } from './VendorServiceForm';

type State = {
	working: boolean;
	error?: any;
	data: Partial<VendorService>;
};

const initialState: State = { working: false, data: {} };

type Props = {
	data: VendorService;
	onServiceUpdated: (data: VendorService) => void;
	onServiceDeleted: (data: VendorService) => void;
	onClose: () => void;
};

export const EditVendorServiceDialog = ({
	data,
	onClose,
	onServiceUpdated,
	onServiceDeleted,
}: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>({ ...initialState, data });

	const handleUpdateVendorService = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			const updated = await api.company.updateVendorService({
				...data,
				...state.data,
			});
			onServiceUpdated(updated);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const handleDeleteVendorService = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			const service = {
				...data,
				...state.data,
			};
			await api.company.deleteVendorService(service);
			onServiceDeleted(service);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const isValid = !!state.data.ServiceID;

	return (
		<Dialog
			isOpen={true}
			title="Edit Vendor Service"
			negativeAction={{
				text: 'Delete',
				onClick: handleDeleteVendorService,
				isDisabled: () => state.working || !!state.error,
				color: 'secondary',
			}}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
				isDisabled: () => state.working,
			}}
			positiveAction={{
				text: 'Update',
				isDisabled: () => state.working || !!state.error || !isValid,
				onClick: handleUpdateVendorService,
			}}
			onClose={onClose}
		>
			<ErrorMessage
				error={state.error}
				onRetry={() => setState((s) => ({ ...s, error: undefined }))}
			/>
			{!state.error && (
				<Container
					component="AddVendorService"
					scrollable
					sx={theme => ({ padding: theme.spacing(2) })}
				>
					<VendorServiceForm
						data={state.data}
						onChanged={(data) => setState((s) => ({ ...s, data }))}
					/>
				</Container>
			)}
		</Dialog>
	);
};
