import Box from '@mui/material/Box';
import React from 'react';
import { Loading } from './Loading';
import { NothingHere } from './NothingHere';
import { SxProps, Theme } from '@mui/material';

type QueryStatusProps = {
    status: 'idle' | 'busy' | 'results' | 'noresults' | 'error';
    idle: string;
    noresults: string;
    scrollable?: boolean;
    error?: Error | null;
    onErrorReset?: () => void;
    className?: string;
    sx?: SxProps<Theme>;
    children: React.ReactNode;
}

export const QueryStatus = (props: QueryStatusProps) => {
    const { status, idle, noresults, error, children, className, sx = {} } = props;

    return (
        <Box
            data-component="query-status"
            className={className}
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowX: 'hidden',
                overflowY: 'auto',
                ...sx
            }}
        >
            {status === 'busy' && <Loading />}
            {status === 'error' && <NothingHere text={error?.message || ''} />}
            {status === 'idle' && <NothingHere text={idle} />}
            {status === 'noresults' && <NothingHere text={noresults} />}
            {status === 'results' && children}
        </Box>
    );
}