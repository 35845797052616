import React, { useState } from 'react';
import { Autocomplete as MuiAutocomplete } from '@mui/material'
import TextField from '@mui/material/TextField';
import { useDebounce } from 'react-use';

type Props = {
	label?: string;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	readOnly?: boolean;
	delay?: number;
	value?: string | null;
	onChange: (value: string | null) => void;
	onLoadAsync: (text: string) => Promise<string[]>;
};

type OptionState = {
	loading: boolean;
	options: string[];
};

export const AutoCompleteAsyncText = (
	{
		label,
		required = false,
		disabled = false,
		error = false,
		readOnly = false,
		delay = 0,
		onChange,
		onLoadAsync,
	}: Props
) => {
	const [state, setState] = useState<OptionState>({
		loading: true,
		options: [],
	});
	const [textValue, setTextValue] = useState('');

	const handleLoadOptions = async () => {
		try {
			setState((s) => ({ ...s, loading: true }));
			const options = (await onLoadAsync(textValue)) || [];
			setState((s) => ({
				...s,
				loading: false,
				options,
			}));
		} catch (error) {
			setState((s) => ({
				...s,
				loading: false,
				options: ['Error'],
			}));
		}
	};

	const onOptionSelected = (value: string) => {
		onChange(value || null);
	};

	useDebounce(handleLoadOptions, delay, [textValue]);

	return (
		<MuiAutocomplete
			// getOptionSelected={(option: any, value: any) =>
			// 	option?.value === value?.value
			// }
			filterOptions={(x) => x}
			autoComplete={false}
			clearOnEscape
			fullWidth
			onChange={(_event, option: any) => onOptionSelected(option)}
			options={state.options}
			loading={state.loading}
			onInputChange={(_event, newInputValue) => setTextValue(newInputValue)}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					autoComplete="off"
					variant="outlined"
					size="small"
					margin="dense"
					fullWidth
					required={required}
					disabled={disabled}
					tabIndex={-1}
					error={error}
					onFocus={(event) => event.target.select()}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						...params.InputProps,
						autoComplete: 'off',
						required: required,
						disabled: disabled,
						readOnly: readOnly,
						error: error,
					}}
				/>
			)}
		/>
	);
};
