import { ContactData } from '../../core';
import { Container, Dialog } from '../common';
import { AddContact } from './AddContact';
import { useAddContact } from './useAddContact';

type Props = {
	onContactAdded: (contact: ContactData) => void;
	onClose: () => void;
	defaults?: Partial<ContactData>;
};

export const AddContactDialog = ({
	onClose,
	onContactAdded,
	defaults,
}: Props) => {

	const controller = useAddContact({ contactDefaults: defaults });
	const { working, error, isContactValid, createContact, resetData } =
		controller;

	return (
		<Dialog
			fullScreen
			isOpen={true}
			title="Add Contact"
			negativeAction={{
				text: 'Clear',
				onClick: resetData,
				isDisabled: () => working,
			}}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
				isDisabled: () => working,
			}}
			positiveAction={{
				text: 'Add Contact',
				isDisabled: () => working || !!error || !isContactValid,
				onClick: createContact,
			}}
			onClose={onClose}
		>
			<Container
				component="AddContact"
				scrollable
				sx={theme => ({ paddingLeft: theme.spacing(0.5) })}
			>
				<AddContact
					controller={controller}
					onContactAdded={onContactAdded}
				/>
			</Container>
		</Dialog>
	);
};
