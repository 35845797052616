import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton, ListItemButton, Autocomplete as MuiAutocomplete, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useDebounce } from 'react-use';
import { useAppContext } from '../../contexts/AppContext';
import { ContactViewData } from '../../core';
import { AutoCompleteOptionOf, FlexRow } from '../common';
import FieldBase from "../common/FieldBase";
import useContact from "../contacts/useContact";
import ContactListItem from "./ContactListItem";

export type ContactAutoCompleteProps = {
	onChange: (contact?: ContactViewData) => void;
	onAddClick?: () => void;
	label: string;
	companyNameSearch?: string;
	companyId?: number;
	contactId?: number;
	typeIds?: number[];
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	autoOpen?: boolean;
	emptyOption?: boolean;
};

export const ContactAutoComplete = ({
	label,
	onChange,
	required = false,
	disabled = false,
	error = false,
	onAddClick,
	companyId,
	contactId,
	companyNameSearch,
	typeIds,
}: ContactAutoCompleteProps) => {
	const { api } = useAppContext();
	const contact = useContact(contactId);
	const [results, setResults] = React.useState<ContactViewData[]>([]);
	const [search, setSearch] = React.useState('');

	const handleSearchContacts = async (): Promise<AutoCompleteOptionOf<number>[]> => {
		try {
			const contacts = await api.contact.query({
				search,
				companyName: companyNameSearch,
				companyId,
				limit: 100,
				types: typeIds
			});
			setResults(contacts);
			return contacts.map((x) => ({
				value: x.ContactID,
				label: x.fullname || '',
			}));
		} catch (error) {
			console.error(error);
			return [{ value: 0, label: 'Error searching contacts' }];
		}
	};

	useDebounce(handleSearchContacts, 700, [search, companyNameSearch, companyId]);

	return (
		<FieldBase>
			<FlexRow nowrap style={{ width: '100%', alignItems: 'center' }}>
				<MuiAutocomplete
					data-component="ContactAutoComplete"
					getOptionLabel={(option) => option.fullname}
					autoHighlight
					autoComplete={false}
					clearOnEscape
					fullWidth
					value={contact}
					onChange={(_event, option: ContactViewData | null) => onChange(option || undefined)}
					options={results}
					onInputChange={(_event, newInputValue) => setSearch(newInputValue)}
					renderOption={(props, option, state) => (
						<ListItemButton
							{...(props as any)}
							onClick={() => onChange(option)}
							selected={state.selected}
							sx={theme => ({
								padding: theme.spacing(0.5, 1),
							})}
						>
							<ContactListItem contact={option} />
						</ListItemButton>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							label={label}
							autoComplete="off"
							variant="outlined"
							size="small"
							margin="dense"
							fullWidth
							required={required}
							disabled={disabled}
							tabIndex={-1}
							error={error}
							onFocus={(event) => event.target.select()}
							InputLabelProps={{ shrink: true }}
							InputProps={{
								...params.InputProps,
								autoComplete: 'off',
								required: required,
								disabled: disabled,
								error: error,
							}}
						/>
					)}
				/>
				{!!onAddClick && (
					<Tooltip title="Add Contact">
						<IconButton size="small" style={{ marginLeft: '8px' }} onClick={onAddClick}>
							<Icon path={mdiPlus} size={1} />
						</IconButton>
					</Tooltip>
				)}
			</FlexRow>
		</FieldBase>
	);
};
