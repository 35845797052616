import Icon from '@mdi/react';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';

type NavigationButtonProps = {
	title: string;
	icon: string;
	onClick: () => void;
	iconSize?: number;
	selected?: boolean;
};

export const NavigationButton = (props: NavigationButtonProps) => {
	const {
		title,
		icon,
		onClick,
		iconSize = 0.8,
		selected = false,
	} = props;

	return (
		<Box
			sx={theme => ({
				borderLeft: selected ? `4px solid red` : `4px solid transparent`,
				padding: theme.spacing(0.75, 0),
				width: '100%',
				textAlign: 'center',
			})}
		>
			<Button
				size="small"
				sx={{ flexDirection: 'column' }}
				onClick={onClick}
			>
				<Icon path={icon} size={iconSize} />
				<Typography variant="caption" sx={{ display: 'block' }}>
					<strong>{title}</strong>
				</Typography>
			</Button>
		</Box>
	);
};
