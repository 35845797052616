import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { User } from '../../core';

import { AutoComplete } from '../common';
import { ListItemButton } from '@mui/material';

type Props = {
	user: Partial<User>;
	onChanged: (data: Partial<User>) => void;
	mode: 'update' | 'add';
	employeeNumberError: boolean;
};

const usrItems = [
	{ key: 'usr1', label: 'EST' },
	{ key: 'usr2', label: 'PM' },
	{ key: 'usr3', label: 'LEVEL5' },
	{ key: 'usr4', label: 'LEAD' },
	{ key: 'usr5', label: 'ADMIN' },
	{ key: 'usr6', label: 'ACCT' },
	{ key: 'usr7', label: 'DRM' },
	{ key: 'usr8', label: 'OPEN' },
	{ key: 'usr9', label: 'MKTING' },
];

const options = [
	{ label: 'System Admin', value: 1 },
	{ label: 'Read Only', value: 2 },
	{ label: 'Accounting', value: 3 },
	{ label: 'Standard', value: 4 },
];

export const UserForm = ({
	mode,
	user,
	onChanged,
	employeeNumberError,
}: Props) => {
	const handleChange = (
		key: string,
		value?: string | number | boolean | null
	) => {
		onChanged({ ...user, [key]: value });
	};

	return (
		<Grid container direction="column" spacing={1}>
			<Grid item>
				<Grid
					container
					direction="row"
					spacing={1}
					style={{ alignItems: 'center' }}
				>
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							label="Employee Number"
							aria-label="Employee Number"
							title="Employee Number"
							value={user?.EmployeeNumber || ''}
							onChange={(e) =>
								handleChange('EmployeeNumber', e.target.value)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							fullWidth
							required
							disabled={mode === 'update'}
							InputLabelProps={{ shrink: true }}
							error={!user?.EmployeeNumber || employeeNumberError}
						/>
					</Grid>
					<Grid item>
						<FormControlLabel
							control={
								<Checkbox
									checked={user?.isActive}
									onChange={(_event, checked) =>
										handleChange('isActive', checked)
									}
									name="isActive"
									color="secondary"
									required
								/>
							}
							label="Active"
							style={{
								minWidth: '100px',
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<TextField
					label="Employee Name (Last, First)"
					aria-label="Employee Name (Last, First)"
					title="Employee Name (Last, First)"
					required
					value={user?.EmployeeName || ''}
					onChange={(e) =>
						handleChange('EmployeeName', e.target.value)
					}
					onFocus={(e) => e.target.select()}
					variant="outlined"
					margin="dense"
					fullWidth
					error={!user?.EmployeeName}
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
			<Grid item>
				<AutoComplete
					label="User Role"
					placeholder="User Role"
					value={user?.UserRoleID}
					onChange={(value) => handleChange('UserRoleID', value)}
					required={true}
					fixed={true}
					autoSelect={true}
					autoOpen={false}
					options={options}
					error={!user?.UserRoleID}
				/>
			</Grid>
			<Grid item>
				<List>
					{usrItems.map((item) => (
						<ListItemButton
							key={item.key}
							onClick={() =>
								handleChange(item.key, !(user as any)[item.key])
							}
						>
							<ListItem
								secondaryAction={(
									<Checkbox
										checked={(user as any)[item.key] === true}
										name="isActive"
										color="secondary"
										onChange={(_event, checked) =>
											handleChange(item.key, checked)
										}
									/>

								)}
							>
								<ListItemText primary={item.label} />
							</ListItem>
						</ListItemButton>
					))}
				</List>
			</Grid>
		</Grid>
	);
};
