import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { formatDate, SellData } from '../../../../core';
import { DatePicker, FlexColumn, FlexRow } from '../../../common';

const rates = [
	{ key: 'rate1', label: 'EST Rate' },
	{ key: 'rate2', label: 'PM Rate' },
	{ key: 'rate3', label: 'LEVEL5 Rate' },
	{ key: 'rate4', label: 'LEAD Rate' },
	{ key: 'rate5', label: 'ADMIN Rate' },
	{ key: 'rate6', label: 'ACCT Rate' },
	{ key: 'rate7', label: 'DRM Rate' },
	{ key: 'rate8', label: 'OPEN Rate' },
	{ key: 'rate9', label: 'MKTING Rate' },
];

export type Props = {
	data: Partial<SellData>;
	onChanged: (data: Partial<SellData>) => void;
};

export const SellForm = ({ data, onChanged }: Props) => {
	const theme = useTheme();
	return (
		<FlexColumn>
			<FlexColumn>
				<TextField
					label="Change Description"
					variant="outlined"
					color="primary"
					margin="none"
					size="small"
					value={data.description}
					multiline
					minRows={5}
					onChange={(e) =>
						onChanged({ ...data, description: e.target.value })
					}
					style={{ margin: theme.spacing(1) }}
					error={!data.description}
					InputLabelProps={{ shrink: true }}
				/>
			</FlexColumn>
			<FlexRow>
				<FlexColumn style={{ flexGrow: 1 }}>
					<TextField
						label="Sale Amount"
						variant="outlined"
						color="primary"
						margin="none"
						size="small"
						required
						type="number"
						InputLabelProps={{ shrink: true }}
						inputProps={{
							onWheel: (e) =>
								(e.target as HTMLInputElement).blur(),
						}}
						value={data.saleAmount}
						onChange={(e) =>
							onChanged({
								...data,
								saleAmount:
									parseFloat(e.target.value) || undefined,
							})
						}
						error={!data.saleAmount && !data.salesTax}
						style={{ margin: theme.spacing(1) }}
					/>
					<TextField
						label="Tax Amount"
						variant="outlined"
						color="primary"
						margin="none"
						size="small"
						value={data.salesTax}
						type="number"
						InputLabelProps={{ shrink: true }}
						inputProps={{
							onWheel: (e) =>
								(e.target as HTMLInputElement).blur(),
						}}
						onChange={(e) =>
							onChanged({
								...data,
								salesTax:
									parseFloat(e.target.value) || undefined,
							})
						}
						error={!data.saleAmount && !data.salesTax}
						style={{ margin: theme.spacing(1) }}
					/>
					<DatePicker
						label="Change Date"
						value={
							data.changeDate
								? formatDate(`${data.changeDate}`)
								: ''
						}
						required
						onChange={(value) =>
							onChanged({
								...data,
								changeDate: value ? new Date(value) : undefined,
							})
						}
						error={!data.changeDate}
						style={{ margin: theme.spacing(1) }}
					/>
					<DatePicker
						label="Sent Date"
						value={
							data.sentDate ? formatDate(`${data.sentDate}`) : ''
						}
						required
						onChange={(value) =>
							onChanged({
								...data,
								sentDate: value ? new Date(value) : undefined,
							})
						}
						error={!data.sentDate}
						style={{ margin: theme.spacing(1) }}
					/>
					<DatePicker
						label="Approved Date"
						value={
							data.approvedDate
								? formatDate(`${data.approvedDate}`)
								: ''
						}
						required
						onChange={(value) =>
							onChanged({
								...data,
								approvedDate: value
									? new Date(value)
									: undefined,
							})
						}
						error={!data.approvedDate}
						style={{ margin: theme.spacing(1) }}
					/>
				</FlexColumn>
				<FlexColumn style={{ marginLeft: theme.spacing(1) }}>
					{rates.map((rate) => (
						<TextField
							key={rate.key}
							label={rate.label}
							variant="outlined"
							color="primary"
							margin="none"
							size="small"
							value={(data as any)[rate.key]}
							type="number"
							InputLabelProps={{ shrink: true }}
							inputProps={{
								onWheel: (e) =>
									(e.target as HTMLInputElement).blur(),
							}}
							onChange={(e) =>
								onChanged({
									...data,
									[rate.key]:
										parseFloat(e.target.value) || undefined,
								})
							}
							style={{ margin: theme.spacing(1) }}
						/>
					))}
				</FlexColumn>
			</FlexRow>
		</FlexColumn>
	);
};
