/* eslint-disable no-console */
import { JobTaskWithJobData } from '../core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Lib
import type { RootState } from './index';

export interface JobTaskState {
	items: JobTaskWithJobData[];
}

const initialState: JobTaskState = {
	items: [],
};

export const slice = createSlice({
	name: 'jobTasks',
	initialState,
	reducers: {
		loadJobTasks: (state, action: PayloadAction<JobTaskWithJobData[]>) => {
			state.items = action.payload;
		},
	},
});

export const { loadJobTasks } = slice.actions;

export const selectJobTasks = (state: RootState) => state.jobTasks.items;

export default slice.reducer;
