import {
	mdiDotsVertical,
	mdiDownloadCircleOutline,
	mdiFolderOutline,
	mdiFolderPlusOutline,
	mdiFormTextbox,
	mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useS3FileBrowserContext } from './S3FileBrowserContext';
import { FolderChangedEvent, S3Object } from './types';
import { isFolderEmpty, isSystemFolder } from './utils';

export type Props = {
	readOnly?: boolean;
	canDelete?: boolean;
	folder: S3Object;
	onChangeFolder?: FolderChangedEvent;
};

export const FolderItem = ({
	folder,
	onChangeFolder,
	readOnly,
	canDelete,
}: Props) => {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const {
		root,
		files,
		onAddSubFolder,
		onRenameFolder,
		onRemoveFolder,
		onDownloadFolder,
	} = useS3FileBrowserContext();

	const handleItemClick = () => {
		if (onChangeFolder) onChangeFolder(folder);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: MouseEvent | TouchEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	return (
		<Box sx={theme => ({
			marginLeft: theme.spacing(0.5),
			marginRight: theme.spacing(0.5),
			marginBottom: theme.spacing(1),
			border: `solid 1px ${theme.palette.divider}`,
			'&:hover': {
				backgroundColor: theme.palette.action,
			},
		})}>
			<ButtonGroup
				ref={anchorRef}
				aria-label="split button"
				disableElevation
			>
				<Tooltip title={`Open Folder`}>
					<Button
						size="small"
						onClick={handleItemClick}
						startIcon={<Icon path={mdiFolderOutline} size={0.75} />}
					>
						<Typography variant="caption">{folder.name}</Typography>
						<Typography variant="caption" sx={theme => ({
							marginLeft: '8px',
							color: theme.palette.info.main,
						})}>
							({folder.size})
						</Typography>
					</Button>
				</Tooltip>
				<Tooltip title={`Open for more options...`}>
					<Button
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-label="select merge strategy"
						aria-haspopup="menu"
						onClick={handleToggle}
						style={{ minWidth: 'unset', padding: '5px 5px' }}
					>
						<Icon path={mdiDotsVertical} size={0.75} />
					</Button>
				</Tooltip>
			</ButtonGroup>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				style={{ zIndex: 99999 }}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom'
									? 'center top'
									: 'center bottom',
						}}
					>
						<Paper style={{ zIndex: 99999 }}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu">
									<MenuItem
										onClick={() => onAddSubFolder(folder)}
										disabled={readOnly}
									>
										<ListItemIcon
											style={{ minWidth: '35px' }}
										>
											<Icon
												path={mdiFolderPlusOutline}
												size={1}
											/>
										</ListItemIcon>
										New Folder
									</MenuItem>
									<MenuItem
										onClick={() => onDownloadFolder(folder)}
									>
										<ListItemIcon
											style={{ minWidth: '35px' }}
										>
											<Icon
												path={mdiDownloadCircleOutline}
												size={1}
											/>
										</ListItemIcon>
										Download Folder
									</MenuItem>
									{!isSystemFolder(root, folder.key) && (
										<>
											<MenuItem
												onClick={() =>
													onRenameFolder(folder)
												}
												disabled={readOnly}
											>
												<ListItemIcon
													style={{ minWidth: '35px' }}
												>
													<Icon
														path={mdiFormTextbox}
														size={1}
													/>
												</ListItemIcon>
												Rename Folder
											</MenuItem>

											<MenuItem
												onClick={() =>
													onRemoveFolder(folder)
												}
												disabled={
													!canDelete ||
													readOnly ||
													!isFolderEmpty(
														files.map((x) => x.key),
														folder.key
													)
												}
											>
												<ListItemIcon
													style={{ minWidth: '35px' }}
												>
													<Icon
														path={
															mdiTrashCanOutline
														}
														size={1}
													/>
												</ListItemIcon>
												Remove Folder
											</MenuItem>
										</>
									)}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Box>
	);
};
