import { Dialog } from './Dialog';

export type InfoDialogAction = {
	title: string;
	text: string;
	action: () => void;
};

type InfoDialogProps = {
	isOpen: boolean;
	title: string;
	text: string;
	onClose: () => void;
};

export const InfoDialog = (props: InfoDialogProps) => {
	const { isOpen, title, text, onClose } = props;

	return (
		<Dialog
			isOpen={isOpen}
			title={title}
			text={text}
			onClose={onClose}
			positiveAction={{
				text: 'Okay',
				color: 'secondary',
				onClick: onClose,
			}}
		/>
	);
};
