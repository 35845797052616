import * as React from 'react';
import { useDebounce } from 'react-use';
import { useSnackbar } from 'notistack';

import { User } from '../../core';

import { Dialog } from '../common/Dialog';
import { UserForm } from './UserForm';
import { validateUser } from './validateUser';
import { useAppContext } from '../../contexts/AppContext';
import { useAppDispatch } from '../../store';
import { addEmployee } from '../employees';
import { Loading } from '../common';

type Props = {
	onClose: () => void;
};

export const AddUserDialog = ({ onClose }: Props) => {
	const { api } = useAppContext();
	const [user, setUser] = React.useState<Partial<User>>({
		isActive: true,
		UserRoleID: 4,
		usr7: true,
	});
	const [busy, setBusy] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const snackbar = useSnackbar();
	const dispatch = useAppDispatch();

	const validate = React.useCallback(
		async (data: Partial<User>) => {
			try {
				if (data.EmployeeNumber) {
					const isUnique = await api.employee.unique(
						data.EmployeeNumber
					);
					console.log('isUnique Result', isUnique);
					if (!isUnique) {
						setErrorMessage('The employee number must be unique');
					} else {
						const message = validateUser(data);
						if (message) {
							setErrorMessage(message || '');
						} else {
							setErrorMessage('');
						}
					}
				} else {
					setErrorMessage('Please provide a unique employee number');
				}
			} catch (error) {
				setErrorMessage(`${error}`);
			}
		},
		[api.employee]
	);

	useDebounce(() => validate(user), 700, [user]);

	const handleOnChange = (data: Partial<User>) => {
		setUser(data);
	};

	const handleDialogAction = async () => {
		setBusy(true);
		try {
			const added = await api.employee.add(user);
			console.log(added);
			dispatch(addEmployee(added));
			snackbar.enqueueSnackbar(`Added Employee ${user.EmployeeName}`, {
				variant: 'success',
			});
			onClose();
		} catch (error) {
			setBusy(false);
			setErrorMessage(`${error}`);
		}
	};

	return (
		<Dialog
			isOpen={true}
			title="Add User"
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
			}}
			positiveAction={{
				text: 'Add User',
				isDisabled: () => busy || !!errorMessage,
				onClick: handleDialogAction,
			}}
			onClose={onClose}
		>
			<div style={{ padding: '8px' }}>
				{busy && <Loading />}
				{!busy && (
					<UserForm
						mode="add"
						user={user}
						onChanged={handleOnChange}
						employeeNumberError={
							errorMessage ===
							'The employee number must be unique'
						}
					/>
				)}
				{errorMessage && (
					<div
						style={{
							padding: '8px',
							textAlign: 'right',
							color: '#b71c1c',
						}}
					>
						<strong>{errorMessage}</strong>
					</div>
				)}
			</div>
		</Dialog>
	);
};
