import React, { useContext } from 'react';

import {
	S3Object,
	ErrorEvent,
	AddSubFolderEvent,
	RenameFolderEvent,
	RemoveFolderEvent,
	DownloadFolderEvent,
	defaultFolders,
	DownloadFileEvent,
	OpenGalleryEvent,
	OpenUploadEvent,
	GalleryData,
	UploadDialogData,
	FileRemovedEvent,
	FileRenamedEvent,
} from './types';
import {
	downloadBlob,
	getSubFolder,
	parseObjectList,
	validateAddSubFolder,
	validateRenameFile,
	validateRenameFolder,
} from './utils';

import { Gallery } from './Gallery';
import { UploadDialog } from './UploadDialog';
import {
	ConfirmDialog,
	ConfirmDialogAction,
	InfoDialog,
	InfoDialogAction,
} from '../common';
import { InputDialog, InputDialogAction } from '../common/InputDialog';
import { ThumbnailToken } from '../../core';
import { useParams } from 'react-router-dom';
import { StorageProvider } from './StorageProvider';

const noop = () => { };

export interface S3FileBrowserState {
	root: string;
	folders: S3Object[];
	files: S3Object[];
	count: number;
	busy: boolean;
	error?: any;
	resetError: () => void;
	onError: ErrorEvent;
	reload: () => void;

	// S3Toolbar
	onAddSubFolder: AddSubFolderEvent;
	onRenameFolder: RenameFolderEvent;
	onRemoveFolder: RemoveFolderEvent;
	onDownloadFolder: DownloadFolderEvent;
	onDownloadFile: DownloadFileEvent;
	onOpenGallery: OpenGalleryEvent;
	onOpenUpload: OpenUploadEvent;
	onRemoveFile: FileRemovedEvent;
	onRenameFile: FileRenamedEvent;
}

const initialState: S3FileBrowserState = {
	root: '',
	folders: [],
	files: [],
	count: 0,
	busy: true,
	reload: noop,
	resetError: noop,
	onError: noop,

	onAddSubFolder: noop,
	onRenameFolder: noop,
	onRemoveFolder: noop,
	onDownloadFolder: noop,
	onDownloadFile: noop,
	onOpenGallery: noop,
	onOpenUpload: noop,
	onRemoveFile: noop,
	onRenameFile: noop,
};

export interface S3FileBrowserContextProps {
	children: React.ReactNode;
	readOnly?: boolean;
	canDelete?: boolean;
	onError?: ErrorEvent;
}

export const S3FileBrowserContext =
	React.createContext<S3FileBrowserState>(initialState);

type LocalState = {
	objects: S3Object[];
	files: S3Object[];
	folders: S3Object[];
	count: number;
	busy: boolean;
	error?: any;
};

const initialLocalState: LocalState = {
	objects: [],
	files: [],
	folders: [],
	count: 0,
	busy: true,
};

const storage = new StorageProvider();

export const S3FileBrowserProvider = ({
	onError = noop,
	readOnly,
	canDelete,
	children,
}: S3FileBrowserContextProps) => {
	const idParam = useParams<{ id: string }>()
	const root = idParam.id || undefined;

	const [initialized, setInitialized] = React.useState(false);
	const [state, setState] = React.useState(initialLocalState);
	const [info, setInfo] = React.useState<InfoDialogAction>();
	const [confirm, setConfirm] = React.useState<ConfirmDialogAction>();
	const [galleryData, setGalleryData] = React.useState<GalleryData>();
	const [uploadDialogData, setUploadDialogData] =
		React.useState<UploadDialogData>();
	const [inputDialogData, setInputDialogData] =
		React.useState<InputDialogAction>();

	const createDefaultFolders = React.useCallback(
		async (data: any[], parent: string) => {
			try {
				const foldersToCreate: string[] = [];
				defaultFolders.forEach((f) => {
					const exists =
						data.filter((x) => x.key.startsWith(`${parent}/${f}`))
							.length > 0;
					if (!exists) {
						foldersToCreate.push(`${parent}/${f}`);
					}
				});
				if (foldersToCreate.length > 0) {
					console.warn('Creating Default Folders:', foldersToCreate);
				}
				const createFolder = async (folder: string) => {
					const result = await storage.createFolder(folder);
					return result;
				};
				const folders = await Promise.all(
					foldersToCreate.map(createFolder)
				);
				return folders.length !== 0;
			} catch (error) {
				console.error('createDefaultFolders', error);
				return false;
			}
		},
		[]
	);

	// on first load and root change; get files from root
	const getAllObjectsInRoot = React.useCallback(async () => {
		if (root) {
			setState((s) => ({ ...s, busy: true }));
			try {
				let result = await storage.list(root);
				// console.log('Storage List', root, result);
				const foldersWereCreated = await createDefaultFolders(
					result,
					root
				);
				if (foldersWereCreated) {
					result = await storage.list(root);
				}
				const [folders, files, count] = parseObjectList(root, result);
				// console.warn('folders', folders);
				const objects = [...folders, ...files];
				setState((s) => ({
					...s,
					folders,
					files,
					objects,
					count,
					busy: false,
					error: undefined,
				}));
			} catch (error) {
				setState((s) => ({ ...s, busy: false, error }));
				onError(error);
				console.error(error);
			}
		}
	}, [root, createDefaultFolders, onError]);

	const handleAddSubFolder = React.useCallback(
		async (folder: S3Object, subFolder: string) => {
			const key = getSubFolder(folder.key, subFolder);
			console.warn('Adding: ', key);
			const result = await storage.put(key, null);
			console.warn('handleAddSubFolder', result);
			setInputDialogData(undefined);
			getAllObjectsInRoot();
			return result;
		},
		[getAllObjectsInRoot]
	);

	const handleRemoveFolder = React.useCallback(
		async (folder: S3Object) => {
			const result = await storage.remove(folder.key);
			console.warn('handleRemoveFolder', result);
			getAllObjectsInRoot();
			setConfirm(undefined);
			return result;
		},
		[getAllObjectsInRoot]
	);

	const handleRemoveFile = React.useCallback(
		async (file: S3Object) => {
			const key = file.isThumbnail
				? file.key.replaceAll(ThumbnailToken, '')
				: file.key;
			console.warn('Removing', key);
			const result = await storage.remove(key);
			console.warn('handleRemoveFolder', result);
			getAllObjectsInRoot();
			setConfirm(undefined);
			return result;
		},
		[getAllObjectsInRoot]
	);

	const handleRenameFolder = React.useCallback(
		async (folder: S3Object, value: string) => {
			console.log('/* TODO: handleRenameFolder */', folder, value);
			getAllObjectsInRoot();
			setInputDialogData(undefined);
			// return result;
		},
		[getAllObjectsInRoot]
	);

	const handleRenameFile = React.useCallback(
		async (folder: S3Object, value: string) => {
			console.log('/* TODO: handleRenameFile */', folder, value);
			getAllObjectsInRoot();
			setInputDialogData(undefined);
			// return result;
		},
		[getAllObjectsInRoot]
	);

	const handleDownloadFolder = React.useCallback(
		async (folder: S3Object) => {
			console.log('/* TODO: handleDownloadFolder */', folder);
			getAllObjectsInRoot();
			setInfo(undefined);
			// return result;
		},
		[getAllObjectsInRoot]
	);

	const onAddSubFolder = React.useCallback(
		(parent: S3Object) => {
			setInputDialogData({
				title: 'Add sub-folder',
				text: `New sub-folder name`,
				validate: (x) =>
					validateAddSubFolder(root, state.objects, parent, x),
				action: (x) => handleAddSubFolder(parent, x),
			});
		},
		[handleAddSubFolder, root, state.objects]
	);

	const onRenameFolder = React.useCallback(
		(parent: S3Object) => {
			setInputDialogData({
				title: 'Rename folder',
				text: `New folder name`,
				defaultValue: parent.name,
				validate: (x) =>
					validateRenameFolder(root, state.objects, parent, x),
				action: (x) => handleRenameFolder(parent, x),
			});
		},
		[handleRenameFolder, root, state.objects]
	);

	const onRenameFile = React.useCallback(
		(file: S3Object) => {
			setInputDialogData({
				title: 'Rename file',
				text: `New file name`,
				defaultValue: file.name,
				validate: (x) =>
					validateRenameFile(root, state.objects, file, x),
				action: (x) => handleRenameFile(file, x),
			});
		},
		[handleRenameFile, root, state.objects]
	);

	const onRemoveFolder = React.useCallback(
		(parent: S3Object) => {
			setConfirm({
				title: 'Remove folder?',
				text: `Are you sure you want to remove the '${parent.name}' folder?`,
				action: () => handleRemoveFolder(parent),
			});
		},
		[handleRemoveFolder]
	);

	const onRemoveFile = React.useCallback(
		(file: S3Object) => {
			setConfirm({
				title: 'Remove file?',
				text: `Are you sure you want to remove '${file.name.replaceAll(
					ThumbnailToken,
					''
				)}'?`,
				action: () => handleRemoveFile(file),
			});
		},
		[handleRemoveFile]
	);

	const onDownloadFolder = React.useCallback(
		(parent: S3Object) => {
			setInfo({
				title: 'Download folder',
				text: `The folder '${parent.key}' will be zipped up and available for download shortly.`,
				action: () => handleDownloadFolder(parent),
			});
		},
		[handleDownloadFolder]
	);

	const onDownloadFile = React.useCallback(async (file: S3Object) => {
		const downloadKey = file.key.includes(ThumbnailToken)
			? file.key.replace(ThumbnailToken, '')
			: file.key;

		const url = await storage.getDownloadUrl(downloadKey);
		downloadBlob(url, file.name);
	}, []);

	const onOpenGallery = React.useCallback(
		async (files: S3Object[], index: number) => {
			setGalleryData({ files, index });
		},
		[]
	);

	const onOpenUpload = React.useCallback(async (folder: S3Object) => {
		setUploadDialogData({ folder });
	}, []);

	const resetError = React.useCallback(() => {
		setState((s) => ({ ...s, error: undefined }));
	}, []);

	React.useEffect(() => {
		console.log('ROOT CHANGED: RESET', root);
		setState(initialLocalState);
		setInitialized(false);
	}, [root]);

	React.useEffect(() => {
		if (!initialized) {
			console.log('Reloading new root', root);
			getAllObjectsInRoot();
			setState((s) => ({ ...s, initialized: true }));
		}
	}, [initialized, getAllObjectsInRoot, root]);

	return (
		<S3FileBrowserContext.Provider
			value={{
				root: `${root}/`,
				folders: state.folders,
				files: state.files,
				count: state.count,
				busy: state.busy,
				error: state.error,
				resetError,
				onError,
				onAddSubFolder,
				onRenameFolder,
				onRemoveFolder,
				onDownloadFolder,
				onDownloadFile,
				onRemoveFile,
				onOpenGallery,
				onOpenUpload,
				onRenameFile,
				reload: () => getAllObjectsInRoot(),
			}}
		>
			{children}
			{galleryData && (
				<Gallery
					readOnly={readOnly}
					canDelete={canDelete}
					files={galleryData.files}
					defaultIndex={galleryData.index}
					onClose={() => setGalleryData(undefined)}
				/>
			)}
			{uploadDialogData && (
				<UploadDialog
					folder={uploadDialogData.folder}
					onClose={() => setUploadDialogData(undefined)}
				/>
			)}
			{confirm && (
				<ConfirmDialog
					isOpen={true}
					title={confirm.title}
					question={confirm.text}
					onCancel={() => setConfirm(undefined)}
					onConfirm={() => confirm.action()}
				/>
			)}
			{!!info && (
				<InfoDialog
					isOpen={true}
					title={info.title}
					text={info.text}
					onClose={() => {
						setInfo(undefined);
						info.action();
					}}
				/>
			)}
			{!!inputDialogData && (
				<InputDialog
					isOpen={true}
					title={inputDialogData.title}
					question={inputDialogData.text}
					validate={inputDialogData.validate}
					onCancel={() => {
						setInputDialogData(undefined);
					}}
					onConfirm={(value: string) => inputDialogData.action(value)}
				/>
			)}
		</S3FileBrowserContext.Provider>
	);
};

export const useS3FileBrowserContext = (): S3FileBrowserState => {
	return useContext(S3FileBrowserContext);
};
