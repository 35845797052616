import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
	LookupTypes,
	MarketingTaskData,
	formatDate,
} from '../../core';
import {
	AutoCompleteLookup,
	Container,
	DatePicker,
	Dialog,
	EmployeeDropDown,
	ErrorMessage,
	FlexColumn,
} from '../common';

type State = {
	working: boolean;
	error?: any;
	data: Partial<MarketingTaskData>;
};

const initialState: State = { working: false, data: {} };

type Props = {
	contactId: number;
	onTaskAdded: (task: MarketingTaskData) => void;
	onClose: () => void;
};

export const AddMarketingTaskDialog = ({
	contactId,
	onClose,
	onTaskAdded,
}: Props) => {
	const theme = useTheme();
	const { api, user } = useAppContext();
	const [state, setState] = React.useState<State>(initialState);

	const resetData = () => {
		setState(initialState);
	};

	const handleCreateMarketingTask = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			const task = await api.contact.addMarketingTask(contactId, {
				...state.data,
				EnteredByEmployeeNumber: user.EmployeeNumber,
				EnteredDate: new Date(),
				ContactID: contactId,
			});
			onTaskAdded(task);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const handleChange = (key: string, value: any) => {
		setState((s) => ({ ...s, data: { ...s.data, [key]: value } }));
	};

	const isValid = !!state.data.EmployeeNumber && !!state.data.DateDue;

	return (
		<Dialog
			isOpen={true}
			title="Add Marketing Task"
			negativeAction={{
				text: 'Clear',
				onClick: resetData,
				isDisabled: () => state.working || !!state.error,
			}}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
				isDisabled: () => state.working,
			}}
			positiveAction={{
				text: 'Add Marketing Task',
				isDisabled: () => state.working || !!state.error || !isValid,
				onClick: handleCreateMarketingTask,
			}}
			onClose={onClose}
		>
			<ErrorMessage
				error={state.error}
				onRetry={() => setState((s) => ({ ...s, error: undefined }))}
			/>
			{!state.error && (
				<Container
					component="AddMarketingTask"
					scrollable
					sx={{ padding: theme.spacing(2) }}
				>
					<FlexColumn style={{ padding: theme.spacing(0.5, 0) }}>
						<EmployeeDropDown
							label="Employee"
							placeholder="Employee"
							value={state.data.EmployeeNumber || null}
							onChange={(value) =>
								handleChange('EmployeeNumber', value)
							}
							filter={(items) => items.filter((x) => x.isActive)}
							required
							error={!state.data.EmployeeNumber}
							disabled={state.working}
						/>
					</FlexColumn>
					<FlexColumn style={{ padding: theme.spacing(0.5, 0) }}>
						<DatePicker
							label="Due Date"
							value={
								state.data.DateDue
									? formatDate(state.data.DateDue)
									: ''
							}
							required
							onChange={(value) => handleChange('DateDue', value)}
							error={!state.data.DateDue}
							disabled={state.working}
						/>
					</FlexColumn>
					<FlexColumn style={{ padding: theme.spacing(0.5, 0) }}>
						<AutoCompleteLookup
							label="Type"
							placeholder="Type"
							lookupType={LookupTypes.MarketingType}
							value={state.data.MarketingType || null}
							onChange={(value) =>
								handleChange('MarketingType', value)
							}
							disabled={state.working}
						/>
					</FlexColumn>
					<FlexColumn style={{ padding: theme.spacing(0.5, 0) }}>
						<TextField
							label="Amount"
							aria-label="Amount"
							title="Amount"
							value={state.data.Amount || ''}
							type="number"
							inputProps={{
								onWheel: (e) =>
									(e.target as HTMLInputElement).blur(),
							}}
							onChange={(e) =>
								handleChange(
									'Amount',
									parseFloat(e.target.value) || undefined
								)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
							disabled={state.working}
						/>
					</FlexColumn>
					<FlexColumn style={{ padding: theme.spacing(0.5, 0) }}>
						<TextField
							label="Task Description"
							aria-label="Task Description"
							title="Task Description"
							value={state.data.TaskDesc || ''}
							onChange={(e) =>
								handleChange('TaskDesc', e.target.value)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
							multiline
							minRows={4}
							disabled={state.working}
						/>
					</FlexColumn>
					<FlexColumn style={{ padding: theme.spacing(0.5, 0) }}>
						<TextField
							label="Task Notes"
							aria-label="Task Notes"
							title="Task Notes"
							value={state.data.TaskNotes || ''}
							onChange={(e) =>
								handleChange('TaskNotes', e.target.value)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
							multiline
							minRows={4}
							disabled={state.working}
						/>
					</FlexColumn>
					<FlexColumn style={{ padding: theme.spacing(0.5, 0) }}>
						<DatePicker
							label="Completed Date"
							value={
								state.data.CompletedDate
									? formatDate(state.data.CompletedDate)
									: ''
							}
							onChange={(value) =>
								handleChange('CompletedDate', value)
							}
							disabled={state.working}
						/>
					</FlexColumn>
				</Container>
			)}
		</Dialog>
	);
};
