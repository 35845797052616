import { mdiFolderOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useAppContext } from '../../contexts/AppContext';
import { Progress } from '../common';
import { useS3FileBrowserContext } from './S3FileBrowserContext';
import { useUpload } from './useUpload';
import { FileData } from './utils';

type UploadProps = {
	folder: string;
	onUploadComplete: (files: FileData[]) => void;
	onError: (error: any) => void;
};

export const Upload = ({
	folder,
	onUploadComplete,
	onError,
}: UploadProps) => {
	const { user } = useAppContext();
	const { root } = useS3FileBrowserContext();

	const namePrefix = user.EmployeeNumber;

	const {
		getRootProps,
		getInputProps,
		status,
		totalUploadSize,
		currentUploadSize,
		error,
	} = useUpload({ folder, onUploadComplete, onError, namePrefix });

	return (
		<Box  component="span" sx={theme => ({
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			padding: theme.spacing(2),
			backgroundColor: '#ffffff',
		})}>
			{status === 'uploading' && (
				<Box  component="span" sx={theme => ({
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					padding: theme.spacing(1),
					flexGrow: 1,
					border: '1px dashed #444444',
					cursor: 'pointer',
					backgroundColor: '#f6f6f6',
					'& span': {
						fontSize: theme.typography.caption.fontSize,
					},
				})}>
					<Progress
						total={totalUploadSize}
						current={currentUploadSize}
						show={true}
					/>
					<span>Please do not leave this page during the upload</span>
				</Box>
			)}
			{status !== 'uploading' && (
				<Box  component="span" sx={theme => ({
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					padding: theme.spacing(1),
					flexGrow: 1,
					border: '1px dashed #444444',
					cursor: 'pointer',
					backgroundColor: '#f6f6f6',
					'& span': {
						fontSize: theme.typography.caption.fontSize,
					},
				})} {...getRootProps()}>
					<input {...getInputProps()} />
					<Typography variant="body1" style={{ fontWeight: 'bold' }}>
						Drop or click to upload to:
					</Typography>
					<ListItem style={{ width: 'unset' }}>
						<ListItemIcon>
							<Icon path={mdiFolderOutline} size={1.75} />
						</ListItemIcon>
						<ListItemText
							primary="Folder"
							secondary={folder.replace(root, 'Job/')}
							primaryTypographyProps={{
								style: { fontSize: '1em' },
							}}
							secondaryTypographyProps={{
								style: { fontSize: '1em' },
							}}
						/>
					</ListItem>
				</Box>
			)}
			{!!error && (
				<Box component="span" sx={theme => ({
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					padding: theme.spacing(1),
					flexGrow: 1,
					border: '1px dashed #444444',
					cursor: 'pointer',
					backgroundColor: '#f6f6f6',
					'& span': {
						fontSize: theme.typography.caption.fontSize,
					},
				})}>
					<p>Errors Occurred During Upload</p>
					<p>{error}</p>
				</Box>
			)}
		</Box>
	);
};
