export type S3ObjectType = 'folder' | 'file';

export interface S3Object {
	key: string;
	name: string;
	size: number;
	lastModified: Date;
	type: S3ObjectType;
	isThumbnail?: boolean;
}

export const errorItem: S3Object = {
	key: '',
	name: 'Error',
	size: 0,
	lastModified: new Date('1900-01-01'),
	type: 'folder',
};

export type S3BrowserView = 'list' | 'thumbnail';

export type AddSubFolderEvent = (obj: S3Object) => void;
export type RenameFolderEvent = (obj: S3Object) => void;
export type RemoveFolderEvent = (obj: S3Object) => void;
export type DownloadFolderEvent = (obj: S3Object) => void;
export type DownloadFileEvent = (obj: S3Object) => void;
export type ToggleViewEvent = () => void;

export type FileRenamedEvent = (obj: S3Object) => void;

export type OpenGalleryEvent = (files: S3Object[], index: number) => void;
export type OpenUploadEvent = (folder: S3Object) => void;

// Triggers

export type ErrorEvent = (error: any) => void;
export type FileListEvent = (objects: S3Object[]) => void;
export type FolderAddedEvent = (obj: S3Object) => void;
export type FolderRemovedEvent = (obj: S3Object) => void;
export type FolderRenamedEvent = (source: S3Object, dest: S3Object) => void;
export type FolderMovedEvent = (source: S3Object, dest: S3Object) => void;
export type FileAddedEvent = (obj: S3Object) => void;
export type FileRemovedEvent = (obj: S3Object) => void;
// export type FileRenamedEvent = (source: S3Object, dest: S3Object) => void;
export type FileMovedEvent = (source: S3Object, dest: S3Object) => void;
export type FileOpenedEvent = (obj: S3Object) => void;
export type SelectionChangedEvent = (objects: S3Object[]) => void;
export type ViewChangedEvent = (view: S3BrowserView) => void;
export type FolderChangedEvent = (obj: S3Object) => void;

// Info Events

export const defaultFolders = [
	'Pictures/',
	'Pictures/Before/',
	'Pictures/After/',
	'Pictures/During/',
	'Pictures/Punch Out/',
];

export type GalleryData = {
	files: S3Object[];
	index: number;
};

export type UploadDialogData = {
	folder: S3Object;
};
