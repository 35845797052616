/* eslint-disable no-console */
import { UserMarketingTasks } from '../core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Lib
import type { RootState } from './index';

export interface MarketingTaskState {
	items: UserMarketingTasks[];
}

const initialState: MarketingTaskState = {
	items: [],
};

export const slice = createSlice({
	name: 'marketingTasks',
	initialState,
	reducers: {
		loadMarketingTasks: (
			state,
			action: PayloadAction<UserMarketingTasks[]>
		) => {
			state.items = action.payload;
		},
	},
});

export const { loadMarketingTasks } = slice.actions;

export const selectMarketingTasks = (state: RootState) =>
	state.marketingTasks.items;

export default slice.reducer;
