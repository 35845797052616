import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import { JobSearchOptions, JobSummary } from '../../core';
import { QueryStatus, QueryPage } from '../common';
import { JobListItem } from './JobListItem';
import { useRecentItems } from '../recent';
import { useAppContext } from '../../contexts/AppContext';
import { JobsListFilter } from './JobListFilter';
import useQuery from '../useQuery';

type Props = {
	onNavigate: (url: string) => void;
	show: boolean;
};

export function JobsList({ onNavigate, show }: Props) {
	const theme = useTheme();
	const { api, user, isMobileLayout } = useAppContext();
	const { addRecentJob } = useRecentItems();

	const [loaded, setLoaded] = useState(false);
	const [options, setOptions] = useState<JobSearchOptions>({
		scope: user.UserRoleID !== 4 ? 'all' : 'open',
	});
	// const [search, setSearch] = useState('');
	// const [status, setStatus] = useState<string | undefined>();
	// const [scope, setScope] = useState<'all' | 'open'>(
	// 	user.UserRoleID !== 4 ? 'all' : 'open'
	// );

	const handleOpenJob = (job: JobSummary) => {
		const url = `/jobs/${job.JobID}`;
		addRecentJob({
			id: job.JobID,
			name: job.JobName,
			number: job.JobNumber,
			desc: job.DamageDesc,
		});
		onNavigate(url);
	};

	const query = useQuery<JobSummary>({
		queryCallback: (limit: number, offset: number) =>
			api.job.query({ ...options, limit, offset }),
		queryCountCallback: () => api.job.queryCount({ ...options }),
		shouldExecute: () => loaded,
		arguments: [options, loaded],
		limit: 50,
		delay: 500,
	});

	useEffect(() => {
		if (show) {
			setLoaded(true);
		}
	}, [show]);

	if (!show) return null;

	return (
		<div
			data-component="job-list"
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				overflow: 'hidden',
			}}
		>
			<JobsListFilter
				busy={query.status === 'busy'}
				options={options}
				onFilterChange={(options) => setOptions(options)}
			/>
			{isMobileLayout && <QueryPage queryResult={query} />}
			<QueryStatus
				status={query.status}
				idle="Enter your search criteria to see results"
				noresults="No results match your search criteria"
				scrollable
				error={query.error}
				sx={{ padding: theme.spacing(1) }}
			>
				<List>
					{query.results.map((item) => (
						<JobListItem
							key={item.JobID}
							job={item}
							onClick={(job) => handleOpenJob(job)}
						/>
					))}
				</List>
				{isMobileLayout && (
					<div style={{ marginBottom: '200px' }}>&nbsp;</div>
				)}
			</QueryStatus>
			{!isMobileLayout && <QueryPage queryResult={query} />}
		</div>
	);
}
