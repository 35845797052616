import { Box } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';

// Provides a little extra scrolling room on mobile devices
export const BottomSpace = () => {
	const { isMobileLayout } = useAppContext();
	if (!isMobileLayout) return null;
	return <Box sx={{
		display: 'block',
		minHeight: '200px',
		height: '200px',
	}}>&nbsp;</Box>;
};
