import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactData, LookupTypes } from '../../core';
import { Toolbar } from '../common';
import { useLookupTypes } from '../lookups';
import { useRecentItems } from '../recent';
import { AddContact } from './AddContact';
import { useAddContact } from './useAddContact';

export const AddContactContainer = () => {
	const navigate = useNavigate();
	const { addRecentContact } = useRecentItems();
	const [contactTypes] = useLookupTypes([LookupTypes.ContactType]);
	const controller = useAddContact({});

	const { working, isContactValid, error } = controller;

	const handleOpenContact = React.useCallback(
		async (contact: ContactData) => {
			addRecentContact({
				id: contact.ContactID,
				name: [contact.FirstName, contact.LastName].join(', '),
				lookupType:
					contactTypes?.find(
						(x: any) => x.ID === contact.ContactTypeID
					)?.Name || '',
				contactType: 'Contact',
			});
			navigate(`/contacts/${contact.ContactID}`);
		},
		[addRecentContact, contactTypes, navigate]
	);

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			height: '100%',
			overflow: 'hidden',
		}}>
			<Toolbar>
				<h4 style={{ flexGrow: 1 }}>Add New Contact</h4>
				<Button
					variant="contained"
					color="secondary"
					disabled={working || !!error || !isContactValid}
					onClick={() => controller.createContact()}
				>
					Create Contact
				</Button>
			</Toolbar>
			<AddContact
				controller={controller}
				onContactAdded={handleOpenContact}
			/>
		</Box>
	);
};
