import { LookupDetail } from '../core';
import { ApiClientInterface } from './client';

export default class LookupApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
	}

	public async query(): Promise<LookupDetail[]> {
		const response = await this.client.get<LookupDetail[]>(`/lookup`);
		return response;
	}
}
