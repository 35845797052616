import {
	mdiCardAccountMailOutline,
	mdiHomeCityOutline,
	mdiOpenInNew,
	mdiPencilOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';
import { JobContactData, JobDetail, LookupTypes } from '../../../core';
import { ApplicationEvent } from '../../ApplicationEvent';
import { ConfirmDialog } from '../../common';
import { EmailCard, PhoneCard } from '../../contacts';
import { useLookupTypes } from '../../lookups';
import { useRecentItems } from '../../recent';
import { AddJobsiteContactDialog } from './AddJobsiteContactDialog';
import { EditJobsiteContactDialog } from './EditJobsiteContactDialog';

type Props = {
	canEditJob?: boolean;
	job?: JobDetail;
	onCountChanged: (count: number) => void;
};

export const JobsiteContactList = (props: Props) => {
	const theme = useTheme();
	const { api } = useAppContext();
	const navigate = useNavigate();
	const { addRecentContact } = useRecentItems();
	const { job, onCountChanged, canEditJob } = props;
	const [removeConfirmation, setRemoveConfirmation] =
		React.useState<JobContactData>();
	const [contactTypes] = useLookupTypes([LookupTypes.JobsiteContactType]);
	const [contacts, setContacts] = useState<JobContactData[]>([]);

	const [addJobsiteContact, setAddJobsiteContact] = useState(false);
	const [editJobsiteContact, setEditJobsiteContact] =
		React.useState<JobContactData>();

	const jobId = job?.JobID;

	const loadContacts = React.useCallback(async () => {
		if (jobId) {
			const contacts = await api.job.queryJobsiteContacts(jobId);
			setContacts(contacts);
			onCountChanged(contacts.length);
		}
	}, [api.job, jobId, onCountChanged]);

	useEffect(() => {
		loadContacts();
	}, [loadContacts]);

	useEffect(() => {
		const unsubscribe = ApplicationEvent.OnReloadJob(loadContacts);
		return () => unsubscribe();
	}, [loadContacts]);

	const getContactType = (id: number) => {
		return contactTypes?.find((x: any) => x.ID === id)?.Name || '';
	};

	const handleRemoveContact = async () => {
		if (removeConfirmation) {
			try {
				await api.job.removeJobsiteContact(
					removeConfirmation.JobID,
					removeConfirmation.ID
				);
				loadContacts();
				setRemoveConfirmation(undefined);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleOpenContact = (data: JobContactData) => {
		if (data.ContactID) {
			addRecentContact({
				contactType: 'Contact',
				id: data.ContactID,
				name: data.ContactName,
				lookupType: getContactType(data.ContactTypeID),
			});
			navigate(`/contacts/${data.ContactID}`);
		}
	};

	if (!job) return null;

	return (
		<>
			{canEditJob && (
				<>
					<Toolbar disableGutters sx={{ padding: theme.spacing(0) }}>
						<span style={{ flexGrow: 1 }} />
						<Button onClick={() => setAddJobsiteContact(true)}>
							Add Jobsite Contact
						</Button>
					</Toolbar>
					{/* <Divider /> */}
				</>
			)}
			{contacts.map((item) => (
				<Box key={`${item.Type}-${item.ID}`} sx={{
					display: 'flex',
					flexDirection: 'column',
					borderBottom: '1px solid rgba(0,0,0,0.3)',
				}}>
					<Box sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						padding: theme.spacing(1, 1, 0, 1),
					}}>
						<Tooltip title={`${item.Type} Contact`}>
							<Icon
								path={
									item.Type === 'Jobsite'
										? mdiCardAccountMailOutline
										: mdiHomeCityOutline
								}
								size={1}
							/>
						</Tooltip>
						<Box sx={{
							flexGrow: 1,
							fontWeight: 'bold',
							padding: theme.spacing(1),
						}}>{item.ContactName}</Box>
						<Box>
							<Tooltip title="Contact Type">
								<span>
									{getContactType(item.ContactTypeID)}
								</span>
							</Tooltip>
						</Box>
						{item.ContactID && (
							<Tooltip title="Open Contact">
								<IconButton
									size="small"
									aria-label="Open Contact"
									onClick={() => handleOpenContact(item)}
								>
									<Icon path={mdiOpenInNew} size={0.8} />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title="Edit Contact">
							<IconButton
								size="small"
								aria-label="Edit Contact"
								onClick={() => setEditJobsiteContact(item)}
								style={{ marginLeft: '10px' }}
							>
								<Icon path={mdiPencilOutline} size={0.8} />
							</IconButton>
						</Tooltip>
					</Box>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						padding: theme.spacing(0, 1, 2, 1),
					}}>
						{item.PhoneNumber && (
							<PhoneCard phone={item.PhoneNumber} />
						)}
						{item.EmailAddress && (
							<EmailCard address={item.EmailAddress} />
						)}
					</Box>
				</Box>
			))}
			{!!addJobsiteContact && jobId && (
				<AddJobsiteContactDialog
					jobId={jobId}
					onClose={() => setAddJobsiteContact(false)}
					onJobsiteContactAdded={() => {
						setAddJobsiteContact(false);
						loadContacts();
					}}
				/>
			)}
			{!!editJobsiteContact && jobId && (
				<EditJobsiteContactDialog
					jobId={jobId}
					contact={editJobsiteContact}
					onClose={() => setEditJobsiteContact(undefined)}
					onJobsiteContactRemoved={() => {
						setEditJobsiteContact(undefined);
						loadContacts();
					}}
					onJobsiteContactUpdated={() => {
						setEditJobsiteContact(undefined);
						loadContacts();
					}}
				/>
			)}
			{!!removeConfirmation && (
				<ConfirmDialog
					isOpen={true}
					title="Remove Contact?"
					question={`Are you sure you want to remove ${removeConfirmation.ContactName} from Other Contacts?`}
					onCancel={() => setRemoveConfirmation(undefined)}
					onConfirm={handleRemoveContact}
				/>
			)}
		</>
	);
};
