import { mdiCurrencyUsd, mdiPercent } from '@mdi/js';
import Icon from '@mdi/react';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { JobData, JobDetail, LookupTypes } from '../../../core';
import { AutoComplete, AutoCompleteLookup, Card } from '../../common';
import { EmployeeCard } from '../../employees';
import { useLookupType } from '../../lookups/useLookupType';
import CompanySelection from "../../selection/CompanySelection";
import ContactSelection from "../../selection/ContactSelection";
import { useTheme } from '@mui/material';
import { useTaskTemplates } from '../../taskTemplates/useTaskTemplates';

type Props = {
	canEditJob?: boolean;
	job?: JobDetail;
	onChange: (data: Partial<JobData>) => void;
};

export const JobReferral = ({ job, onChange, canEditJob }: Props) => {
	const theme = useTheme();
	const agentTypeId = useLookupType(LookupTypes.ContactType, 'Agent').ID;
	const taskTemplates = useTaskTemplates();
	const agentCompanyTypeId = useLookupType(
		LookupTypes.CompanyType,
		'Agent Company'
	).ID;
	const iadjusterTypeId = useLookupType(
		LookupTypes.ContactType,
		'Independant Adjuster'
	).ID;
	const iaCompanyTypeId = useLookupType(
		LookupTypes.CompanyType,
		'Independant Adjuster'
	).ID;
	const adjusterTypeId = useLookupType(
		LookupTypes.ContactType,
		'Adjuster/Customer'
	).ID;
	const adjusterCompanyTypeId = useLookupType(
		LookupTypes.CompanyType,
		'Adjuster Company'
	).ID;

	const taskTemplateOptions = taskTemplates.map((item) => ({
		label: item.name,
		value: item.id,
	}));

	if (!job) return null;

	return (
		<Card label="Referral / Stakeholders">
			<Grid container direction="row" wrap="wrap" spacing={1}>
				<Grid item style={{ flexGrow: 1, minWidth: '50%', marginBottom: theme.spacing(1) }}>
					<AutoCompleteLookup
						label="Reported By"
						placeholder="Reported By"
						lookupType={LookupTypes.ReportedByType}
						value={job.CalledInBy || null}
						disabled={!canEditJob}
						onChange={(value) =>
							onChange({
								...job,
								CalledInBy: value || undefined,
							})
						}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1, minWidth: '50%' }}>
					<AutoCompleteLookup
						label="Referral Type"
						placeholder="Referral Type"
						lookupType={LookupTypes.ReferredByType}
						value={job.ReferrelTypeID || null}
						disabled={!canEditJob}
						onChange={(value) =>
							onChange({
								...job,
								ReferrelTypeID: value || undefined,
							})
						}
					/>
				</Grid>
			</Grid>
			<Grid container direction="row" wrap="wrap" spacing={1}>
				<Grid item style={{ flexGrow: 1, minWidth: '50%' }}>
					<CompanySelection
						title="Referral Company"
						companyId={job.RefererCompanyID}
						companyTypeIds={[378, 588]}
						onAddClick={() => { }}
						onSelected={company => onChange({
							...job,
							RefererCompanyID:
								company?.CompanyID || undefined,
						})}
						disabled={!canEditJob}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1, minWidth: '50%', margin: theme.spacing(0,0,1,0) }}>
					<ContactSelection
						title="Referral Contact"
						contactId={job.ReferredContactID}
						companyId={job.RefererCompanyID}
						onSelected={(contact, company) => {
							onChange({
								...job,
								ReferredContactID:
									contact?.ContactID || undefined,
								RefererCompanyID: company ? company.CompanyID : undefined,
							})
						}}
						disabled={!canEditJob}
					/>
				</Grid>
			</Grid>

			<Grid container direction="row" wrap="wrap" spacing={1} sx={{ marginBottom: theme.spacing(1) }}>
				<Grid item>
					<TextField
						label="Referral Fee"
						aria-label="Referral Fee"
						title="Referral Fee"
						value={job.ReferredByFee || ''}
						type="number"
						disabled={!canEditJob}
						inputProps={{
							onWheel: (e) =>
								(e.target as HTMLInputElement).blur(),
						}}
						onChange={(e) =>
							onChange({
								...job,
								ReferredByFee:
									parseFloat(e.target.value) || undefined,
							})
						}
						variant="outlined"
						margin="dense"
						style={{ maxWidth: 100 }}
						InputLabelProps={{ shrink: true }}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icon
										path={mdiCurrencyUsd}
										size={0.6}
										color="rgba(0,0,0,.5)"
									/>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item>
					<TextField
						label="Referral %"
						aria-label="Referral %"
						title="Referral %"
						value={job.ReferredByPerc || ''}
						type="number"
						disabled={!canEditJob}
						inputProps={{
							onWheel: (e) =>
								(e.target as HTMLInputElement).blur(),
						}}
						onChange={(e) =>
							onChange({
								...job,
								ReferredByPerc:
									parseFloat(e.target.value) || undefined,
							})
						}
						variant="outlined"
						margin="dense"
						style={{ maxWidth: 100 }}
						InputLabelProps={{ shrink: true }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon
										path={mdiPercent}
										size={0.6}
										color="rgba(0,0,0,.5)"
									/>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}>
					<EmployeeCard
						label="Lead Taker"
						employeeNumber={job.LeadTaker}
						onClick={() => { }}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}>
					<AutoComplete
						label="Task Template"
						placeholder="Task Template"
						disabled
						value={job.TaskTemplateID || null}
						options={taskTemplateOptions}
						onChange={() => { }}
					/>
				</Grid>
			</Grid>

			<Grid container direction="row" wrap="wrap" spacing={1}>
				<Grid item style={{ flexGrow: 1, minWidth: '50%', marginBottom: theme.spacing(1)  }}>
					<CompanySelection
						title="Ins / PM Company"
						companyId={job.AdjusterCompanyID}
						companyTypeIds={[378, 588]}
						onAddClick={() => { }}
						onSelected={company => onChange({
							...job,
							AdjusterCompanyID:
								company?.CompanyID || undefined,
						})}
						disabled={!canEditJob}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1, minWidth: '50%' }}>
					<ContactSelection
						title="Agent"
						contactId={job.AgentID}
						contactTypeIds={!!agentTypeId ? [agentTypeId] : undefined}
						companyTypeIds={!!agentCompanyTypeId ? [agentCompanyTypeId] : undefined}
						onSelected={(contact) => {
							onChange({
								...job,
								AgentID: contact?.ContactID || undefined,
							})
						}}
						disabled={!canEditJob}
					/>
				</Grid>
			</Grid>
			<Grid container direction="row" wrap="wrap" spacing={1}>
				<Grid item style={{ flexGrow: 1, minWidth: '50%', marginBottom: theme.spacing(1) }}>
					<ContactSelection
						title="Adjuster"
						contactId={job.AdjusterID}
						contactTypeIds={!!adjusterTypeId ? [adjusterTypeId] : undefined}
						companyTypeIds={!!adjusterCompanyTypeId ? [adjusterCompanyTypeId] : undefined}
						onSelected={(contact, company) => {
							onChange({
								...job,
								AdjusterID: contact?.ContactID || undefined,
								AdjusterCompanyID: company?.CompanyID
							})
						}}
						disabled={!canEditJob}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1, minWidth: '50%' }}>
					<ContactSelection
						title="Independent Adjuster"
						contactId={job.IAAdjusterID}
						contactTypeIds={!!iadjusterTypeId ? [iadjusterTypeId] : undefined}
						companyTypeIds={!!iaCompanyTypeId ? [iaCompanyTypeId] : undefined}
						onSelected={(contact) => {
							onChange({
								...job,
								IAAdjusterID: contact?.ContactID || undefined,
							})
						}}
						disabled={!canEditJob}
					/>
				</Grid>
			</Grid>
			<Grid container direction="row" wrap="wrap" spacing={1} sx={{marginBottom: theme.spacing(1)}}>
				<Grid item style={{ flexGrow: 1, minWidth: '50%' }}>
					<TextField
						label="Claim Number"
						aria-label="Claim Number"
						title="Claim Number"
						value={job.InsClaimNumber || ''}
						disabled={!canEditJob}
						onChange={(e) =>
							onChange({ ...job, InsClaimNumber: e.target.value })
						}
						variant="outlined"
						margin="dense"
						fullWidth
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1, minWidth: '50%' }}>
					<TextField
						label="Deductible"
						aria-label="Deductible"
						title="Deductible"
						value={job.InsDeductableAmount || ''}
						disabled={!canEditJob}
						onChange={(e) =>
							onChange({
								...job,
								InsDeductableAmount: e.target.value,
							})
						}
						variant="outlined"
						margin="dense"
						fullWidth
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
			</Grid>

			<Grid container direction="row" wrap="wrap" spacing={1}>
				<Grid item style={{ flexGrow: 1, minWidth: '50%' }}>
					<TextField
						label="Policy Number"
						aria-label="Policy Number"
						title="Policy Number"
						value={job.InsPolicyNumber || ''}
						disabled={!canEditJob}
						onChange={(e) =>
							onChange({
								...job,
								InsPolicyNumber: e.target.value,
							})
						}
						variant="outlined"
						margin="dense"
						fullWidth
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
				<Grid item style={{ flexGrow: 1, minWidth: '50%' }}>
					<AutoCompleteLookup
						lookupType={LookupTypes.StormLog}
						label="Storm Event"
						placeholder="Storm Event"
						autoOpen
						// error={!!validationErrors['StormID']}
						emptyOption={{ value: -1, label: 'None' }}
						value={job.StormID || null}
						disabled={!canEditJob}
						onChange={(value) =>
							onChange({
								...job,
								StormID:
									!!value && value > 0 ? value : undefined,
							})
						}
					/>
				</Grid>
			</Grid>
		</Card>
	);
};
