import { mdiCheckboxMarkedCircle, mdiOpenInNew, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { CompanyContactViewData } from '../../core';
import { EmailCard, NameCard, PhoneCard } from '../contacts';

type ContactListItemProps = {
	contact: CompanyContactViewData;
	onSetPrimary: (contact: CompanyContactViewData) => void;
	onRemove: (contact: CompanyContactViewData) => void;
	onOpen: (contact: CompanyContactViewData) => void;
	sx: any;
	canEdit?: boolean;
};

export const ContactListItem = (props: ContactListItemProps) => {
	const theme = useTheme();
	const {
		contact,
		onSetPrimary,
		onRemove,
		onOpen,
		sx = {},
		canEdit,
	} = props;

	return (
		<Box
			sx={theme => ({
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				borderLeft: contact.isPrimary ? `4px solid ${theme.palette.secondary.main}` : undefined,
				...sx
			})}
		>
			<Grid
				container
				direction="row"
				alignItems="flex-start"
				wrap="nowrap"
			>
				<Grid item>
					<Tooltip title="Open Contact">
						<IconButton
							aria-label="Open Contact"
							onClick={() => onOpen(contact)}
						>
							<Icon path={mdiOpenInNew} size={1} />
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item style={{ flexGrow: 1 }}>
					<Grid
						container
						direction="row"
						alignItems="center"
						wrap="wrap"
					>
						<Grid item>
							<NameCard
								label="Name"
								name={contact.fullname}
								variant="contact"
								sx={{ margin: theme.spacing(0.5) }}
								onClick={() => onOpen(contact)}
							/>
						</Grid>
						<Grid item>
							<PhoneCard
								label="Primary Phone"
								phone={contact.phonenumber}
								extension={contact.phoneext}
								sx={{ margin: theme.spacing(0.5) }}
							/>
						</Grid>
						<Grid item>
							<PhoneCard
								label="Fax"
								phone={contact.faxnumber}
								isCallable={false}
								sx={{ margin: theme.spacing(0.5) }}
							/>
						</Grid>
						<Grid item>
							<EmailCard
								label="Primary Email"
								address={contact.emailaddress}
								sx={{ margin: theme.spacing(0.5) }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container direction="row" alignItems="center">
				<Grid item style={{ flexGrow: 1 }}>
					<Toolbar variant="dense" disableGutters>
						<span style={{ flexGrow: 1 }} />
						<Tooltip title="Set Primary">
							<IconButton
								aria-label="Set Primary"
								onClick={
									canEdit
										? () => onSetPrimary(contact)
										: undefined
								}
								sx={theme => ({
									color: contact.isPrimary ? theme.palette.secondary.main : undefined
								})}
							>
								<Icon path={mdiCheckboxMarkedCircle} size={1} />
							</IconButton>
						</Tooltip>
						{canEdit && (
							<Tooltip title="Remove Contact">
								<IconButton
									aria-label="Remove Contact"
									onClick={() => onRemove(contact)}
								>
									<Icon path={mdiTrashCan} size={1} />
								</IconButton>
							</Tooltip>
						)}
					</Toolbar>
				</Grid>
			</Grid>
		</Box>
	);
};
