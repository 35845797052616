import { useLookupTypes } from '../lookups';
import { AutoComplete, AutoCompleteOptionOf } from './AutoComplete';

type AutoCompleteLookupProps = {
	label?: string;
	placeholder?: string;
	lookupType: string;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	autoOpen?: boolean;
	value: number | null;
	emptyOption?: AutoCompleteOptionOf<number>;
	onChange: (value: number | null) => void;
	typesFilter?: string[];
	descriptionAsLabel?: boolean;
};

export const AutoCompleteLookup = (props: AutoCompleteLookupProps) => {
	const {
		lookupType,
		label,
		placeholder,
		value,
		onChange,
		required,
		error,
		disabled,
		autoOpen,
		emptyOption,
		typesFilter,
		descriptionAsLabel = false,
	} = props;
	const [types] = useLookupTypes([lookupType]);

	const filteredTypes =
		typesFilter && typesFilter.length > 0
			? types?.filter((x: any) => typesFilter.includes(x.Name)) || []
			: types || [];

	const options: AutoCompleteOptionOf<number>[] = filteredTypes.map(
		(type: any) => ({
			label: descriptionAsLabel
				? type.Description || type.ShortText || type.Name
				: type.Name,
			value: type.ID,
		})
	);

	const currentOption: AutoCompleteOptionOf<number> | null =
		options.find((x) => x.value === value) || null;

	if (emptyOption) {
		options.splice(0, 0, emptyOption);
	}

	return (
		<AutoComplete
			label={label}
			placeholder={placeholder}
			value={currentOption?.value || null}
			onChange={(value) => onChange(value as number | null)}
			required={required}
			disabled={disabled}
			error={error}
			fixed={true}
			autoSelect={true}
			autoOpen={autoOpen}
			options={options}
		/>
	);
};
