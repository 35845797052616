import { Box, Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { useAppSelector } from '../../store';
import { User } from '../auth';
import { selectAllEmployees } from '../employees';
import { AutoCompleteOptionOf } from './AutoComplete';

export type EmployeeDropDownProps = {
	label: string;
	placeholder: string;
	required?: boolean;
	disabled?: boolean;
	autoOpen?: boolean;
	error?: boolean;
	value: string | null;
	emptyOption?: AutoCompleteOptionOf<string>;
	filter: (employees: User[]) => User[];
	onChange: (employeeNumber: string | null) => void;
};

interface Option extends AutoCompleteOptionOf<string> {
	isActive?: boolean;
}

export const EmployeeDropDown = (props: EmployeeDropDownProps) => {
	const {
		label,
		value,
		onChange,
		required,
		autoOpen,
		disabled,
		error,
		filter = (users) => users,
	} = props;
	const employees = useAppSelector(selectAllEmployees);

	const ActiveOptions: Option[] = filter(employees)
		.filter((x) => x.isActive === true)
		.map((item) => ({
			label: item.EmployeeName,
			value: item.EmployeeNumber,
			isActive: item.isActive === true,
		}));

	const InactiveOptions: Option[] = filter(employees)
		.filter((x) => x.isActive !== true)
		.map((item) => ({
			label: item.EmployeeName,
			value: item.EmployeeNumber,
			isActive: item.isActive === true,
		}));

	const options = [...ActiveOptions, ...InactiveOptions];

	const handleOnChange = (option: Option | null) => {
		if (!option) {
			onChange(null);
		} else {
			const opt = options.find((x) => x.value === option.value);
			if (!opt) {
				onChange(null);
			} else {
				if (opt.isActive === true) {
					onChange(option.value);
				} else {
					onChange(value);
				}
			}
		}
	};

	return (
		<MuiAutocomplete
			getOptionLabel={(option) => option.label}
			autoHighlight
			fullWidth
			freeSolo={false}
			autoComplete={false}
			disabled={disabled}
			autoSelect={false}
			clearOnEscape
			openOnFocus={autoOpen && !value}
			value={options.find((x) => x.value === value) || null}
			onChange={(_event, option) => handleOnChange(option)}
			options={options}
			renderOption={(props, option) => (
				<Box
					{...(props as any)}
					sx={{
						color: option.isActive !== true ? '#cccccc' : undefined,
					}}
				>
					<strong>{option.value}</strong> - {option.label}
				</Box>
			)}
			style={{ minWidth: '250px' }}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					// placeholder={placeholder || label}
					autoComplete="off"
					variant="outlined"
					size="small"
					margin="dense"
					fullWidth
					required={required}
					disabled={disabled}
					error={error}
					onFocus={(event) => event.target.select()}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						...params.InputProps,
						autoComplete: 'off',
						required: required,
						disabled: disabled,
						error: error,
					}}
				/>
			)}
		/>
	);
};
