import { ApiClientInterface } from './client';

export default class HealthApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.getIp = this.getIp.bind(this);
	}

	public async getIp(): Promise<{ ip?: string }> {
		const response = await this.client.get<{ ip?: string }>(`/health/ip`);
		return response;
	}
}
