import { useCallback, useEffect, useState } from "react";
import { api } from "../../api";
import { ContactViewData } from "../../core";

export default function useContact(contactId?: number) {
    const [contact, setContact] = useState<ContactViewData>();

    const getContact = useCallback(async (id: number) => {
        try {
            const result = await api.contact.getView(id);
            setContact(result);
        } catch (error) {
            setContact(undefined);
            console.error(error);
        }
    }, []);

    useEffect(() => {
        if (contactId) {
            if (!contact || contact.ContactID !== contactId) {
                getContact(contactId);
            }
        } else {
            setContact(undefined);
        }
    }, [contact, contactId, getContact])

    return contact;
}