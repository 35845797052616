import { User } from '../../core';

export const validateUser = (user: Partial<User>): string | undefined => {
	if (!user.EmployeeNumber) return 'Please provide an Employee Number';
	if (!user.EmployeeName) return 'Please provide an Employee Name';
	if (!user.UserRoleID) return 'Please provide a User Role';
	if (
		!user.usr1 &&
		!user.usr2 &&
		!user.usr3 &&
		!user.usr4 &&
		!user.usr5 &&
		!user.usr6 &&
		!user.usr7 &&
		!user.usr8 &&
		!user.usr9
	) {
		return 'Please select at least one User Flag';
	}
};
