import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const Loading = () => {
	return (
		<Backdrop
			sx={theme => ({
				zIndex: theme.zIndex.drawer + 10,
				color: '#fff',
				display: 'fixed',
				flexDirection: 'column',
				flexGrow: 1,
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				width: '100%',
				height: '100%',
				position: 'relative',
				backgroundColor: 'transparent',
			})}
			open={true}>
			<CircularProgress color="secondary" />
		</Backdrop>
	);
};
