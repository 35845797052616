import { Box } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import { NavigationButton } from '../common';
import { createNavigation } from './utils';

type SidebarIconsProps = {
	selected: string;
	onClick: (key: string) => void;
};

export function SidebarIcons(props: SidebarIconsProps) {
	const { hasPermission } = useAppContext();
	const { selected, onClick } = props;
	const [sidebarItems, sidebarBottomItems] = createNavigation(hasPermission);

	return (
		<Box sx={theme => ({
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.palette.primary.main,
			padding: theme.spacing(0, 0, 2, 0),
			'& button': {
				color: '#ffffff',
			},
		})}>
			{sidebarItems.map((item) => (
				<NavigationButton
					key={item.key}
					title={item.key}
					onClick={() => onClick(item.key)}
					icon={item.icon}
					iconSize={1}
					selected={selected === item.key}
				/>
			))}
			<span style={{ flexGrow: 1 }} />
			{sidebarBottomItems.map((item) => (
				<NavigationButton
					key={item.key}
					title={item.key}
					onClick={() => onClick(item.key)}
					icon={item.icon}
					iconSize={1}
					selected={selected === item.key}
				/>
			))}
		</Box>
	);
}
