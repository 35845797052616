import { Box } from '@mui/material';
import { FolderItem } from './FolderItem';
import { useS3FileBrowserContext } from './S3FileBrowserContext';
import { FolderChangedEvent } from './types';
import { getSubFolderObjects } from './utils';

type Props = {
	folder: string;
	onChangeFolder?: FolderChangedEvent;
	readOnly?: boolean;
	canDelete?: boolean;
};

export const S3Folders = ({
	folder,
	onChangeFolder,
	readOnly,
	canDelete,
}: Props) => {
	const { folders: allFolders } = useS3FileBrowserContext();
	const folders = getSubFolderObjects(allFolders, folder).filter(
		(x) => x.key !== folder
	);

	return (
		<Box sx={{
			display: 'flex',
			flexFlow: 'row wrap',
			alignItems: 'center',
			paddingLeft: '8px',
			marginBottom: '24px',
		}}>
			{folders.map((item) => (
				<FolderItem
					readOnly={readOnly}
					canDelete={canDelete}
					key={item.key}
					folder={item}
					onChangeFolder={onChangeFolder}
				/>
			))}
		</Box>
	);
};
