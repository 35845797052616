export const Permissions = {
	SYSADMIN: 'SYSADMIN',
	EditJobs: 'EditJobs',
	ViewJobs: 'ViewJobs',
	ViewAddressBook: 'ViewAddressBook',
	EditCompanies: 'EditCompanies',
	ViewCompanies: 'ViewCompanies',
	EditContacts: 'EditContacts',
	ViewContacts: 'ViewContacts',
	AdminJobs: 'AdminJobs',
	AccountingJobs: 'AccountingJobs',
	NewLead: 'NewLead',
	RunReports: 'RunReports',
	AddNote: 'AddNote',
};

/**
 * EVERYONE HAS THESE PERMISSION CURRENTLY
 * 	ViewJobs
 * 	ViewAddressBook
 * 	ViewCompanies
 * 	ViewContacts
 * 	AddNote
 */
