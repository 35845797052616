import { useCallback, useEffect, useState } from "react";

// Monorepo
import { StreetAddressData } from "../../core";

// Lib
import { api } from "../../api";

export default function useAddress(addressId?: number) {
    const [address, setAddress] = useState<StreetAddressData>();

    const getAddress = useCallback(async (id: number) => {
        try {
            const result = await api.address.get(id);
            setAddress(result);
        } catch (error) {
            setAddress(undefined);
            console.error(error);
        }
    }, []);

    useEffect(() => {
        if (addressId) {
            if (!address || address.AddressID !== addressId) {
                getAddress(addressId);
            }
        } else {
            setAddress(undefined);
        }
    }, [address, addressId, getAddress])

    return address;
}