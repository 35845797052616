import { mdiChevronLeft, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

type DialogToolbarProps = {
    title?: string;
    children?: React.ReactNode;
    onClose?: () => void;
    onGoBack?: () => void;
}

export const DialogToolbar = (props: DialogToolbarProps) => {
    const { title, children, onClose, onGoBack } = props;

    return (
        <AppBar elevation={0} position="static" color="default">
            <Toolbar variant="dense" sx={theme => ({
                margin: theme.spacing(0, -1),
                backgroundColor: '#ffffff',
                alignItems: 'center',
            })}>
                {onGoBack && (
                    <Tooltip title="Go Back" sx={theme => ({ marginLeft: theme.spacing(1.5) })}>
                        <IconButton edge="start" size="small" onClick={onGoBack}>
                            <Icon path={mdiChevronLeft} size={1} />
                        </IconButton>
                    </Tooltip>
                )}
                {title && (
                    <Box sx={theme => ({ fontSize: theme.typography.body2.fontSize, flexGrow: 1, })}>{title}</Box>
                )}
                {children}
                {onClose && (
                    <Tooltip title="Close" sx={theme => ({ marginLeft: theme.spacing(1.5) })}>
                        <IconButton edge="end" size="small" onClick={onClose}>
                            <Icon path={mdiClose} size={1} />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        </AppBar>
    );
}