export const useWindowOpen = () => {
	// return async (url: string): Promise<any> => {
	// 	const ref = window.open();
	// 	const promise = new Promise((resolve) => {
	// 		setTimeout(() => {
	// 			resolve(ref);
	// 		});
	// 	});
	// 	return promise.then(() => {
	// 		if (ref) {
	// 			ref.location.href = url;
	// 		}
	// 		return ref;
	// 	});
	// };
	return async (url: string): Promise<any> => {
		const a = document.createElement('a');
		a.href = url;
		// a.download = filename || 'download';
		a.target = '_blank';
		// const clickHandler = () => {
		// 	setTimeout(() => {
		// 		// URL.revokeObjectURL(url);
		// 		a.removeEventListener('click', clickHandler);
		// 	}, 150);
		// };
		// a.addEventListener('click', clickHandler, false);
		a.click();
		return a;
	};
};
