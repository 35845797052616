import { useState } from 'react';

export type SortDirection = 'Ascending' | 'Descending';

export interface SortOption {
	label: string;
	value: string;
	direction: SortDirection;
}

export interface UseSortOptions extends SortOption {
	options: SortOption[];
	onChangeSortOption: (value: string) => void;
	onToggleSortDirection: (value: string) => void;
}

export default function useSortOptions(
	defaultOptions: SortOption[]
): UseSortOptions {
	const [options, setOptions] = useState<SortOption[]>(defaultOptions);
	const [selectedOption, setSelectedOption] = useState<SortOption>(
		options[0] as SortOption
	);

	const onChangeSortOption = (value: string) => {
		const option = options.find((x) => x.value === value);
		if (option) {
			setSelectedOption(option);
		} else {
			setSelectedOption(options[0] as SortOption);
		}
	};

	const onToggleSortDirection = (value: string) => {
		const index = options.findIndex((x) => x.value === value);
		if (index !== -1) {
			const option = options[index] as SortOption;
			const newOptions = [...options];
			newOptions[index] = {
				label: option.label,
				value: option.value,
				direction:
					option.direction === 'Ascending'
						? 'Descending'
						: 'Ascending',
			};
			setOptions(newOptions);
			setSelectedOption(newOptions[index] as SortOption);
		}
	};

	return {
		onChangeSortOption,
		onToggleSortDirection,
		options,
		label: selectedOption.label,
		value: selectedOption.value,
		direction: selectedOption.direction,
	};
}
