import { useCallback, useState } from "react";
import { CompanyViewData, ContactData, ContactViewData } from "../../core";
import { FlexColumn } from '../common';
import { ContactAutoComplete } from './ContactAutoComplete';
import SelectionCard from "./SelectionCard";
import { CompanyNamesAutoComplete } from "./CompanyNamesAutoComplete";
import useContact from "../contacts/useContact";
import ContactListItem from "./ContactListItem";
import { api } from "../../api";
import { useRecentItems } from "../recent";
import { useWindowOpen } from "../useWindowOpen";
import { AddContactDialog } from "../contacts/AddContactDialog";

type Props = {
	title: string;
	companyTitle?: string;
	contactId?: number;
	companyId?: number;
	contactTypeIds?: number[];
	companyTypeIds?: number[];
	onSelected?: (contact?: ContactViewData, company?: CompanyViewData) => void;
	error?: boolean;
	disabled?: boolean;
	style?: React.CSSProperties;
	actions?: React.ReactNode;
};

export default function ContactSelection({
	title,
	companyTitle,
	contactId,
	contactTypeIds,
	companyTypeIds,
	companyId,
	onSelected,
	error,
	disabled,
	style,
	actions,
}: Props) {
	const { addRecentContact } = useRecentItems();
	const contact = useContact(contactId);
	const [companyName, setCompanyName] = useState('');
	const [showAddContactDialog, setShowAddContactDialog] = useState(false);
	const open = useWindowOpen();

	const handleOpenInNew = () => {
		if (contact && contact.ContactID) {
			addRecentContact({
				contactType: 'Contact',
				id: contact.ContactID,
				name:
					contact.fullname ||
					`${[contact.lastname, contact.firstname].join(', ')}`,
				lookupType: contact.ContactType || '',
			});
			open(`/contacts/${contact.ContactID}`);
		}
	};

	const handleContactSelection = useCallback(async (target?: ContactViewData | null) => {
		if (onSelected) {
			if (!target) {
				onSelected();
			} else {
				let company: CompanyViewData | undefined = undefined;
				if (target?.CurrentCompanyID) {
					try {
						company = await api.company.getView(target.CurrentCompanyID);
					} catch (error) {
						console.error(error);
					}
				}
				onSelected(target, company);
			}
		}
	}, [onSelected]);

	const handleContactAdded = async (contact: ContactData) => {
		try {
			const contactView = await api.contact.getView(contact.ContactID);
			handleContactSelection(contactView);
		} catch (error) {
			console.error(error);
		}
		setShowAddContactDialog(false);
	};

	return (
		<>
			<SelectionCard
				style={style}
				title={title}
				mode={!!contact ? 'render' : 'selection'}
				onClearSelection={() => {
					handleContactSelection(undefined);
					setCompanyName('');
				}}
				onOpenSelection={handleOpenInNew}
				actions={actions}
				disabled={disabled ? 'Disabled' : undefined}
				render={<ContactListItem contact={contact} />}
				selection={!contact && 
					<FlexColumn style={{ padding: '8px' }}>
						{!companyId && <CompanyNamesAutoComplete
							label={companyTitle || 'Company Name Search'}
							onChange={name => setCompanyName(name || '')}
							typeIds={companyTypeIds}
							disabled={disabled}
						/>}
						<ContactAutoComplete
							label="Contact"
							onChange={handleContactSelection}
							companyNameSearch={companyName || undefined}
							companyId={companyId}
							typeIds={contactTypeIds}
							onAddClick={() => setShowAddContactDialog(true)}
							error={error}
							disabled={disabled}
						/>
					</FlexColumn>
				}
			/>
			{showAddContactDialog && (
				<AddContactDialog
					onClose={() => {
						setShowAddContactDialog(false);
					}}
					onContactAdded={handleContactAdded}
					defaults={{
						ContactTypeID: contactTypeIds && contactTypeIds.length > 0 ? contactTypeIds[0] : undefined,
						CurrentCompanyID: companyId,
					}}
				/>
			)}
		</>

	);
}
