import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

// Monorepo
import { CompanyData, LookupTypes } from '../../core';

// Lib
import { AutoCompleteLookup, Chip } from '../common';
import { useTheme } from '@mui/material';

const flags = [
	{ key: 'isActive', label: 'Active' },
	{ key: 'isManagedRepairProgram', label: 'Managed Repair Program' },
];

export type CompanyFormProps = {
	company: Partial<CompanyData>;
	onChange: (attr: string, value: string | number | boolean | null) => void;
	style?: React.CSSProperties;
	className?: string;
};

export const CompanyForm = (props: CompanyFormProps) => {
	const theme = useTheme();
	const { company, onChange, className, style = {} } = props;

	return (
		<Grid
			container
			direction="column"
			spacing={1}
			className={className}
			style={style}
		>
			<Grid item>
				<Grid container spacing={1} direction="row" wrap="wrap">
					<Grid item style={{ flexGrow: 1, marginBottom: theme.spacing(1) }}>
						<TextField
							label="Company Name"
							aria-label="Company Name"
							title="Company Name"
							value={company?.CompanyName || ''}
							onChange={(e) =>
								onChange('CompanyName', e.target.value || null)
							}
							variant="outlined"
							margin="dense"
							fullWidth
							required
							error={!company?.CompanyName}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1, minWidth: '250px' }}>
						<AutoCompleteLookup
							label="Company Type"
							placeholder="Company Type"
							lookupType={LookupTypes.CompanyType}
							value={company?.CompanyTypeID || null}
							onChange={(value) =>
								onChange('CompanyTypeID', value || null)
							}
							required
							error={!company?.CompanyTypeID}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={1} direction="row" wrap="wrap">
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							label="Office Name"
							aria-label="Office Name"
							title="Office Name"
							value={company?.OfficeName || ''}
							onChange={(e) =>
								onChange('OfficeName', e.target.value || null)
							}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<TextField
							label="Website"
							aria-label="Website"
							title="Website"
							value={company?.Website || ''}
							onChange={(e) =>
								onChange('Website', e.target.value || null)
							}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					wrap="wrap"
					justifyContent="center"
					alignItems="center"
				>
					{flags.map((flag) => (
						<Grid item key={flag.key}>
							<Chip
								label={flag.label}
								selected={(company as any)[flag.key] === true}
								onClick={() =>
									onChange(
										flag.key,
										!(company as any)[flag.key]
									)
								}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};
