import React from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mdi/react';
import { mdiCardAccountMailOutline } from '@mdi/js';
import useContact from "./useContact";
import { useRecentItems } from "../recent";
import { useNavigate } from 'react-router-dom';

type Props = {
	label?: string;
	contactId?: number;
	className?: string;
	style?: React.CSSProperties;

};

export const ContactCard = ({ label, contactId, className, style }: Props) => {
	const contact = useContact(contactId);
	const { addRecentContact } = useRecentItems();
	const navigate = useNavigate();

	const handleOpenContact = () => {
		if (contact) {
			addRecentContact({
				contactType: 'Contact',
				id: contact.ContactID,
				name: contact.fullname,
				lookupType: contact.ContactType,
			});
			navigate(`/contacts/${contact.ContactID}`);
		}
	};

	return (
		<TextField
			fullWidth
			aria-label={label}
			label={label}
			title={label}
			value={contact?.fullname || ''}
			onChange={() => {}}
			onFocus={(e) => e.target.select()}
			variant="outlined"
			margin="dense"
			className={className}
			style={style}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Tooltip title="Open Contact">
							<span>
								<IconButton
									aria-label="Open Contact"
									onClick={handleOpenContact}
									size="small"
								>
									<Icon
										path={mdiCardAccountMailOutline}
										size={1}
									/>
								</IconButton>
							</span>
						</Tooltip>
					</InputAdornment>
				),
			}}
		/>
	);
};
