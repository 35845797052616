import { mdiChevronLeft, mdiChevronRight, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useKey } from 'react-use';
import { useWindowOpen } from '../useWindowOpen';
import { FileThumbnail } from './FileThumbnail';
import { storage } from './StorageProvider';
import { S3Object } from './types';

type GalleryProps = {
	files: S3Object[];
	defaultIndex: number;
	onClose: () => void;
	readOnly?: boolean;
	canDelete?: boolean;
};

export const Gallery = ({
	files,
	defaultIndex,
	onClose,
	readOnly,
	canDelete,
}: GalleryProps) => {
	const theme = useTheme();
	const open = useWindowOpen();
	const [selectedIndex, setSelectedIndex] = React.useState(defaultIndex);

	const maxIndex = files.length - 1;

	const handleLeftClick = () => {
		if (selectedIndex > 0) {
			setSelectedIndex(selectedIndex - 1);
		} else if (selectedIndex === 0) {
			setSelectedIndex(maxIndex);
		}
	};

	const handleRightClick = () => {
		console.log('handleRightClick', selectedIndex, maxIndex);
		if (selectedIndex < maxIndex) {
			setSelectedIndex(selectedIndex + 1);
		} else if (selectedIndex === maxIndex) {
			setSelectedIndex(0);
		}
	};

	const handleOpenFile = async (file: S3Object) => {
		const url = await storage.getDownloadUrl(file.key);
		if (url) {
			open(url);
		}
	};

	useKey('ArrowLeft', handleLeftClick, {}, [selectedIndex]);
	useKey('ArrowRight', handleRightClick, {}, [selectedIndex]);

	if (!files || files.length === 0) return null;
	const selectedFile = files[selectedIndex];

	return (
		<Dialog fullScreen title="Gallery" open={true} onClose={onClose}>
			<AppBar position="relative">
				<Toolbar variant="dense">
					<span style={{ flexGrow: 1, fontWeight: 500 }}>
						Gallery
					</span>
					<IconButton
						size="small"
						edge="end"
						aria-label="Close"
						onClick={onClose}
					>
						<Icon path={mdiClose} size={1} color="#fff" />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent sx={{
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
				padding: 0,
				margin: 0,
			}}>
				<Box component="span" sx={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					padding: 0,
					margin: 0,
				}}>
					<Box
						component="span"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							position: 'relative',
							// width: '50px',
							cursor: 'pointer',
							opacity: 0.5,
							'&:hover': {
								backgroundColor: '#fff',
								opacity: 1,
							},
						}}
						role="button"
						onClick={handleLeftClick}
					>
						<Icon
							path={mdiChevronLeft}
							size={2}
							color={theme.palette.secondary.main}
						/>
					</Box>
					<Box component="span" sx={{
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						alignItems: 'center',
						justifyContent: 'center',
						'&:first-child': {
							width: '100%',
						},
					}}>
						{selectedFile && (
							<FileThumbnail
								readOnly={readOnly}
								canDelete={canDelete}
								key={selectedIndex}
								file={selectedFile}
								onFileOpen={handleOpenFile}
								mode="contain"
								thumbnail={false}
							/>
						)}
					</Box>
					<Box component="span"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							position: 'relative',
							// width: '50px',
							cursor: 'pointer',
							opacity: 0.5,
							'&:hover': {
								backgroundColor: '#fff',
								opacity: 1,
							},
						}}
						role="button"
						onClick={handleRightClick}
					>
						<Icon
							path={mdiChevronRight}
							size={2}
							color={theme.palette.secondary.main}
						/>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions
				sx={{
					overflowX: 'scroll',
					overflowY: 'hidden',
					justifyContent: 'unset',
				}}
				onWheel={(ev) => {
					const left = ev.currentTarget.scrollLeft;
					ev.currentTarget.scroll({
						left: left + ev.deltaY,
					});
				}}
			>
				{files.map((file, idx) => (
					<Box
						key={file.key}
						sx={{
							border: idx === selectedIndex ? `4px solid ${theme.palette.secondary.main}` : `4px solid transparent`,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							padding: '3px',
							'& div': {
								margin: 0,
							},
						}}
					>
						<FileThumbnail
							readOnly={readOnly}
							canDelete={canDelete}
							file={file}
							onFileOpen={() => setSelectedIndex(idx)}
							disableMenu
							disableInfo
							size={100}
							thumbnail={true}
						/>
					</Box>
				))}
			</DialogActions>
		</Dialog>
	);
};
