// Monorepo
import {
	JobAccountingData,
	tryParseFloat,
	formatDecimal,
} from '../core';

export type GrossProfit = {
	estimated: string;
	actual: string;
};

export const calculateGP = (data?: JobAccountingData): GrossProfit => {
	if (!data) return { estimated: '0', actual: '0' };
	const estimatedCost = tryParseFloat(data.EstimatedCost);
	const totalSaleAmount =
		tryParseFloat(data.FeeAmt) +
		tryParseFloat(data.OriginalTotal) +
		tryParseFloat(data.SupplementalTotal) +
		tryParseFloat(data.ChangeOrderTotal);
	const profit = totalSaleAmount - estimatedCost;
	const estimatedPercent = totalSaleAmount ? profit / totalSaleAmount : 0;

	const actualCost = totalSaleAmount - tryParseFloat(data.ActualCost);
	const actualPercent =
		estimatedCost && totalSaleAmount ? actualCost / totalSaleAmount : 0;

	return {
		estimated:
			`${formatDecimal(Math.round(estimatedPercent * 100))} %` || '0',
		actual: `${formatDecimal(Math.round(actualPercent * 100))} %` || '0',
	};
};

export const calculateTotal = (data?: JobAccountingData): string => {
	if (!data) return '0';
	const value =
		tryParseFloat(data.FeeAmt) +
		tryParseFloat(data.OriginalTotal) +
		tryParseFloat(data.SupplementalTotal) +
		tryParseFloat(data.ChangeOrderTotal) +
		tryParseFloat(data.SalesTaxTotal);
	return value ? formatDecimal(value) : '0';
};

export const calculateContractBalance = (data?: JobAccountingData): string => {
	if (!data) return '0';
	const value =
		tryParseFloat(data.FeeAmt) +
		tryParseFloat(data.OriginalTotal) +
		tryParseFloat(data.SupplementalTotal) +
		tryParseFloat(data.ChangeOrderTotal) +
		tryParseFloat(data.SalesTaxTotal) -
		tryParseFloat(data.CashReceipt);
	return value ? formatDecimal(value) : '0';
};

export const calculateBalance = (data?: JobAccountingData): string => {
	if (!data) return '0';
	if (!data.InvoiceAmount && !data.CashReceipt) return '0';
	const value =
		tryParseFloat(data.InvoiceAmount) - tryParseFloat(data.CashReceipt);
	return value ? formatDecimal(value) : formatDecimal(0);
};
