import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Avatar } from '../common';

const ExpirationTimeout = 60000 * 30;

const formatDateTime = (value: Date) => {
	const dt = new Date(value);
	return dt.toLocaleTimeString([], {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
};

type NoteListItemProps = {
	date: Date;
	content: string;
	type?: string | null | undefined;
	employeeNumber?: string | null | undefined;
	showCheckbox?: boolean;
	onToggleSelection?: () => void;
	onEditNote: () => void;
	selected?: boolean;
	className?: string;
	style?: React.CSSProperties;
};

export const NoteListItem = (props: NoteListItemProps) => {
	const theme = useTheme();
	const [canEditNote, setCanEditNote] = React.useState(false);
	const {
		date,
		content,
		type,
		employeeNumber,
		showCheckbox,
		onToggleSelection,
		onEditNote,
		selected = false,
	} = props;

	React.useEffect(() => {
		const i = setInterval(() => {
			const expirationDate = new Date(
				new Date(date).getTime() + ExpirationTimeout
			);
			const dt = new Date();
			const canEdit = dt <= expirationDate;
			setCanEditNote(canEdit);
		}, 1000);
		return () => clearInterval(i);
	}, [date]);

	return (
		<Grid
			container
			direction="column"
			sx={theme => ({
				display: 'flex',
				flexDirection: 'column',
				padding: theme.spacing(1, 1, 2, 1),
				borderBottom: '1px solid rgba(0,0,0,0.2)',
				'&:hover': {
					backgroundColor: '#f6f6f6',
					'& $metadata': {
						opacity: 1,
					},
				},
			})}
		>
			<Grid
				container
				direction="row"
				wrap="nowrap"
				alignItems="center"
				sx={theme => ({
					[theme.breakpoints.up('sm')]: {
						opacity: 0.5,
					},
					'&:hover': {
						opacity: 1,
					},
				})}
			>
				{showCheckbox && (
					<Grid item>
						<Checkbox
							edge="start"
							value={selected}
							checked={selected}
							onChange={onToggleSelection}
							color="primary"
						/>
					</Grid>
				)}
				<Grid item sx={{ flexGrow: 1 }}>
					<span style={{
						fontSize: theme.typography.caption.fontSize,
						color: 'rgba(0,0,0,0.8)',
					}}>{formatDateTime(date)}</span>
				</Grid>
				{type && (
					<Grid item style={{ marginRight: theme.spacing(1) }}>
						<span style={{
							fontSize: theme.typography.caption.fontSize,
							color: 'rgba(0,0,0,0.8)',
							fontWeight: 'bold',
						}}>{type}</span>
					</Grid>
				)}
				{canEditNote && (
					<Grid item style={{ minWidth: 84, textAlign: 'right' }}>
						<Tooltip title="Edit Note">
							<IconButton
								size="small"
								aria-label="Edit Note"
								onClick={onEditNote}
							>
								<Icon path={mdiPencil} size={0.8} />
							</IconButton>
						</Tooltip>
					</Grid>
				)}
				{employeeNumber && !canEditNote && (
					<Grid item>
						<Avatar
							small
							align="left"
							employeeNumber={employeeNumber}
						/>
					</Grid>
				)}
			</Grid>
			<Grid item sx={{
				fontSize: theme.typography.body2.fontSize,
				padding: theme.spacing(1, 0),
				color: '#000000',
			}}>
				<span>{content}</span>
			</Grid>
		</Grid>
	);
};
