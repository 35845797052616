import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { ReportData } from '../../core';
import { getReportUrl } from '../../lib/reports';
import { useWindowOpen } from '../useWindowOpen';

export const useOpenReport = () => {
	const open = useWindowOpen();
	const { api } = useAppContext();
	const [ip, setIp] = useState<string>();

	const loadIp = useCallback(async () => {
		try {
			const result = await api.health.getIp();
			setIp(result?.ip);
		} catch (error) {
			console.error(error);
		}
	}, [api.health]);

	useEffect(() => {
		if (!ip) {
			loadIp();
		}
	}, [ip, loadIp]);

	return async (report: ReportData, jobId?: any) => {
		const url = getReportUrl(report, jobId, ip);
		open(url);
		try {
			await api.reports.hit(report.path);
		} catch (error) {
			console.error(error);
		}
	};
};
