import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
	AutoCompleteLookup,
	ErrorMessage,
	FlexColumn,
	Loading,
} from '../../common';
import { Dialog } from '../../common/Dialog';
import { useAppContext } from '../../../contexts/AppContext';
import { LookupTypes } from '../../../core';

type Props = {
	jobId: number;
	onCancel: () => void;
	onJobKilled: () => void;
};

type State = {
	working: boolean;
	error?: any;
	isJobKilled?: boolean;
	killedDate: Date;
	reasonId?: number;
	description?: string;
};

export const KillJobDialog = ({ jobId, onCancel, onJobKilled }: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>({
		working: false,
		killedDate: new Date(),
	});

	const handleKillJob = async () => {
		if (state.reasonId && state.killedDate) {
			setState((s) => ({ ...s, working: true, error: undefined }));
			try {
				await api.job.kill({
					jobId,
					description: state.description || '',
					isJobKilled: true,
					killedDate: state.killedDate,
					reasonId: state.reasonId,
				});
				onJobKilled();
			} catch (error) {
				setState((s) => ({ ...s, working: false, error }));
			}
		}
	};

	const handleOnChange = (property: string, value: any) => {
		setState((s) => ({
			...s,
			[property]: value,
		}));
	};

	const isValid =
		!!state.reasonId && !!state.description && !!state.killedDate;

	return (
		<Dialog
			isOpen={true}
			title="Kill Job"
			onClose={onCancel}
			cancelAction={{
				text: 'Cancel',
				color: 'inherit',
				onClick: onCancel,
				isDisabled: state.working,
			}}
			positiveAction={{
				text: 'Kill Job',
				color: 'secondary',
				onClick: handleKillJob,
				isDisabled: !isValid || state.working || !!state.error,
			}}
		>
			<Box style={{ padding: '16px' }}>
				{state.working && <Loading />}
				<ErrorMessage error={state.error} />
				{!state.error && !state.working && (
					<FlexColumn>
						<AutoCompleteLookup
							lookupType={LookupTypes.KillReasonType}
							label="Kill Reason"
							placeholder="Kill Reason"
							required
							value={state.reasonId || null}
							error={!state.reasonId}
							onChange={(value) =>
								handleOnChange('reasonId', value || null)
							}
						/>
						<TextField
							label="Description"
							aria-label="Description"
							title="Description"
							value={state.description || ''}
							onChange={(e) =>
								handleOnChange(
									'description',
									e.target.value || ''
								)
							}
							variant="outlined"
							margin="dense"
							fullWidth
							required
							multiline
							minRows={5}
							error={!state.description}
							InputLabelProps={{ shrink: true }}
						/>
					</FlexColumn>
				)}
			</Box>
		</Dialog>
	);
};
