import { SearchResult } from '../core';

import { ApiClientInterface } from './client';

export default class SearchApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
	}

	public async query(
		query: string,
		scope: 'all' | 'open'
	): Promise<SearchResult[]> {
		const response = await this.client.get<SearchResult[]>(
			`/search?query=${query}&scope=${scope}`
		);
		return response || [];
	}
}
