import React from 'react';
import { ContactData } from '../../core';
import { mdiContentCopy, mdiPlus, mdiTrashCan } from '@mdi/js';
import { BottomSpace, ErrorMessage, Loading } from '../common';
import { Card } from '../common';
import { AddressForm } from '../addresses/AddressForm';
import { EmailAddressForm } from '../email/EmailAddressForm';
import { PhoneNumberForm } from '../phone/PhoneNumberForm';
import { ContactForm } from './ContactForm';
import { AddContactController } from './useAddContact';
import { Box } from '@mui/material';

type Props = {
	controller: AddContactController;
	onContactAdded: (contact: ContactData) => void;
};

export const AddContact = ({ controller, onContactAdded }: Props) => {
	const {
		data,
		contact,
		working,
		error,
		onContactChange,
		onJobsiteChange,
		onBillingChange,
		onEmailChange,
		onPhoneChange,
		resetError,
		onAddAnotherEmail,
		onAddAnotherPhone,
		onRemoveEmail,
		onRemovePhone,
	} = controller;

	React.useEffect(() => {
		if (contact) {
			onContactAdded(contact);
		}
	}, [contact, onContactAdded]);

	if (error) {
		return (
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				height: '100%',
				overflow: 'hidden',
				overflowY: 'auto',
			}}>
				<ErrorMessage error={error} onRetry={resetError} />
			</Box>
		)
	}

	if (working) {
		return (
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				height: '100%',
				overflow: 'hidden',
				overflowY: 'auto',
			}}>
				<Loading />
			</Box>
		);
	}

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			height: '100%',
			overflow: 'hidden',
			overflowY: 'auto',
		}}>
			<Card label="Contact Information" sx={theme => ({ margin: theme.spacing(2, 0) })}>
				<ContactForm
					contact={data.contact}
					onChanged={onContactChange}
				/>
			</Card>
			<Card
				label="Jobsite Address"
				sx={theme => ({ margin: theme.spacing(2, 0) })}
				actions={[
					{
						key: 'copybilling',
						label: 'Copy from billing',
						icon: mdiContentCopy,
						visible: !!data.billing.AddressLine1,
						onClick: () =>
							onJobsiteChange({
								...data.billing,
								AddressTypeID: data.jobsite.AddressTypeID,
							}),
					},
				]}
			>
				<AddressForm
					address={data.jobsite}
					onChanged={onJobsiteChange}
					autoComplete
				/>
			</Card>
			<Card
				label="Billing Address"
				sx={theme => ({ margin: theme.spacing(2, 0) })}
				actions={[
					{
						key: 'copybilling',
						label: 'Copy from Jobsite',
						icon: mdiContentCopy,
						visible: !!data.jobsite.AddressLine1,
						onClick: () =>
							onBillingChange({
								...data.jobsite,
								AddressTypeID: data.billing.AddressTypeID,
							}),
					},
				]}
			>
				<AddressForm
					address={data.billing}
					onChanged={onBillingChange}
					autoComplete
				/>
			</Card>

			{data.email.map((item, index) => (
				<Card
					key={`email_${index}`}
					label={`Email Address (${index + 1})`}
					sx={theme => ({ margin: theme.spacing(2, 0) })}
					actions={[
						{
							key: 'addEmail',
							icon: mdiPlus,
							label: 'Add Another Email',
							visible: index === 0,
							onClick: onAddAnotherEmail,
							iconSize: 0.75,
						},
						{
							key: 'removeEmail',
							icon: mdiTrashCan,
							label: 'Remove This Email',
							visible: index !== 0,
							onClick: () => onRemoveEmail(index),
							iconSize: 0.75,
						},
					]}
				>
					<EmailAddressForm
						emailAddress={item || {}}
						onChanged={(email) => onEmailChange(index, email)}
						required={false}
					/>
				</Card>
			))}

			{data.phone.map((item, index) => (
				<Card
					key={`phone_${index}`}
					label={`Phone Number (${index + 1})`}
					sx={theme => ({ margin: theme.spacing(2, 0) })}
					actions={[
						{
							key: 'addPhone',
							icon: mdiPlus,
							label: 'Add Another Phone',
							visible: index === 0,
							onClick: onAddAnotherPhone,
							iconSize: 0.75,
						},
						{
							key: 'removePhone',
							icon: mdiTrashCan,
							label: 'Remove This Phone Number',
							visible: index !== 0,
							onClick: () => onRemovePhone(index),
							iconSize: 0.75,
						},
					]}
				>
					<PhoneNumberForm
						required={false}
						phoneNumber={item || {}}
						onChanged={(phone) => onPhoneChange(index, phone)}
					/>
				</Card>
			))}

			<BottomSpace />
		</Box>
	);
};
