import { TextField, TextFieldProps } from "@mui/material";
import { NumericFormat } from 'react-number-format';

type Props = {
    label?: string;
	hint?: string;
	value: number | null | undefined;
	disabled?: boolean;
	readOnly?: boolean;
	error?: boolean;
	rows?: number;
	onEnter?: () => void;
	onChange: (value: number | null) => void;
}

export default function NumberField({
	label,
	hint,
	value,
	disabled = false,
	readOnly = false,
	error = false,
	rows,
	onEnter,
	onChange,
}: Props) {
    const materialUITextFieldProps: TextFieldProps = {
        label,
        variant: 'outlined',
        fullWidth: true,
        margin: 'dense',
        "aria-readonly": readOnly === true,
        tabIndex: -1,
        disabled: disabled === true,
        error: error === true,
        multiline: !!rows && rows > 0,
        minRows: !!rows ? 4 : undefined,
        maxRows: rows,
        InputLabelProps: {
            shrink: true,
        },
        onFocus: (e) => e.target.select(),
        onKeyDown: (e) => {
            if (e.key === 'Enter' && !!onEnter) {
                onEnter();
            }
        },
    };

    return (
        <NumericFormat
            value={value}
            customInput={TextField}
            thousandSeparator
            allowNegative={false}
            allowLeadingZeros={false}
            prefix="$"
            onValueChange={data => onChange(data.value ? parseFloat(data.value) : null)}
            {...(materialUITextFieldProps as any)}
        />
    )
    // return (
    //     <TextField
    //         label={label}
    //         fullWidth
    //         aria-readonly={readOnly === true}
    //         tabIndex={-1}
    //         disabled={disabled === true}
    //         error={error === true}
    //         multiline={!!rows && rows > 0}
    //         minRows={!!rows ? 4 : undefined}
    //         maxRows={rows}
    //         onFocus={(e) => e.target.select()}
    //         onKeyDown={(e) => {
    //             if (e.key === 'Enter' && !!onEnter) {
    //                 onEnter();
    //             }
    //         }}
    //         value={value}
    //         onChange={(e) => onChange(e.target.value || null)}
    //         InputProps={{
    //             inputComponent: NumberFormatCustom as any,
    //         }}
    //     />
    // )
}