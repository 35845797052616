import React from 'react';
import {
	StreetAddressData,
	isStreetAddressValid,
	isCompanyInformationValid,
	CompanyData,
} from '../../core';
import { useAppContext } from '../../contexts/AppContext';
import useClient from '../useClient';

export interface AddCompanyData {
	company: Partial<CompanyData>;
	address: Partial<StreetAddressData>;
}

export interface AddCompanyController {
	data: AddCompanyData;
	company: CompanyData | undefined;
	isCompanyValid: boolean;
	working: boolean;
	error?: any;
	onCompanyChange: (company: Partial<CompanyData>) => void;
	onAddressChange: (jobsite: Partial<StreetAddressData>) => void;
	resetError: () => void;
	resetData: () => void;
	createCompany: () => void;
}

const validate = (data: AddCompanyData): boolean => {
	if (!isStreetAddressValid(data.address)) return false;
	if (!isCompanyInformationValid(data.company)) return false;
	return true;
};

export const useAddCompany = (): AddCompanyController => {
	const { api } = useAppContext();

	const [addCompanyData, setAddCompanyData] = React.useState<AddCompanyData>({
		company: { isActive: true },
		address: { State: 'OH' },
	});

	const { data, execute, working, error, resetError } =
		useClient<CompanyData>({
			error: 'An error occured creating the company.  Please try again.',
		});

	const handleCreateCompany = () => {
		execute(() => api.company.add(addCompanyData));
	};

	const onCompanyChange = (company: Partial<CompanyData>) => {
		setAddCompanyData((s) => ({ ...s, company }));
	};
	const onAddressChange = (address: Partial<StreetAddressData>) => {
		setAddCompanyData((s) => ({ ...s, address }));
	};

	return {
		data: addCompanyData,
		company: data,
		working,
		error,
		resetError,
		onCompanyChange,
		onAddressChange,
		isCompanyValid: validate(addCompanyData),
		createCompany: () => handleCreateCompany(),
		resetData: () =>
			setAddCompanyData({
				company: { isActive: true },
				address: { State: 'OH' },
			}),
	};
};
