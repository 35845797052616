import {
	mdiDotsVertical,
	mdiDownloadCircleOutline,
	mdiFormTextbox,
	mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useIntersection } from 'react-use';
import { ThumbnailToken, formatDateTime } from '../../core';
import { useS3FileBrowserContext } from './S3FileBrowserContext';
import { storage } from './StorageProvider';
import { FileOpenedEvent, S3Object } from './types';
import { getFileIcon, isMediaFile } from './utils';

export type Props = {
	file: S3Object;
	onFileOpen?: FileOpenedEvent;
	size?: number;
	disableMenu?: boolean;
	disableInfo?: boolean;
	readOnly?: boolean;
	canDelete?: boolean;
	thumbnail: boolean;
	mode?: 'contain' | 'cover';
};

export const FileThumbnail = ({
	file,
	onFileOpen,
	size = 0,
	disableMenu,
	disableInfo,
	readOnly,
	canDelete,
	thumbnail,
	mode = 'cover',
}: Props) => {
	const intersectionRef = React.useRef<HTMLDivElement>(null);
	const anchorRef = React.useRef<HTMLButtonElement>(null);
	const [url, setUrl] = React.useState<string>();
	const [open, setOpen] = React.useState(false);

	const { onRemoveFile, onDownloadFile, onRenameFile } =
		useS3FileBrowserContext();

	const intersection = useIntersection(intersectionRef, {
		root: null,
		rootMargin: '0px',
		threshold: 1,
	});

	const maxWidth = size === 0 ? '100%' : `${size}px`;
	const height = size === 0 ? `${300}px` : `${size}px`;
	const isMedia = isMediaFile(file.key);

	const handleOpenFile = () => {
		if (onFileOpen) {
			onFileOpen(file);
		}
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: MouseEvent | TouchEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	React.useEffect(() => {
		if (isMedia && intersection && intersection.intersectionRatio >= 1) {
			if (!url) {
				storage.getDownloadUrl(file.key, thumbnail === true)
					.then(setUrl)
					.catch(console.error);
			}
		}
	}, [file, intersection, isMedia, url, thumbnail]);

	const style: React.CSSProperties = url
		? {
			backgroundImage: `url(${url})`,
			backgroundSize: mode,
		}
		: {};

	return (
		<Box
			ref={intersectionRef}
			sx={theme => ({
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				border: `solid 1px ${theme.palette.divider}`,
				marginLeft: theme.spacing(0.5),
				marginRight: theme.spacing(0.5),
				marginBottom: theme.spacing(1),
				'&:hover': {
					backgroundColor: theme.palette.action,
				},
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				width: maxWidth,
				minWidth: maxWidth,
				maxWidth: maxWidth,
				height,
				...style,
			})}
		>
			<Box sx={theme => ({
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'nowrap',
				alignItems: 'center',
				justifyContent: 'flex-end',
				marginTop: theme.spacing(0.5),
				paddingRight: theme.spacing(0.5),
			})}>
				{!disableMenu && (
					<>
						<Tooltip title={`Open for more options...`}>
							<IconButton
								ref={anchorRef}
								size="small"
								aria-controls={
									open ? 'split-button-menu' : undefined
								}
								aria-expanded={open ? 'true' : undefined}
								aria-label="Open for more options..."
								aria-haspopup="menu"
								onClick={handleToggle}
								style={{
									backgroundColor: '#ffffff',
									opacity: 0.6,
								}}
							>
								<Icon
									path={mdiDotsVertical}
									size={0.75}
									color="red"
								/>
							</IconButton>
						</Tooltip>
						<Popper
							open={open}
							anchorEl={anchorRef.current}
							role={undefined}
							transition
							disablePortal
							style={{
								zIndex: 99999,
							}}
						>
							{({ TransitionProps, placement }) => (
								<Grow
									{...TransitionProps}
									style={{
										transformOrigin:
											placement === 'bottom'
												? 'center top'
												: 'center bottom',
									}}
								>
									<Paper
										style={{
											zIndex: 99999,
										}}
									>
										<ClickAwayListener onClickAway={handleClose}>
											<MenuList id="split-button-menu">
												<MenuItem
													onClick={() =>
														onDownloadFile(file)
													}
												>
													<ListItemIcon
														style={{
															minWidth: '35px',
														}}
													>
														<Icon
															path={
																mdiDownloadCircleOutline
															}
															size={1}
														/>
													</ListItemIcon>
													Download
												</MenuItem>
												<MenuItem
													onClick={() =>
														onRenameFile(file)
													}
													disabled={readOnly}
												>
													<ListItemIcon
														style={{
															minWidth: '35px',
														}}
													>
														<Icon
															path={
																mdiFormTextbox
															}
															size={1}
														/>
													</ListItemIcon>
													Rename
												</MenuItem>
												<MenuItem
													onClick={() =>
														onRemoveFile(file)
													}
													disabled={
														readOnly || !canDelete
													}
												>
													<ListItemIcon
														style={{
															minWidth: '35px',
														}}
													>
														<Icon
															path={
																mdiTrashCanOutline
															}
															size={1}
														/>
													</ListItemIcon>
													Remove
												</MenuItem>
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					</>
				)}
			</Box>
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				cursor: 'pointer',
			}} onClick={handleOpenFile}>
				&nbsp;
			</Box>
			{!disableInfo && (
				<Box sx={theme => ({
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'nowrap',
					alignItems: 'center',
					padding: theme.spacing(0, 0.5),
					borderTop: `solid 1px ${theme.palette.divider}`,
					opacity: 0.6,
					backgroundColor: '#f6f6f6',
				})}>
					<ListItem
						disableGutters
						style={{ padding: 0, paddingLeft: '8px' }}
					>
						{!isMedia && (
							<ListItemIcon style={{ minWidth: '30px' }}>
								<Icon
									path={getFileIcon(file.key)}
									size={0.75}
								/>
							</ListItemIcon>
						)}
						<ListItemText
							primary={file.name.replaceAll(ThumbnailToken, '')}
							secondary={formatDateTime(file.lastModified)}
							style={{
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								minWidth: '80%',
								maxWidth: '80%',
							}}
							primaryTypographyProps={{
								style: {
									fontSize: '13px',
								},
							}}
							secondaryTypographyProps={{
								style: {
									fontSize: '11px',
								},
							}}
						/>
					</ListItem>
				</Box>
			)}
		</Box>
	);
};
