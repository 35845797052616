import { mdiChevronDown, mdiChevronUp, mdiPlusCircleOutline } from '@mdi/js';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import {
	ContactPhoneNumberViewData,
	PrimaryPhoneNumberData,
	validatePhoneNumber,
} from '../../core';
import { Card, ConfirmDialog, InfoDialog } from '../common';
import { AddPhoneNumberDialog } from '../phone/AddPhoneNumberDialog';
import { EditPhoneNumberDialog } from '../phone/EditPhoneNumberDialog';
import { useWindowOpen } from '../useWindowOpen';
import { PhoneNumberListItem } from './PhoneNumberListItem';
import { useTheme } from '@mui/material';

type ContactPhoneListProps = {
	contactId: number;
	canEdit?: boolean;
};

type DialogAction = {
	title: string;
	text: string;
	action: () => void;
};

export const ContactPhoneList = (props: ContactPhoneListProps) => {
	const theme = useTheme();
	const { api } = useAppContext();
	const { contactId, canEdit } = props;
	const [phoneNumbers, setPhoneNumbers] = useState<
		ContactPhoneNumberViewData[]
	>([]);
	const [confirm, setConfirm] = useState<DialogAction>();
	const [info, setInfo] = useState<DialogAction>();
	const [showAddPhoneNumber, setShowAddPhoneNumber] = useState(false);
	const [editPhoneNumber, setEditPhoneNumber] = useState<PrimaryPhoneNumberData>();
	const [count, setCount] = useState(1);
	const open = useWindowOpen();

	const loadPhoneNumbers = useCallback(() => {
		api.contact
			.queryPhoneNumbers(contactId)
			.then(setPhoneNumbers)
			.catch(console.error);
	}, [api.contact, contactId]);

	useEffect(() => {
		loadPhoneNumbers();
	}, [contactId, loadPhoneNumbers]);

	const handlePhoneNumberAdded = useCallback(
		(phone: ContactPhoneNumberViewData) => {
			setShowAddPhoneNumber(false);
			// setPhoneNumbers((s) => {
			// 	const ns = [...s];
			// 	ns.push(phone);
			// 	return orderBy(ns, ['isPrimary'], ['desc']);
			// });
			loadPhoneNumbers();
		},
		[loadPhoneNumbers]
	);

	const handleOnEdit = (data: PrimaryPhoneNumberData) => {
		setEditPhoneNumber(data);
	};

	const handleSavePhoneNumber = async (data: PrimaryPhoneNumberData) => {
		try {
			await api.contact.updatePhoneNumber(contactId, data);
			loadPhoneNumbers();
		} catch (error) {
			console.error(error);
		} finally {
			setEditPhoneNumber(undefined);
		}
	};

	const handleSetPrimary = useCallback(
		(data: ContactPhoneNumberViewData) => {
			if (!data.isPrimary) {
				setConfirm({
					title: 'Set Primary Phone Number',
					text: `Are you sure you want to set '${data.PhoneNumber}' as the primary phone number?`,
					action: () => {
						api.contact
							.setPrimaryPhoneNumber(contactId, data.PhoneID)
							.then(() => {
								setConfirm(undefined);
								loadPhoneNumbers();
							})
							.catch((error) => {
								setConfirm(undefined);
								console.error(error);
							});
					},
				});
			}
		},
		[api.contact, contactId, loadPhoneNumbers]
	);

	const handleOnRemove = useCallback(
		(data: ContactPhoneNumberViewData) => {
			if (!data.isPrimary) {
				setConfirm({
					title: 'Remove Phone Number?',
					text: `Are you sure you want to remove '${data.PhoneNumber}' from this contact?`,
					action: () => {
						api.contact
							.removePhoneNumber(contactId, data.PhoneID)
							.then(() => {
								setConfirm(undefined);
								loadPhoneNumbers();
							})
							.catch((error) => {
								setConfirm(undefined);
								console.error(error);
							});
					},
				});
			} else {
				setInfo({
					title: 'Cannot Remove the Primary Phone Number',
					text: 'Please set a different phone number as the primary before removing this one.',
					action: () => setInfo(undefined),
				});
			}
		},
		[api.contact, contactId, loadPhoneNumbers]
	);

	const handleOnOpen = (data: PrimaryPhoneNumberData) => {
		if (validatePhoneNumber(data.PhoneNumber)) {
			open(`tel:${data.PhoneNumber}`);
		} else {
			setInfo({
				title: 'Invalid',
				text: 'The phone number is invalid',
				action: () => setInfo(undefined),
			});
		}
	};

	return (
		<Card
			label={`Phone Numbers (${phoneNumbers.length})`}
			actions={[
				{
					key: 'addphonenumber',
					label: 'Add Phone Number',
					icon: mdiPlusCircleOutline,
					onClick: () => setShowAddPhoneNumber(true),
					visible: canEdit,
				},
				{
					key: 'collapse',
					label: 'Show Primary Only',
					icon: mdiChevronUp,
					onClick: () => setCount(1),
					visible: phoneNumbers.length > 1,
				},
				{
					key: 'expand',
					label: 'Expand All',
					icon: mdiChevronDown,
					onClick: () => setCount(phoneNumbers.length),
					visible: phoneNumbers.length > 1,
				},
			]}
			sx={theme => ({ margin: theme.spacing(1, 0, 0, 0) })}
		>
			{phoneNumbers.length === 0 && <p>None</p>}
			{phoneNumbers.slice(0, count).map((phone, index) => (
				<PhoneNumberListItem
					canEdit={canEdit}
					key={phone.PhoneID}
					phoneNumber={phone}
					onSetPrimary={(num) =>
						handleSetPrimary({ ...phone, ...num })
					}
					onRemove={(num) => handleOnRemove({ ...phone, ...num })}
					onEdit={handleOnEdit}
					onOpen={handleOnOpen}
					sx={{
						backgroundColor: index % 2 ? 'transparent' : 'rgba(0, 0, 0, 0.1)',
						padding: theme.spacing(1, 0.5),
					}}
				/>
			))}
			{!!confirm && (
				<ConfirmDialog
					isOpen={true}
					title={confirm.title}
					question={confirm.text}
					onCancel={() => setConfirm(undefined)}
					onConfirm={() => confirm.action()}
				/>
			)}
			{!!info && (
				<InfoDialog
					isOpen={true}
					title={info.title}
					text={info.text}
					onClose={() => info.action()}
				/>
			)}
			{showAddPhoneNumber && (
				<AddPhoneNumberDialog
					contactId={contactId}
					onPhoneNumberAdded={handlePhoneNumberAdded}
					onClose={() => setShowAddPhoneNumber(false)}
				/>
			)}
			{!!editPhoneNumber && (
				<EditPhoneNumberDialog
					isOpen={true}
					phoneNumber={editPhoneNumber}
					onCancel={() => setEditPhoneNumber(undefined)}
					onSaveChanges={handleSavePhoneNumber}
				/>
			)}
		</Card>
	);
};
