import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { JobSummary } from '../../core';
import { Container } from '../common';
import { JobListItem } from '../jobs';
import { useRecentItems } from '../recent';

type ContactJobListProps = {
	contactId: number;
	onCountChanged: (count: number) => void;
	show: boolean;
};

export const ContactJobList = (props: ContactJobListProps) => {
	const { api } = useAppContext();
	const navigate = useNavigate();
	const { addRecentJob } = useRecentItems();
	const { contactId, onCountChanged, show } = props;
	const [jobs, setJobs] = useState<JobSummary[]>([]);

	useEffect(() => {
		if (contactId) {
			api.contact.queryJobs(contactId).then(setJobs).catch(console.error);
		}
	}, [api.contact, contactId]);

	useEffect(() => {
		onCountChanged(jobs.length);
	}, [jobs.length, onCountChanged]);

	if (!show) return null;

	return (
		<Container component="ContactJobList" scrollable>
			{jobs.map((job) => (
				<JobListItem
					key={job.JobID}
					job={job}
					onClick={() => {
						addRecentJob({
							id: job.JobID,
							name: job.JobName,
							number: job.JobNumber,
							desc: job.DamageDesc,
						});
						navigate(`/jobs/${job.JobID}`);
					}}
				/>
			))}
		</Container>
	);
};
