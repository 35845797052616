import { Box } from '@mui/material';
import React from 'react';
import { TOOLBAR_HEIGHT } from '../constants';

type Props = {
	children: React.ReactNode;
};

export const AppBar = ({ children }: Props) => {
	return (
		<Box
			sx={theme => ({
				position: 'relative',
				display: 'flex',
				flexDirection: 'row',
				flexGrow: 1,
				height: TOOLBAR_HEIGHT,
				width: '100%',
				overflow: 'hidden',
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				zIndex: 1201,
				boxShadow:
					'0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
				padding: theme.spacing(0, 1),
			})}
		>
			{children}
		</Box>
	);
};
