import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { QueryResult } from '../useQuery';

export interface QueryPageProps<T> {
    queryResult: QueryResult<T>
}

export const QueryPage = <T extends unknown>(props: QueryPageProps<T>) => {
    const theme = useTheme();
    const { queryResult: { count, limit, offset, next, previous } } = props;
    const pages = Math.ceil(count / limit);
    const current = (offset / limit) + 1;
    const upper = offset + limit;

    if (count <= 0 || pages <= 1) return null;

    return (
        <Box
            data-component="query-page"
            sx={theme => ({
                display: 'flex',
                flexFlow: 'row nowrap',
                flexShrink: 0,
                width: '100%',
                padding: theme.spacing(0.5, 1),
                alignItems: 'center',
            })}
        >
            <Tooltip title="Move Previous">
                <span>
                    <IconButton size="small" aria-label="Previous" onClick={previous} disabled={current <= 1}>
                        <Icon path={mdiChevronLeft} size={1} style={{ color: theme.palette.secondary.main }} />
                    </IconButton>
                </span>
            </Tooltip>
            <span style={{
                fontSize: theme.typography.caption.fontSize,
                flexGrow: 1,
                textAlign: 'center',
                color: theme.palette.secondary.main,
            }}>{offset + 1} - {upper > count ? count : upper} of {count}</span>
            <Tooltip title="Move Next">
                <span>
                    <IconButton size="small" aria-label="Next" onClick={next} disabled={current === pages}>
                        <Icon path={mdiChevronRight} size={1} style={{ color: theme.palette.secondary.main }} />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    );
}